import React, { useState, useEffect } from "react";
import "../../App.css";
import { useAuth0 } from "../../react-auth0-spa";
import { RadioGroup } from "devextreme-react";

import {
  Chart,
  Export,
  Label,
  Point,
  Tooltip,
  Series,
  ArgumentAxis,
  ZoomAndPan,
} from "devextreme-react/chart";

const camelCaseConverter = (text) => {
  if (text.length === 0) return "";
  var result = text.replace(".", "_").replace(/([A-Z])/g, " $1");
  return (result.charAt(0).toUpperCase() + result.slice(1)).trim();
};

const query = `select lane, sample, perfectMatch / 1000 perfectMatch, mismatch / 1000 mismatch, (perfectMatch + mismatch) / 1000 as assignedCount, mismatch / (mismatch + perfectMatch + 1) as mismatchFraction, (mismatch + perfectMatch) / (ARL.PFCount + 1) * 100 as assignedPercent from AnalysisResult AR 
inner join ARLane ARL on ARL.analysisResultID = AR.id
inner join SampleAssignedCount SAC on SAC.arLaneID = ARL.id 
where 
AR.id = %
order by sample`;

const options = [
  { field: "perfectMatch", label: "Perfect Match (k)" },
  { field: "mismatch", label: "Mismatch (k)" },
  { field: "assignedCount", label: "Assigned (k)" },
  { field: "mismatchFraction", label: "Mismatch Fraction" },
  { field: "assignedPercent", label: "Assigned (%)" },
];

const processResults = (txt) => {
  var outarray = [];
  var fields = [];

  if (txt.length > 0) {
    const lines = txt.split("\n");
    if (lines.length > 1) {
      fields = lines[0].split(",").map((v) => {
        return { name: v, label: camelCaseConverter(v), fieldType: "number" };
      });
      var i;
      var lineNum, tmp;
      for (lineNum = 1; lineNum < lines.length; lineNum++) {
        const line = lines[lineNum];
        if (line.length === 0) continue;
        const els = line.split(",");
        if (els.length === fields.length) {
          for (i = 0; i < els.length; i++) {
            tmp = els[i];
            if (isNaN(tmp) && fields[i].fieldType !== "string") {
              if (isNaN(Date.parse(tmp))) fields[i].fieldType = "string";
              else fields[i].fieldType = "date";
            }
          }
        }
      }
      for (lineNum = 1; lineNum < lines.length; lineNum++) {
        const line = lines[lineNum];
        if (line.length === 0) continue;
        var outobj = { idx: lineNum };
        const els = line.split(",");
        if (els.length === fields.length) {
          for (i = 0; i < els.length; i++) {
            tmp = els[i];
            if (fields[i].fieldType === "number") {
              if (tmp === "-999") tmp = NaN;
              else tmp = parseFloat(parseFloat(tmp).toPrecision(4));
            } else if (fields[i].fieldType === "date") {
              //tmp = Date.parse(tmp)
            }
            outobj[fields[i].name] = tmp;
          }
          outarray.push(outobj);
        }
      }
    }
  }

  return outarray;
};

export const BarChart = (props) => {
  const { loading, fetchNoJson } = useAuth0();
  const [data, setData] = useState([]);
  const [currentField, setCurrentField] = useState(options[0].field);

  useEffect(() => {
    const callAPI = async () => {
      const d = await fetchNoJson("/report/generalQuery", {
        method: "POST",
        body: JSON.stringify({
          QueryString: query.replace("%", props.id.toString()),
          Filename: "file.csv",
        }),
      }).then((txt) => processResults(txt));
      setData(d);
    };
    if (!loading) callAPI();
  }, [loading, fetchNoJson, props.id]);

  const renderTooltip = (pointInfo) => {
    if (pointInfo && pointInfo.point && pointInfo.point.data) {
      const data = pointInfo.point.data;
      let keys = Object.keys(data);

      let items = keys.map((x, i) => (
        <div key={i} style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>{x}</div>
          <div style={{ flex: 1 }}>{data[x]}</div>
        </div>
      ));
      return <div>{items}</div>;
    }
    return <div />;
  };

  return (
    <React.Fragment>
      <RadioGroup
        valueExpr="field"
        displayExpr="label"
        items={options}
        defaultValue={options[0].field}
        layout="horizontal"
        onValueChanged={(e) => {
          setCurrentField(e.value);
        }}
      />
      <Chart
        dataSource={data}
        valueAxis={{
          title: options.filter((x) => x.field === currentField)[0].label,
        }}
        legend={{ visible: false }}
      >
        <ArgumentAxis argumentType="string" title="Sample">
          <Label rotationAngle={80} overlappingBehavior="rotate" />
        </ArgumentAxis>

        <Series
          name={options.filter((x) => x.field === currentField)[0].label}
          argumentField={"sample"}
          valueField={currentField}
          type={"bar"}
        >
          <Point size={5} />
        </Series>

        <Tooltip enabled={true} contentRender={renderTooltip} />
        <Export enabled={true} />

        <ZoomAndPan argumentAxis="both" valueAxis="both" />
      </Chart>
    </React.Fragment>
  );
};
