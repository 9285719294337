import React, { useEffect, useRef } from "react";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";
import { Link } from "react-router-dom";
import Button from "devextreme-react/button";
import CustomStore from "devextreme/data/custom_store";

//import SelectBox from 'devextreme-react/select-box';
//import { StatusImages } from "./StatusImages"
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  Paging,
  Pager,
  GroupPanel,
  GroupItem,
  Summary,
  StateStoring,
  Export,
  //   RequiredRule
} from "devextreme-react/data-grid";
import SelectBox from "devextreme-react/select-box";

const query = `
select SR.id, runName 'runName', disposition, dispositionComment, I.name 'instrument', side, dateStarted, SR.analysisVersion, numTiles, SR.comment,
IFNULL(L.name, 'N/A') 'library', IFNULL(F.libraryConcentrationL1, -999) 'libraryConcentration',
numCyclesR1, numCyclesR2, numCyclesI1, numCyclesI2, 
IFNULL(ratioR2R1,-999) ratioR2R1,
RCS.status 'runCompleteStatus', REM.errorMode 'errorMode', 
IFNULL(AVG(LRS_R1.percentQ30) * 100,-999) 'percentQ30R1', IFNULL(AVG(LRS_R2.percentQ30) * 100, -999) 'percentQ30R2',
IFNULL(AVG(LRS_R1.errorRateMean) * 100,-999) 'percentErrorR1', IFNULL(AVG(LRS_R2.errorRateMean) * 100, -999) 'percentErrorR2', IFNULL(SUM(ARL.pfCount) * 424 / SR.numTiles / 1000 / 1000 / COUNT(ART.id) * 2, -999)  'extrapolatedReads',
assignedPercentage 'indexAssignedPercentage', IFNULL(SUM(ARTR1.errorRate>0.005), -999) 'badTilesR1', IFNULL(SUM(ARTR2.errorRate>0.0125), -999) 'badTilesR2'
    from SequencingRun SR
    inner join RunCompleteStatus RCS on RCS.id = runCompleteStatusID
    inner join RunErrorModes REM on REM.id = SR.errorMode
    inner join
      (select SR.id from SequencingRun SR
        inner join RunTag RT on RT.runID = SR.id
        inner join Tag T on T.id = RT.tagID and T.name = 'Quality Tracking'
        where deleted = 0)SR2 on SR.id = SR2.id
    left join AnalysisResult AR on AR.sequencingRunID = SR.id and AR.analysisVersion = SR.analysisVersion
		left JOIN ARLane as ARL ON ARL.analysisResultID = AR.id
		LEFT JOIN LaneReadStats LRS_R1 ON LRS_R1.arLaneID = ARL.id AND LRS_R1.read = "R1"
		LEFT JOIN LaneReadStats LRS_R2 ON LRS_R2.arLaneID = ARL.id AND LRS_R2.read = "R2"
    inner join Instrument I on SR.instrumentID = I.id
    left join Flowcell F on SR.flowcellID = F.id
    left join Library L on F.l1LibraryID = L.id
	left join ARTile ART on ART.arLaneID = ARL.id
	left join ARTileRead ARTR1 on ARTR1.arTileID = ART.id and ARTR1.read = 'R1'
	left join ARTileRead ARTR2 on ARTR2.arTileID = ART.id and ARTR2.read = 'R2'
    left join (
		-- this query returns the assigned rate for each run (two columns, id and assignedPercentage)
		select N.id, IFNULL(100 * assigned / pfCount, -999) 'assignedPercentage' from 
		  -- subquery sums up the perfect match and mismatch across both lanes
		(select SR.id, SUM(SAC.perfectMatch + SAC.mismatch) 'assigned' from SequencingRun SR
			inner join (select SR.id from SequencingRun SR
				inner join RunTag RT on RT.runID = SR.id
				inner join Tag T on T.id = RT.tagID and T.name = 'Quality Tracking'
				where deleted = 0)SR2 on SR2.id = SR.id
			inner join AnalysisResult AR on AR.sequencingRunID = SR.id and AR.analysisVersion = SR.analysisVersion
			INNER JOIN ARLane as ARL ON ARL.analysisResultID = AR.id
			left join SampleAssignedCount SAC on SAC.arLaneID = ARL.id
			group by AR.id) M
		inner join 
		-- subquery sums up the pfCount for both lanes... is there a better way to do this?
		(select SR.id, SUM(ARL.pfCount) 'pfCount' from SequencingRun SR
			inner join (select SR.id from SequencingRun SR
				inner join RunTag RT on RT.runID = SR.id
				inner join Tag T on T.id = RT.tagID and T.name = 'Quality Tracking'
				where deleted = 0)SR2 on SR2.id = SR.id
			inner join AnalysisResult AR on AR.sequencingRunID = SR.id and AR.analysisVersion = SR.analysisVersion
			inner join ARLane as ARL ON ARL.analysisResultID = AR.id
			group by SR.runName) N
		on N.id = M.id
	)P on P.id = SR.id
    group by SR.id
    order by dateStarted desc
`;

const query2 = `select runName, runStatusID,  SRH.read, cycle, dateSent from SequencingRun SR
inner join SequencingRunHistory SRH on SRH.sequencingRunID = SR.id
inner join
  (select SR.id from SequencingRun SR
    inner join RunTag RT on RT.runID = SR.id
    inner join Tag T on T.id = RT.tagID and T.name = 'Quality Tracking'
    where deleted = 0)SR2 on SR.id = SR2.id
where SRH.read = "R1" and cycle > 60 and cycle <= 62 and (runStatusID = 4 or runStatusID = 5)
order by runName, dateSent`;

const handleNaN = (e) => {
  if (isNaN(e.value)) return "";
  return e.valueText;
};

const camelCaseConverter = (text) => {
  if (text.length === 0) return "";
  var result = text.replace(".", "_").replace(/([A-Z])/g, " $1");
  return (result.charAt(0).toUpperCase() + result.slice(1)).trim();
};

// This will parse a delimited string into an array of
// strings. The default delimiter is the comma, but this
// can be overriden in the second argument.
const CSVToArray = (strData, strDelimiter) => {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = strDelimiter || ",";

  // Create a regular expression to parse the CSV values.
  var objPattern = new RegExp(
    // Delimiters.
    "(\\" +
      strDelimiter +
      "|\\r?\\n|\\r|^)" +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' +
      strDelimiter +
      "\\r\\n]*))",
    "gi"
  );

  // Create an array to hold our data. Give the array
  // a default empty first row.
  var arrData = [];

  // Create an array to hold our individual pattern
  // matching groups.
  var arrMatches = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while ((arrMatches = objPattern.exec(strData))) {
    // Get the delimiter that was found.
    var strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      // arrData.push( [] );
    }

    var strMatchedValue;

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];
    }

    // Now that we have our value string, let's add
    // it to the data array.
    arrData /*[ arrData.length - 1 ]*/
      .push(strMatchedValue);
  }

  // Return the parsed data.
  return arrData;
};

const processResults = (txt) => {
  var outarray = [];
  var fields = [];

  if (txt.length > 0) {
    const lines = txt.split("\n");
    if (lines.length > 1) {
      fields = CSVToArray(lines[0]).map((v) => {
        return { name: v, label: camelCaseConverter(v), fieldType: "number" };
      });
      var i;
      var lineNum, tmp;
      for (lineNum = 1; lineNum < lines.length; lineNum++) {
        const line = lines[lineNum];
        if (line.length === 0) continue;
        const els = CSVToArray(line);
        if (els.length === fields.length) {
          for (i = 0; i < els.length; i++) {
            tmp = els[i];
            if (tmp === "") fields[i].fieldType = "string";
            if (isNaN(tmp) && fields[i].fieldType !== "string") {
              if (isNaN(Date.parse(tmp))) fields[i].fieldType = "string";
              else fields[i].fieldType = "date";
            }
          }
        }
      }

      for (i = 0; i < fields.length; i++) {
        if (fields[i].name.startsWith("percent"))
          fields[i].fieldType = "number";
      }

      console.log("fields", fields);

      for (lineNum = 1; lineNum < lines.length; lineNum++) {
        const line = lines[lineNum];
        if (line.length === 0) continue;
        var outobj = { idx: lineNum };
        const els = CSVToArray(line);
        if (els.length === fields.length) {
          for (i = 0; i < els.length; i++) {
            tmp = els[i];
            if (fields[i].name !== "comment") {
              if (fields[i].fieldType === "number") {
                if (fields[i].name === "id") tmp = parseInt(tmp, 10);
                else if (tmp === "-999") tmp = NaN;
                else tmp = parseFloat(parseFloat(tmp).toPrecision(4));
              } else if (fields[i].fieldType === "date") {
                //tmp = Date.parse(tmp)
              }
            }
            outobj[fields[i].name] = tmp;
          }
          outarray.push(outobj);
        }
      }
    }
  }

  return outarray;
};

const dispositionOptions = [
  "Unresolved",
  "Fixed",
  "Addressed in Pilot",
  "No Action",
];

export const QualityTrackingDashboard = (props) => {
  const { fetchNoJson, fetchNoResponse } = useAuth0();
  const theGrid = useRef(null);

  const handleResize = React.useCallback(() => {
    if (theGrid) {
      theGrid.current.instance.option("height", window.innerHeight - 250);
    }
  }, [theGrid]);

  // useEffect(() => {
  //   const callAPI = async () => {
  //     setData(d);
  //     handleResize();
  //   };
  //   if (!loading) callAPI();
  // }, [loading, fetchNoJson, props.id, handleResize]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  var loadData = async () => {
    const getDate = (str) => {
      const s1 = str.substr(0, 10) + "T" + str.substr(11, 8);
      return new Date(s1);
    };
    const d = await fetchNoJson("/report/generalQuery", {
      method: "POST",
      body: JSON.stringify({
        QueryString: query,
        Filename: "file.csv",
      }),
    }).then((txt) => processResults(txt));

    const d2 = await fetchNoJson("/report/generalQuery", {
      method: "POST",
      body: JSON.stringify({
        QueryString: query2,
        Filename: "file.csv",
      }),
    }).then((txt) => processResults(txt));

    for (var i = 0; i < d.length; i++) {
      for (var j = 0; j < d2.length; j++) {
        if (d2[j].runName === d[i].runName) {
          const startImaging = getDate(d2[j].dateSent);
          const endImaging = getDate(d2[j + 1].dateSent);
          const endCycle = getDate(d2[j + 2].dateSent);
          d[i].imagingTime = parseFloat(
            (
              ((Math.abs(endImaging - startImaging) / 1000 / 60) * 424) /
              d[i].numTiles
            ).toFixed(2)
          );
          d[i].chemTime = parseFloat(
            (Math.abs(endCycle - endImaging) / 1000 / 60).toFixed(2)
          );
          break;
        }
      }
    }
    return d;
  };

  var updateData = async (key, values) => {
    console.log(key);
    console.log(JSON.stringify(values));
    await fetchNoResponse("/runs/" + key, {
      method: "PATCH",
      body: JSON.stringify(values),
    });
  };

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
    update: updateData,
  });

  const cellRenderRunName = (cell) => {
    if (!cell.value) {
      return <div></div>;
    }
    return (
      <Link to={"/runs/" + cell.value + "?v=" + cell.data.analysisVersion}>
        {cell.value}
      </Link>
    );
  };

  return (
    <div>
      <br />
      <h3>Quality Tracking Dashboard</h3>
      <Button
        onClick={() => {
          theGrid.current.instance.state({});
        }}
        text="Reset Table Formatting"
      />
      <br />
      <br />

      <DataGrid
        dataSource={customStore}
        ref={theGrid}
        wordWrapEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={"widget"}
        columnHidingEnabled={true}
        columnAutoWidth={true}
        columnFixing={{ enabled: true }}
        headerFilter={{ visible: true }}
        onCellPrepared={(e) => {
          if (e.rowType !== "data") return;

          if (
            e.column.name === "badTilesR1" ||
            e.column.name === "badTilesR2"
          ) {
            if (e.value > 10) e.cellElement.style.backgroundColor = "red";
            else if (e.value <= 10)
              e.cellElement.style.backgroundColor = "green";
            e.cellElement.style.color = "white";
          }

          if (e.column.name === "indexAssignedPercentage") {
            if (e.value < 95) e.cellElement.style.backgroundColor = "red";
            else if (e.value >= 95)
              e.cellElement.style.backgroundColor = "green";
            e.cellElement.style.color = "white";
          }

          if (
            e.column.name === "percentQ30R1" ||
            e.column.name === "percentQ30R2"
          ) {
            if (e.value < 85) e.cellElement.style.backgroundColor = "red";
            else if (e.value >= 85)
              e.cellElement.style.backgroundColor = "green";
            e.cellElement.style.color = "white";
          }
          if (
            e.column.name === "percentErrorR1" ||
            e.column.name === "percentErrorR2"
          ) {
            if (e.value < 1) e.cellElement.style.backgroundColor = "green";
            else if (e.value >= 1) e.cellElement.style.backgroundColor = "red";
            e.cellElement.style.color = "white";
          }
          if (e.column.name === "ratioR2R1") {
            if (e.value >= 1) e.cellElement.style.backgroundColor = "green";
            else if (e.value >= 0.8)
              e.cellElement.style.backgroundColor = "orange";
            else if (e.value < 0.8) e.cellElement.style.backgroundColor = "red";
            e.cellElement.style.color = "white";
          }
          if (e.column.name === "extrapolatedReads") {
            if (e.value >= 800) e.cellElement.style.backgroundColor = "green";
            else if (e.value < 800) e.cellElement.style.backgroundColor = "red";
            e.cellElement.style.color = "white";
          }

          if (e.column.name === "errorMode") {
            if (e.value === "No Error")
              e.cellElement.style.backgroundColor = "green";
            else e.cellElement.style.backgroundColor = "red";
            e.cellElement.style.color = "white";
          }

          if (e.column.name === "disposition") {
            if (e.value === "No Action") {
              // do nothing
            } else {
              if (e.value === "Fixed")
                e.cellElement.style.backgroundColor = "green";
              else if (e.value === "Unresolved")
                e.cellElement.style.backgroundColor = "red";
              else e.cellElement.style.backgroundColor = "orange";
              e.cellElement.style.color = "white";
            }
          }

          if (e.column.name === "runCompleteStatus") {
            if (e.value) {
              if (e.value === "Run Complete")
                e.cellElement.style.backgroundColor = "green";
              else if (e.value.startsWith("Run Complete"))
                e.cellElement.style.backgroundColor = "orange";
              else e.cellElement.style.backgroundColor = "red";
              e.cellElement.style.color = "white";
            }
          }

          if (e.column.name === "chemTime" || e.column.name === "imagingTime") {
            if (e.value <= 3.5) e.cellElement.style.backgroundColor = "green";
            else if (e.value <= 4.8)
              e.cellElement.style.backgroundColor = "orange";
            // else if (e.value.startsWith("Run Complete"))
            //   e.cellElement.style.backgroundColor = "orange";
            else if (e.value > 4.8) e.cellElement.style.backgroundColor = "red";
            e.cellElement.style.color = "white";
          }
        }}
      >
        <Editing
          mode="batch" //"form" "batch" "popup" "cell"
          allowUpdating={true}
          allowAdding={false}
          allowDeleting={false}
        />

        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="seqJulyMilestoneReliabilityv6"
        />

        <FilterRow visible={true} />

        <GroupPanel visible={true} />
        <Export
          enabled={true}
          fileName={"SequencingRuns"}
          allowExportSelectedData={true}
        />

        <ColumnChooser enabled={true} />

        <Column
          dataField={"runName"}
          cellRender={cellRenderRunName}
          fixed={true}
          fixedPosition="left"
          width={161}
          allowEditing={false}
        />
        <Column dataField={"instrument"} width={93} allowEditing={false} />
        <Column dataField={"side"} width={93} allowEditing={false} />
        <Column
          dataField={"dateStarted"}
          dataType={"date"}
          caption="Run Date"
          format={"dd MMM yyyy HH:mm"}
          allowEditing={false}
          width={125}
          visible={true}
        />

        <Column dataField={"library"} width={250} allowEditing={false} />
        <Column
          dataField={"libraryConcentration"}
          width={150}
          allowEditing={false}
        />

        <Column
          dataField={"numCyclesR1"}
          caption={"Cycles R1"}
          allowEditing={false}
          width={68}
        />
        <Column
          dataField={"numCyclesR2"}
          caption={"Cycles R2"}
          allowEditing={false}
          width={68}
        />
        <Column
          dataField={"numCyclesI1"}
          caption={"Cycles I1"}
          allowEditing={false}
          width={68}
        />
        <Column
          dataField={"numCyclesI2"}
          caption={"Cycles I2"}
          allowEditing={false}
          width={68}
        />
        <Column
          dataField={"ratioR2R1"}
          caption={"R2/R1"}
          allowEditing={false}
          width={100}
          customizeText={handleNaN}
        />
        <Column dataField={"numTiles"} width={68} allowEditing={false} />

        <Column dataField={"runCompleteStatus"} width={139} />
        <Column dataField={"errorMode"} width={139} />

        <Column dataField={"comment"} width={250} />

        <Column
          dataField="disposition"
          width={160}
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              dataSource={dispositionOptions}
              acceptCustomValue={true}
              searchEnabled={true}
              onCustomItemCreating={(e) => {
                e.customItem = e.text;
              }}
              onValueChanged={(e) => cell.setValue(e.value)}
              itemRender={(data) => <span>{data}</span>}
            />
          )}
        ></Column>

        <Column dataField={"dispositionComment"} width={250} />

        <Column
          dataField={"imagingTime"}
          caption="Imaging Cycle Time (Extrapolated)"
          width={100}
          allowEditing={false}
        />
        <Column
          dataField={"chemTime"}
          caption="Chemistry Cycle Time"
          width={100}
          allowEditing={false}
        />
        <Column
          dataField={"percentQ30R1"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        />
        <Column
          dataField={"percentQ30R2"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        />
        <Column
          dataField={"percentErrorR1"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        />
        <Column
          dataField={"percentErrorR2"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        />
        <Column
          dataField={"extrapolatedReads"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        />
        <Column
          dataField={"indexAssignedPercentage"}
          width={100}
          allowEditing={false}
        />
        <Column
          dataField={"badTilesR1"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        />
        <Column
          dataField={"badTilesR2"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        />

        <Summary>
          <GroupItem
            column={"title"}
            summaryType={"count"}
            displayFormat={"{0}"}
          />
        </Summary>

        <Paging defaultPageSize={100} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 500]} />
      </DataGrid>
    </div>
  );
};
