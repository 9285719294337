import React, { useState, useCallback, useEffect, useRef } from "react";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";
import Tabs from "devextreme-react/tabs";
import styled from "styled-components";

import "devextreme-react/text-area";
import "devextreme-react/html-editor";
import SelectBox from "devextreme-react/select-box";
import Button from "devextreme-react/button";

import DataGrid, {
  Column,
  ColumnChooser,
  Format,
  Paging,
  Pager,
  GroupPanel,
  GroupItem,
  Summary,
  SearchPanel,
  Export,
  StateStoring,
  HeaderFilter,
} from "devextreme-react/data-grid";

import { SingleChart } from "./Dashboard/SingleChart";

const Styles = styled.div`
  .left {
    margin-right: 1015px;
  }
  .left-wrapper {
    float: right;
    width: 100%;
  }
  .right {
    float: right;
    width: 1015px;
    margin-right: -100%;
  }
`;

const TileCycleTable = React.memo((props) => {
  const [state, setState] = useState(null);
  const id = props.id;
  const { loading, fetchWithCheck } = useAuth0();
  const theGrid = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (theGrid) {
        theGrid.current.instance.option("height", window.innerHeight - 300);
      }
    };
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    const callApi = async () => {
      const tmp = await fetchWithCheck("/analyses/" + id + "/cycle");
      if (tmp && tmp.length > 0) {
        for (var i = 0; i < tmp.length; i++) {
          tmp[i].id = i;
          for (var key of Object.keys(tmp[i])) {
            if (tmp[i][key] === -999) {
              tmp[i][key] = "";
            }
          }
        }

        var fields = [];
        for (var field of Object.keys(tmp[0])) {
          fields.push(field);
        }

        console.log(tmp);
        setState({ data: tmp, fields: fields });
      }
      if (theGrid && theGrid.current) {
        theGrid.current.instance.option("height", window.innerHeight - 300);
      }
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, id]);

  var columns = [];
  if (state) {
    columns = state.fields
      .filter((x) => x !== "id")
      .map((x) => (
        <Column dataField={x}>
          {" "}
          {x.includes("Rate") && <Format type="percent" precision={4} />}{" "}
        </Column>
      ));
  } else {
    return <div>loading...</div>;
  }

  return (
    <React.Fragment>
      <Button
        onClick={() => {
          theGrid.current.instance.state({});
        }}
        text="Reset Table Formatting"
      />
      <br />
      <br />

      <DataGrid
        ref={theGrid}
        dataSource={state ? state.data : []}
        keyExpr="id"
        wordWrapEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        focusedRowEnabled={false}
        allowColumnReordering={true}
        columnResizingMode={"widget"}
        columnHidingEnabled={true}
        columnAutoWidth={true}
        hoverStateEnabled={false}
        scrolling={{ showScrollbar: "always" }}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="cycleTable"
        />
        <SearchPanel visible={true} width={240} placeholder={"Search..."} />

        <GroupPanel visible={true} />
        <Export
          enabled={true}
          fileName={"CycleTable"}
          allowExportSelectedData={true}
        />
        <HeaderFilter visible={true} />

        <ColumnChooser enabled={true} />

        {columns}

        <Summary>
          <GroupItem
            column={"title"}
            summaryType={"count"}
            displayFormat={"{0}"}
          />
        </Summary>

        <Paging defaultPageSize={1000} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 1000]} />
      </DataGrid>
    </React.Fragment>
  );
});

const fields = [
  { name: "% Error Rate", value: "ErrorRate" },
  { name: "Z Score Green", value: "zScoreGreen" },
  { name: "Z Score Red", value: "zScoreRed" },
  // { name: "% A", value: "PercentA" },
  // { name: "% C", value: "PercentC" },
  // { name: "% G", value: "PercentG" },
  // { name: "% T", value: "PercentT" },
  { name: "Tx", value: "tx" },
  { name: "Ty", value: "ty" },
];

export const TileCycleContainer = (props) => {
  const tabs = ["Table", "Graph"];

  const id = props.id;
  const [currentTab, setCurrentTab] = useState(0);
  const [field, setField] = useState(fields[0]);
  //const [thumbnailID, setThunmbnailID] = useState(0)

  const onSelectedTabChange = useCallback(({ name, value }) => {
    if (name === "selectedIndex") {
      setCurrentTab(value);
    }
  }, []);

  const onFieldValueChanged = useCallback(async (v) => {
    var newval = fields.filter((t) => t.value === v.value)[0];
    setField(newval);
  }, []);

  return (
    <Styles>
      <Tabs
        dataSource={tabs}
        selectedIndex={currentTab}
        onOptionChanged={onSelectedTabChange}
        style={{ width: "400px" }}
        className={"widget"}
      />
      {currentTab === 1 && (
        <React.Fragment>
          <SelectBox
            value={field.value}
            valueExpr="value"
            displayExpr="name"
            placeholder="Select a value..."
            showClearButton={false}
            dataSource={fields}
            onValueChanged={onFieldValueChanged}
            style={{ width: "150px" }}
          />

          <SingleChart
            numTiles={5}
            id={id}
            field={"errorRate"}
            yLabel="%"
            title="Error Rate"
            multby100={true}
            runInfo={{ readOrder: "R1,I1,I2,R2" }}
          />
        </React.Fragment>
      )}

      {currentTab === 0 && (
        <React.Fragment>
          {/* <div className="left-wrapper">
                        <div className="left"> */}
          <TileCycleTable
            id={id}
            filter={props.filter}
            onFiltChanged={props.onFiltChanged}
          />
          {/* </div>
                    </div>
                    <div className="right">
                        <ImagePanel id={thumbnailID} />
                    </div> */}
        </React.Fragment>
      )}
    </Styles>
  );
};
