import React, { useState } from "react";
import { RadioGroup } from 'devextreme-react';
export const DashboardContainer = props => {
    
    const [display, setDisplay] = useState("Flowcell")

    var item
    if (display==="Tile") item = props.children[0]
    else {
        item = React.cloneElement(props.children[1], { byLane: display==="Lane" });
    }

    return (
        <div title={props.title}>
         <RadioGroup items={["Flowcell", "Lane", "Tile"]} value={display} layout="horizontal" onValueChanged={(e) => { setDisplay(e.value) }}/>
        {item}
        </div>
    )
}
