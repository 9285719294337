import React, { useEffect, useState } from "react";
import "../../App.css";
import { useAuth0 } from "../../react-auth0-spa";

import {
  Chart,
  CommonSeriesSettings,
  Export,
  SeriesTemplate,
  Legend,
  ValueAxis,
  Point,
  Tooltip,
  ValueErrorBar,
  ArgumentAxis,
  ZoomAndPan,
} from "devextreme-react/chart";
import { CheckBox } from "devextreme-react";

export const MultiQueryFieldChart = (props) => {
  const { loading, fetchWithCheck } = useAuth0();
  const [state, setState] = useState({ data: null });
  const [showErrorBars, setShowErrorBars] = useState(true);

  useEffect(() => {
    const callApi = async () => {
      var data = [];
      var i, j;
      var fields = props.fields.split(",");
      var labels = props.labels.split(",");
      var factor = 1;
      if (props.multby100) {
        factor = 100;
      }
      var lanestr = "";
      if (props.byLane) lanestr = "&groupBy=lane";
      for (i = 0; i < fields.length; i++) {
        var data2 = await fetchWithCheck(
          "/report/tileLevelStats?id=" +
            props.id +
            "&field=" +
            fields[i] +
            lanestr
        );

        console.log("data2", data2);

        const tmp = [...new Set(data2.map((x) => x.read))];
        const sortOrder = props.runInfo.readOrder.split(",");
        let distinctReads = [];
        for (j = 0; j < sortOrder.length; j++) {
          if (tmp.includes(sortOrder[j])) distinctReads.push(sortOrder[j]);
        }
        console.log("distinctReads", distinctReads);
        console.log("sort order", sortOrder);
        var maxCycleForRead = {};
        for (j = 0; j < distinctReads.length; j++) {
          maxCycleForRead[distinctReads[j]] = 0;
        }

        for (j = 0; j < data2.length; j++) {
          if (data2[j].cycle > maxCycleForRead[data2[j].read]) {
            maxCycleForRead[data2[j].read] = data2[j].cycle;
          }
        }

        var readCycleOffset = {};
        readCycleOffset[distinctReads[0]] = 0;
        var currentOffset = 0;
        for (j = 0; j < distinctReads.length; j++) {
          readCycleOffset[distinctReads[j]] = currentOffset;
          currentOffset += maxCycleForRead[distinctReads[j]];
        }
        var laneLabel = "";
        for (j = 0; j < data2.length; j++) {
          if (props.byLane) laneLabel = "_L" + data2[j].lane;
          data2[j].var = labels[i] + laneLabel + " (" + data2[j].read + ")";
          data2[j].cycle += readCycleOffset[data2[j].read];
          if (data2[j].median !== -999) {
            if (factor > 1) {
              data2[j].median = data2[j].median * 100;
              data2[j].p75 = data2[j].p75 * 100;
              data2[j].p25 = data2[j].p25 * 100;
            }
            data.push(data2[j]);
          }
        }
      }
      console.log(data);
      console.log(readCycleOffset);
      setState({ data: data, readCycleOffset: readCycleOffset });
    };

    if (!loading) {
      callApi();
    }
  }, [
    loading,
    fetchWithCheck,
    props.fields,
    props.labels,
    props.multby100,
    props.id,
    props.byLane,
    props.runInfo,
  ]);

  const sortLegendItems = (items) => {
    return items.sort((a, b) => {
      let itemA = a.text.toLowerCase();
      let itemB = b.text.toLowerCase();
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      return 0;
    });
  };

  const setSeriesColor = (val) => {
    const v = val.split(" ")[0];
    if (v === "Green" || v === "Red") return { color: v };
    if (props.colors) {
      var i;
      var labels = props.labels.split(",");
      var colors = props.colors.split(";");
      var dashstyle = props.dashstyle.split(",");
      for (i = 0; i < labels.length; i++) {
        if (labels[i] === v) {
          return {
            color: "rgba(" + colors[i] + ",1)",
            dashStyle: dashstyle[i],
          };
        }
      }
    }
    return {};
  };

  const renderTooltip = (pointInfo) => {
    if (pointInfo && pointInfo.point && pointInfo.point.data) {
      const data = pointInfo.point.data;
      const label1 = data.runID;
      const label1b = data.instrument;
      //const label2 = "Tile: " + /*data.flowcell +  " L" + data.lane + " " + */data.tile;
      const label3 = "Cycle: " + Math.floor(data.cycle);
      return (
        <div>
          <strong>{label1}</strong>
          <br />
          {label1b}
          <br />
          {label3}
          <br />
          {"Median: " + data.median.toFixed(2) + "%"}
          <br />
          {"Mean: " + data.mean.toFixed(2) + "%"}
          <br />
          {"Dev: " + data.dev.toFixed(2) + "%"}
        </div>
      );
    }
    return <div />;
  };

  return (
    <React.Fragment>
      <br />
      <CheckBox
        text="Show Error Bars"
        defaultValue={true}
        onValueChanged={(v) => {
          setShowErrorBars(v.value);
        }}
      />
      <br />
      <br />
      <em>
        Clicking on an item in the legend will show/hide the correspodning data
        series
      </em>
      <Chart
        height="500px"
        id="chart"
        title={props.title}
        dataSource={state.data}
        animation={{ enabled: false }}
        onLegendClick={(e) => {
          const series = e.target;
          if (series.isVisible()) {
            series.hide();
          } else {
            series.show();
          }
        }}
      >
        <CommonSeriesSettings
          argumentField="cycle"
          valueField="median"
          type="line"
        >
          <Point size={4} />
          <ValueErrorBar
            displayMode={showErrorBars ? "auto" : "none"}
            highValueField="p75"
            lowValueField="p25"
            color="gray"
            opacity={0.5}
          />
        </CommonSeriesSettings>

        <SeriesTemplate nameField="var" customizeSeries={setSeriesColor} />
        <Tooltip enabled={true} contentRender={renderTooltip} />
        <ValueAxis title={props.yLabel} />
        <ArgumentAxis title={"Cycle"} allowDecimals={true} />
        <Legend visible={true} customizeItems={sortLegendItems} />
        <Export enabled={true} />
        <ZoomAndPan argumentAxis="both" valueAxis="both" />
      </Chart>
    </React.Fragment>
  );
};
