import vars from "./variables";

import {
  paddingY,
  rowHeight,
  labelRowHeight,
  pxlsPerMinute,
  x0,
  calendarStart,
} from "./constants";

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const indexOfInstrument = (id) => {
  const val = vars.instruments.indexOf(
    vars.instruments.find((v) => v.id === id)
  );
  return val;
};

const getYForRun = (r) => {
  return (
    labelRowHeight +
    paddingY +
    5 +
    indexOfInstrument(r.instrumentID) * rowHeight +
    (r.side === "A" ? 0 : rowHeight / 2)
  );
};

const noOverlappingRuns = (run, instr, side, startDate) => {
  for (var i = 0; i < vars.runs.length; i++) {
    const r = vars.runs[i];
    if (r === run) continue;

    if (r.instrumentID === instr.id && r.side === side) {
      if (between(r.startDate, r.runLength, startDate, run.runLength)) {
        return false;
      }
    }
  }
  return true;
};

const dateToPxl = (d: Date) => {
  var diffInMinutes = (d.getTime() - calendarStart.getTime()) / 1000 / 60;
  return diffInMinutes * pxlsPerMinute + x0;
};

const pxlToDate = (p) => {
  const minutes = (p - x0) / pxlsPerMinute;
  const dt = new Date(calendarStart.getTime() + minutes * 60000);
  return dt;
};

const hoursToPxls = (h) => {
  return h * 60 * pxlsPerMinute;
};

const getInstrumentIndexForYPos = (y) => {
  return Math.floor((y - labelRowHeight - paddingY) / rowHeight);
};

const getSideForYPos = (y) => {
  return (y - labelRowHeight - paddingY) % rowHeight < rowHeight / 2
    ? "A"
    : "B";
};

const between = (v, vDuration, w, wDuration) => {
  const vStart = v.getTime();
  const wStart = w.getTime();
  const vEnd = vStart + vDuration * 1000 * 60 * 60;
  const wEnd = wStart + wDuration * 1000 * 60 * 60;
  return (
    (vStart > wStart && vStart < wEnd) ||
    (vEnd > wStart && vEnd < wEnd) ||
    (wStart > vStart && wStart < vEnd) ||
    (wEnd > vStart && wEnd < vEnd)
  );
};

const roundRect = (ctx, x, y, width, height, radius, fill, stroke) => {
  if (typeof stroke === "undefined") {
    stroke = true;
  }
  if (typeof radius === "undefined") {
    radius = 5;
  }
  if (typeof radius === "number") {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  } else {
    var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius.br,
    y + height
  );
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);

  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
};

export {
  addDays,
  indexOfInstrument,
  getYForRun,
  noOverlappingRuns,
  dateToPxl,
  pxlToDate,
  hoursToPxls,
  getInstrumentIndexForYPos,
  getSideForYPos,
  roundRect,
};
