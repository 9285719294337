// src/react-auth0-spa.js
import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
var jwtDecode = require("jwt-decode");

// const DEFAULT_REDIRECT_CALLBACK = () =>
//   window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        console.log("Redirect callback", window.location.search);
        const { appState } = await auth0FromHook.handleRedirectCallback();
        console.log(appState);
        //window.location.href = appState.targetUrl +  :
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        const token = await auth0FromHook.getTokenSilently();
        var decoded = jwtDecode(token);
        user.groups = decoded["https://elembio.io/user_groups"];
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  //export REACT_APP_API_URL="http://localhost/api"
  const fetchWithCheck = async (url, options) => {
    var baseurl = process.env.REACT_APP_API_URL;
    if (!baseurl || baseurl === "") {
      baseurl = "https://seqdb-backend.tools.elembio.io/api";
    }
    console.log(baseurl);
    try {
      const token = await auth0Client.getTokenSilently();
      const fullurl = baseurl + url;
      console.log(fullurl);
      const response = await fetch(fullurl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...options,
      })
      await _checkStatus(response);
      return await response.json();
    } catch (error) {
      if (error.response) {
        alert("Error " + error.response.status + " : " + error.mymessage);
      } else if (error.message) {
        alert("Error: " + JSON.stringify(error.message));
      } else {
        alert("Error " + error);
      }
    }
    return [];
  };

  const fetchNoCheck = async (url, options) => {
    var baseurl = process.env.REACT_APP_API_URL;
    if (!baseurl || baseurl === "") {
      baseurl = "https://seqdb-backend.tools.elembio.io/api";
    }
    console.log(baseurl);
    
      const token = await auth0Client.getTokenSilently();
      const fullurl = baseurl + url;
      console.log(fullurl);
      const response = await fetch(fullurl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...options,
      })
      await _checkStatus(response);
      return await response.json();
  };

  //export REACT_APP_API_URL="http://localhost/api"
  const fetchNoJson = async (url, options) => {
    var baseurl = process.env.REACT_APP_API_URL;
    if (!baseurl || baseurl === "") {
      baseurl = "https://elembio-cloud-seqdb-backend.elembio.io/api";
    }
    console.log(baseurl);
    try {
      const token = await auth0Client.getTokenSilently();
      const fullurl = baseurl + url;
      console.log(fullurl);
      const response = await fetch(fullurl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...options,
      })
      await _checkStatus(response);
      return await response.text();
    } catch (error) {
      if (error.response) {
        alert("Error " + error.response.status + " : " + error.mymessage);
      } else if (error.message) {
        alert("Error: " + JSON.stringify(error.message));
      } else {
        alert("Error " + error);
      }
    }
    return [];
  };

  const fetchBlob = async (url, options) => {
    var baseurl = process.env.REACT_APP_API_URL;
    if (!baseurl || baseurl === "") {
      baseurl = "https://elembio-cloud-seqdb-backend.elembio.io/api";
    }
    console.log(baseurl);
    try {
      const token = await auth0Client.getTokenSilently();
      const fullurl = baseurl + url;
      console.log(fullurl);
      const response = await fetch(fullurl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...options,
      })
      await _checkStatus(response);
      return response.blob();
    } catch (error) {
      if (error.response) {
        alert("Error " + error.response.status + " : " + error.mymessage);
      } else if (error.message) {
        alert("Error: " + JSON.stringify(error.message));
      } else {
        alert("Error " + error);
      }
    }
    return [];
  };

  const myFetch = async (url, options) => {
    var baseurl = process.env.REACT_APP_API_URL;
    if (!baseurl || baseurl === "") {
      baseurl = "https://elembio-cloud-seqdb-backend.elembio.io/api";
    }
    const token = await auth0Client.getTokenSilently();
    const fullurl = baseurl + url;
    return await fetch(fullurl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...options,
    }).then(_checkStatus);
  };

  const fetchNoResponse = async (url, options) => {
    var baseurl = process.env.REACT_APP_API_URL;
    if (!baseurl || baseurl === "") {
      baseurl = "https://elembio-cloud-seqdb-backend.elembio.io/api";
    }
    try {
      const token = await auth0Client.getTokenSilently();
      console.log(token);
      const fullurl = baseurl + url;
      console.log(fullurl);
      return await fetch(fullurl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...options,
      }).then(_checkStatus);
    } catch (error) {
      if (error.response) {
        alert("Error " + error.response.status + " : " + error.mymessage);
      } else if (error.message) {
        alert("Error: " + JSON.stringify(error.message));
      } else {
        alert("Error " + error);
      }
      return null;
    }
  };

  const _checkStatus = async (response) => {
    console.log("checking status", response.status)
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      console.log(response);
      return response;
    } else {
      var error;
      if (response.status === 401) {
        error = new Error("Your session has timed out");
        error.redirect = true;
      } else if (response.status === 403) {
        error = new Error("You are not authorized");
      }  else {
        var body = await response.text();
        try {
        var obj = JSON.parse(body);
        if (obj && obj.message) {
          error = new Error(obj.message);
          error.mymessage = obj.message;
        } else {
          error = new Error(body);
          error.mymessage = body;
        }
        } catch {
          error = new Error(`${body}`);
          error.mymessage = `${body}`;
        }
      }
      throw error;
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        fetchWithCheck,
        fetchNoCheck,
        fetchBlob,
        fetchNoResponse,
        myFetch,
        fetchNoJson,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
