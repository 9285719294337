import React, { useState, useEffect, useRef } from "react";

import {
  Chart,
  SeriesTemplate,
  ConstantLine,
  ValueAxis,
  Point,
  ArgumentAxis,
  Tooltip,
  Grid,
  Legend,
  Title,
  Subtitle,
  Export,
  Label,
  CommonSeriesSettings,
  Series,
  ZoomAndPan,
} from "devextreme-react/chart";

export const BeeSwarmChart = ({
  data,
  showZero,
  constantLine,
  xField,
  yField,
  groupField,
  title,
  subtitle,
  height,
  max,
  history,
}) => {
  const [distinctVals, setDistinctVals] = useState([]);
  const [beeswarmData, setBeeswarmData] = useState([]);
  const chart = useRef(null);

  useEffect(() => {
    var i, k;
    var dv = [];
    var bd = [];
    var xFieldname = xField ? xField.name : "";
    var yFieldname = yField ? yField.name : "";
    if (data.values && data.values.length > 0) {
      const nVerticalBins = 100;
      var minv = data.values[0][yFieldname];
      var maxv = minv;
      for (i = 0; i < data.values.length; i++) {
        let v = data.values[i][yFieldname];
        if (v < minv) minv = v;
        if (v > maxv) maxv = v;
      }
      if (max) maxv = max;
      let binheight = (maxv - minv) / nVerticalBins;

      for (i = 0; i < data.values.length; i++) {
        let copy = Object.assign({}, data.values[i]);
        var val = copy[xFieldname];

        var found = false;
        for (k = 0; k < dv.length; k++) {
          if (dv[k] === val) {
            copy.xValue = k;
            found = true;
            break;
          }
        }
        if (!found) {
          if (dv.length > 40) {
            copy.xValue = NaN;
          } else {
            copy.xValue = dv.length;
            dv.push(val);
          }
        }
        bd.push(copy);
      }

      if (dv.length > 0) {
        var cats = [];
        let binwidth = dv.length / 400;
        for (i = 0; i < dv.length; i++) {
          var binArray = [];
          for (k = 0; k < nVerticalBins; k++) binArray.push(0);
          cats.push(binArray);
        }
        let vals = bd;
        for (i = 0; i < vals.length; i++) {
          let v = vals[i];
          const y = v[yFieldname];
          const x = v.xValue;
          if (isNaN(x)) continue;
          var ybin = Math.floor((y - minv) / binheight);
          if (ybin < 0) ybin = 0;
          if (ybin >= nVerticalBins) ybin = nVerticalBins - 1;
          const bincnt = cats[x][ybin];
          const mag = Math.floor(bincnt / 2);
          const dir = ((bincnt % 2) - 0.5) * 2;
          var delta = mag * binwidth;
          if (delta > 0.4) delta = 0.4;

          v.xValue = v.xValue + delta * dir;
          v.yValue = minv + ybin * binheight;

          cats[x][ybin] = bincnt + 1;
        }
      }
    }
    setDistinctVals(dv);
    setBeeswarmData(bd);
    chart.current.instance.render();
  }, [data.values, xField, yField, max]);

  const sortLegendItems = (items) => {
    return items.sort((a, b) => {
      if (!isNaN(a.text) || !isNaN(b.text)) {
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
      }
      let itemA = a.text.toLowerCase();
      let itemB = b.text.toLowerCase();
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      return 0;
    });
  };

  const renderTooltip = (pointInfo) => {
    if (pointInfo && pointInfo.point && pointInfo.point.data) {
      const data = pointInfo.point.data;
      let keys = Object.keys(data);

      let items = keys.map((x, i) => (
        <div key={i} style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>{x}</div>
          <div style={{ flex: 1 }}>{data[x]}</div>
        </div>
      ));
      return <div>{items}</div>;
    }
    return <div />;
  };

  var xFieldlabel = xField ? xField.label : "";
  var yFieldlabel = yField ? yField.label : "";
  var groupFieldname = groupField ? groupField.name : "";

  return (
    <div>
      <Chart
        ref={chart}
        dataSource={beeswarmData}
        height={height ?? "auto"}
        onPointClick={(e) => {
          const point = e.target;
          const data = point.data;
          if (history && data && data.runID && data.analysisVersion)
            history.push("runs/" + data.runID + "?v=" + data.analysisVersion);
          console.log(point);
        }}
        onLegendClick={(e) => {
          const series = e.target;
          if (series.isVisible()) {
            series.hide();
          } else {
            series.show();
          }
        }}
      >
        {title && (
          <Title text={title}>{subtitle && <Subtitle text={subtitle} />}</Title>
        )}
        <ArgumentAxis
          argumentType={"numeric"}
          title={xFieldlabel}
          allowDecimals={true}
          tickInterval={1}
        >
          <Label
            rotationAngle={30}
            overlappingBehavior="rotate"
            customizeText={(e) => {
              return distinctVals[e.value];
            }}
          />
          <Grid visible={true} />
        </ArgumentAxis>
        <Tooltip enabled={true} />
        <ValueAxis
          argumentType={"numeric"}
          title={yFieldlabel}
          wholeRange={[0, max]}
          showZero={showZero}
        >
          <ConstantLine
            width={2}
            value={constantLine}
            color="orange"
            dashStyle="dash"
          >
            <Label text="Target" />
          </ConstantLine>
        </ValueAxis>
        {groupFieldname !== "" && (
          <CommonSeriesSettings
            name={"series"}
            argumentField={"xValue"}
            valueField={"yValue"}
            type={"scatter"}
          >
            <Point size={5} />
          </CommonSeriesSettings>
        )}
        {groupFieldname === "" && (
          <Series
            name={yField.name}
            argumentField={"xValue"}
            valueField={"yValue"}
            type={"scatter"}
          >
            <Point size={5} />
          </Series>
        )}
        {groupFieldname === "" && <Legend visible={false} />}
        {groupFieldname !== "" && <SeriesTemplate nameField={groupFieldname} />}
        {groupFieldname !== "" && <Legend customizeItems={sortLegendItems} />}
        <Tooltip
          enabled={true}
          //customizeTooltip={customizeTooltip}
          contentRender={renderTooltip}
        />
        <Export enabled={true} />

        <ZoomAndPan argumentAxis="both" valueAxis="both" />
      </Chart>
    </div>
  );
};
