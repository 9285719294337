import React /*, { useEffect, useState }*/ from "react";
//import { BeeSwarmChart } from "./components/Reports/BeeSwarmChart";
import { useAuth0 } from "./react-auth0-spa";
// import { RadioGroup } from "devextreme-react";
// import { NumberBox } from "devextreme-react/number-box";

// const camelCaseConverter = (text) => {
//   if (text.length === 0) return "";
//   var result = text.replace(".", "_").replace(/([A-Z])/g, " $1");
//   return (result.charAt(0).toUpperCase() + result.slice(1)).trim();
// };

export const Home = (props) => {
  const { user, isAuthenticated /*, loading, fetchNoJson*/ } = useAuth0();
  /*    const [data, setData] = useState({fields:[], values:[]})
    const [data2, setData2] = useState({fields:[], values:[]})
    const [colorBy, setColorBy] = useState("Instrument")
    const  [max, setMax] = useState(5)

    useEffect(() => {
        const processResults = (txt) => {
            var outarray = [];
            var fields = []
            
              if (txt.length > 0) {
                const lines = txt.split("\n")
                if (lines.length > 1) {
                  fields = lines[0].split(",").map(v => { return { name: v, label: camelCaseConverter(v) } })
                  var i;
                  var lineNum
                  for (lineNum = 1; lineNum < lines.length; lineNum++) {
                    const line = lines[lineNum];
                    if (line.length === 0) continue;
                    var outobj = { idx: lineNum };
                    const els = line.split(",")
                    if (els.length === fields.length) {
                      for (i = 0; i < els.length; i++) {
                        var tmp = els[i];
                        if (!isNaN(tmp)) tmp = parseFloat(parseFloat(tmp).toPrecision(4))
                        outobj[fields[i].name] = tmp;
                      }
                      outarray.push(outobj);
                    }
                  }
                }
              }
              
              console.log(outarray)
              return { fields: fields, values: outarray };
              
          }
        if (!loading && isAuthenticated) {

const query = `select dateStarted, tile, ART.row, col, lane, surface, runName runID, AR.analysisVersion, 
concat(I.name, ' ', runName, ' ', date_format(dateStarted,'%Y-%m-%d')) runName, I.name instrument, min(ARC.errorRateNC) * 100 errorPercent, ART.pfDensity / 1000 pfDensity, tag
from SequencingRun SR
inner join
  (select SR.id, T.name tag
      from SequencingRun SR
    inner join RunTag RT on RT.runID = SR.id
    inner join Tag T on T.id = RT.tagID and T.name like 'Baseline%'
    where deleted = 0
    order by dateStarted desc
    limit 30)SR2 on SR.id = SR2.id
inner join AnalysisResult AR on AR.sequencingRunID = SR.id and AR.analysisVersion = SR.analysisVersion
inner join ARLane ARL on ARL.analysisResultID = AR.id
inner join ARTile ART on ART.arLaneID = ARL.id 
inner join ARTileRead ARTR on ARTR.arTileID = ART.id
inner join ARCycle ARC on ARC.arTileReadID = ARTR.id 
inner join Instrument I on SR.instrumentID = I.id
where ARC.errorRateNC > 0 AND cycle>=65 AND cycle<70
group by ARTR.id
order by dateStarted
limit 999999`

const query2 = `select concat(I.name, ' ', runName, ' ', date_format(dateStarted,'%Y-%m-%d')) runName, tile, ARTR.read, dateStarted, I.name instrument, numCyclesR1, numCyclesR2, min(ARC.errorRateNC) * 100 as errorPercent from SequencingRun SR

inner join
  (select SR.id, T.name tag
      from SequencingRun SR
    inner join RunTag RT on RT.runID = SR.id
    inner join Tag T on T.id = RT.tagID and T.name like 'Paired End Baseline%'
    where deleted = 0
    order by dateStarted desc
    limit 50)SR2 on SR.id = SR2.id
        
inner join Instrument I on SR.instrumentID = I.id
inner join AnalysisResult AR on AR.sequencingRunID = SR.id and AR.analysisVersion = SR.analysisVersion
inner join ARLane ARL on ARL.analysisResultID = AR.id
inner join ARTile ART on ART.arLaneID = ARL.id
inner join ARTileRead ARTR on ARTR.arTileID = ART.id
inner join ARCycle ARC on ARC.arTileReadID = ARTR.id
where ARC.errorRateNC>0 and cycle>65  and cycle<71
group by ARTR.id
order by ARTR.read, dateStarted
limit 99999`

  fetchNoJson("/report/generalQuery", {
    method: "POST",
    body: JSON.stringify({ QueryString: query, Filename: "file.csv" })
  })
    .then(txt => setData(processResults(txt)) )

  fetchNoJson("/report/generalQuery", {
      method: "POST",
      body: JSON.stringify({ QueryString: query2, Filename: "file.csv" })
  })
      .then(txt => setData2(processResults(txt)) )
        }
    }, [fetchNoJson, loading, isAuthenticated])

    */
  return (
    <div>
      <br />
      {!isAuthenticated && (
        <strong> You are not logged in. Please log in. </strong>
      )}
      {isAuthenticated && (
        <div>
          <h3>{user ? "Hi " + user.given_name + "," : "Hi,"}</h3>
          <h3 style={{ marginTop: "10px" }}>
            {" "}
            Welcome to the Element Biosciences Sequencing Database
          </h3>
          <br />
          {/* <a
            href="https://elembio.atlassian.net/wiki/spaces/SOF/pages/891355172/SeqDB+-+Internal+Customer+Release+Notes"
            target="_blank"
            rel="noopener noreferrer"
          >
            SeqDB Release Notes
          </a>
          <br />
          <br />
          <br />
          <UtilizationChart /> */}
          {/* 
            <div style={{width:"300px"}}>
          <div className="dx-field">
            <div className="dx-field-label">Color by:</div>
            <div className="dx-field-value">
            <RadioGroup items={["Instrument", "Tag"]} defaultValue={colorBy} layout="horizontal" onValueChanged={(e) => { setColorBy(e.value) }}/>
            </div>
          </div>

          <div className="dx-field">
            <div className="dx-field-label">Max:</div>
            <div className="dx-field-value">
              <NumberBox
                value={max}
                onValueChanged={(e) => {setMax(e.value)}}
                showSpinButtons={false}
              />
            </div>
          </div>
          </div>

                    {data.values.length > 0 && <div>
                        <BeeSwarmChart 
                                data = {data}
                                height = {600}
                                title = "Most Recent Baseline Runs"
                                subtitle = "C65 Error by Tile"
                                xField = {{name: 'runName', label: 'Run'}}
                                yField = {{name: 'errorPercent', label: 'Error Percent'}}
                                groupField = {{name: colorBy.toLowerCase(), label: colorBy}}
                                tooltipField = {{name: 'tile', label: 'Tile'}}
                                max = {max}
                                history = {props.history}
                                constantLine = {0.5}
                                showZero = {true}
                            />

                        <BeeSwarmChart 
                                data = {data}
                                height = {600}
                                title = " "
                                subtitle = "PF Density by Tile"
                                xField = {{name: 'runName', label: 'Run'}}
                                yField = {{name: 'pfDensity', label: 'k/mm2'}}
                                groupField = {{name: colorBy.toLowerCase(), label: colorBy}}
                                tooltipField = {{name: 'tile', label: 'Tile'}}
                                max = {600}
                                constantLine = {400}
                                showZero = {true}
                            />
<br/>
            <div className="dx-field-item-help-text"> 
            Note what is actually being plotted is the minimum error percent for each tile between C65 and C70.  This will normally be the error at C65, unless there
            was a problem with that cycle.<br/><br/><br/>
            </div>

                        </div>
                    }


{data2.values.length > 0 && <div>
                        <BeeSwarmChart 
                                data = {data2}
                                height = {600}
                                title = "Recent Paired End Runs"
                                subtitle = "C65 Error by Tile"
                                xField = {{name: 'runName', label: 'Run'}}
                                yField = {{name: 'errorPercent', label: 'Error Percent'}}
                                groupField = {{name: 'read', label: 'Read'}}
                                tooltipField = {{name: 'tile', label: 'Tile'}}
                                max = {max}
                                constantLine = {0.5}
                                showZero = {true}
                            />
<br/>


                        </div>
                    }*/}
        </div>
      )}
    </div>
  );
};
