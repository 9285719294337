import React, { useState, useRef, useEffect } from "react";
import "../../App.css";
import { useAuth0 } from "../../react-auth0-spa";
import styled from "styled-components";

import { CheckBox } from "devextreme-react";
import {
  Chart,
  CommonSeriesSettings,
  Export,
  SeriesTemplate,
  Legend,
  ValueAxis,
  Point,
  Tooltip,
  ValueErrorBar,
  ArgumentAxis,
  ZoomAndPan,
} from "devextreme-react/chart";

const Styles = styled.div`
  .offset-title {
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
  }
`;
export const MultiFieldChart = (props) => {
  const { loading, fetchWithCheck } = useAuth0();
  const [showErrorBars, setShowErrorBars] = useState(true);
  const chart = useRef(null);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const callApi = async () => {
      var lanestr = "";
      if (props.byLane) lanestr = "&groupBy=lane";
      const data = await fetchWithCheck(
        "/report/channelLevelStats?id=" +
          props.id +
          "&field=" +
          props.field +
          lanestr
      );

      console.log(data);

      const tmp = [...new Set(data.map((x) => x.read))];
      const sortOrder = props.runInfo.readOrder.split(",");
      let distinctReads = [];
      for (var j = 0; j < sortOrder.length; j++) {
        if (tmp.includes(sortOrder[j])) distinctReads.push(sortOrder[j]);
      }
      console.log("distinctReads", distinctReads);
      var maxCycleForRead = {};

      for (j = 0; j < distinctReads.length; j++) {
        maxCycleForRead[distinctReads[j]] = 0;
      }

      for (j = 0; j < data.length; j++) {
        if (data[j].cycle > maxCycleForRead[data[j].read]) {
          maxCycleForRead[data[j].read] = data[j].cycle;
        }
      }

      var readCycleOffset = {};
      readCycleOffset[distinctReads[0]] = 0;
      var currentOffset = 0;
      for (j = 0; j < distinctReads.length; j++) {
        readCycleOffset[distinctReads[j]] = currentOffset;
        currentOffset += maxCycleForRead[distinctReads[j]];
      }

      for (var i = 0; i < data.length; i++) {
        if (props.byLane) lanestr = " L" + data[i].lane;
        data[i].cycle += readCycleOffset[data[i].read];
        data[i][props.groupField] =
          data[i][props.groupField] + lanestr + " (" + data[i].read + ")";
        let factor = 1;
        if (props.multby100) factor = 100;
        if (data[i].median > -999) {
          data[i].median = data[i].median * factor;

          if (data[i].p75 !== -999) {
            data[i].p75 = data[i].p75 * factor;
            data[i].p25 = data[i].p25 * factor;
          } else {
            data[i].p25 = NaN;
            data[i].p75 = NaN;
          }
        } else {
          data[i].p25 = data[i].p75 = data[i].median = NaN;
        }
      }

      setChartData(data);
    };

    if (!loading) {
      callApi();
    }
  }, [
    loading,
    fetchWithCheck,
    props.fields,
    props.labels,
    props.id,
    props.byLane,
    props.field,
    props.groupField,
    props.runInfo,
    props.multby100,
  ]);

  const sortLegendItems = (items) => {
    return items.sort((a, b) => {
      let itemA = a.text.toLowerCase();
      let itemB = b.text.toLowerCase();
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      return 0;
    });
  };

  const setSeriesColor = (val) => {
    console.log(val);
    if (val.startsWith("Ch 1")) return { color: "blue" };
    if (val.startsWith("Ch 2")) return { color: "red" };
    if (val.startsWith("Ch 3")) return { color: "brown" };
    if (val.startsWith("Ch 4")) return { color: "green" };
    return {};
  };

  const renderTooltip = (pointInfo) => {
    if (pointInfo && pointInfo.point && pointInfo.point.data) {
      const data = pointInfo.point.data;
      const label3 = "Cycle: " + Math.floor(data.cycle);
      var suffix = "";
      if (props.multby100) {
        suffix = "%";
      }
      var precision = 0;
      if (data.median < 0.1) {
        precision = 4;
      } else if (data.median < 1) {
        precision = 3;
      } else if (data.median < 10) {
        precision = 2;
      } else if (data.median < 100) {
        precision = 1;
      }
      return (
        <div>
          {label3}
          <br />
          {pointInfo.seriesName +
            ": " +
            data.median.toFixed(precision) +
            suffix}
        </div>
      );
    }
    return <div />;
  };

  return (
    <Styles>
      <br />
      <CheckBox
        text="Show Error Bars"
        defaultValue={true}
        onValueChanged={(v) => {
          setShowErrorBars(v.value);
        }}
      />
      <br />
      <br />
      <em>
        Clicking on an item in the legend will show/hide the correspodning data
        series
      </em>
      <Chart
        height="500px"
        id="chart"
        title={props.runInfo.name}
        ref={chart}
        dataSource={chartData}
        animation={{ enabled: false }}
        onLegendClick={(e) => {
          const series = e.target;
          if (series.isVisible()) {
            series.hide();
          } else {
            series.show();
          }
        }}
      >
        <CommonSeriesSettings
          aggregation={{ enabled: true, method: "avg" }}
          argumentField="cycle"
          valueField="median"
          type="line"
        >
          <Point size={4} />
          <ValueErrorBar
            displayMode={showErrorBars ? "auto" : "none"}
            highValueField="p75"
            lowValueField="p25"
            color="gray"
            opacity={0.5}
          />
        </CommonSeriesSettings>
        <SeriesTemplate
          nameField={props.groupField}
          customizeSeries={setSeriesColor}
        />
        <Tooltip enabled={true} contentRender={renderTooltip} />
        <ValueAxis title={props.yLabel} />
        <ArgumentAxis title={"Cycle"} allowDecimals={true} />
        <Legend visible={true} customizeItems={sortLegendItems} />
        <Export enabled={true} />
        <ZoomAndPan argumentAxis="both" valueAxis="both" />
      </Chart>
    </Styles>
  );
};
