/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @generated by protobuf-ts 2.0.2 with parameter client_none,server_none
// @generated from protobuf file "control_recipe.proto" (package "control", syntax proto3)
// tslint:disable
// ---------------------------------------------------------------------------------------------
// CUSTOM RECIPES ------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------

// Predefined Steps/Functions --------------------------

/**
 * Predefined contains an Element-provided library of variables, steps, and cycle types
 * which may be referenced by user-defined steps in a Recipe.
 *
 * @generated from protobuf message control.Predefined
 */
export interface Predefined {
  /**
   * @generated from protobuf field: string iD = 1;
   */
  ID: string; // optional globally-unique identifier for the predefined library
  /**
   * @generated from protobuf field: string version = 2;
   */
  Version: string; // optional version for the predefined library, versioned over the globally-unique identifier
  /**
   * @generated from protobuf field: string name = 3;
   */
  Name: string; // optional name for the predefined library
  /**
   * VariableGroups defines groupings/folders for variable definitions in a UI.
   *
   * @generated from protobuf field: repeated control.GroupDef variableGroups = 10;
   */
  VariableGroups: GroupDef[];
  /**
   * VariableDefinitions (edited in "Variables" UI tab) lists global user-defined/editable variables which may be referenced
   * as parameters to predefined steps.
   *
   * @generated from protobuf field: repeated control.VariableDef variableDefinitions = 11;
   */
  VariableDefinitions: VariableDef[];
  /**
   * StepGroups defines groupings/folders for step definitions in a UI.
   *
   * @generated from protobuf field: repeated control.GroupDef stepGroups = 20;
   */
  StepGroups: GroupDef[];
  /**
   * StepDefinitions (edited in "Steps" UI tab) are ultimately defined in terms of built-in/predefined steps (IDs) but may be composed.
   *
   * @generated from protobuf field: repeated control.StepDef stepDefinitions = 21;
   */
  StepDefinitions: StepDef[];
  /**
   * FunctionGroups defines groupings/folders for predefined function-steps in a UI.
   *
   * @generated from protobuf field: repeated control.GroupDef functionGroups = 30;
   */
  FunctionGroups: GroupDef[];
  /**
   * FunctionDefinitions provides predefined function-steps which may be reference by user-defined steps.
   *
   * @generated from protobuf field: repeated control.FunctionStepDef functionDefinitions = 31;
   */
  FunctionDefinitions: FunctionStepDef[];
}
/**
 * FunctionStepDef is a predefined step which calls a built-in Starlark function with optional parameters.
 *
 * @generated from protobuf message control.FunctionStepDef
 */
export interface FunctionStepDef {
  /**
   * @generated from protobuf field: uint32 iD = 1;
   */
  ID: number; // local identifier for the step definition, unique within the containing list of function-step definitions
  /**
   * @generated from protobuf field: uint32 groupID = 2;
   */
  GroupID: number; // optional group ID to display the definition within a UI folder
  /**
   * @generated from protobuf field: string globalID = 3;
   */
  GlobalID: string; // optional globally-unique ID for the definition to synchronize with remote libraries
  /**
   * @generated from protobuf field: string label = 4;
   */
  Label: string; // label for the step definition in a UI
  /**
   * @generated from protobuf field: string description = 5;
   */
  Description: string; // optional description for the step definition in a UI
  /**
   * @generated from protobuf field: string function = 6;
   */
  Function: string; // starlark function name
  /**
   * @generated from protobuf field: repeated control.StepOptionDef options = 7;
   */
  Options: StepOptionDef[]; // optional parameter names and types
}
/**
 * StepOptionDef defines a named parameter provided to a predefined step which calls a built-in Starlark function.
 *
 * @generated from protobuf message control.StepOptionDef
 */
export interface StepOptionDef {
  /**
   * @generated from protobuf field: control.DataType type = 1;
   */
  Type: DataType; // function parameter type
  /**
   * @generated from protobuf field: uint32 variableGroupID = 2;
   */
  VariableGroupID: number; // optionally restrict to a set (group) of variable definitions to select from
  /**
   * @generated from protobuf field: string name = 3;
   */
  Name: string; // function parameter name
  /**
   * @generated from protobuf field: string label = 4;
   */
  Label: string; // label for the parameter in a UI
  /**
   * @generated from protobuf field: string description = 5;
   */
  Description: string; // optional description for the parameter in a UI
}
// Main Recipe and Definitions --------------------------

/**
 * Recipe is a high-level intermediate representation for customizable recipes.
 *
 * The format can be created and edited from a UI and/or programmatically, and can be passed along as data to a generic Starlark recipe
 * which interprets and executes all the defined steps. The format ultimately composes lists of references to steps, and contains
 * no actual code or commands. High-level, predefined steps may be referenced with parameters.
 *
 * User-defined steps are lists of references to other user-defined steps and/or predefined steps with optional parameters.
 * Compositions of steps may not contain recursive/cyclical references, where a step is reachable from any of its sub-steps.
 * User-defined steps must have 1 or more sub-steps and may be composed as sub-steps within multiple (parent) steps, so the composition
 * of steps is not necessarily tree-structured -- in general, the composition must form a directed acyclic graph (DAG).
 *
 * @generated from protobuf message control.Recipe
 */
export interface Recipe {
  /**
   * @generated from protobuf field: string iD = 1;
   */
  ID: string; // optional globally-unique unique identifier for the recipe
  /**
   * @generated from protobuf field: string version = 2;
   */
  Version: string; // optional version for the recipe, versioned over the globally-unique identifier
  /**
   * @generated from protobuf field: string name = 3;
   */
  Name: string; // optional name for the recipe
  /**
   * @generated from protobuf field: string description = 4;
   */
  Description: string; // optional description for the recipe
  /**
   * @generated from protobuf field: repeated string tags = 5;
   */
  Tags: string[]; // optional tags/labels for the recipe
  /**
   * VariableGroups defines groupings/folders for variable definitions in a UI.
   *
   * @generated from protobuf field: repeated control.GroupDef variableGroups = 10;
   */
  VariableGroups: GroupDef[];
  /**
   * VariableDefinitions (edited in "Variables" UI tab) lists global user-defined/editable variables which may be referenced
   * as parameters to predefined steps.
   *
   * @generated from protobuf field: repeated control.VariableDef variableDefinitions = 11;
   */
  VariableDefinitions: VariableDef[];
  /**
   * VariableOverrides can be used to override all references to an Element-provided (predefined) variable definition.
   * All user-provided/Element-provided steps referencing the original ID will automatically be translated to a corresponding user-defined variable.
   *
   * @generated from protobuf field: repeated control.DefOverride variableOverrides = 12;
   */
  VariableOverrides: DefOverride[];
  /**
   * StepGroups defines groupings/folders for step definitions in a UI.
   *
   * @generated from protobuf field: repeated control.GroupDef stepGroups = 20;
   */
  StepGroups: GroupDef[];
  /**
   * StepDefinitions (edited in "Steps" UI tab) are ultimately defined in terms of built-in/predefined steps (IDs) but may be composed.
   *
   * @generated from protobuf field: repeated control.StepDef stepDefinitions = 21;
   */
  StepDefinitions: StepDef[];
  /**
   * StepOverrides can be used to override all references to an Element-provided (predefined) step definition.
   * All predefined steps referencing the original ID will automatically be translated to a corresponding user-defined step.
   *
   * @generated from protobuf field: repeated control.DefOverride stepOverrides = 22;
   */
  StepOverrides: DefOverride[];
  /**
   * MainRecipeSteps lists the steps of the main recipe (edited in "Recipe" UI tab), a mixed list of steps/reads.
   *
   * @generated from protobuf field: repeated control.RecipeStep mainRecipeSteps = 30;
   */
  MainRecipeSteps: RecipeStep[];
}
/**
 * DefOverride can be used to override all references to an Element-provided (predefined) variable or step definition.
 * All user-provided/Element-provided steps referencing the original ID will automatically be translated to a corresponding user-defined
 * variable or step.
 *
 * @generated from protobuf message control.DefOverride
 */
export interface DefOverride {
  /**
   * @generated from protobuf field: uint32 originalID = 1;
   */
  OriginalID: number; // Element-provided (predefined) variable or step ID
  /**
   * @generated from protobuf field: uint32 overrideID = 2;
   */
  OverrideID: number; // User-provided variable or step ID to automatically replace all existing references
}
// Variable and Step Definitions --------------------------

/**
 * VariableDef is a global user-defined variable which may be referenced as an optional parameter to a predefined step.
 *
 * @generated from protobuf message control.VariableDef
 */
export interface VariableDef {
  /**
   * @generated from protobuf field: uint32 iD = 1;
   */
  ID: number; // local identifier for the variable definition, unique within the containing list of variable definitions
  /**
   * @generated from protobuf field: uint32 groupID = 2;
   */
  GroupID: number; // optional group ID to display the definition within a UI folder
  /**
   * @generated from protobuf field: string globalID = 3;
   */
  GlobalID: string; // optional globally-unique ID for the definition to synchronize with remote libraries
  /**
   * @generated from protobuf field: string label = 4;
   */
  Label: string; // label for the variable definition in a UI
  /**
   * @generated from protobuf field: string description = 5;
   */
  Description: string; // optional description for the variable definition in a UI
  /**
   * @generated from protobuf field: control.DataType type = 6;
   */
  Type: DataType; // distinguishes whether the IntValue, FloatValue, StringValue, or BoolValue is set
  /**
   * @generated from protobuf field: int32 intValue = 7;
   */
  IntValue: number; // value is set for Type ArgInt
  /**
   * @generated from protobuf field: float floatValue = 8;
   */
  FloatValue: number; // value is set for Type ArgFloat
  /**
   * @generated from protobuf field: bool boolValue = 9;
   */
  BoolValue: boolean; // value is set for Type ArgBool
  /**
   * @generated from protobuf field: string stringValue = 10;
   */
  StringValue: string; // value is set for Type ArgString
}
/**
 * GroupDef is a grouping for step definitions or cycle-type definitions in a UI.
 *
 * @generated from protobuf message control.GroupDef
 */
export interface GroupDef {
  /**
   * @generated from protobuf field: uint32 iD = 1;
   */
  ID: number; // local identifier for the group definition, unique within the containing list of group definitions
  /**
   * @generated from protobuf field: string globalID = 2;
   */
  GlobalID: string; // optional globally-unique ID for the definition to synchronize with remote libraries
  /**
   * @generated from protobuf field: string label = 3;
   */
  Label: string; // editable label for the group definition in a UI
  /**
   * @generated from protobuf field: string description = 4;
   */
  Description: string; // optional description for the group definition in a UI
}
/**
 * StepDef is a definition of a named step, composed of references to steps in the Definitions section or predefined steps with optional parameters.
 *
 * @generated from protobuf message control.StepDef
 */
export interface StepDef {
  /**
   * @generated from protobuf field: uint32 iD = 1;
   */
  ID: number; // local identifier for the step definition, unique within the containing list of step definitions
  /**
   * @generated from protobuf field: uint32 groupID = 2;
   */
  GroupID: number; // optional group ID to display the definition within a UI folder
  /**
   * @generated from protobuf field: string globalID = 3;
   */
  GlobalID: string; // optional globally-unique ID for the definition to synchronize with remote libraries
  /**
   * @generated from protobuf field: string label = 4;
   */
  Label: string; // editable label for the step definition in a UI
  /**
   * @generated from protobuf field: string description = 5;
   */
  Description: string; // optional description for the step definition in a UI
  /**
   * @generated from protobuf field: repeated control.StepRef steps = 6;
   */
  Steps: StepRef[]; // references to sub-step definitions
}
/**
 * StepRef references a step in the Definitions section or a predefined step with optional parameters.
 *
 * @generated from protobuf message control.StepRef
 */
export interface StepRef {
  /**
   * @generated from protobuf field: control.StepRefType type = 1;
   */
  Type: StepRefType; // type of the referenced step definition
  /**
   * @generated from protobuf field: uint32 stepID = 2;
   */
  StepID: number; // local identifier of the referenced step definition
  /**
   * @generated from protobuf field: uint32 repeat = 3;
   */
  Repeat: number; // may be set greater than zero to repeat the step an additional number of times; the value is the number of additional repetitions
  // Field set only for Type BaseFunctionStepRef:

  /**
   * @generated from protobuf field: repeated control.StepOption functionStepOptions = 10;
   */
  FunctionStepOptions: StepOption[]; // optional parameters to an Element-provided (predefined) function-step
}
/**
 * StepOption is an optional parameter to an Element-provided (predefined) function-step. The parameter may reference a global variable.
 *
 * @generated from protobuf message control.StepOption
 */
export interface StepOption {
  /**
   * @generated from protobuf field: control.StepOptionType type = 1;
   */
  Type: StepOptionType; // parameter value/variable type
  /**
   * @generated from protobuf field: string name = 2;
   */
  Name: string; // parameter name
  // Variable reference:

  /**
   * @generated from protobuf field: uint32 variableID = 10;
   */
  VariableID: number; // only for Type StepOptionBaseVariable or StepOptionUserVariable, must be a reference to a global variable and the value fields must be empty
  // Value:

  /**
   * @generated from protobuf field: int32 intValue = 21;
   */
  IntValue: number; // value is set only for Type StepOptionIntValue
  /**
   * @generated from protobuf field: float floatValue = 22;
   */
  FloatValue: number; // value is set only for Type StepOptionFloatValue
  /**
   * @generated from protobuf field: bool boolValue = 23;
   */
  BoolValue: boolean; // value is set only for Type StepOptionBoolValue
  /**
   * @generated from protobuf field: string stringValue = 24;
   */
  StringValue: string; // value is set only for Type StepOptionStringValue
}
/**
 * RecipeStep is a top-level recipe step, which may be a step reference or a read.
 *
 * @generated from protobuf message control.RecipeStep
 */
export interface RecipeStep {
  /**
   * @generated from protobuf field: control.RecipeStepType type = 1;
   */
  Type: RecipeStepType; // top-level step type
  // Fields set only for Type RecipeRead:

  /**
   * @generated from protobuf field: string readName = 10;
   */
  ReadName: string;
  /**
   * @generated from protobuf field: repeated control.ReadStep readSteps = 11;
   */
  ReadSteps: ReadStep[]; // references to steps or cycles/cycle-ranges
  // Field set only for Type RecipeUserStepRef, RecipeBaseStepRef, RecipeBaseFunctionStepRef:

  /**
   * @generated from protobuf field: uint32 stepID = 20;
   */
  StepID: number; // unique identifier of the referenced step definition
  /**
   * @generated from protobuf field: uint32 repeat = 21;
   */
  Repeat: number; // may be set greater than zero to repeat the step an additional number of times. The value is the number of additional repetitions
  // Field set only for Type RecipeBaseFunctionStepRef:

  /**
   * @generated from protobuf field: repeated control.StepOption functionStepOptions = 30;
   */
  FunctionStepOptions: StepOption[]; // optional parameters to an Element-provided (predefined) function-step
}
/**
 * ReadStep is a step within a read, which may be a step reference or a cycle/cycle-range.
 *
 * @generated from protobuf message control.ReadStep
 */
export interface ReadStep {
  /**
   * @generated from protobuf field: control.ReadStepType type = 1;
   */
  Type: ReadStepType; // read-scoped step type
  /**
   * @generated from protobuf field: uint32 stepID = 2;
   */
  StepID: number; // unique identifier of the referenced step definition (re-executed per cycle for cycle ranges)
  /**
   * @generated from protobuf field: uint32 repeat = 3;
   */
  Repeat: number; // may be set greater than zero to repeat the step an additional number of times. The value is the number of additional repetitions
  // Field set only for Type ReadUserCycleRange, ReadBaseCycleRange:

  /**
   * StartCycle is the initial (1-based) cycle for the range.
   * The end cycle for the range is inferred as the cycle before StartCycle of the next defined cycle/cycle-range in the read,
   * or to the end of the read (cycle count) if this is the last defined cycle/cycle-range in the read.
   *
   * As a special case, if the StartCycle is -1, the StartCycle for the range is inferred as the initial PMG cycle
   * indicated by the PMG mask from the run parameters.
   *
   * After a cycle/cycle-range with StartCycle -1 (PMG start cycle from mask), all subsequent cycles/cycle-ranges
   * in the read have a StartCycle inferred as a relative offset from the PMG start cycle.
   *
   * @generated from protobuf field: int32 startCycle = 10;
   */
  StartCycle: number;
  // Field set only for Type ReadBaseFunctionStepRef:

  /**
   * @generated from protobuf field: repeated control.StepOption functionStepOptions = 20;
   */
  FunctionStepOptions: StepOption[]; // provides optional parameters to an Element-provided (predefined) function-step
}
// ENUMS ------------------------------------------------------------------------------

/**
 * @generated from protobuf enum control.SystemSide
 */
export enum SystemSide {
  /**
   * @generated from protobuf enum value: SideA = 0;
   */
  SideA = 0,
  /**
   * @generated from protobuf enum value: SideB = 1;
   */
  SideB = 1,
}
/**
 * System status flags (may be combined within a single enum value).
 * Up to 31 status values may be defined.
 *
 * @generated from protobuf enum control.SystemStatus
 */
export enum SystemStatus {
  /**
   * @generated from protobuf enum value: SystemUninitialized = 0;
   */
  SystemUninitialized = 0,
  /**
   * @generated from protobuf enum value: SystemInitialized = 1;
   */
  SystemInitialized = 1,
  /**
   * @generated from protobuf enum value: SideA_Busy = 2;
   */
  SideA_Busy = 2,
  /**
   * @generated from protobuf enum value: SideA_Paused = 4;
   */
  SideA_Paused = 4,
  /**
   * @generated from protobuf enum value: SideA_Pausing = 8;
   */
  SideA_Pausing = 8,
  /**
   * @generated from protobuf enum value: SideA_Stopping = 16;
   */
  SideA_Stopping = 16,
  /**
   * @generated from protobuf enum value: SideB_Busy = 32;
   */
  SideB_Busy = 32,
  /**
   * @generated from protobuf enum value: SideB_Paused = 64;
   */
  SideB_Paused = 64,
  /**
   * @generated from protobuf enum value: SideB_Pausing = 128;
   */
  SideB_Pausing = 128,
  /**
   * @generated from protobuf enum value: SideB_Stopping = 256;
   */
  SideB_Stopping = 256,
  /**
   * waiting for event from Atom/Molecule
   *
   * @generated from protobuf enum value: SideA_EventWaiting = 512;
   */
  SideA_EventWaiting = 512,
  /**
   * waiting for event from Atom/Molecule
   *
   * @generated from protobuf enum value: SideB_EventWaiting = 1024;
   */
  SideB_EventWaiting = 1024,
  /**
   * paused and available to receive consumables reloading commands
   *
   * @generated from protobuf enum value: SideA_PausedForLoad = 2048;
   */
  SideA_PausedForLoad = 2048,
  /**
   * paused and available to receive consumables reloading commands
   *
   * @generated from protobuf enum value: SideB_PausedForLoad = 4096;
   */
  SideB_PausedForLoad = 4096,
  /**
   * critical E3 error while idle
   *
   * @generated from protobuf enum value: SideA_Critical = 8192;
   */
  SideA_Critical = 8192,
  /**
   * critical E3 error while idle
   *
   * @generated from protobuf enum value: SideB_Critical = 16384;
   */
  SideB_Critical = 16384,
}
/**
 * Register value data-types
 *
 * @generated from protobuf enum control.DataType
 */
export enum DataType {
  /**
   * @generated from protobuf enum value: DataTypeMissing = 0;
   */
  DataTypeMissing = 0,
  /**
   * @generated from protobuf enum value: RegisterInt = 1;
   */
  RegisterInt = 1,
  /**
   * @generated from protobuf enum value: RegisterFloat = 2;
   */
  RegisterFloat = 2,
  /**
   * RegisterWide indicates a 64-byte register (e.g. barcode reader)
   *
   * @generated from protobuf enum value: RegisterWide = 3;
   */
  RegisterWide = 3,
  /**
   * @generated from protobuf enum value: ArgInt = 4;
   */
  ArgInt = 4,
  /**
   * @generated from protobuf enum value: ArgFloat = 5;
   */
  ArgFloat = 5,
  /**
   * @generated from protobuf enum value: ArgString = 6;
   */
  ArgString = 6,
  /**
   * @generated from protobuf enum value: ArgBool = 7;
   */
  ArgBool = 7,
}
/**
 * Device classes/categories
 *
 * @generated from protobuf enum control.DeviceClass
 */
export enum DeviceClass {
  /**
   * @generated from protobuf enum value: DeviceClassMissing = 0;
   */
  DeviceClassMissing = 0,
  /**
   * @generated from protobuf enum value: AFControl = 1;
   */
  AFControl = 1,
  /**
   * @generated from protobuf enum value: Camera = 2;
   */
  Camera = 2,
  /**
   * @generated from protobuf enum value: Container = 3;
   */
  Container = 3,
  /**
   * @generated from protobuf enum value: CoolingFan = 4;
   */
  CoolingFan = 4,
  /**
   * @generated from protobuf enum value: FluidicSensor = 5;
   */
  FluidicSensor = 5,
  /**
   * @generated from protobuf enum value: Marker = 6;
   */
  Marker = 6,
  /**
   * @generated from protobuf enum value: Handshake = 7;
   */
  Handshake = 7,
  /**
   * @generated from protobuf enum value: Interlock = 8;
   */
  Interlock = 8,
  /**
   * @generated from protobuf enum value: Laser = 9;
   */
  Laser = 9,
  /**
   * @generated from protobuf enum value: NestBay = 10;
   */
  NestBay = 10,
  /**
   * @generated from protobuf enum value: Stage = 11;
   */
  Stage = 11,
  /**
   * @generated from protobuf enum value: ComboStage = 12;
   */
  ComboStage = 12,
  /**
   * @generated from protobuf enum value: StepperMotor = 13;
   */
  StepperMotor = 13,
  /**
   * @generated from protobuf enum value: SyringePump = 14;
   */
  SyringePump = 14,
  /**
   * @generated from protobuf enum value: TEC = 15;
   */
  TEC = 15,
  /**
   * @generated from protobuf enum value: Valve = 16;
   */
  Valve = 16,
  /**
   * @generated from protobuf enum value: LED = 17;
   */
  LED = 17,
  /**
   * @generated from protobuf enum value: Barcode = 18;
   */
  Barcode = 18,
  /**
   * @generated from protobuf enum value: TestNode = 19;
   */
  TestNode = 19,
  /**
   * @generated from protobuf enum value: FWUpgrade = 20;
   */
  FWUpgrade = 20,
}
/**
 * Type of image file saved
 *
 * @generated from protobuf enum control.ImageType
 */
export enum ImageType {
  /**
   * @generated from protobuf enum value: Raw = 0;
   */
  Raw = 0,
  /**
   * @generated from protobuf enum value: LoGCorrected = 1;
   */
  LoGCorrected = 1,
  /**
   * @generated from protobuf enum value: BackgroundCorrected = 2;
   */
  BackgroundCorrected = 2,
  /**
   * @generated from protobuf enum value: EBitt = 3;
   */
  EBitt = 3,
  /**
   * @generated from protobuf enum value: WashImage = 4;
   */
  WashImage = 4,
}
/**
 * Type of Bases/Scores file saved
 *
 * @generated from protobuf enum control.BaseCallType
 */
export enum BaseCallType {
  /**
   * @generated from protobuf enum value: Regular = 0;
   */
  Regular = 0,
  /**
   * @generated from protobuf enum value: FullyExploded = 1;
   */
  FullyExploded = 1,
  /**
   * @generated from protobuf enum value: ExplodeShrunken = 2;
   */
  ExplodeShrunken = 2,
}
/**
 * Type of intensity file saved
 *
 * @generated from protobuf enum control.IntensityType
 */
export enum IntensityType {
  /**
   * @generated from protobuf enum value: AvidIntensity = 0;
   */
  AvidIntensity = 0,
  /**
   * @generated from protobuf enum value: WashIntensity = 1;
   */
  WashIntensity = 1,
}
/**
 * @generated from protobuf enum control.AnalysisMode
 */
export enum AnalysisMode {
  /**
   * default
   *
   * @generated from protobuf enum value: AnalysisModeNoAnalysis = 0;
   */
  AnalysisModeNoAnalysis = 0,
  /**
   * @generated from protobuf enum value: AnalysisModeLocalAnalysis = 1;
   */
  AnalysisModeLocalAnalysis = 1,
  /**
   * @generated from protobuf enum value: AnalysisModeCloudAnalysis = 2;
   */
  AnalysisModeCloudAnalysis = 2,
}
/**
 * @generated from protobuf enum control.RecipeType
 */
export enum RecipeType {
  /**
   * @generated from protobuf enum value: UnknownRecipeType = 0;
   */
  UnknownRecipeType = 0,
  /**
   * @generated from protobuf enum value: Prime = 5;
   */
  Prime = 5,
  /**
   * @generated from protobuf enum value: Sequencing = 10;
   */
  Sequencing = 10,
  /**
   * @generated from protobuf enum value: Wash = 15;
   */
  Wash = 15,
  /**
   * including R&D Wash
   *
   * @generated from protobuf enum value: RAndD = 20;
   */
  RAndD = 20,
}
/**
 * Renamed to RecipeExecutionStatus (vs Status) to avoid conflict with control.proto
 *
 * @generated from protobuf enum control.RecipeExecutionStatus
 */
export enum RecipeExecutionStatus {
  /**
   * Pre-run state
   *
   * @generated from protobuf enum value: UnknownRecipeStatus = 0;
   */
  UnknownRecipeStatus = 0,
  /**
   * active recipe run states
   *
   * @generated from protobuf enum value: Running = 5;
   */
  Running = 5,
  /**
   * @generated from protobuf enum value: Pausing = 10;
   */
  Pausing = 10,
  /**
   * @generated from protobuf enum value: Paused = 15;
   */
  Paused = 15,
  /**
   * Note: a recipe transitioning out of Resuming goes back to Running (not resumed)
   *
   * @generated from protobuf enum value: Resuming = 20;
   */
  Resuming = 20,
  /**
   * @generated from protobuf enum value: Stopping = 25;
   */
  Stopping = 25,
  /**
   * Note: consumables reloading commands may be sent when a side is PausedForLoad
   *
   * @generated from protobuf enum value: PausedForLoad = 30;
   */
  PausedForLoad = 30,
  /**
   * terminal recipe run states
   *
   * Unexpected system error
   *
   * @generated from protobuf enum value: Failed = 105;
   */
  Failed = 105,
  /**
   * User triggered action
   *
   * @generated from protobuf enum value: Stopped = 110;
   */
  Stopped = 110,
  /**
   * only when RecipeUpdateKind=RecipeFinished
   *
   * @generated from protobuf enum value: Completed = 115;
   */
  Completed = 115,
}
/**
 * @generated from protobuf enum control.RecipeProgressKindEnum
 */
export enum RecipeProgressKindEnum {
  /**
   * @generated from protobuf enum value: Kind_UnknownRecipeProgress = 0;
   */
  Kind_UnknownRecipeProgress = 0,
  /**
   * @generated from protobuf enum value: Kind_PreRecipeStarted = 10;
   */
  Kind_PreRecipeStarted = 10,
  /**
   * @generated from protobuf enum value: Kind_PreRecipeFinished = 11;
   */
  Kind_PreRecipeFinished = 11,
  /**
   * @generated from protobuf enum value: Kind_MainRecipeStarted = 12;
   */
  Kind_MainRecipeStarted = 12,
  /**
   * @generated from protobuf enum value: Kind_MainRecipeFinished = 13;
   */
  Kind_MainRecipeFinished = 13,
  /**
   * @generated from protobuf enum value: Kind_PostRecipeStarted = 14;
   */
  Kind_PostRecipeStarted = 14,
  /**
   * @generated from protobuf enum value: Kind_PostRecipeFinished = 15;
   */
  Kind_PostRecipeFinished = 15,
  /**
   * @generated from protobuf enum value: Kind_PolonizationStarted = 20;
   */
  Kind_PolonizationStarted = 20,
  /**
   * @generated from protobuf enum value: Kind_PolonizationFinished = 21;
   */
  Kind_PolonizationFinished = 21,
  /**
   * @generated from protobuf enum value: Kind_ImagingStarted = 30;
   */
  Kind_ImagingStarted = 30,
  /**
   * @generated from protobuf enum value: Kind_ImagingFinished = 31;
   */
  Kind_ImagingFinished = 31,
  /**
   * @generated from protobuf enum value: Kind_ReadStarted = 40;
   */
  Kind_ReadStarted = 40,
  /**
   * @generated from protobuf enum value: Kind_ReadFinished = 41;
   */
  Kind_ReadFinished = 41,
  /**
   * @generated from protobuf enum value: Kind_CycleStarted = 42;
   */
  Kind_CycleStarted = 42,
  /**
   * @generated from protobuf enum value: Kind_CycleFinished = 43;
   */
  Kind_CycleFinished = 43,
  /**
   * @generated from protobuf enum value: Kind_WashStarted = 44;
   */
  Kind_WashStarted = 44,
  /**
   * @generated from protobuf enum value: Kind_WashFinished = 45;
   */
  Kind_WashFinished = 45,
  /**
   * @generated from protobuf enum value: Kind_TurnStarted = 46;
   */
  Kind_TurnStarted = 46,
  /**
   * @generated from protobuf enum value: Kind_TurnFinished = 47;
   */
  Kind_TurnFinished = 47,
  /**
   * @generated from protobuf enum value: Kind_TrapStarted = 50;
   */
  Kind_TrapStarted = 50,
  /**
   * @generated from protobuf enum value: Kind_TrapFinished = 51;
   */
  Kind_TrapFinished = 51,
  /**
   * @generated from protobuf enum value: Kind_TrapRemovalStarted = 52;
   */
  Kind_TrapRemovalStarted = 52,
  /**
   * @generated from protobuf enum value: Kind_TrapRemovalFinished = 53;
   */
  Kind_TrapRemovalFinished = 53,
  /**
   * @generated from protobuf enum value: Kind_IncorpStarted = 60;
   */
  Kind_IncorpStarted = 60,
  /**
   * @generated from protobuf enum value: Kind_IncorpFinished = 61;
   */
  Kind_IncorpFinished = 61,
  /**
   * @generated from protobuf enum value: Kind_IncorpRemovalStarted = 62;
   */
  Kind_IncorpRemovalStarted = 62,
  /**
   * @generated from protobuf enum value: Kind_IncorpRemovalFinished = 63;
   */
  Kind_IncorpRemovalFinished = 63,
  /**
   * @generated from protobuf enum value: Kind_CleaveStarted = 70;
   */
  Kind_CleaveStarted = 70,
  /**
   * @generated from protobuf enum value: Kind_CleaveFinished = 71;
   */
  Kind_CleaveFinished = 71,
  /**
   * @generated from protobuf enum value: Kind_HybridizationStarted = 80;
   */
  Kind_HybridizationStarted = 80,
  /**
   * @generated from protobuf enum value: Kind_HybridizationFinished = 81;
   */
  Kind_HybridizationFinished = 81,
  /**
   * @generated from protobuf enum value: Kind_DehybridizationStarted = 82;
   */
  Kind_DehybridizationStarted = 82,
  /**
   * @generated from protobuf enum value: Kind_DehybridizationFinished = 83;
   */
  Kind_DehybridizationFinished = 83,
}
/**
 * @generated from protobuf enum control.RecipeTileProgressKindEnum
 */
export enum RecipeTileProgressKindEnum {
  /**
   * @generated from protobuf enum value: Kind_UnknownRecipeTileProgress = 0;
   */
  Kind_UnknownRecipeTileProgress = 0,
  /**
   * @generated from protobuf enum value: Kind_ImageSaved = 32;
   */
  Kind_ImageSaved = 32,
  /**
   * @generated from protobuf enum value: Kind_IntensitySaved = 33;
   */
  Kind_IntensitySaved = 33,
  /**
   * @generated from protobuf enum value: Kind_PMGOutputSaved = 34;
   */
  Kind_PMGOutputSaved = 34,
}
/**
 * @generated from protobuf enum control.RecipeUploadStatus
 */
export enum RecipeUploadStatus {
  /**
   * Undefined status
   *
   * @generated from protobuf enum value: RecipeUpload_None = 0;
   */
  RecipeUpload_None = 0,
  /**
   * Currently uploading
   *
   * @generated from protobuf enum value: RecipeUpload_Uploading = 1;
   */
  RecipeUpload_Uploading = 1,
  /**
   * User request to abandon
   *
   * @generated from protobuf enum value: RecipeUpload_Abandoning = 2;
   */
  RecipeUpload_Abandoning = 2,
  /**
   * Upload successfully abandoned
   *
   * @generated from protobuf enum value: RecipeUpload_Abandoned = 4;
   */
  RecipeUpload_Abandoned = 4,
  /**
   * Upload completed successfully
   *
   * @generated from protobuf enum value: RecipeUpload_Completed = 8;
   */
  RecipeUpload_Completed = 8,
  /**
   * An error occurred upload; upload will be re-queued
   *
   * @generated from protobuf enum value: RecipeUpload_NeedsAttention = 16;
   */
  RecipeUpload_NeedsAttention = 16,
}
/**
 * @generated from protobuf enum control.RecipeExecutionOptions
 */
export enum RecipeExecutionOptions {
  /**
   * @generated from protobuf enum value: Normal = 0;
   */
  Normal = 0,
  /**
   * Note: this is used as a bit flag so all entries should be power of 2
   *
   * @generated from protobuf enum value: HighAltitude = 1;
   */
  HighAltitude = 1,
}
/**
 * @generated from protobuf enum control.TelemetryUploadStatus
 */
export enum TelemetryUploadStatus {
  /**
   * @generated from protobuf enum value: UploadNotStarted = 0;
   */
  UploadNotStarted = 0,
  /**
   * @generated from protobuf enum value: UploadUploading = 10;
   */
  UploadUploading = 10,
  /**
   * @generated from protobuf enum value: UploadFinalizingUpload = 20;
   */
  UploadFinalizingUpload = 20,
  /**
   * all files uploaded
   *
   * @generated from protobuf enum value: UploadComplete = 30;
   */
  UploadComplete = 30,
  /**
   * offline instrument, folder was compressed for offline export, upload to s3 may happen asynchronously
   *
   * @generated from protobuf enum value: OfflineCompressed = 40;
   */
  OfflineCompressed = 40,
}
/**
 * StepRefType indicates the type of a referenced step (sub-step within a parent step).
 *
 * @generated from protobuf enum control.StepRefType
 */
export enum StepRefType {
  /**
   * @generated from protobuf enum value: StepRefUndefined = 0;
   */
  StepRefUndefined = 0,
  /**
   * reference to an Element-provided (predefined) function-step definition with optional parameters
   *
   * @generated from protobuf enum value: BaseFunctionStepRef = 1;
   */
  BaseFunctionStepRef = 1,
  /**
   * reference to an Element-provided (predefined) step definition
   *
   * @generated from protobuf enum value: BaseStepRef = 2;
   */
  BaseStepRef = 2,
  /**
   * reference to a user-provided step definition
   *
   * @generated from protobuf enum value: UserStepRef = 3;
   */
  UserStepRef = 3,
}
/**
 * StepOptionType indicates the type of a top-level recipe step, which may be a step reference or a read.
 *
 * @generated from protobuf enum control.StepOptionType
 */
export enum StepOptionType {
  /**
   * @generated from protobuf enum value: StepOptionUndefined = 0;
   */
  StepOptionUndefined = 0,
  /**
   * @generated from protobuf enum value: StepOptionBaseVariable = 1;
   */
  StepOptionBaseVariable = 1,
  /**
   * @generated from protobuf enum value: StepOptionUserVariable = 2;
   */
  StepOptionUserVariable = 2,
  /**
   * @generated from protobuf enum value: StepOptionIntValue = 3;
   */
  StepOptionIntValue = 3,
  /**
   * @generated from protobuf enum value: StepOptionFloatValue = 4;
   */
  StepOptionFloatValue = 4,
  /**
   * @generated from protobuf enum value: StepOptionBoolValue = 5;
   */
  StepOptionBoolValue = 5,
  /**
   * @generated from protobuf enum value: StepOptionStringValue = 6;
   */
  StepOptionStringValue = 6,
}
// Steps in Recipe Top-Level --------------------------

/**
 * RecipeStepType indicates the type of a top-level recipe step, which may be a step reference or a read.
 *
 * @generated from protobuf enum control.RecipeStepType
 */
export enum RecipeStepType {
  /**
   * @generated from protobuf enum value: RecipeStepUndefined = 0;
   */
  RecipeStepUndefined = 0,
  /**
   * top-level reference to an Element-provided (predefined) function-step definition with optional parameters
   *
   * @generated from protobuf enum value: RecipeBaseFunctionStepRef = 1;
   */
  RecipeBaseFunctionStepRef = 1,
  /**
   * top-level reference to an Element-provided (predefined) step definition
   *
   * @generated from protobuf enum value: RecipeBaseStepRef = 2;
   */
  RecipeBaseStepRef = 2,
  /**
   * top-level reference to a user-provided step definition
   *
   * @generated from protobuf enum value: RecipeUserStepRef = 3;
   */
  RecipeUserStepRef = 3,
  /**
   * top-level named read with sub-steps
   *
   * @generated from protobuf enum value: RecipeRead = 4;
   */
  RecipeRead = 4,
}
// Steps in Reads --------------------------

/**
 * ReadStepType indicates the type of a step within a read, which may be a step reference or a cycle/cycle-range.
 *
 * @generated from protobuf enum control.ReadStepType
 */
export enum ReadStepType {
  /**
   * @generated from protobuf enum value: ReadStepUndefined = 0;
   */
  ReadStepUndefined = 0,
  /**
   * read-scoped reference to an Element-provided (predefined) function-step definition with optional parameters
   *
   * @generated from protobuf enum value: ReadBaseFunctionStepRef = 1;
   */
  ReadBaseFunctionStepRef = 1,
  /**
   * read-scoped reference to an Element-provided (predefined) step definition
   *
   * @generated from protobuf enum value: ReadBaseStepRef = 2;
   */
  ReadBaseStepRef = 2,
  /**
   * read-scoped reference to a user-provided step definition
   *
   * @generated from protobuf enum value: ReadUserStepRef = 3;
   */
  ReadUserStepRef = 3,
  /**
   * read-scoped cycle/cycle-range with a reference to an Element-provided (predefined) step to re-execute per cycle
   *
   * @generated from protobuf enum value: ReadBaseCycleRange = 4;
   */
  ReadBaseCycleRange = 4,
  /**
   * read-scoped cycle/cycle-range with a reference to a user-provided step to re-execute per cycle
   *
   * @generated from protobuf enum value: ReadUserCycleRange = 5;
   */
  ReadUserCycleRange = 5,
}
// @generated message type with reflection information, may provide speed optimized methods
