import React from "react";
import PropTypes from 'prop-types'
import Picker from "react-month-picker"
require('react-month-picker/css/month-picker.css');

class MonthBox extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            value: this.props.value || 'N/A',
        }

        this._handleClick = this._handleClick.bind(this)
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            value: nextProps.value || 'N/A',
        })
    }

    render() {

        return (
            <div className="box" onClick={this._handleClick}>
                <label>{this.state.value}</label>
            </div>
        )
    }

    _handleClick(e) {
        this.props.onClick && this.props.onClick(e)
    }
}

MonthBox.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
}


export default class DateRangeSelector extends React.Component {
    constructor(props, context) {
        super(props, context)
        const range = {from: {year: 2020, month: 5}, to: {year: 2021, month: 4}};
        console.log(range);
        this.state = {
            mrange: range,
        }
        this._handleClickRangeBox = this._handleClickRangeBox.bind(this)
        this.handleRangeChange = this.handleRangeChange.bind(this)
        this.handleRangeDissmis = this.handleRangeDissmis.bind(this)
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            value: nextProps.value || 'N/A',
        })
    }

    render() {

        const pickerLang = {
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            from: 'From', to: 'To',
        }
        const mrange = this.state.mrange

        const makeText = m => {
            if (m && m.year && m.month) return (pickerLang.months[m.month-1] + ' ' + m.year)
            return '?'
        }

        return (
            <React.Fragment>
            <label><strong>Date Range:</strong></label>&nbsp;
            <div className="edit"
            style={{display:"inline-block"}} >
            <Picker
                ref="pickRange"
                years={{min: 2020, max: 2024}}
                value={mrange}
                lang={pickerLang}
                theme="light"
                onChange={this.handleRangeChange}
                onDismiss={this.handleRangeDissmis}
            >
                <MonthBox value={makeText(mrange.from) + ' - ' + makeText(mrange.to)} onClick={this._handleClickRangeBox} />
            </Picker>
        </div>
        </React.Fragment>
        )
    }

    _handleClickRangeBox(e) {
        this.refs.pickRange.show()
    }
    handleRangeChange() {
    }

    handleRangeDissmis(value) {
        this.setState( {mrange: value} )
        if (this.props.onRangeChanged) {
            this.props.onRangeChanged(value);
        }
    }
}