import React, {useState} from "react";

import TextBox from 'devextreme-react/text-box';
import { RunsChooser } from "./RunsChooser";
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';


export const RunsTextBox = (props) => {
    const [selectedRuns, setSelectedRuns] = useState( props.defaultValue || "" );
    const [tmpSelectedRuns, setTmpSelectedRuns] = useState( props.defaultValue || "" ); // value before user hits ok to dismiss the popup
    const [popupVisible, setPopupVisible] = useState(false)
    
    const updateRunsText = (text) => {
        setSelectedRuns(text);
        if (props.onValueChanged) props.onValueChanged(text)
    }

    console.log(props.defaultValue)

    return <div style={{display:"flex", justifyContent:"right"}}>
                <div style={{flex:1, width: "100%"}}>
                    <TextBox value={selectedRuns} style={{width: "100%"}}
                    onValueChanged={v => updateRunsText(v.value)}
                    showClearButton={true} />
                </div>
                <div style={{marginLeft:"20px", flex:"0 0 200px"}}>
                    <Button text={props.multiSelect ? "Select Runs..." : "Select Run..."} onClick={(e) => { setPopupVisible(true) }} />
                </div>
        {popupVisible && (
            <Popup
                visible={true}
                onHiding={e => { setPopupVisible(false) }}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                title={props.multiSelect ? "Select Runs" : "Select Run"}
                width="80%"
                height="80%"
                fullScreen={false}
            >
                <RunsChooser 
                    multiSelect={props.multiSelect} 
                    initialValue={selectedRuns} 
                    setTmpSelectedRuns={v=>setTmpSelectedRuns(v)}
                />
                
                
                <ToolbarItem
                    options={{
                        text: 'OK',
                        onClick: function() {
                            setPopupVisible(false) 
                            updateRunsText(tmpSelectedRuns.join(","))
                        }
                    }
                    }
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                />
                
            </Popup>)}
    </div>
}