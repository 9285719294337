import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";
import { Link } from "react-router-dom";
import Button from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";

//import SelectBox from 'devextreme-react/select-box';
//import { StatusImages } from "./StatusImages"
import DataGrid, {
  Column,
  ColumnChooser,
  FilterRow,
  Paging,
  Pager,
  GroupPanel,
  GroupItem,
  Summary,
  Lookup,
  StateStoring,
  Export,
  //   RequiredRule
} from "devextreme-react/data-grid";

const fields = [
  { name: "Last Month", value: 1 },
  { name: "Last 3 Months", value: 3 },
  { name: "Last 6 Months", value: 6 },
  { name: "Last Year", value: 12 },
  { name: "All", value: 0 },
];

const renderGridCell = (data) => {
  console.log(data);
  if (data.value === 1)
    return <div style={{ backgroundColor: "red" }}>&nbsp;</div>;
  return <div style={{ backgroundColor: "green" }}>&nbsp;</div>;
};

const query = `

select runName, analysisVersion, runDescription, dateStarted, instrument, avg(avgError) avgError, sum(numLowErrorTiles) numLowErrorTiles, 
analysisName, operator, numCyclesR1, numCyclesR2, numCyclesI1, numCyclesI2, l1LibraryID, l2LibraryID, numTiles,
numTiles * 2 - sum(numLowErrorTiles) numHighErrorTiles,
sum(numReallyBadTiles) numReallyBadTiles, 
max(highError) highError, 
max(error3xAvg) error3xAvg, 
max(errorVariation) errorVariation, 
max(lowDensity) lowDensity, 
max(densityGradient) densityGradient
from (
select runName, analysisVersion, runDescription, dateStarted, numTiles, instrument, DT.read, avgError, sum(numLowErrorTiles) numLowErrorTiles, 
analysisName, operator, numCyclesR1, numCyclesR2, numCyclesI1, numCyclesI2, l1LibraryID, l2LibraryID,
numTiles - sum(numLowErrorTiles) numHighErrorTiles,
sum(numReallyBadTiles) numReallyBadTiles, 
max(highError) highError, 
max(error3xAvg) error3xAvg, 
max(errorVariation) errorVariation, 
max(lowDensity) lowDensity, 
max(densityGradient) densityGradient
from (select dateStarted, P.analysisVersion, P.runDescription, runName, I.name instrument, numTiles, lane, P.read, avg(error)  avgError, avgLaneError, sum(error<0.5) numLowErrorTiles, 
    P.analysisName, P.operator, P.numCyclesR1, P.numCyclesR2, P.numCyclesI1, P.numCyclesI2, P.l1LibraryID, P.l2LibraryID,
       if (sum(error<0.5)<numTiles*0.4,1,0) highError,
       sum(error>3*avgLaneError) numReallyBadTiles,
       if (sum(error-3*avgLaneError>0),1,0) error3xAvg,
       if (std(error)/avg(error)>0.1,1,0) errorVariation,
       if ( avg(polonyDensity)/1000<200,1,0) lowDensity, 
       if ( std(polonyDensity)/avg(polonyDensity)*100>10,1,0) densityGradient
from
(   -- This table contains the min error rate by tile-read for the specified cycles, as well as the density
    select SR.id runID, SR.analysisVersion, SR.runDescription, ARL.id laneID, ARL.lane, ARTR.read, min(ARC.errorRate) * 100 'error', ART.polonyDensity,
    analysisName, O.name operator, numCyclesR1, numCyclesR2, numCyclesI1, numCyclesI2, F.l1LibraryID, F.l2LibraryID
    from SequencingRun SR
    inner join AnalysisResult AR on AR.sequencingRunID = SR.id and AR.analysisVersion = SR.analysisVersion
    inner join ARLane ARL on ARL.analysisResultID = AR.id
    inner join ARTile ART on ART.arLaneID = ARL.id
    inner join ARTileRead ARTR on ARTR.arTileID = ART.id
    inner join ARCycle ARC on ARC.arTileReadID = ARTR.id
    inner join Operator O on O.id = SR.operatorID
    inner join Flowcell F on F.id = SR.flowcellID
    where deleted = 0 and ARC.errorRate > 0  and ARC.errorRate < 0.5 and dateStarted > '2021-04-01' and cycle >= 65 and cycle <= 70 
     and numCyclesR1 > 70 and numCyclesR2 > 70
    group by ARTR.id
) P
inner join 
( -- This table has the average min error for each lane by read
   select laneID, Q.read, avg(minError) 'avgLaneError'
    from  
    -- This table has the minimum error between the specified cycles for each tile-read
    -- it only has three columns, the laneID, the read, and the minError, since we will be grouping by laneID-read
    (select ARL.id laneID, ARTR.read, min(ARC.errorRate) * 100 minError
    from SequencingRun SR
    inner join AnalysisResult AR on AR.sequencingRunID = SR.id and AR.analysisVersion = SR.analysisVersion
    inner join ARLane ARL on ARL.analysisResultID = AR.id
    inner join ARTile ART on ART.arLaneID = ARL.id 
    inner join ARTileRead ARTR on ARTR.arTileID = ART.id
    inner join ARCycle ARC on ARC.arTileReadID = ARTR.id
    where deleted = 0 and ARC.errorRate > 0  and ARC.errorRate < 0.5 and dateStarted > '2021-04-01' and cycle >= 65 and cycle <= 70
    group by ARTR.id
    ) Q
    group by laneID, Q.read) R
  ON P.laneID = R.laneID and P.read = R.read
inner join SequencingRun SR on SR.id = P.runID
inner join Instrument I on SR.instrumentID = I.id
group by P.laneID, P.read) DT
group by DT.runName, DT.read
)DT2 
group by DT2.runName
`;

const camelCaseConverter = (text) => {
  if (text.length === 0) return "";
  var result = text.replace(".", "_").replace(/([A-Z])/g, " $1");
  return (result.charAt(0).toUpperCase() + result.slice(1)).trim();
};

const processResults = (txt) => {
  var outarray = [];
  var fields = [];

  if (txt.length > 0) {
    const lines = txt.split("\n");
    if (lines.length > 1) {
      fields = lines[0].split(",").map((v) => {
        return { name: v, label: camelCaseConverter(v), fieldType: "number" };
      });
      var i;
      var lineNum, tmp;
      for (lineNum = 1; lineNum < lines.length; lineNum++) {
        const line = lines[lineNum];
        if (line.length === 0) continue;
        const els = line.split(",");
        if (els.length === fields.length) {
          for (i = 0; i < els.length; i++) {
            tmp = els[i];
            if (isNaN(tmp) && fields[i].fieldType !== "string") {
              if (isNaN(Date.parse(tmp))) fields[i].fieldType = "string";
              else fields[i].fieldType = "date";
            }
          }
        }
      }
      for (lineNum = 1; lineNum < lines.length; lineNum++) {
        const line = lines[lineNum];
        if (line.length === 0) continue;
        var outobj = { idx: lineNum };
        const els = line.split(",");
        if (els.length === fields.length) {
          for (i = 0; i < els.length; i++) {
            tmp = els[i];
            if (fields[i].fieldType === "number") {
              if (tmp === "-999") tmp = NaN;
              else tmp = parseFloat(parseFloat(tmp).toPrecision(4));
            } else if (fields[i].fieldType === "date") {
              //tmp = Date.parse(tmp)
            }
            outobj[fields[i].name] = tmp;
          }
          outarray.push(outobj);
        }
      }
    }
  }

  return outarray;
};

export const RunsQualityMetrics = (props) => {
  const { loading, fetchWithCheck, fetchNoJson } = useAuth0();
  const [all, setAll] = useState(null);
  const [data, setData] = useState([]);
  const [numMonths, setNumMonths] = useState(1);
  const theGrid = useRef(null);

  useEffect(() => {
    const callAPI = async () => {
      const d = await fetchNoJson("/report/generalQuery", {
        method: "POST",
        body: JSON.stringify({
          QueryString: query,
          Filename: "file.csv",
        }),
      }).then((txt) => processResults(txt));
      setData(d);
    };
    if (!loading) callAPI();
  }, [loading, fetchNoJson, props.id]);

  useEffect(() => {
    const handleResize = () => {
      if (theGrid) {
        theGrid.current.instance.option("height", window.innerHeight - 250);
      }
    };
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    const callApi = async () => {
      setAll(await fetchWithCheck("/lookups"));
      if (theGrid) {
        theGrid.current.instance.option("height", window.innerHeight - 250);
      }
    };

    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck]);

  const cellRenderRunName = (cell) => {
    if (!cell.value) {
      return <div></div>;
    }
    console.log(cell);
    return (
      <Link to={"/runs/" + cell.value + "?v=" + cell.data.analysisVersion}>
        {cell.value}
      </Link>
    );
  };

  var libraryLookup = [];

  if (all) {
    libraryLookup = all.libraries;
  }

  return (
    <div>
      <br />
      <h3>Run Quality Metrics Table</h3>
      <Button
        onClick={() => {
          theGrid.current.instance.state({});
        }}
        text="Reset Table Formatting"
      />
      <br />
      <br />

      <SelectBox
        value={numMonths}
        valueExpr="value"
        displayExpr="name"
        placeholder="Select a value..."
        showClearButton={false}
        dataSource={fields}
        text="Data to query"
        onValueChanged={(v) => {
          setNumMonths(v.value);
          theGrid.current.instance.getDataSource().reload();
        }}
        style={{ width: "150px" }}
      />

      <DataGrid
        dataSource={data}
        ref={theGrid}
        wordWrapEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={"widget"}
        columnHidingEnabled={true}
        columnAutoWidth={true}
        columnFixing={{ enabled: true }}
        scrolling={{ showScrollbar: "always" }}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="seqRunQualityTablev3"
        />

        <FilterRow visible={true} />

        <GroupPanel visible={true} />
        <Export
          enabled={true}
          fileName={"SequencingRuns"}
          allowExportSelectedData={true}
        />

        <ColumnChooser enabled={true} />

        <Column
          dataField={"runName"}
          cellRender={cellRenderRunName}
          allowEditing={false}
          fixed={true}
          fixedPosition="left"
          width={161}
        />

        <Column dataField={"runDescription"} width={250} />

        <Column dataField={"instrument"} width={93} />

        <Column dataField={"analysisName"} width={139} allowEditing={false} />

        <Column
          dataField={"dateStarted"}
          dataType={"date"}
          caption="Run Date"
          format={"dd MMM yyyy HH:mm"}
          allowEditing={false}
          width={125}
          visible={true}
        />

        <Column dataField={"numTiles"} allowEditing={false} width={59} />
        <Column
          dataField={"numCyclesR1"}
          caption={"Cycles R1"}
          allowEditing={false}
          width={68}
        />
        <Column
          dataField={"numCyclesR2"}
          caption={"Cycles R2"}
          allowEditing={false}
          width={68}
        />
        <Column
          dataField={"numCyclesI1"}
          caption={"Cycles I1"}
          allowEditing={false}
          width={68}
        />
        <Column
          dataField={"numCyclesI2"}
          caption={"Cycles I2"}
          allowEditing={false}
          width={68}
        />

        <Column
          dataField={"l1LibraryID"}
          caption="Lane 1 Library"
          width={220}
          allowEditing={false}
        >
          <Lookup
            dataSource={libraryLookup}
            displayExpr="name"
            valueExpr="id"
          />
        </Column>

        <Column
          dataField={"l2LibraryID"}
          caption="Lane 2 Library"
          width={220}
          allowEditing={false}
        >
          <Lookup
            dataSource={libraryLookup}
            displayExpr="name"
            valueExpr="id"
          />
        </Column>

        <Column dataField="avgError" />
        <Column dataField="numLowErrorTiles" cellRender={renderGridCell} />
        <Column dataField="numHighErrorTiles" cellRender={renderGridCell} />
        <Column dataField="numReallyBadTiles" cellRender={renderGridCell} />
        <Column dataField="highError" cellRender={renderGridCell} />
        <Column dataField="error3xAvg" cellRender={renderGridCell} />
        <Column dataField="errorVariation" cellRender={renderGridCell} />
        <Column dataField="lowDensity" cellRender={renderGridCell} />
        <Column dataField="densityGradient" cellRender={renderGridCell} />

        <Summary>
          <GroupItem
            column={"title"}
            summaryType={"count"}
            displayFormat={"{0}"}
          />
        </Summary>

        <Paging defaultPageSize={100} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 500]} />
      </DataGrid>
    </div>
  );
};
