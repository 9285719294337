import React, { useEffect, useState, useMemo, useRef } from "react";

import Scheduler, { Resource } from "devextreme-react/scheduler";
import { getPalette } from "devextreme/viz/palette";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";
import CustomStore from "devextreme/data/custom_store";

import Toolbar, { Item } from "devextreme-react/toolbar";
import PlannedRunCalendar from "../views/PlannedRunsCalendar/PlannedRunCalendar";

let palette = getPalette("Bright");

const currentDate = new Date();
const groups = ["instrumentID"];

const views = [
  { name: "Week", type: "timelineWeek", intervalCount: 1 },
  { name: "2 Weeks", type: "timelineWeek", intervalCount: 2 },
  { name: "4 Weeks", type: "timelineWeek", intervalCount: 4 },
];

const editting = {
  allowAdding: true,
  allowDeleting: true,
  allowResizing: true,
  allowDragging: true,
  allowUpdating: true,
};

export const PlannedRunsForm = () => {
  const [state, setState] = useState({ groupData: [], instrumentData: [] });
  const { loading, fetchWithCheck, fetchNoResponse } = useAuth0();
  const scheduler = useRef(null);
  const [cntr, setCntr] = useState(0);

  useEffect(() => {
    const callAPI = async () => {
      console.log("here");
      const grps = await fetchWithCheck("/groups");
      console.log(grps);
      for (var i = 0; i < grps.length; i++)
        grps[i].color = palette.simpleSet[(i % 4) + 2];
      //grps[0].color = "#00000020";
      const instr = await fetchWithCheck("/instruments");
      const filterList = [
        "P1-01",
        "P1-02",
        "P1-03",
        "P1-04",
        "P1-05",
        "P2-01",
        "P2-02",
        "P2-03",
        "P2-04",
      ];
      let instrData = [];
      filterList.forEach((v) => {
        instrData.push(instr.find((x) => x.name === v));
      });
      setState({ groupData: grps, instrumentData: instrData });
      console.log(instr);
    };
    if (!loading) {
      callAPI();
    }
    console.log("here2");
  }, [loading, fetchWithCheck]);

  var loadData = async () => {
    const data = await fetchWithCheck("/plannedRuns");
    console.log(data);
    return data;
  };

  var updateData = async (key, values) => {
    await fetchNoResponse("/plannedRuns/" + key, {
      method: "PATCH",
      body: JSON.stringify(values),
    });
  };

  var insertData = async (values) => {
    console.log(values);
    await fetchNoResponse("/plannedRuns", {
      method: "POST",
      body: JSON.stringify(values),
    });
  };

  var removeData = async (key) => {
    await fetchNoResponse("/plannedRuns/" + key, {
      method: "DELETE",
    });
  };

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
    update: updateData,
    insert: insertData,
    remove: removeData,
  });

  useEffect(() => {
    if (scheduler.current) {
      scheduler.current.instance.option("cellDuration", 60 * 24);
      setCntr((x) => x + 1);
    }
  }, [scheduler]);

  const addAppointmentOptions = useMemo(() => {
    return {
      icon: "inserttable",
      hint: "add apointment",
      text: "add",
      stylingMode: "text",
      onClick: () => {
        scheduler.current.instance.showAppointmentPopup();
      },
    };
  }, [scheduler]);

  const refreshOptions = useMemo(() => {
    return {
      icon: "refresh",
      hint: "refresh",
      text: "refresh",
      stylingMode: "text",
      onClick: () => {
        var dataSource = scheduler.current.instance.getDataSource();
        dataSource.reload();
      },
    };
  }, [scheduler]);

  const zoomInOptions = useMemo(() => {
    let enabled = true;
    console.log(cntr);
    if (scheduler.current) {
      enabled = scheduler.current.instance.option("cellDuration") > 60;
    }
    return {
      disabled: !enabled,
      icon: "plus",
      hint: "zoom in",
      text: "zoom in",
      stylingMode: "text",
      onClick: () => {
        var cellDuration = scheduler.current.instance.option("cellDuration");

        if (cellDuration === 60 * 24)
          scheduler.current.instance.option("cellDuration", 60 * 12);
        else if (cellDuration === 60 * 12)
          scheduler.current.instance.option("cellDuration", 60 * 4);
        else if (cellDuration === 60 * 4)
          scheduler.current.instance.option("cellDuration", 60 * 2);
        else if (cellDuration === 60 * 2)
          scheduler.current.instance.option("cellDuration", 60);
        scheduler.current.instance.repaint();
        setCntr((x) => x + 1);
      },
    };
  }, [scheduler, cntr]);

  const zoomOutOptions = useMemo(() => {
    let enabled = true;
    console.log(cntr);
    if (scheduler.current) {
      enabled = scheduler.current.instance.option("cellDuration") < 60 * 24;
    }
    return {
      disabled: !enabled,
      icon: "minus",
      hint: "zoom out",
      text: "zoom out",
      stylingMode: "text",
      onClick: () => {
        var cellDuration = scheduler.current.instance.option("cellDuration");

        if (cellDuration === 60)
          scheduler.current.instance.option("cellDuration", 60 * 2);
        else if (cellDuration === 60 * 2)
          scheduler.current.instance.option("cellDuration", 60 * 4);
        else if (cellDuration === 60 * 4)
          scheduler.current.instance.option("cellDuration", 60 * 12);
        else if (cellDuration === 60 * 12)
          scheduler.current.instance.option("cellDuration", 60 * 24);
        scheduler.current.instance.repaint();
        setCntr((x) => x + 1);
      },
    };
  }, [scheduler, cntr]);

  const onAppointmentFormOpening = (data) => {
    let form = data.form,
      apptInfo = data.appointmentData || {};

    console.log("data", data);
    console.log("apptInfo", apptInfo);

    form.option("items", [
      {
        label: {
          text: "Run Description",
        },
        name: "text",
        dataField: "text",
        colSpan: 2,
        editorType: "dxTextBox",
        editorOptions: {
          value: apptInfo.text,
        },
      },
      {
        dataField: "startDate",
        editorType: "dxDateBox",
        editorOptions: {
          width: "100%",
          type: "datetime",
        },
      },
      {
        name: "endDate",
        dataField: "endDate",
        editorType: "dxDateBox",
        editorOptions: {
          width: "100%",
          type: "datetime",
        },
      },
      {
        label: {
          text: "Details",
        },
        name: "description",
        editorType: "dxTextBox",
        colSpan: 2,
        editorOptions: {
          value: apptInfo.description,
        },
      },
      {
        label: {
          text: "Group",
        },
        validationRules: [{ type: "required", message: "Group is required" }],
        editorType: "dxSelectBox",
        dataField: "groupID",
        editorOptions: {
          items: state.groupData,
          displayExpr: "name",
          valueExpr: "id",
        },
      },
      {
        label: {
          text: "Instrument",
        },
        validationRules: [
          { type: "required", message: "Instrument is required" },
        ],
        editorType: "dxSelectBox",
        dataField: "instrumentID",
        editorOptions: {
          items: state.instrumentData,
          displayExpr: "name",
          valueExpr: "id",
        },
      } /*{
      dataField: 'price',
      editorType: 'dxRadioGroup',
      editorOptions: {
        dataSource: [5, 10, 15, 20],
        itemTemplate: function(itemData) {
          return `$${itemData}`;
        }
      }
    }*/,
    ]);
  };

  return (
    <React.Fragment>
      <Toolbar>
        <Item location="before" widget="dxButton" options={zoomOutOptions} />
        <Item location="before" widget="dxButton" options={zoomInOptions} />
        <Item
          location="before"
          widget="dxButton"
          options={addAppointmentOptions}
        />
        <Item location="before" widget="dxButton" options={refreshOptions} />
      </Toolbar>

      <Scheduler
        ref={scheduler}
        timeZone="America/Los_Angeles"
        dataSource={customStore}
        views={views}
        defaultCurrentView="timelineWeek"
        defaultCurrentDate={currentDate}
        height={80 * state.instrumentData.length}
        editing={editting}
        shadeUntilCurrentTime={true}
        groups={groups}
        firstDayOfWeek={0}
        startDayHour={0}
        endDayHour={24}
        maxAppointmentsPerCell="unlimited"
        onAppointmentFormOpening={onAppointmentFormOpening}
      >
        <Resource
          fieldExpr="groupID"
          displayExpr="name"
          allowMultiple={false}
          dataSource={state.groupData}
          label="Group"
          useColorAsDefault={true}
        />
        <Resource
          fieldExpr="instrumentID"
          displayExpr="name"
          allowMultiple={false}
          dataSource={state.instrumentData}
          label="Instrument"
        />
      </Scheduler>

      <PlannedRunCalendar />
    </React.Fragment>
  );
};
