import React, {useState, useEffect, useRef} from "react";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";

import 'devextreme-react/text-area';
import 'devextreme-react/html-editor';
import DataGrid, {
    Column,
    ColumnChooser,
    Format,
    Paging,
    Pager,
    GroupPanel,
    GroupItem,
    Summary,
    SearchPanel,
    StateStoring,
    Export,
    HeaderFilter,
    FilterPanel,
    FilterBuilder
} from "devextreme-react/data-grid";
import Button from 'devextreme-react/button';


export const TileCycleChannelTable = props => {
    const [state, setState] = useState(null)
    const id = props.id;
    const theGrid = useRef(null)

    const { fetchWithCheck, loading } = useAuth0();


    useEffect(() => {
        const handleResize = () => {
          if (theGrid) { theGrid.current.instance.option("height", window.innerHeight - 300); }
        }
        window.addEventListener('resize', handleResize)
        return _ => {  window.removeEventListener('resize', handleResize) }
      })

    useEffect(() => {
        const callApi = async () => {
            const tmp = await fetchWithCheck("/analyses/" + id + "/channel")
            if (tmp && tmp.length > 0) {
                for (var i = 0; i < tmp.length; i++) {
                    tmp[i].id = i;
                    for (var key of Object.keys(tmp[i])) {
                        if (tmp[i][key] === -999) {
                            tmp[i][key] = ""
                        }
                    }
                }

                var fields = [];
                for (var field of Object.keys(tmp[0])) {
                    fields.push(field)
                }

                console.log(tmp)
                setState({data: tmp, fields: fields})
            }
            if (theGrid) { theGrid.current.instance.option("height", window.innerHeight - 300); }

        }
        if (!loading) {
            callApi()
        }
    }, [loading, fetchWithCheck, id])

    
    var columns = []
    if (state) {
        columns = state.fields.filter(x=>x!=="id").map(x=> <Column dataField={x}> {x.includes("Rate") && <Format type="percent"  precision={4} /> } </Column>)
    } else {
        return <div>loading...</div>
    }

    return (
        <React.Fragment>

<Button onClick={()=>{theGrid.current.instance.state({})}} text="Reset Table Formatting"/><br/><br/>
        <DataGrid
            ref={theGrid}
            dataSource={state ? state.data : []}
            keyExpr="id"
            wordWrapEnabled={true}
            showBorders={true}
            showColumnLines={true}
            showRowLines={true}
            rowAlternationEnabled={true}
            allowColumnResizing={true}
            focusedRowEnabled={false}
            allowColumnReordering={true}
            columnResizingMode={"widget"}
            columnHidingEnabled={true}
            columnAutoWidth={true}
            hoverStateEnabled={false}
            scrolling={{showScrollbar: "always"}}
        >
           <StateStoring enabled={true} type="localStorage" storageKey="channelsTable" />
            <SearchPanel visible={true} width={240} placeholder={"Search..."} />

            <GroupPanel visible={true} />
            <Export
                enabled={true}
                fileName={"ChannelTable"}
                allowExportSelectedData={true}
            />
            <HeaderFilter visible={true} />

            <ColumnChooser enabled={true} />

            <FilterPanel visible={true} />
            <FilterBuilder visible={true} />
            
            {columns}

            <Summary>
                <GroupItem
                    column={"title"}
                    summaryType={"count"}
                    displayFormat={"{0}"}
                />
            </Summary>
            <Paging defaultPageSize={20} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[20, 50, 100, 1000]}
            />
        </DataGrid>
        </React.Fragment>
    );
}