import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";
import CustomStore from "devextreme/data/custom_store";

import DataGrid, {
  Column,
  ColumnChooser,
  GroupPanel,
  SearchPanel,
  Export,
  Pager,
  Paging,
  HeaderFilter,
} from "devextreme-react/data-grid";

export const FlowcellReportForm = () => {
  const { fetchWithCheck } = useAuth0();

  var loadData = async () => {
    var data = await fetchWithCheck("/report/flowcells");
    var i;
    for (i = 0; i < data.length; i++) {
      data[i].id = i;
    }
    return data;
  };

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
  });

  return (
    <div>
      <br />
      <h3>Flowcell Report</h3>

      <DataGrid
        dataSource={customStore}
        wordWrapEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={"widget"}
        columnHidingEnabled={true}
        columnAutoWidth={true}
      >
        <SearchPanel visible={true} width={240} placeholder={"Search..."} />

        <Export
          enabled={true}
          fileName={"SeqDB Export"}
          allowExportSelectedData={true}
        />
        <HeaderFilter visible={true} />

        <ColumnChooser enabled={true} />

        <GroupPanel visible={true} />

        <Column dataField={"serialNumber"} />
        <Column dataField={"type"} />
        <Column dataField={"numLanes"} />
        <Column dataField={"lotNumber"} />
        <Column dataField={"comment"} />

        <Column dataField={"forwardPrimerLotNumber"} />
        <Column dataField={"reversePrimerLotNumber"} />
        <Column dataField={"edcLotNumber"} />
        <Column
          dataField={"date"}
          dataType={"date"}
          format={"dd MMM yyyy HH:mm"}
        />
        <Column dataField={"instrument"} />
        <Column dataField={"operator"} />
        <Column dataField={"runName"} />
        <Column dataField={"runDescription"} />
        <Column dataField={"recipeName"} />
        <Column dataField={"runCondition"} />
        <Column dataField={"library"} />
        <Column dataField={"concentration"} />
        <Column dataField={"qcMethod"} />
        <Column dataField={"enzymeName"} />
        <Column dataField={"laserPercentage"} />
        <Column dataField={"aviditeF2"} />
        <Column dataField={"aviditeF2LotNumber"} />
        <Column dataField={"aviditeF3"} />
        <Column dataField={"aviditeF3LotNumber"} />
        <Column dataField={"hp11HybBuffer"} />
        <Column dataField={"libraryHybBuffer"} />
        <Column dataField={"equiPhi29LotNumber"} />
        <Column dataField={"laserPowerGreen"} />
        <Column dataField={"laserPowerRed"} />
        <Column dataField={"lane"} />
        <Column dataField={"totalRegions"} />
        <Column dataField={"percentageOver100kpmm2"} />

        <Column dataField={"density"} />
        <Column dataField={"cvDensity"} />
        <Column dataField={"p50TrapF2"} />
        <Column dataField={"cvTrapF2"} />
        <Column dataField={"washResidualF2"} />
        <Column dataField={"p50TrapF3"} />
        <Column dataField={"cvTrapF3"} />
        <Column dataField={"p50BgndF3"} />
        <Column dataField={"washResidualF3"} />

        <Paging defaultPageSize={20} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[20, 100, 500]} />
      </DataGrid>
    </div>
  );
};
