import React, { useState, useCallback } from "react";
import "../App.css";
import { TileCycleChannelTable } from "./TileCycleChannelTable";
import { TileCycleContainer } from "./TileCycleTable";
import { TileTable } from "./TileTable";
import { ReadTable } from "./ReadTable";
import { LaneTable } from "./LaneTable";
import Tabs from 'devextreme-react/tabs';
import { Link } from "react-router-dom";

const getUrlVars = () => {
    var vars = {};
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  };
  
export const AnalysisDetailsForm = props => {
    const {
        match: { params }
    } = props;

    const id = params.id;
    const name = params.name;

    const vars = getUrlVars();

    const version = decodeURI(vars["v"]);

    const [currentTabAndFilter, setCurrentTabFilter] = useState({tab:0,filter:null})

    const onSelectedTabChange = useCallback(({ name, value }) => {
        if (name === "selectedIndex") {
            setCurrentTabFilter({tab:value, filter:null})
        }
    }, []);

    const onFiltChanged = useCallback((newfilt) => {
        if (newfilt) {
            const ss = newfilt.split(":")
            setCurrentTabFilter({tab:parseInt(ss[0]), filter: ss[1]})
        } 
    }, []);

    const tabs = ["Lane Level", "Read Level", "Tile Level", "Cycle Level", "Channel Level"];

    return (
            <div>
            <Link to={"/runs/"+name + "?v="+version}>Back to dashboard</Link>
            <br />
            <br />
            <h3>{name}</h3>
            <br />
            <Tabs dataSource={tabs} selectedIndex={currentTabAndFilter.tab} onOptionChanged={onSelectedTabChange} className={"widget"}/>
            {currentTabAndFilter.tab === 0 && (<LaneTable id={id} />)}
            {currentTabAndFilter.tab === 1 && (<ReadTable id={id} />)}
            {currentTabAndFilter.tab === 2 && (<TileTable id={id} />)}
            {currentTabAndFilter.tab === 3 && (<TileCycleContainer id={id} filter={currentTabAndFilter.filter} onFiltChanged={onFiltChanged} />)}
            {currentTabAndFilter.tab === 4 && (<TileCycleChannelTable id={id} filter={currentTabAndFilter.filter} onFiltChanged={onFiltChanged} />)}
        </div>
    );
}
