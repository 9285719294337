import React from "react";
import { useAuth0 } from "../../react-auth0-spa";
import "../../App.css";
import CustomStore from "devextreme/data/custom_store";

import 'devextreme-react/html-editor';
import DataGrid, {
    Column,
    ColumnChooser,
    Format,
    Paging,
    Pager,
    GroupPanel,
    SearchPanel,
    Export,
    HeaderFilter,
    Summary,
    TotalItem,
    GroupItem,
} from "devextreme-react/data-grid";


import styled from 'styled-components';
const Styles = styled.div`
.offset-title {
    margin-top: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
}

`

const myCustomizeText = (v) => {return v.value<0 ? "" : "Avg: "+(v.value * 100).toPrecision(4).toString() + "%"}

export const ErrorSummaryTable = React.memo(props => {
    const id = props.id;

    const { fetchWithCheck } = useAuth0();

    var loadData = async () => {
        const tmp = await fetchWithCheck("/report/errorRateSummary/" + id)

        var i;
        for (i = 0; i < tmp.length; i++) {
            if (tmp[i].field === -999) {
                tmp[i].field = NaN
            }
        }
        return tmp;
    }

    var customStore = new CustomStore({
        key: "Tile",
        load: loadData,
    })

    const filtNeg999s = (data) => {
        if (data.value === -999) {
            return <div></div>
        }
        return <div>{data.text}</div>
    }

    return (
        <Styles>
            <div className="offset-title">Error Rate Summary</div>

            <DataGrid
                dataSource={customStore}
                keyExpr="id"
                wordWrapEnabled={true}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                focusedRowEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnResizingMode={"widget"}
                columnHidingEnabled={true}
                columnAutoWidth={true}
                hoverStateEnabled={false}
            >
                <SearchPanel visible={true} width={240} placeholder={"Search..."} />

                <GroupPanel visible={true} />
                <Export
                    enabled={true}
                    fileName={"TileTable"}
                    allowExportSelectedData={true}
                />
                <HeaderFilter visible={true} />

                <ColumnChooser enabled={true} />

                <Column dataField={"Tile"} />
                <Column dataField="Lane"/>
                <Column dataField="Row"/>
                <Column dataField="Col"/>
                <Column dataField="Surface"/>
 
                <Column dataField={"Avg1to35R1"} caption={" R1 35 Cycle Error Rate (%)"} cellRender={filtNeg999s}>
                    <Format type="percent" precision={2} />
                </Column>
                <Column dataField={"Avg1to75R1"} caption={" R1 75 Cycle Error Rate (%)"} cellRender={filtNeg999s} >
                    <Format type="percent" precision={2} />
                </Column>
                <Column dataField={"Avg1to100R1"} caption={" R1 100 Cycle Error Rate (%)"} cellRender={filtNeg999s} >
                    <Format type="percent" precision={2} />
                </Column>
                <Column dataField={"Avg1to150R1"} caption={" R1 150 Cycle Error Rate (%)"} cellRender={filtNeg999s} >
                    <Format type="percent" precision={2} />
                </Column>

                <Column dataField={"Avg1to35R2"} caption={" R2 35 Cycle Error Rate (%)"} cellRender={filtNeg999s}>
                    <Format type="percent" precision={2} />
                </Column>
                <Column dataField={"Avg1to75R2"} caption={" R2 75 Cycle Error Rate (%)"} cellRender={filtNeg999s} >
                    <Format type="percent" precision={2} />
                </Column>
                <Column dataField={"Avg1to100R2"} caption={" R2 100 Cycle Error Rate (%)"} cellRender={filtNeg999s} >
                    <Format type="percent" precision={2} />
                </Column>
                <Column dataField={"Avg1to150R2"} caption={" R2 150 Cycle Error Rate (%)"} cellRender={filtNeg999s} >
                    <Format type="percent" precision={2} />
                </Column>

                <Summary>
                    <TotalItem column="Avg1to35R1" customizeText={myCustomizeText} summaryType="avg" />
                    <TotalItem column="Avg1to75R1" customizeText={myCustomizeText} summaryType="avg" />
                    <TotalItem column="Avg1to100R1" customizeText={myCustomizeText} summaryType="avg" />
                    <TotalItem column="Avg1to150R1" customizeText={myCustomizeText} summaryType="avg" />
                    <TotalItem column="Avg1to35R2" customizeText={myCustomizeText} summaryType="avg" />
                    <TotalItem column="Avg1to75R2" customizeText={myCustomizeText}  summaryType="avg" />
                    <TotalItem column="Avg1to100R2" customizeText={myCustomizeText}  summaryType="avg" />
                    <TotalItem column="Avg1to150R2" customizeText={myCustomizeText}  summaryType="avg" />

                    <GroupItem column="Avg1to35R1" customizeText={myCustomizeText} summaryType="avg" showInGroupFooter={false} alignByColumn={true}/>
                    <GroupItem column="Avg1to75R1" customizeText={myCustomizeText} summaryType="avg" showInGroupFooter={false} alignByColumn={true}/>
                    <GroupItem column="Avg1to100R1" customizeText={myCustomizeText} summaryType="avg" showInGroupFooter={false} alignByColumn={true}/>
                    <GroupItem column="Avg1to150R1" customizeText={myCustomizeText} summaryType="avg" showInGroupFooter={false} alignByColumn={true}/>
                    <GroupItem column="Avg1to35R2" customizeText={myCustomizeText} summaryType="avg" showInGroupFooter={false} alignByColumn={true}/>
                    <GroupItem column="Avg1to75R2" customizeText={myCustomizeText}  summaryType="avg" showInGroupFooter={false} alignByColumn={true}/>
                    <GroupItem column="Avg1to100R2" customizeText={myCustomizeText}  summaryType="avg" showInGroupFooter={false} alignByColumn={true}/>
                    <GroupItem column="Avg1to150R2" customizeText={myCustomizeText}  summaryType="avg" showInGroupFooter={false} alignByColumn={true}/>

                    
              
                </Summary>
                
                <Paging defaultPageSize={1000} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[50, 100, 1000]}
                />
            </DataGrid>
        </Styles>
    )
})