import vars from "./variables";
import * as utils from "./utils";
import {
  blPaddingX,
  blPaddingY,
  blWidth,
  blLabelHeight,
  paddingY,
  rowHeight,
  labelRowHeight,
  colWidth,
  x0,
  y0,
  rheight,
} from "./constants";

export const doubleClickHandler = (e, forceRedraw) => {
  const handleUI = (run) => {
    vars.selectedRun = run;
    vars.isEditing = true;
    vars.popupVisible = true;
    forceRedraw();
  };
  if (e.offsetX < blPaddingX + blWidth) {
    const tmp = vars.runs.filter((v) => v.instrumentID === 0 && (!vars.showOnlyMyRuns || vars.currentUser === v.requester));

    const ty =
      e.offsetY - vars.backlogDeltaY - (blPaddingY + blLabelHeight + 35);
    const idx = Math.floor(ty / 20);
    if (idx >= 0 && idx < tmp.length) {
      handleUI({ ...tmp[idx] });
    }
  } else {
    const px = e.offsetX - vars.deltaX;
    const py = e.offsetY - vars.deltaY;

    const r = vars.runs
      .filter((x) => x.instrumentID !== 0)
      .find((v) => {
        const y = utils.getYForRun(v);
        const x = utils.dateToPxl(v.startDate);
        const w = utils.hoursToPxls(v.runLength);
        return px > x && px < x + w && py > y && py < y + rowHeight / 2;
      });

    if (r) {
      handleUI({ ...r });
    }
  }
};

export const mouseDownHandler = (e, forceRedraw) => {
  vars.runBeingCoppied = false;
  if (e.offsetX < blPaddingX + blWidth) {
    const tmp = vars.runs.filter((v) => v.instrumentID === 0 && (!vars.showOnlyMyRuns || vars.currentUser === v.requester));
    const ty =
      e.offsetY - vars.backlogDeltaY - (blPaddingY + blLabelHeight + 35);
    const idx = Math.floor(ty / 20);
    if (idx >= 0 && idx < tmp.length) {
      const startdate = utils.pxlToDate(e.offsetX - vars.deltaX);
      vars.origStart = new Date(
        startdate.getTime() - tmp[idx].runLength * 30 * 60000
      );
      if ((window.event as any).shiftKey) {
        vars.runBeingCoppied = true;
        vars.copiedRun = { ...tmp[idx] };
      }
      vars.orig = { ...tmp[idx] };
      vars.runBeingDraggedIdx = vars.runs.indexOf(tmp[idx]);
      vars.pt = {
        x: e.offsetX - vars.deltaX,
        y: e.offsetY - vars.backlogDeltaY,
      };
    }

    vars.selectedRow = vars.highlightedRow;
    vars.deleteButtonEnabled = vars.selectedRow >= 0;
    forceRedraw();
    return;
  }
  if (
    e.offsetX >= blPaddingX + blWidth + 16 &&
    e.offsetX <= blPaddingX + blWidth + 32
  ) {
    // in v scroll bar for backlog runs
    const top = blPaddingY + blLabelHeight + 35;
    const bottom = vars.bottom - 10;
    const runsInBacklog = vars.runs.filter((x) => x.instrumentID === 0 && (!vars.showOnlyMyRuns || vars.currentUser === x.requester)).length;

    const visibleHeightInPixels = bottom - top;
    const totalHeightInPixels = runsInBacklog * rheight;
    let tabHeight =
      (visibleHeightInPixels / totalHeightInPixels) * visibleHeightInPixels;

    if (tabHeight > visibleHeightInPixels) tabHeight = visibleHeightInPixels;
    const tabPos =
      (-vars.backlogDeltaY / totalHeightInPixels) * visibleHeightInPixels + top;

    let dy = 0;
    if (e.offsetY < tabPos) {
      // in space above tab so just page up
      dy = -tabHeight;
    } else if (e.offsetY > tabPos + tabHeight) {
      // in space below tab so page down
      dy = tabHeight;
    } else {
      // dragging tab
      vars.origBacklogDeltaY = vars.backlogDeltaY;
      vars.pt = { x: e.offsetX, y: e.offsetY };
      vars.dragPos = { x: e.offsetX, y: e.offsetY };
      vars.draggingVBacklogScroll = true;
      return;
    }
    const origTabPos =
      (-vars.backlogDeltaY / totalHeightInPixels) * visibleHeightInPixels + y0;
    const newTabPos = origTabPos + dy;
    let newDeltaY =
      (-(newTabPos - y0) / visibleHeightInPixels) * totalHeightInPixels;
    if (newDeltaY > 0) newDeltaY = 0;
    let minY = -(totalHeightInPixels - visibleHeightInPixels);
    if (minY > 0) minY = 0;
    if (newDeltaY < minY) newDeltaY = minY;
    vars.backlogDeltaY = newDeltaY;
    forceRedraw();
    return;
  }
  if (e.offsetX >= vars.canvasWidth - 16) {
    // in v scroll bar
    const visibleHeightInPixels = vars.canvasHeight - y0 - 1 - 16;
    const totalHeightInPixels = vars.instruments.length * rowHeight;
    const tabHeight =
      (visibleHeightInPixels / totalHeightInPixels) * visibleHeightInPixels;
    const tabPos =
      (-vars.deltaY / totalHeightInPixels) * visibleHeightInPixels + y0;
    let dy = 0;
    if (e.offsetY < tabPos) {
      // in space above tab so just page up
      dy = -tabHeight;
    } else if (e.offsetY > tabPos + tabHeight) {
      // in space below tab so page down
      dy = tabHeight;
    } else {
      // dragging tab
      vars.origDeltaX = vars.deltaX;
      vars.origDeltaY = vars.deltaY;
      vars.pt = { x: e.offsetX, y: e.offsetY };
      vars.dragPos = { x: e.offsetX, y: e.offsetY };
      vars.draggingVScroll = true;
      return;
    }
    const chartBottom =
      paddingY + labelRowHeight + rowHeight * vars.instruments.length;
    const origTabPos =
      (-vars.deltaY / totalHeightInPixels) * visibleHeightInPixels + y0;
    const newTabPos = origTabPos + dy;
    let newDeltaY =
      (-(newTabPos - y0) / visibleHeightInPixels) * totalHeightInPixels;
    if (newDeltaY > 0) newDeltaY = 0;
    let minY = -(chartBottom - vars.canvasHeight + 16);
    if (minY > 0) minY = 0;
    if (newDeltaY < minY) newDeltaY = minY;
    vars.deltaY = newDeltaY;
    forceRedraw();
    return;
  }
  if (e.offsetY > vars.canvasHeight - 16) {
    // in horiz scroll bar
    const visibleWidthInPixels = vars.canvasWidth - x0 - 1 - 16;
    const totalWidthInPixels = 9 * colWidth;
    const tabWidth =
      (visibleWidthInPixels / totalWidthInPixels) * visibleWidthInPixels;
    const tabPos =
      (-(vars.deltaX - 400) / totalWidthInPixels) * visibleWidthInPixels + x0;

    let dx = 0;
    if (e.offsetX < tabPos) {
      // in space to left of tab so just page left
      dx = -tabWidth;
    } else if (e.offsetX > tabPos + tabWidth) {
      // in space to right of tab so page right
      dx = tabWidth;
    } else {
      //dragging tab
      vars.origDeltaX = vars.deltaX;
      vars.origDeltaY = vars.deltaY;
      vars.pt = { x: e.offsetX, y: e.offsetY };
      vars.dragPos = { x: e.offsetX, y: e.offsetY };
      vars.draggingHScroll = true;
      return;
    }
    const origTabPos =
      (-vars.deltaX / totalWidthInPixels) * visibleWidthInPixels + x0;
    const newTabPos = origTabPos + dx;
    let newDeltaX =
      (-(newTabPos - x0) / visibleWidthInPixels) * totalWidthInPixels;
    if (newDeltaX > 400) newDeltaX = 400;
    let minX = -7 * 200;
    if (minX > 0) minX = 0;
    if (newDeltaX < minX) newDeltaX = minX;
    vars.deltaX = newDeltaX;
    forceRedraw();
    return;
  }

  const px = e.offsetX - vars.deltaX;
  const py = e.offsetY - vars.deltaY;

  const r = vars.runs
    .filter((x) => x.instrumentID !== 0)
    .find((v) => {
      const y = utils.getYForRun(v);
      const x = utils.dateToPxl(v.startDate);
      const w = utils.hoursToPxls(v.runLength);
      return px > x && px < x + w && py > y && py < y + rowHeight / 2;
    });

  if (r) {
    if (r === vars.selectedRun) {
      vars.runBeingDraggedIdx = vars.runs.indexOf(r);
      if ((window.event as any).shiftKey) {
        vars.runBeingCoppied = true;
        vars.copiedRun = { ...r };
      }
      vars.origStart = r.startDate;
      vars.orig = { ...r };
      vars.pt = { x: px, y: py };
      vars.dragPos = { x: e.offsetX, y: e.offsetY };
    } else {
      vars.selectedRun = r;
      vars.dragPos = { x: e.offsetX, y: e.offsetY };
      vars.runForMouseOverIdx = vars.runs.indexOf(r);
    }
  } else {
    vars.selectedRun = null;
    vars.origDeltaX = vars.deltaX;
    vars.origDeltaY = vars.deltaY;
    vars.pt = { x: e.offsetX, y: e.offsetY };
    vars.dragPos = { x: e.offsetX, y: e.offsetY };
    vars.panning = true;
  }
  forceRedraw();
};

export const mouseWheelHandler = (e, forceRedraw) => {
  if (e.offsetX < blPaddingX + blWidth) {
    const top = blPaddingY + blLabelHeight + 35;
    const bottom = vars.bottom - 10;
    const runsInBacklog = vars.runs.filter((x) => x.instrumentID === 0 && (!vars.showOnlyMyRuns || vars.currentUser === x.requester)).length;
    const visibleHeightInPixels = bottom - top;
    const totalHeightInPixels = runsInBacklog * rheight;
    let newDeltaY = vars.backlogDeltaY - e.deltaY;
    if (newDeltaY > 0) newDeltaY = 0;
    let minY = -(totalHeightInPixels - visibleHeightInPixels);
    if (minY > 0) minY = 0;
    if (newDeltaY < minY) newDeltaY = minY;
    vars.backlogDeltaY = newDeltaY;
  } else {
    const visibleHeightInPixels = vars.canvasHeight - y0 - 1 - 16;
    const totalHeightInPixels = vars.instruments.length * rowHeight;
    let newDeltaY = vars.deltaY - e.deltaY;
    if (newDeltaY > 0) newDeltaY = 0;
    let minY = -(totalHeightInPixels - visibleHeightInPixels);
    if (minY > 0) minY = 0;
    if (newDeltaY < minY) newDeltaY = minY;
    vars.deltaY = newDeltaY;

    let newDeltaX = vars.deltaX - e.deltaX;
    if (newDeltaX > 400) newDeltaX = 400;
    let minX = -7 * 200;
    if (minX > 0) minX = 0;
    if (newDeltaX < minX) newDeltaX = minX;
    vars.deltaX = newDeltaX;
  }

  e.preventDefault();
  forceRedraw();
};

export const mouseMoveHandler = (e, forceRedraw) => {
  vars.runForMouseOverIdx = -1;
  vars.dragPos = { x: e.offsetX, y: e.offsetY };

  const chartBottom =
    paddingY + labelRowHeight + rowHeight * vars.instruments.length;

  if (vars.draggingVBacklogScroll) {
    const top = blPaddingY + blLabelHeight + 35;
    const bottom = vars.bottom - 10;
    const runsInBacklog = vars.runs.filter((x) => x.instrumentID === 0 && (!vars.showOnlyMyRuns || vars.currentUser === x.requester)).length;

    const visibleHeightInPixels = bottom - top;
    const totalHeightInPixels = runsInBacklog * rheight;
    let tabHeight =
      (visibleHeightInPixels / totalHeightInPixels) * visibleHeightInPixels;

    if (tabHeight > visibleHeightInPixels) tabHeight = visibleHeightInPixels;
    const origTabPos =
      (-vars.backlogDeltaY / totalHeightInPixels) * visibleHeightInPixels + top;

    const dy = e.offsetY - vars.pt.y;
    const newTabPos = origTabPos + dy;
    let newDeltaY =
      (-(newTabPos - y0) / visibleHeightInPixels) * totalHeightInPixels;
    if (newDeltaY > 0) newDeltaY = 0;
    let minY = -(totalHeightInPixels - visibleHeightInPixels);
    if (minY > 0) minY = 0;
    if (newDeltaY < minY) newDeltaY = minY;
    vars.backlogDeltaY = newDeltaY;
    forceRedraw();
    return;
  }

  if (vars.draggingVScroll) {
    const visibleHeightInPixels = vars.canvasHeight - y0 - 1 - 16;
    const totalHeightInPixels = vars.instruments.length * rowHeight;
    const origTabPos =
      (-vars.origDeltaY / totalHeightInPixels) * visibleHeightInPixels + y0;
    const dy = e.offsetY - vars.pt.y;
    const newTabPos = origTabPos + dy;
    let newDeltaY =
      (-(newTabPos - y0) / visibleHeightInPixels) * totalHeightInPixels;
    if (newDeltaY > 0) newDeltaY = 0;
    let minY = -(totalHeightInPixels - visibleHeightInPixels);
    if (minY > 0) minY = 0;
    if (newDeltaY < minY) newDeltaY = minY;
    vars.deltaY = newDeltaY;
    forceRedraw();
    return;
  }

  if (vars.draggingHScroll) {
    const visibleWidthInPixels = vars.canvasWidth - x0 - 1 - 16;
    const totalWidthInPixels = 9 * colWidth;
    const origTabPos =
      (-(vars.origDeltaX - 400) / totalWidthInPixels) * visibleWidthInPixels +
      x0;
    const dx = e.offsetX - vars.pt.x;
    const newTabPos = origTabPos + dx;
    let newDeltaX =
      (-(newTabPos - x0) / visibleWidthInPixels) * totalWidthInPixels + 400;
    if (newDeltaX > 400) newDeltaX = 400;
    let minX = -7 * 200;
    if (minX > 0) minX = 0;
    if (newDeltaX < minX) newDeltaX = minX;
    vars.deltaX = newDeltaX;
    forceRedraw();
    return;
  }

  const x = e.offsetX - vars.deltaX;
  const y = e.offsetY - vars.deltaY;

  if (vars.runBeingDraggedIdx >= 0) {
    let theRun = vars.runs[vars.runBeingDraggedIdx];
    if (vars.runBeingCoppied) theRun = vars.copiedRun;
    if (e.offsetX < x0) {
      if (theRun.instrumentID !== 0) {
        vars.somethingChanged = true;
        theRun.instrumentID = 0;
      }
      forceRedraw();
      return;
    }
    vars.selectedRow = -1;
    vars.deleteButtonEnabled = false;
    vars.highlightedRow = -1;
    const instrumentIndex = utils.getInstrumentIndexForYPos(y);
    const instr = vars.instruments[instrumentIndex];

    const side = utils.getSideForYPos(y);
    const startDate = utils.pxlToDate(
      utils.dateToPxl(vars.origStart) + x - vars.pt.x
    );

    if (utils.noOverlappingRuns(theRun, instr, side, startDate)) {
      theRun.instrumentID = instr.id;
      theRun.startDate = startDate;
      theRun.side = side;
      vars.somethingChanged = true;
    }
    forceRedraw();
  } else if (vars.panning) {
    let newY = vars.origDeltaY + e.offsetY - vars.pt.y;
    if (newY > 0) newY = 0;
    let minY = -(chartBottom - vars.canvasHeight + 16);
    if (minY > 0) minY = 0;
    if (newY < minY) newY = minY;
    let newdeltaX = vars.origDeltaX + e.offsetX - vars.pt.x;
    if (newdeltaX > 400) newdeltaX = 400; // two days worth
    if (newdeltaX < -200 * 7) newdeltaX = -200 * 7;
    vars.deltaX = newdeltaX;
    vars.deltaY = newY;
    forceRedraw();
  } else {
    if (e.offsetX < x0) {
      const ty = e.offsetY - (blPaddingY + blLabelHeight + 35);
      const idx = Math.floor((ty - vars.backlogDeltaY) / 20);
      const cnt = vars.runs.filter((x) => x.instrumentID === 0 && (!vars.showOnlyMyRuns || vars.currentUser === x.requester)).length;
      if (idx < cnt) vars.highlightedRow = idx;
      else vars.highlightedRow = -1;
      forceRedraw();
    } else {
      vars.highlightedRow = -1;
      // handle mouse-over

      if (e.offsetX > blPaddingX + blWidth) {
        const px = e.offsetX - vars.deltaX;
        const py = e.offsetY - vars.deltaY;
        const r = vars.runs
          .filter((x) => x.instrumentID !== 0)
          .find((v) => {
            const y = utils.getYForRun(v);
            const x = utils.dateToPxl(v.startDate);
            const w = utils.hoursToPxls(v.runLength);
            return px > x && px < x + w && py > y && py < y + rowHeight / 2;
          });

        if (r && r === vars.selectedRun) {
          const idx = vars.runs.indexOf(r);
          vars.dragPos = { x: e.offsetX, y: e.offsetY };
          vars.runForMouseOverIdx = idx;
        } else {
          if (vars.selectedRun != null) {
            vars.runForMouseOverIdx = -1;
          } else return;
        }
      }
      forceRedraw();
    }
  }
};

const handleDone = (forceRedraw, updatePlannedRun) => {
  if (vars.runBeingDraggedIdx >= 0) {
    if (updatePlannedRun) {
      if (vars.runBeingCoppied) {
        updatePlannedRun(vars.copiedRun);
      } else if (vars.somethingChanged) {
        updatePlannedRun(vars.runs[vars.runBeingDraggedIdx]);
      }
    } else {
      vars.runs[vars.runBeingDraggedIdx] = { ...vars.orig };
    }
    vars.runBeingDraggedIdx = -1;
    vars.somethingChanged = false;
    vars.runBeingCoppied = false;
    vars.copiedRun = null;
  }
  vars.panning = false;
  vars.draggingVScroll = false;
  vars.draggingHScroll = false;
  vars.draggingVBacklogScroll = false;
  forceRedraw();
};

export const keyUpHandler = (e, forceRedraw) => {
  if (vars.runBeingDraggedIdx > 0 && e.key === "Escape")
    handleDone(forceRedraw, null);
};

export const mouseUpHandler = (e, forceRedraw, updatePlannedRun) => {
  handleDone(forceRedraw, updatePlannedRun);
};

export const mouseExitHandler = (e, forceRedraw, updatePlannedRun) => {
  // if (e.offsetX > vars.canvasWidth - 16) return;
  // handleDone(forceRedraw, updatePlannedRun);
};

export const resizeHandler = (e) => {};
