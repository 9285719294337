import React, { useRef, useEffect } from "react";

import { Button } from "devextreme-react/button";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";

import {
  Chart,
  SeriesTemplate,
  Point,
  ArgumentAxis,
  Tooltip,
  Grid,
  Legend,
  Title,
  Subtitle,
  Export,
  Label,
  CommonSeriesSettings,
  Series,
  ZoomAndPan,
} from "devextreme-react/chart";

export const ReportChart = ({
  data,
  xField,
  yField,
  groupField,
  title,
  subtitle,
  height,
  graphType,
}) => {
  const chart = useRef(null);
  const ymin = useRef(null);
  const ymax = useRef(null);
  const xmin = useRef(null);
  const xmax = useRef(null);
  const showlegendRef = useRef(null);

  useEffect(() => {
    chart.current.instance.render();
  }, []);

  const sortLegendItems = (items) => {
    return items.sort((a, b) => {
      if (!isNaN(a.text) || !isNaN(b.text)) {
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
      }
      let itemA = a.text.toLowerCase();
      let itemB = b.text.toLowerCase();
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      return 0;
    });
  };

  const renderTooltip = (pointInfo) => {
    if (pointInfo && pointInfo.point && pointInfo.point.data) {
      const data = pointInfo.point.data;
      let keys = Object.keys(data);

      let items = keys.map((x, i) => (
        <div key={i} style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>{x}</div>
          <div style={{ flex: 1 }}>{data[x]}</div>
        </div>
      ));
      return <div>{items}</div>;
    }
    return <div />;
  };

  var argumentType = "numeric";
  if (xField.fieldType === "string") argumentType = "string";
  if (xField.fieldType === "date") argumentType = "time";
  var xFieldname = xField ? xField.name : "";
  var yFieldname = yField ? yField.name : "";
  var xFieldlabel = xField ? xField.label : "";
  var yFieldlabel = yField ? yField.label : "";
  var groupFieldname = groupField ? groupField.name : "";

  console.log("argumentType", argumentType);
  console.log("graphType", graphType);

  return (
    <div>
      <div
        style={{ display: "flex", marginLeft: "10px", alignItems: "center" }}
      >
        <span>X Min:&nbsp;</span>
        <NumberBox ref={xmin} showClearButton={true} />
        <span style={{ marginLeft: "20px" }}>X Max:&nbsp;</span>
        <NumberBox ref={xmax} showClearButton={true} />
        <Button
          style={{ marginLeft: "20px" }}
          onClick={(_) => {
            const chartToUpdate = chart.current.instance;
            var val = chartToUpdate.option("argumentAxis");
            console.log(val);
            const mn = xmin.current.instance.option("value");
            const mx = xmax.current.instance.option("value");
            val.wholeRange = { startValue: mn, endValue: mx };
            val.visualRange = { startValue: mn, endValue: mx };
            chartToUpdate.option("argumentAxis", val);
            chart.current.instance.render();
          }}
          text={"Apply"}
        />
      </div>

      <div
        style={{ display: "flex", marginLeft: "10px", alignItems: "center" }}
      >
        <span>Y Min:&nbsp;</span>
        <NumberBox ref={ymin} showClearButton={true} />
        <span style={{ marginLeft: "20px" }}>Y Max:&nbsp;</span>
        <NumberBox ref={ymax} showClearButton={true} />
        <Button
          style={{ marginLeft: "20px" }}
          onClick={(_) => {
            const chartToUpdate = chart.current.instance;
            var val = chartToUpdate.option("valueAxis");
            console.log(val);
            const mn = ymin.current.instance.option("value");
            const mx = ymax.current.instance.option("value");
            val.wholeRange = { startValue: mn, endValue: mx };
            val.visualRange = { startValue: mn, endValue: mx };
            chartToUpdate.option("valueAxis", val);
            chart.current.instance.render();
          }}
          text={"Apply"}
        />
      </div>

      <CheckBox
        ref={showlegendRef}
        text="Show Legend"
        defaultValue={true}
        onValueChanged={(v) => {
          var val = chart.current.instance.option("legend");
          val.visible = v.value;
          chart.current.instance.option("legend", val);
          chart.current.instance.render();
        }}
      />

      <Chart
        ref={chart}
        dataSource={data.values}
        height={height ?? "auto"}
        valueField={{ argumentType: "numeric", title: { yFieldlabel } }}
        onLegendClick={(e) => {
          const series = e.target;
          if (series.isVisible()) {
            series.hide();
          } else {
            series.show();
          }
        }}
        valueAxis={{ title: yFieldlabel }}
      >
        {title && (
          <Title text={title}>{subtitle && <Subtitle text={subtitle} />}</Title>
        )}
        <ArgumentAxis argumentType={argumentType} title={xFieldlabel}>
          <Label rotationAngle={30} overlappingBehavior="rotate" />
          <Grid visible={true} />
        </ArgumentAxis>

        {groupFieldname !== "" && (
          <CommonSeriesSettings
            name={"series"}
            argumentField={xFieldname}
            valueField={yFieldname}
            type={graphType.val}
          >
            <Point size={5} />
          </CommonSeriesSettings>
        )}
        {groupFieldname === "" && (
          <Series
            name={yField.name}
            argumentField={xFieldname}
            valueField={yFieldname}
            type={graphType.val}
          >
            <Point size={5} />
          </Series>
        )}
        {/* <Series name={""} argumentField="x" valueField="y"></Series> */}

        {groupFieldname === "" && <Legend visible={false} />}
        {groupFieldname !== "" && <SeriesTemplate nameField={groupFieldname} />}
        {groupFieldname !== "" && <Legend customizeItems={sortLegendItems} />}
        <Tooltip enabled={true} contentRender={renderTooltip} />
        <Export enabled={true} />

        <ZoomAndPan argumentAxis="both" valueAxis="both" />
      </Chart>
    </div>
  );
};
