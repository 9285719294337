import React, { useRef } from "react";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";
import CustomStore from "devextreme/data/custom_store";
import Button from "devextreme-react/button";

import DataGrid, {
  Editing,
  Column,
  Paging,
  Pager,
  ColumnChooser,
  FilterRow,
  GroupPanel,
  Export,
  StateStoring,
} from "devextreme-react/data-grid";

export const LibrariesForm = () => {
  const grid = useRef();

  const { fetchWithCheck, fetchNoResponse, user } = useAuth0();

  var loadData = async () => {
    return await fetchWithCheck("/libraries");
  };

  var updateData = async (key, values) => {
    await fetchNoResponse("/libraries/" + key, {
      method: "PATCH",
      body: JSON.stringify(values),
    });
  };

  var insertData = async (values) => {
    await fetchNoResponse("/libraries", {
      method: "POST",
      body: JSON.stringify(values),
    });
  };

  var removeData = async (key) => {
    await fetchNoResponse("/libraries/" + key, {
      method: "DELETE",
    });
  };

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
    update: updateData,
    insert: insertData,
    remove: removeData,
  });

  console.log({ user });
  let canEdit = user ? user.groups.includes("SeqDB-CanEditLibraries") : false;

  return (
    <div>
      <br />
      <h3>Manage Libraries</h3>

      <Button
        onClick={() => {
          grid.current.instance.state({});
        }}
        text="Reset Table Formatting"
      />
      <br />
      <br />

      <DataGrid
        ref={grid}
        dataSource={customStore}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="librariesTable"
        />

        <FilterRow visible={true} />

        <GroupPanel visible={true} />

        <Export
          enabled={true}
          fileName={"Libraries"}
          allowExportSelectedData={true}
        />

        <Editing
          mode="batch"
          allowAdding={canEdit}
          allowDeleting={canEdit}
          allowUpdating={canEdit}
        />
        <Column dataField={"name"} />
        <Column dataField={"description"} />
        <Column dataField={"input"} />
        <Column dataField={"vendor"} />
        <Column dataField={"workflow"} />
        <Column dataField={"assay"} />
        <Column dataField={"preparedBy"} />
        <Column dataField={"surfacePrimers"} />
        <Column dataField={"seqPrimerRead1"} />
        <Column dataField={"seqPrimerRead2"} />
        <Column dataField={"splint"} />
        <Column dataField={"condenserOligos"} />
        <Column dataField={"index1"} />
        <Column dataField={"index2"} />
        <Column dataField={"status"} />
        <Column
          dataField={"created"}
          allowEditing={false}
          dataType={"date"}
          format={"dd MMM yyyy"}
        />

        <ColumnChooser enabled={true} />

        <Paging defaultPageSize={40} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[20, 40, 100, 200]}
        />
      </DataGrid>

      <Button
        onClick={async () => {
          const text = await navigator.clipboard.readText();
          var rows = text.split("\n");
          console.log("rows :" + rows);
          for (var y in rows) {
            var cells = rows[y].split("\t");
            if (cells && cells.length === 13) {
              customStore.insert({
                name: cells[0],
                description: cells[1],
                input: cells[2],
                vendor: cells[3],
                workflow: cells[4],
                assay: cells[5],
                preparedBy: cells[6],
                surfacePrimers: cells[7],
                seqPrimerRead1: cells[8],
                seqPrimerRead2: cells[9],
                splint: cells[10],
                condenserOligos: cells[11],
                status: cells[12],
              });
            } else {
              console.log("wrong length");
            }
          }
          grid.current.instance.refresh();
        }}
        text="Paste values"
      />
      <br />
      <br />
    </div>
  );
};

/*
$(function(){

  bindGrid();
  const editor = document.getElementById('gridContainer');

editor.onpaste = logPaste;
});

function bindGrid(){
  $("#gridContainer").dxDataGrid({
        dataSource: customers,
        columns: ["FirstName", "LastName", "Age", "Email"],
        showBorders: true
    });
}

function logPaste(event) {
  console.log(event);
  var clipboardData, pastedData;

    event.stopPropagation();
    event.preventDefault();

    clipboardData = event.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData('Text');

  var data = pastedData;

  var rows = data.split("\n");
  console.log("rows :" + rows);
  for(var y in rows) {
    var cells = rows[y].split("\t");
    if(cells !== "" || cells !== null){
      console.log("cells :" + cells);
      console.log("customers :" + customers);
      customers.push({"FirstName":cells[0],"LastName":cells[1],"Age":cells[2],"Email":cells[3]});
    }
  }
  bindGrid();
}

var customers = [{
    "FirstName": 1,
    "LastName": "Super Mart of the West",
    "Age": "702 SW 8th Street",
    "Email": "Bentonville"
}, {
    "FirstName": 1,
    "LastName": "Super Mart of the West",
    "Age": "702 SW 8th Street",
    "Email": "Bentonville"
}];
*/
