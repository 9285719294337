import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "../react-auth0-spa";
import Tabs from "devextreme-react/tabs";

import "devextreme-react/text-area";
import "devextreme-react/html-editor";
import DataGrid, {
  Column,
  ColumnChooser,
  Format,
  Export,
  HeaderFilter,
  FilterPanel,
  FilterBuilder,
} from "devextreme-react/data-grid";
import { PrimerQCDetailsTable } from "./PolonyQC/PrimerQCDetailsTable";

export const FlowcellPrimerQC = (props) => {
  const { loading, fetchWithCheck } = useAuth0();
  const [data, setData] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const callApi = async () => {
      const v = await fetchWithCheck("/flowcells/" + props.id + "/primerQC");
      setData(v);

      if (v) {
        var temp = [];
        var i;
        for (i = 0; i < v.length; i++) {
          temp.push(v[i].primer);
        }
        setTabs(temp);
      }
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, props.id]);

  const onSelectedTabChange = useCallback(({ name, value }) => {
    if (name === "selectedIndex") {
      setCurrentTab(value);
    }
  }, []);

  const filtNeg999s = (data) => {
    if (data.value === -999) {
      return <div></div>;
    }
    return <div>{data.text}</div>;
  };

  return (
    <React.Fragment>
      <Tabs
        dataSource={tabs}
        selectedIndex={currentTab}
        onOptionChanged={onSelectedTabChange}
        style={{ width: "400px" }}
        className={"widget"}
      />
      {data && <PrimerQCDetailsTable details={data[currentTab]} />}
      <br />

      {data && data[currentTab].lanes && (
        <DataGrid
          dataSource={data[currentTab].lanes}
          keyExpr="Lane"
          wordWrapEnabled={true}
          showBorders={true}
          showColumnLines={true}
          showRowLines={true}
          rowAlternationEnabled={true}
          focusedRowEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode={"widget"}
          columnHidingEnabled={true}
          columnAutoWidth={true}
          hoverStateEnabled={false}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <HeaderFilter visible={false} />
          <ColumnChooser enabled={false} />
          <FilterPanel visible={false} />
          <FilterBuilder visible={false} />
          <Column dataField={"Lane"} allowEditing={false} />
          <Column dataField={"Median"} allowEditing={false} />
          <Column dataField={"Mean"} allowEditing={false} />
          <Column
            dataField={"CV"}
            allowEditing={false}
            cellRender={filtNeg999s}
          >
            <Format type="percent" precision={1} />
          </Column>
          <Column
            dataField={"Density"}
            allowEditing={false}
            cellRender={filtNeg999s}
          >
            <Format type="fixedPoint" precision={1} />
          </Column>
        </DataGrid>
      )}
    </React.Fragment>
  );
};
