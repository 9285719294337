import React, { useState } from "react";
import "../../App.css";
import { RadioGroup } from "devextreme-react";
import { DashboardContainer } from "./DashboardContainer";
import { SingleChart } from "./SingleChart";
import { MultiQueryFieldChart } from "./MultiQueryFieldChart";

export const PlotWithFieldOptions = (props) => {
  const id = props.id;
  const [currentField, setCurrentField] = useState(props.options[0].field);

  return (
    <div>
      <RadioGroup
        valueExpr="field"
        displayExpr="label"
        items={props.options}
        defaultValue={props.options[0].field}
        layout="horizontal"
        onValueChanged={(e) => {
          setCurrentField(e.value);
        }}
      />
      <br />
      <br />
      <DashboardContainer title={props.title}>
        <SingleChart
          numTiles={5}
          id={id}
          title={props.runInfo.name}
          field={currentField}
          runInfo={props.runInfo}
          yLabel={
            props.options.filter((x) => x.field === currentField)[0].label
          }
          multby100={currentField.includes("ercent")}
        />
        <MultiQueryFieldChart
          id={id}
          runName={props.runInfo.name}
          runInfo={props.runInfo}
          multby100={currentField.includes("ercent")}
          yLabel={
            props.options.filter((x) => x.field === currentField)[0].label
          }
          fields={currentField}
          labels={
            props.options.filter((x) => x.field === currentField)[0].label
          }
        />
      </DashboardContainer>
    </div>
  );
};
