import React, { useEffect, useRef } from "react";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";
import { Link } from "react-router-dom";
import Button from "devextreme-react/button";
import CustomStore from "devextreme/data/custom_store";

//import SelectBox from 'devextreme-react/select-box';
//import { StatusImages } from "./StatusImages"
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  Paging,
  Pager,
  GroupPanel,
  GroupItem,
  Format,
  Summary,
  StateStoring,
  Export,
  //   RequiredRule
} from "devextreme-react/data-grid";
import SelectBox from "devextreme-react/select-box";

const dispositionOptions = [
  "Unresolved",
  "Fixed",
  "Addressed in Pilot",
  "No Action",
];

const handleNaN = (e) => {
  if (isNaN(e.value) || e.value <= 0) return "";
  return e.valueText;
};
const handleNaN2 = (e) => {
  if (isNaN(e.value) || e.value < 0) return "";
  return e.valueText;
};
export const PilotTrackingDashboard = (props) => {
  const { fetchWithCheck, fetchNoResponse } = useAuth0();
  const theGrid = useRef(null);

  const handleResize = React.useCallback(() => {
    if (theGrid) {
      theGrid.current.instance.option("height", window.innerHeight - 250);
    }
  }, [theGrid]);

  // useEffect(() => {
  //   const callAPI = async () => {
  //     setData(d);
  //     handleResize();
  //   };
  //   if (!loading) callAPI();
  // }, [loading, fetchNoJson, props.id, handleResize]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  var loadData = async () => {
    const data = await fetchWithCheck("/pilotTracking");
    if (data) {
      for (let i = 0; i < data.length; i++) {
        data[
          i
        ].runLength = `${data[i].numCyclesR1}+${data[i].numCyclesR2},${data[i].numCyclesI1}+${data[i].numCyclesI2}`;

        if (data[i].percentQ30R1 > 0 && data[i].percentQ30R2 > 0)
          data[i].percentQ30 =
            (data[i].percentQ30R1 + data[i].percentQ30R2) / 2;
        else data[i].percentQ30 = NaN;
      }
    }
    return data;
  };

  var updateData = async (key, values) => {
    console.log(key);
    console.log(JSON.stringify(values));
    await fetchNoResponse("/runs/" + key, {
      method: "PATCH",
      body: JSON.stringify(values),
    });
  };

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
    update: updateData,
  });

  const cellRenderRunName = (cell) => {
    if (!cell.value) {
      return <div></div>;
    }
    return (
      <Link to={"/runs/" + cell.value + "?v=" + cell.data.analysisVersion}>
        {cell.value}
      </Link>
    );
  };

  return (
    <div>
      <br />
      <h3>Pilot Tracking Dashboard</h3>
      <Button
        onClick={() => {
          theGrid.current.instance.state({});
        }}
        text="Reset Table Formatting"
      />
      <br />
      <br />

      <DataGrid
        dataSource={customStore}
        ref={theGrid}
        wordWrapEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={"widget"}
        columnHidingEnabled={true}
        columnAutoWidth={true}
        columnFixing={{ enabled: true }}
        headerFilter={{ visible: true }}
        onCellPrepared={(e) => {
          if (e.rowType !== "data") return;

          if (
            e.column.name === "badTilesR1" ||
            e.column.name === "badTilesR2"
          ) {
            if (e.value >= 0) {
              if (e.value > 10) e.cellElement.style.backgroundColor = "red";
              else if (e.value <= 10)
                e.cellElement.style.backgroundColor = "green";
              e.cellElement.style.color = "white";
            }
          }

          if (e.column.name === "percentIndexAssigned") {
            if (e.value > 0) {
              if (e.value < 0.9) e.cellElement.style.backgroundColor = "red";
              else if (e.value < 0.95)
                e.cellElement.style.backgroundColor = "orange";
              else if (e.value >= 0.95)
                e.cellElement.style.backgroundColor = "green";
              e.cellElement.style.color = "white";
            }
          }

          if (e.column.name === "percentQ30") {
            if (e.value > 0) {
              if (e.value < 0.8) e.cellElement.style.backgroundColor = "red";
              else if (e.value < 0.85)
                e.cellElement.style.backgroundColor = "orange";
              else if (e.value >= 0.85)
                e.cellElement.style.backgroundColor = "green";
              e.cellElement.style.color = "white";
            }
          }
          if (e.column.name === "percentQ30R1") {
            if (e.value > 0) {
              if (e.value < 0.85) e.cellElement.style.backgroundColor = "red";
              else if (e.value < 0.92)
                e.cellElement.style.backgroundColor = "orange";
              else if (e.value >= 0.92)
                e.cellElement.style.backgroundColor = "green";
              e.cellElement.style.color = "white";
            }
          }
          if (e.column.name === "percentQ30R2") {
            if (e.value > 0) {
              if (e.value < 0.78) e.cellElement.style.backgroundColor = "red";
              else if (e.value < 0.85)
                e.cellElement.style.backgroundColor = "orange";
              else if (e.value >= 0.85)
                e.cellElement.style.backgroundColor = "green";
              e.cellElement.style.color = "white";
            }
          }
          if (
            e.column.name === "percentErrorR1" ||
            e.column.name === "percentErrorR2"
          ) {
            if (e.value > 0) {
              if (e.value < 0.01) e.cellElement.style.backgroundColor = "green";
              else if (e.value >= 0.01)
                e.cellElement.style.backgroundColor = "red";
              e.cellElement.style.color = "white";
            }
          }
          if (e.column.name === "ratioR2R1") {
            if (e.value >= 1) e.cellElement.style.backgroundColor = "green";
            else if (e.value >= 0.8)
              e.cellElement.style.backgroundColor = "orange";
            else if (e.value < 0.8) e.cellElement.style.backgroundColor = "red";
            e.cellElement.style.color = "white";
          }
          if (e.column.name === "extrapolatedReads") {
            if (e.value > 0) {
              if (e.value >= 800) e.cellElement.style.backgroundColor = "green";
              else if (e.value < 800)
                e.cellElement.style.backgroundColor = "red";
              e.cellElement.style.color = "white";
            }
          }

          if (e.column.name === "errorMode") {
            if (e.value === "No Error")
              e.cellElement.style.backgroundColor = "green";
            else e.cellElement.style.backgroundColor = "red";
            e.cellElement.style.color = "white";
          }

          if (e.column.name === "disposition") {
            if (e.value === "No Action") {
              // do nothing
            } else {
              if (e.value === "Fixed")
                e.cellElement.style.backgroundColor = "green";
              else if (e.value === "Unresolved")
                e.cellElement.style.backgroundColor = "red";
              else e.cellElement.style.backgroundColor = "orange";
              e.cellElement.style.color = "white";
            }
          }

          if (e.column.name === "runCompleteStatus") {
            if (e.value) {
              if (e.value === "Run Complete")
                e.cellElement.style.backgroundColor = "green";
              else if (e.value.startsWith("Run Complete"))
                e.cellElement.style.backgroundColor = "orange";
              else e.cellElement.style.backgroundColor = "red";
              e.cellElement.style.color = "white";
            }
          }

          if (
            e.column.name === "chemistryCycleTime" ||
            e.column.name === "imagingCycleTime"
          ) {
            if (e.value > 0) {
              if (e.value <= 3.5) e.cellElement.style.backgroundColor = "green";
              else if (e.value <= 4.8)
                e.cellElement.style.backgroundColor = "orange";
              // else if (e.value.startsWith("Run Complete"))
              //   e.cellElement.style.backgroundColor = "orange";
              else if (e.value > 4.8)
                e.cellElement.style.backgroundColor = "red";
              e.cellElement.style.color = "white";
            }
          }
        }}
      >
        <Editing
          mode="batch" //"form" "batch" "popup" "cell"
          allowUpdating={true}
          allowAdding={false}
          allowDeleting={false}
        />

        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="pilotTrackingv3"
        />

        <FilterRow visible={true} />

        <GroupPanel visible={true} />
        <Export
          enabled={true}
          fileName={"PilotRuns"}
          allowExportSelectedData={true}
        />

        <ColumnChooser enabled={true} />

        <Column
          dataField={"runName"}
          cellRender={cellRenderRunName}
          fixed={true}
          fixedPosition="left"
          width={161}
          allowEditing={false}
        />
        <Column
          dataField={"instrument"}
          width={93}
          allowEditing={false}
          groupIndex={0}
        />
        <Column
          dataField={"side"}
          width={93}
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              dataSource={["A", "B"]}
              acceptCustomValue={false}
              searchEnabled={false}
              onValueChanged={(e) => cell.setValue(e.value)}
              itemRender={(data) => <span>{data}</span>}
            />
          )}
        />
        <Column
          dataField={"dateStarted"}
          dataType={"date"}
          caption="Run Date"
          format={"dd MMM yyyy HH:mm"}
          allowEditing={false}
          width={125}
          visible={true}
        />

        <Column dataField={"library"} width={250} allowEditing={false} />
        <Column
          dataField={"libraryConcentration"}
          width={150}
          allowEditing={false}
        />

        <Column dataField="runLength" allowEditing={false} width={140} />

        <Column
          dataField={"numCyclesR1"}
          caption={"Cycles R1"}
          allowEditing={false}
          visible={false}
          width={68}
        />
        <Column
          dataField={"numCyclesR2"}
          caption={"Cycles R2"}
          allowEditing={false}
          visible={false}
          width={68}
        />
        <Column
          dataField={"numCyclesI1"}
          caption={"Cycles I1"}
          allowEditing={false}
          width={68}
          visible={false}
        />
        <Column
          dataField={"numCyclesI2"}
          caption={"Cycles I2"}
          allowEditing={false}
          visible={false}
          width={68}
        />
        <Column dataField={"numTiles"} width={68} allowEditing={false} />

        <Column
          dataField={"imagingCycleTime"}
          caption="Imaging Cycle Time (min)"
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>
        <Column
          dataField={"chemistryCycleTime"}
          caption="Chemistry Cycle Time (min)"
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>
        <Column
          dataField={"cycleTime"}
          caption="Cycle Time (min)"
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>
        <Column
          dataField={"runTime"}
          caption="Run Time (hrs)"
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>

        <Column
          dataField={"r2OverR1"}
          caption={"R2/R1"}
          allowEditing={false}
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>

        <Column dataField={"runDescription"} width={250} />

        <Column
          dataField={"percentQ30"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        >
          <Format type="percent" precision={2} />
        </Column>
        <Column
          dataField={"percentQ30R1"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        >
          <Format type="percent" precision={2} />
        </Column>
        <Column
          dataField={"percentQ30R2"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        >
          <Format type="percent" precision={2} />
        </Column>
        <Column
          dataField={"percentErrorR1"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        >
          <Format type="percent" precision={4} />
        </Column>
        <Column
          dataField={"percentErrorR2"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        >
          <Format type="percent" precision={4} />
        </Column>
        <Column
          dataField={"extrapolatedReads"}
          width={100}
          caption="Extrapolated Reads (M)"
          allowEditing={false}
          customizeText={handleNaN}
        >
          <Format precision={1} />
        </Column>
        <Column
          dataField={"percentIndexAssigned"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN}
        >
          <Format type="percent" precision={4} />
        </Column>
        <Column
          dataField={"badTilesR1"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN2}
        />
        <Column
          dataField={"badTilesR2"}
          width={100}
          allowEditing={false}
          customizeText={handleNaN2}
        />

        <Column
          dataField={"runCompleteStatus"}
          width={139}
          allowEditing={false}
          visible={false}
        />
        <Column
          dataField={"errorMode"}
          width={139}
          allowEditing={false}
          visible={false}
        />

        <Column dataField={"comment"} width={250} visible={false} />

        <Column
          visible={false}
          dataField="disposition"
          width={160}
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              dataSource={dispositionOptions}
              acceptCustomValue={true}
              searchEnabled={true}
              onCustomItemCreating={(e) => {
                e.customItem = e.text;
              }}
              onValueChanged={(e) => cell.setValue(e.value)}
              itemRender={(data) => <span>{data}</span>}
            />
          )}
        ></Column>

        <Column dataField={"dispositionComment"} width={250} visible={false} />
        <Summary>
          <GroupItem
            column={"title"}
            summaryType={"count"}
            displayFormat={"{0}"}
          />
        </Summary>

        <Paging defaultPageSize={100} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[100, 3000, "all"]}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
    </div>
  );
};
