import React, { useState, useEffect } from "react";
import "../../App.css";
import { useAuth0 } from "../../react-auth0-spa";

import { DualSurfaceFlowcell } from "../Reports/DualSurfaceFlowcell";
import { Flowcell } from "../Reports/Flowcell";

export const FlowcellPanel = (props) => {
  const id = props.id;
  const numcycles = props.runInfo.numcycles;
  const runName = props.runInfo.name;
  const fcid = props.runInfo.flowcellID;
  const { fetchWithCheck, loading } = useAuth0();
  const [data, setData] = useState([]);

  useEffect(() => {
    const callApi = async () => {
      const tmp = await fetchWithCheck("/analyses/" + id + "/tile");
      setData(tmp);
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, id]);

  return (
    <div title="Flowcell View" style={{ display: "flex" }}>
      {data && (
        <React.Fragment>
          <div style={{ flex: "1" }}>
            <DualSurfaceFlowcell
              id={id}
              numcycles={numcycles}
              data={data}
              field="errorRate"
              runName={runName}
              runInfo={props.runInfo}
            />
          </div>
          <div style={{ flex: "1", width: "50px" }} />
          <div style={{ flex: "1" }}>
            <DualSurfaceFlowcell
              id={id}
              numcycles={numcycles}
              data={data}
              field="polonyDensity"
              runName={runName}
              runInfo={props.runInfo}
            />
          </div>
          <div style={{ flex: "1", width: "50px" }} />
          <div style={{ flex: "1" }}>
            Flowcell Metrology Data <br />
            <br />
            <br />
            <Flowcell id={fcid} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
