import SelectBox from "devextreme-react/select-box";

export const TestForm = () => {
  return (
    <SelectBox
      dataSource={[
        { name: "one", iD: 1 },
        { name: "two", iD: 2 },
      ]}
      style={{ marginLeft: 0, marginRight: 0 }}
      displayExpr="name"
      valueExpr="iD"
      //  value={state.connectionID}
      // onSelectionChanged={(e) => {
      //   console.log('seleciton changed', e);
      //   if (e.selectedItem) {
      //     setState((v) => ({ ...v, connectionID: e.selectedItem.connectionID }));
      //   }
      // }}
      // fieldRender={ConnectionField}
      // itemRender={ConnectionItem}
    />
  );
};

/*
const resourcesData = [
    {
      text: 'Applications',
      id: 1,
      color: palette.simpleSet[0],
    }, {
      text: 'System Integration',
      id: 2,
      color: palette.simpleSet[5],
    }, {
      text: 'Biochemistry',
      id: 3,
      color: palette.simpleSet[2],
    }, {
        text: 'Discovery',
        color: palette.simpleSet[3],
        id: 4,
      }, {
        text: 'Engineering',
        color: palette.simpleSet[4],
        id: 5
      }
  ];
  
const instrumentData = [
    {
      text: 'P1-01',
      id: 1,
    }, {
        text: 'P1-02',
        id: 2,
      }, {
        text: 'P1-03',
        id: 3,
      }, {
        text: 'P1-04',
        id: 4,
      }, {
        text: 'P1-05',
        id: 5,
      },
  ];

const data = [
  {
    text: "YL191",
    ownerId: 2,
    startDate: new Date("2021-03-26T16:00:00.000Z"),
    endDate: new Date("2021-03-27T17:30:00.000Z"),
    description: "this is a test",
    instrument: 1,
  },
  {
    text: "LE201",
    ownerId: 1,
    startDate: new Date("2021-03-25T18:30:00.000Z"),
    endDate: new Date("2021-03-27T21:15:00.000Z"),
    instrument: 2,
  },
  {
    text: "LE203",
    ownerId: 4,
    startDate: new Date("2021-03-24T20:15:00.000Z"),
    endDate: new Date("2021-03-25T23:15:00.000Z"),
    instrument: 3,
  },
  {
    text: "SI151",
    ownerId: 3,
    startDate: new Date("2021-03-23T23:45:00.000Z"),
    endDate: new Date("2021-03-25T18:15:00.000Z"),
    instrument: 4,
  },
  {
    text: "SI152",
    ownerId: 5,
    startDate: new Date("2021-03-23T23:45:00.000Z"),
    endDate: new Date("2021-03-26T18:15:00.000Z"),
    instrument: 5,
  },
];
*/
