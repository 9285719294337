import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";
import CustomStore from "devextreme/data/custom_store";
import { Link } from "react-router-dom";
import TagBox from "devextreme-react/tag-box";
import Button from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";
import DataSource from "devextreme/data/data_source";

//import SelectBox from 'devextreme-react/select-box';
//import { StatusImages } from "./StatusImages"
import "devextreme-react/text-area";
import "devextreme-react/html-editor";
import DataGrid, {
  Editing,
  Column,
  ColumnChooser,
  FilterRow,
  Paging,
  Pager,
  FormItem,
  GroupPanel,
  GroupItem,
  Summary,
  Lookup,
  StateStoring,
  Export,
  SearchPanel,
  //   RequiredRule
} from "devextreme-react/data-grid";

const htmlEditorOptions = {
  height: 310,
  toolbar: {
    items: [
      "undo",
      "redo",
      "separator",
      "separator",
      "bold",
      "italic",
      "strike",
      "underline",
      "separator",
      "alignLeft",
      "alignRight",
      "alignJustify",
      "separator",
      "orderedList",
      "bulletList",
      "separator",
      "color",
      "background",
    ],
  },
};

const fields = [
  { name: "Last Month", value: 1 },
  { name: "Last 3 Months", value: 3 },
  { name: "Last 6 Months", value: 6 },
  { name: "Last Year", value: 12 },
  { name: "All", value: 0 },
];

const handleNaN = (e) => {
  if (isNaN(e.value)) return "";
  if (e.value === 0) return "";
  return e.valueText;
};

export const SequencingRunsForm = (props) => {
  const { loading, fetchWithCheck, fetchNoResponse } = useAuth0();
  const [all, setAll] = useState(null);
  const [numMonths, setNumMonths] = useState(1);
  const theGrid = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (theGrid) {
        theGrid.current.instance.option("height", window.innerHeight - 250);
      }
    };
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const callApi = async () => {
      setAll(await fetchWithCheck("/lookups"));
      if (theGrid) {
        theGrid.current.instance.option("height", window.innerHeight - 250);
      }
    };

    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck]);

  var loadData = async () => {
    return await fetchWithCheck("/runs?numMonths=" + numMonths);
  };

  var updateData = async (key, values) => {
    await fetchNoResponse("/runs/" + key, {
      method: "PATCH",
      body: JSON.stringify(values),
    });
  };

  const deleteData = async (key) => {
    await fetchNoResponse("/runs/" + key, {
      method: "DELETE",
    });
  };

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
    update: updateData,
    remove: deleteData,
  });

  const tagEditorRender = (cell) => {
    return (
      <TagBox
        dataSource={all.tags}
        defaultValue={cell.value}
        onValueChanged={(e) => cell.setValue(e.value)}
        showSelectionControls={false}
        searchEnabled={true}
        placeholder="Choose Tags..."
      />
    );
  };

  const cellRenderAnalysis = (cell) => {
    if (!cell.value) {
      return <div></div>;
    }
    const items = cell.value.map((x) => (
      <React.Fragment>
        <Link to={"/runs/" + x.runName + "?v=" + x.version}>
          {x.name} (v{x.version})
        </Link>
        <br />
      </React.Fragment>
    ));
    return <div>{items}</div>;
  };

  const cellRenderRunName = (cell) => {
    if (!cell.value) {
      return <div></div>;
    }
    return <Link to={"/runs/" + cell.value}>{cell.value}</Link>;
  };

  const cellRenderFlowcell = (cell) => {
    if (!cell.value) {
      return <div />;
    }
    return <Link to={"/flowcells/" + cell.value}>{cell.text}</Link>;
  };

  const calculateFilterExpression = (
    filterValues,
    selectedFilterOperation,
    target
  ) => {
    return function (itemData) {
      var array1 = itemData.tags;
      var array2 = filterValues;

      if (array2.length === 0) return true;

      if (array1 === null) return false;

      return array2.every(function (value) {
        return array1.includes(value);
      });
    };
  };

  // const filtNeg999s = (data) => {
  //     if (data.value === -999) {
  //         return <div></div>
  //     }
  //     return <div>{data.text}</div>
  // }

  const calculateFilterExpressionFlowcell = (
    filterValues,
    selectedFilterOperation
  ) => {
    return function (itemData) {
      return itemData.flowcell.includes(filterValues);
    };
  };

  const editorPreparing = (e) => {
    if (all && e.parentType === "filterRow" && e.dataField === "tags") {
      e.editorName = "dxTagBox";
      e.editorOptions.dataSource = all.tags;
      e.editorOptions.showSelectionControls = true;
      // e.editorOptions.displayExpr = "Name";
      // e.editorOptions.valueExpr = "ID";
      e.editorOptions.value = e.value || [];
      e.editorOptions.onValueChanged = function (args) {
        e.setValue(args.value);
      };
    }
    if (e.parentType === "filterRow" && e.dataField === "flowcellID") {
      e.editorName = "dxTextBox";
      e.editorOptions.value = e.value || [];
      e.editorOptions.onValueChanged = function (args) {
        e.setValue(args.value);
      };
    }
  };

  var flowcellLookup = [];
  var instrumentLookup = [];
  var operatorLookup = [];
  var errorModeLookup = [];
  var libraryLookup = [];
  var runCompleteStatusLookup = [];
  if (all) {
    flowcellLookup = all.flowcells;
    instrumentLookup = all.instruments;
    operatorLookup = all.operators;
    errorModeLookup = all.errorModes;
    libraryLookup = all.libraries;
    runCompleteStatusLookup = all.runCompleteStatuses;
  }

  const selectBoxData = new DataSource({
    store: flowcellLookup,
    key: "id",
    paginate: true,
    pageSize: 10,
  });

  return (
    <div>
      <br />
      <h3>Sequencing Runs</h3>
      <Button
        onClick={() => {
          theGrid.current.instance.state({});
        }}
        text="Reset Table Formatting"
      />
      <br />
      <br />

      <SelectBox
        value={numMonths}
        valueExpr="value"
        displayExpr="name"
        placeholder="Select a value..."
        showClearButton={false}
        dataSource={fields}
        text="Data to query"
        onValueChanged={(v) => {
          setNumMonths(v.value);
          theGrid.current.instance.getDataSource().reload();
        }}
        style={{ width: "150px" }}
      />

      <DataGrid
        dataSource={customStore}
        ref={theGrid}
        //onRowInserted={onRowInserted}
        wordWrapEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={"widget"}
        columnHidingEnabled={true}
        columnAutoWidth={true}
        headerFilter={{ visible: true }}
        onEditorPreparing={editorPreparing}
        columnFixing={{ enabled: true }}
        scrolling={{ showScrollbar: "always" }}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="seqRunsTablev9"
        />

        <SearchPanel visible={false} />
        <FilterRow visible={true} />

        <GroupPanel visible={true} />
        <Export
          enabled={true}
          fileName={"SequencingRuns"}
          allowExportSelectedData={true}
        />

        <ColumnChooser enabled={true} />

        <Editing
          mode={"batch" /* 'batch' | 'cell' | 'form' | 'popup' | 'row' */}
          allowUpdating={true}
          allowDeleting={true}
          useIcons={true}
        />

        <Column
          dataField={"runName"}
          cellRender={cellRenderRunName}
          allowEditing={false}
          fixed={true}
          fixedPosition="left"
          width={161}
        />

        <Column
          dataField={"runTime"}
          width={125}
          format={{ type: "fixedPoint", precision: 2 }}
          customizeText={handleNaN}
        />

        <Column dataField={"runDescription"} width={250} />

        <Column dataField={"recipeBranch"} width={125} />

        <Column dataField={"instrumentID"} caption={"Instrument"} width={93}>
          <Lookup
            dataSource={instrumentLookup}
            displayExpr="name"
            valueExpr="id"
          />
        </Column>

        <Column dataField={"side"} width={93}>
          <Lookup dataSource={["", "A", "B"]} />
        </Column>

        <Column
          dataField={"flowcellID"}
          caption={"Flowcell"}
          allowEditing={true}
          cellRender={cellRenderFlowcell}
          editCellRender={(cell) => {
            console.log(cell);
            return (
              <SelectBox
                defaultValue={cell.value}
                valueExpr="id"
                displayExpr="serialNumber"
                dataSource={selectBoxData}
                acceptCustomValue={false}
                searchEnabled={true}
                onValueChanged={(e) => cell.setValue(e.value)}
                itemRender={(data) => <span>{data.serialNumber}</span>}
              />
            );
          }}
          width={103}
          calculateFilterExpression={calculateFilterExpressionFlowcell}
        >
          <Lookup
            dataSource={flowcellLookup}
            displayExpr="serialNumber"
            valueExpr="id"
          />
        </Column>
        <Column
          dataField={"analyses"}
          width={297}
          cellRender={cellRenderAnalysis}
          editCellRender={cellRenderAnalysis}
          allowEditing={false}
        />
        <Column
          dataField={"lastAnalysisDate"}
          dataType={"date"}
          format={"dd MMM yyyy HH:mm"}
          width={139}
          allowEditing={false}
          customizeText={(e) => {
            if (e.value < new Date(2019, 2, 2)) return "";
            return e.valueText;
          }}
        />

        <Column
          dataField="runCompleteStatusID"
          caption="Complete Status"
          width={130}
          // editCellRender={statusEditorRender}
          // cellRender={cellRender}
        >
          <Lookup
            dataSource={runCompleteStatusLookup}
            displayExpr="status"
            valueExpr="id"
          />
        </Column>

        <Column dataField={"errorModeID"} caption="Error Mode" width={130}>
          <Lookup
            dataSource={errorModeLookup}
            displayExpr="errorMode"
            valueExpr="id"
          />
        </Column>
        <Column dataField={"comment"} width={550}>
          <FormItem
            colSpan={2}
            editorType="dxTextArea"
            editorOptions={{ height: 100 }}
          />
        </Column>

        <Column
          dataField={"dateStarted"}
          dataType={"date"}
          caption="Run Date"
          format={"dd MMM yyyy HH:mm"}
          allowEditing={false}
          width={125}
          visible={true}
        />
        {/* <Column
                    dataField={"dateFinished"}
                    dataType={"date"}
                    caption="Run Finished"
                    format={"dd MMM yyyy HH:mm"}
                    allowEditing={false}
                    visible={false}
                /> */}
        <Column dataField={"operatorID"} caption={"Operator"} visible={false}>
          <Lookup
            dataSource={operatorLookup}
            displayExpr="name"
            valueExpr="id"
          />
        </Column>
        <Column dataField={"numTiles"} allowEditing={false} width={59} />
        <Column
          dataField={"cyclesR1"}
          caption={"Cycles R1"}
          allowEditing={false}
          width={68}
        />
        <Column
          dataField={"cyclesR2"}
          caption={"Cycles R2"}
          allowEditing={false}
          width={68}
        />
        <Column
          dataField={"cyclesI1"}
          caption={"Cycles I1"}
          allowEditing={false}
          width={68}
        />
        <Column
          dataField={"cyclesI2"}
          caption={"Cycles I2"}
          allowEditing={false}
          width={68}
        />
        {/* <Column
                    dataField={"errorPercentR1"}
                    caption={"C65 Err R1"}
                    format={{type:"percent", precision:2}}
                    allowEditing={false}
                    cellRender={filtNeg999s}
                />
                <Column
                    dataField={"errorPercentR2"}
                    caption={"C65 Err R2"}
                    format={{type:"percent", precision:2}}
                    allowEditing={false}
                    cellRender={filtNeg999s}
                /> */}
        <Column
          dataField={"tags"}
          editCellRender={tagEditorRender}
          width={376}
          allowSorting={false}
          calculateFilterExpression={calculateFilterExpression}
        >
          <FormItem colSpan={2} />
        </Column>
        <Column dataField={"task"} visible={false} />
        <Column
          dataField={"l1LibraryID"}
          caption="Lane 1 Library"
          width={220}
          allowEditing={false}
        >
          <Lookup
            dataSource={libraryLookup}
            displayExpr="name"
            valueExpr="id"
          />
        </Column>

        <Column
          dataField={"l1SpikeInLibraryID"}
          caption="Lane 1 Spike-inLibrary"
          width={220}
          allowEditing={false}
        >
          <Lookup
            dataSource={libraryLookup}
            displayExpr="name"
            valueExpr="id"
          />
        </Column>

        <Column
          dataField={"l2LibraryID"}
          caption="Lane 2 Library"
          width={220}
          allowEditing={false}
        >
          <Lookup
            dataSource={libraryLookup}
            displayExpr="name"
            valueExpr="id"
          />
        </Column>

        <Column
          dataField={"l2SpikeInLibraryID"}
          caption="Lane 2 Spike-in Library"
          width={220}
          allowEditing={false}
        >
          <Lookup
            dataSource={libraryLookup}
            displayExpr="name"
            valueExpr="id"
          />
        </Column>
        <Column dataField={"chemistry"} width={300} visible={false}></Column>

        <Column dataField={"summary"} width={450} visible={false}>
          <FormItem
            colSpan={2}
            editorType="dxHtmlEditor"
            editorOptions={htmlEditorOptions}
          />
        </Column>

        <Column dataField="currentRead" allowEditing={false} width={80} />
        <Column dataField="currentCycle" allowEditing={false} width={100} />
        <Column dataField="statusMessage" allowEditing={false} width={120} />
        <Column
          dataField="statusUpdated"
          allowEditing={false}
          width={154}
          dataType={"date"}
          format={"dd MMM yyyy HH:mm"}
          sortIndex={0}
          sortOrder={"desc"}
        />

        <Column
          dataField={"lastUpdate"}
          dataType={"date"}
          format={"dd MMM yyyy HH:mm"}
          allowEditing={false}
          visible={false}
        />
        <Column
          dataField={"averageCycleTime"}
          caption={"Average Cycle Time (min)"}
          allowEditing={false}
          visible={false}
        />

        <Column dataField={"id"} visible={false} allowEditing={false} />

        <Column dataField={"runRoot"} visible={false} />

        <Column dataField={"controlSWVersion"} visible={false} />

        <Summary>
          <GroupItem
            column={"title"}
            summaryType={"count"}
            displayFormat={"{0}"}
          />
        </Summary>

        <Paging defaultPageSize={50} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[20, 50, 100]}
          showInfo={true}
          showNavigationButtons={true}
          visible
        />
      </DataGrid>
    </div>
  );
};
