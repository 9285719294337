import React, { memo, useCallback, useMemo, useState } from "react";
import data from "./Predefined.json";
import {
  Predefined,
  Recipe,
  StepRefType,
  StepDef,
  FunctionStepDef,
  StepRef,
  StepOptionType,
  DataType,
  StepOption,
  RecipeStepType,
  ReadStepType,
} from "./control_recipe";
import recipedata from "./v2_elevate_sequencing.json";
import TreeList, { Column } from "devextreme-react/tree-list";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/tab-panel";

const predefinedData = data as Predefined;
const elevateRecipe = recipedata as Recipe;

console.log("elevateRecipe", elevateRecipe);

interface TreeElement {
  label: string;
  parent: number;
  treeID: number;
  stepDef: StepDef;
  fcnDef: FunctionStepDef;
  options: StepOption[];
}

const recursiveScan = (
  tree: TreeElement[],
  steps: StepRef[],
  parent: number,
  idCntr: number
): number => {
  let newCntr = idCntr;
  for (let j = 0; j < steps?.length; j++) {
    const w = steps[j];
    const tmp =
      w.Type === StepRefType.BaseStepRef &&
      predefinedData.StepDefinitions.find((x) => x.ID === w.StepID);
    const tmp2 =
      w.Type === StepRefType.BaseFunctionStepRef &&
      predefinedData.FunctionDefinitions.find((x) => x.ID === w.StepID);
    const obj = tmp2 || tmp;
    const newparent = newCntr;
    tree.push({
      treeID: newCntr++,
      stepDef: tmp ?? undefined,
      fcnDef: tmp2 ?? undefined,
      label: obj?.Label,
      parent,
      options: w.FunctionStepOptions,
    });
    if (tmp) {
      newCntr = recursiveScan(tree, tmp.Steps, newparent, newCntr);
    }
  }
  return newCntr;
};

const functionTreeData: TreeElement[] = [];
const stepTreeData: TreeElement[] = [];
const recipeTreeData: TreeElement[] = [];

let cntr = 10000;

for (let i = 0; i < predefinedData.StepDefinitions.length; i++) {
  const v = predefinedData.StepDefinitions[i];

  stepTreeData.push({
    label: v.Label,
    stepDef: v,
    fcnDef: null,
    parent: -1,
    treeID: v.ID,
    options: null,
  });
  cntr = recursiveScan(stepTreeData, v.Steps, v.ID, cntr);
}

for (let i = 0; i < predefinedData.FunctionDefinitions.length; i++) {
  const v = predefinedData.FunctionDefinitions[i];
  functionTreeData.push({
    label: v.Label,
    parent: -1,
    treeID: v.ID,
    fcnDef: v,
    stepDef: undefined,
    options: null,
  });
}

cntr = 20000;

for (let i = 0; i < elevateRecipe.MainRecipeSteps.length; i++) {
  const v = elevateRecipe.MainRecipeSteps[i];

  if (v.Type === RecipeStepType.RecipeRead) {
    const parent = cntr;

    recipeTreeData.push({
      label: "Read " + v.ReadName,
      stepDef: undefined,
      fcnDef: undefined,
      parent: -1,
      treeID: cntr++,
      options: null,
    });

    for (let j = 0; j < v.ReadSteps.length; j++) {
      const w = v.ReadSteps[j];
      console.log("read step", w);

      const tmp1 =
        (w.Type === ReadStepType.ReadBaseStepRef ||
          w.Type === ReadStepType.ReadBaseCycleRange) &&
        predefinedData.StepDefinitions.find((x) => x.ID === w.StepID);
      const tmp2 =
        w.Type === ReadStepType.ReadBaseFunctionStepRef &&
        predefinedData.FunctionDefinitions.find((x) => x.ID === w.StepID);
      const obj = tmp1 || tmp2;

      const newparent = cntr;

      recipeTreeData.push({
        label: obj.Label,
        stepDef: tmp1 ?? undefined,
        fcnDef: tmp2 ?? undefined,
        parent,
        treeID: cntr++,
        options: null,
      });

      if (tmp1)
        cntr = recursiveScan(recipeTreeData, tmp1.Steps, newparent, cntr);
    }
  } else {
    const tmp1 =
      v.Type === RecipeStepType.RecipeBaseStepRef &&
      predefinedData.StepDefinitions.find((x) => x.ID === v.StepID);
    const tmp2 =
      v.Type === RecipeStepType.RecipeBaseFunctionStepRef &&
      predefinedData.FunctionDefinitions.find((x) => x.ID === v.StepID);
    const obj = tmp1 || tmp2;

    const parent = cntr;

    recipeTreeData.push({
      label: obj.Label,
      stepDef: tmp1 ?? undefined,
      fcnDef: tmp2 ?? undefined,
      parent: -1,
      treeID: cntr++,
      options: null,
    });

    if (tmp1) cntr = recursiveScan(recipeTreeData, tmp1.Steps, parent, cntr);
  }
}

console.log("recipe tree data", recipeTreeData);

const DefinitionsTree = memo(
  ({
    focusedRowKey,
    setFocusedRowKey,
    setSelectedElement,
    mode,
  }: {
    focusedRowKey: number;
    setFocusedRowKey: React.Dispatch<React.SetStateAction<number>>;
    setSelectedElement: (el: TreeElement) => void;
    mode: "Functions" | "Steps";
  }) => {
    console.log("rendering");
    return (
      <TreeList
        width={600}
        height={"90vh"}
        showColumnHeaders={false}
        id="stepdefinitions"
        dataSource={mode === "Functions" ? functionTreeData : stepTreeData}
        showRowLines={true}
        showBorders={true}
        columnAutoWidth={true}
        focusedRowEnabled
        rootValue={-1}
        onCellDblClick={useCallback((e) => {
          // FIXME
          // if (e.data?.fcnDef) {
          //   setFocusedRowKey(-e.data.fcnDef.ID);
          // } else if (e.data?.stepDef) {
          //   setFocusedRowKey(e.data.stepDef.ID);
          // }
        }, [])}
        keyExpr="treeID"
        parentIdExpr="parent"
        focusedRowKey={focusedRowKey}
        onFocusedRowChanged={useCallback(
          (e) => {
            const rowData = e.row && ((e.row as any).data as TreeElement);

            if (rowData) {
              setSelectedElement(rowData);
              setFocusedRowKey(rowData.treeID);
            }
          },
          [setFocusedRowKey, setSelectedElement]
        )}
      >
        <Column dataField="label" />
      </TreeList>
    );
  }
);

const RecipeTree = memo(
  ({
    setSelectedElement,
    setFocusedRowKeyInDefinitionTree,
  }: {
    setFocusedRowKeyInDefinitionTree: React.Dispatch<
      React.SetStateAction<number>
    >;
    setSelectedElement: (el: TreeElement) => void;
  }) => {
    const [focusedRowKey, setFocusedRowKey] = useState(null);

    console.log("rendering");
    return (
      <TreeList
        width={600}
        height={"90vh"}
        showColumnHeaders={false}
        id="recipes"
        dataSource={recipeTreeData}
        showRowLines={true}
        showBorders={true}
        columnAutoWidth={true}
        focusedRowEnabled
        rootValue={-1}
        onCellDblClick={useCallback(
          (e) => {
            const treeElement = e.data as TreeElement;
            if (treeElement) {
              if (treeElement.fcnDef) {
                setFocusedRowKeyInDefinitionTree(treeElement.parent);
              } else if (treeElement.stepDef) {
                setFocusedRowKeyInDefinitionTree(treeElement.stepDef.ID);
              }
            }
          },
          [setFocusedRowKeyInDefinitionTree]
        )}
        keyExpr="treeID"
        parentIdExpr="parent"
        focusedRowKey={focusedRowKey}
        onFocusedRowChanged={useCallback(
          (e) => {
            const rowData = e.row && ((e.row as any).data as TreeElement);

            if (rowData) {
              setSelectedElement(rowData);
              setFocusedRowKey(rowData.treeID);
            }
          },
          [setSelectedElement]
        )}
      >
        <Column dataField="label" />
      </TreeList>
    );
  }
);

const StepOptionsTable = memo(
  ({
    stepOptions,
    fcnDef,
  }: {
    stepOptions: StepOption[];
    fcnDef: FunctionStepDef;
  }) => {
    console.log("stepOptions", stepOptions);
    console.log("fcnDef", fcnDef);

    return (
      <div style={{ width: "500px" }}>
        {stepOptions?.map((v, i) => {
          let obj = undefined;

          if (v.Type === StepOptionType.StepOptionBaseVariable) {
            const tmp = predefinedData.VariableDefinitions.find(
              (w) =>
                w.GroupID === fcnDef.Options[i].VariableGroupID &&
                w.ID === v.VariableID
            );

            if (tmp) {
              switch (tmp.Type) {
                case DataType.ArgBool:
                  obj = tmp.BoolValue ? "true" : "false";
                  break;
                case DataType.ArgInt:
                  obj = tmp.IntValue || "0";
                  break;
                case DataType.ArgString:
                  obj = tmp.StringValue || "";
                  break;
                case DataType.ArgFloat:
                  obj = tmp.FloatValue || "0.0";
                  break;
                default:
                  obj = "wha??";
                  break;
              }
            }
          } else {
            switch (v.Type) {
              case StepOptionType.StepOptionBoolValue:
                obj = v.BoolValue ? "true" : "false";
                break;
              case StepOptionType.StepOptionIntValue:
                obj = v.IntValue || "0";
                break;
              case StepOptionType.StepOptionStringValue:
                obj = v.StringValue || "";
                break;
              case StepOptionType.StepOptionFloatValue:
                obj = v.FloatValue || "0.0";
                break;
              default:
                obj = "wha??";
                break;
            }
          }
          return (
            <div key={v.Name}>
              {v.Name} : {obj}
            </div>
          );
        })}
      </div>
    );
  }
);

export const FunctionNavigator = memo(() => {
  const [selectedElement, setSelectedElement] = useState<TreeElement>();
  const [focusedRowKey, setFocusedRowKey] = useState<number>(null);

  const selectedSubStep = useMemo<{
    stepOptions: StepOption[];
    fcnDef: FunctionStepDef;
  }>(() => {
    if (!selectedElement) return null;

    var p = stepTreeData.find((v) => v.treeID === selectedElement.parent);

    if (!p) return null;
    return {
      stepOptions: selectedElement.options,
      fcnDef: selectedElement.fcnDef,
    };
  }, [selectedElement]);
  return (
    <div style={{ display: "flex" }}>
      <TabPanel>
        <Item title="Functions" icon="floppy">
          <DefinitionsTree
            focusedRowKey={focusedRowKey}
            setFocusedRowKey={setFocusedRowKey}
            setSelectedElement={useCallback((el) => setSelectedElement(el), [])}
            mode="Functions"
          />
        </Item>
        <Item title="Steps" icon="floppy">
          <DefinitionsTree
            focusedRowKey={focusedRowKey}
            setFocusedRowKey={setFocusedRowKey}
            setSelectedElement={useCallback((el) => setSelectedElement(el), [])}
            mode="Steps"
          />
        </Item>
      </TabPanel>
      <StepOptionsTable
        stepOptions={selectedSubStep?.stepOptions}
        fcnDef={selectedSubStep?.fcnDef}
      />

      <RecipeTree
        setFocusedRowKeyInDefinitionTree={setFocusedRowKey}
        setSelectedElement={useCallback((el) => {}, [])}
      />
    </div>
  );
});

// JSON.stringify(selectedElement, null, 2)}
