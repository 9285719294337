import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";
import CustomStore from "devextreme/data/custom_store";

import DataGrid, {
  Editing,
  Column,
  Paging,
  Pager,
} from "devextreme-react/data-grid";

export const TagsForm = () => {
  const { fetchWithCheck, fetchNoResponse } = useAuth0();

  var loadData = async () => {
    return await fetchWithCheck("/fullTags");
  };

  var updateData = async (key, values) => {
    await fetchNoResponse("/tags/" + key, {
      method: "PATCH",
      body: JSON.stringify(values),
    });
  };

  var insertData = async (values) => {
    await fetchNoResponse("/tags", {
      method: "POST",
      body: JSON.stringify(values),
    });
  };

  var removeData = async (key) => {
    await fetchNoResponse("/tags/" + key, {
      method: "DELETE",
    });
  };

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
    update: updateData,
    insert: insertData,
    remove: removeData,
  });

  return (
    <div>
      <br />
      <h3>Manage Tags</h3>

      <DataGrid
        dataSource={customStore}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
      >
        <Editing
          mode="row"
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />

        <Column dataField={"name"} />

        <Column
          dataField={"created"}
          allowEditing={false}
          defaultSortOrder={"desc"}
          dataType={"date"}
          format={"dd MMM yyyy"}
        />

        <Paging defaultPageSize={20} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[20, 40, 100, 200]}
        />
      </DataGrid>
    </div>
  );
};
