import React, { useState, useEffect } from "react";
import "../../App.css";
import { useAuth0 } from "../../react-auth0-spa";
import styled from "styled-components";

import {
  Chart,
  CommonSeriesSettings,
  Export,
  SeriesTemplate,
  Legend,
  ValueAxis,
  Point,
  Tooltip,
  ArgumentAxis,
  ZoomAndPan,
} from "devextreme-react/chart";

const Styles = styled.div`
  .flex-container {
    display: flex;
  }

  .flex-child {
    flex: 1;
  }

  .flex-child:first-child {
    margin-right: 20px;
  }

  .float-container {
    padding: 20px;
  }

  .float-child {
    width: 50%;
    float: left;
    padding: 20px;
  }

  .inline-container {
  }

  .inline-child {
    display: inline-block;
  }

  .center-div {
    margin-left: auto;
    margin-right: auto;
    width: 450px;
  }

  .offset-title {
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
  }

  .subtitle {
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;
  }

  .grid-child {
    height: 430px;
  }

  /* Solid border */
  hr.solid {
    border-top: 3px solid rgba(191, 191, 191, 0.15);
  }

  .margins {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
`;
export const ChannelSubPlots = (props) => {
  const { fetchWithCheck, loading } = useAuth0();
  const id = props.id;

  const [state, setState] = useState({
    d1: [],
    d2: [],
    d3: [],
    d4: [],
    min: 0,
    max: 100,
  });

  useEffect(() => {
    const splitReadData = (data) => {
      var j;

      const distinctReads = [...new Set(data.map((x) => x.read))];
      var maxCycleForRead = {};
      for (j = 0; j < distinctReads.length; j++) {
        maxCycleForRead[distinctReads[j]] = 0;
      }

      for (j = 0; j < data.length; j++) {
        if (data[j].cycle > maxCycleForRead[data[j].read]) {
          maxCycleForRead[data[j].read] = data[j].cycle;
        }
      }

      var readCycleOffset = {};
      readCycleOffset[distinctReads[0]] = 0;
      var currentOffset = 0;
      for (j = 0; j < distinctReads.length; j++) {
        readCycleOffset[distinctReads[j]] = currentOffset;
        currentOffset += maxCycleForRead[distinctReads[j]];
      }

      var data2 = [];
      for (j = 0; j < data.length; j++) {
        data[j].var = data[j].tile + " (" + data[j].read + ")";
        data[j].cycle += readCycleOffset[data[j].read];
        data2.push(data[j]);
      }
      return data2;
    };
    const callApi = async () => {
      var data1 = await fetchWithCheck(
        "/report/fieldByCycleChannel?id=" +
          id +
          "&field=" +
          props.field +
          "&channel=1"
      );
      var data2 = await fetchWithCheck(
        "/report/fieldByCycleChannel?id=" +
          id +
          "&field=" +
          props.field +
          "&channel=2"
      );
      var data3 = await fetchWithCheck(
        "/report/fieldByCycleChannel?id=" +
          id +
          "&field=" +
          props.field +
          "&channel=3"
      );
      var data4 = await fetchWithCheck(
        "/report/fieldByCycleChannel?id=" +
          id +
          "&field=" +
          props.field +
          "&channel=4"
      );

      if (data1 && data2 && data3 && data4) {
        var i;
        var tmin = 99999,
          tmax = -99999;
        var val;
        var factor = 1;
        if (props.multby100) {
          factor = 100;
        }
        for (i = 0; i < data1.length; i++) {
          if (data1[i].value === -999) {
            data1[i].value = NaN;
          }
          val = data1[i].value = data1[i].value * factor;
          if (val < tmin) {
            tmin = val;
          }
          if (val > tmax) {
            tmax = val;
          }
        }
        for (i = 0; i < data2.length; i++) {
          if (data2[i].value === -999) {
            data2[i].value = NaN;
          }
          val = data2[i].value = data2[i].value * factor;
          if (val < tmin) {
            tmin = val;
          }
          if (val > tmax) {
            tmax = val;
          }
        }
        for (i = 0; i < data3.length; i++) {
          if (data3[i].value === -999) {
            data3[i].value = NaN;
          }
          val = data3[i].value = data3[i].value * factor;
          if (val < tmin) {
            tmin = val;
          }
          if (val > tmax) {
            tmax = val;
          }
        }
        for (i = 0; i < data4.length; i++) {
          if (data4[i].value === -999) {
            data4[i].value = NaN;
          }
          val = data4[i].value = data4[i].value * factor;
          if (val < tmin) {
            tmin = val;
          }
          if (val > tmax) {
            tmax = val;
          }
        }
        if (typeof props.min !== "undefined") tmin = props.min;
        if (typeof props.max !== "undefined") tmax = props.max;

        data1 = splitReadData(data1);
        data2 = splitReadData(data2);
        data3 = splitReadData(data3);
        data4 = splitReadData(data4);
        setState({
          d1: data1,
          d2: data2,
          d3: data3,
          d4: data4,
          min: tmin,
          max: tmax,
        });
      }
    };
    if (!loading && id !== 0) {
      callApi();
    }
  }, [
    loading,
    fetchWithCheck,
    props.field,
    id,
    props.multby100,
    props.min,
    props.max,
  ]);

  const sortLegendItems = (items) => {
    return items.sort((a, b) => {
      let itemA = a.text.toLowerCase();
      let itemB = b.text.toLowerCase();
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      return 0;
    });
  };

  const renderTooltip = (pointInfo) => {
    if (pointInfo && pointInfo.point && pointInfo.point.data) {
      const data = pointInfo.point.data;
      const label3 = "Cycle: " + Math.floor(data.cycle);
      var suffix = "";
      if (props.multby100) {
        suffix = "%";
      }
      var precision = 0;
      if (data.value < 0.1) {
        precision = 4;
      } else if (data.value < 1) {
        precision = 3;
      } else if (data.value < 10) {
        precision = 2;
      } else if (data.value < 100) {
        precision = 1;
      }
      return (
        <div>
          {data.var}
          <br />
          {label3}
          <br />
          {data.value.toFixed(precision) + suffix}
        </div>
      );
    }
    return <div />;
  };

  return (
    <Styles>
      <div className="margins">
        <hr className="solid" />
        <div className="grid-container" style={{ backgroundColor: "#FFFFFF" }}>
          <div className="grid-child">
            <div className="subtitle">Ch 1</div>
            <Chart
              height="300px"
              id="chart"
              dataSource={state.d1}
              onLegendClick={(e) => {
                const series = e.target;
                if (series.isVisible()) {
                  series.hide();
                } else {
                  series.show();
                }
              }}
            >
              <CommonSeriesSettings
                argumentField="cycle"
                valueField="value"
                type="line"
              >
                <Point size={4} />
              </CommonSeriesSettings>
              <SeriesTemplate nameField="var" />
              <Tooltip enabled={true} contentRender={renderTooltip} />
              <ValueAxis
                title={props.yLabel}
                wholeRange={[state.min, state.max]}
                visualRange={[state.min, state.max]}
              />
              <ArgumentAxis title={"Cycle"} allowDecimals={true} />
              <Legend visible={true} customizeItems={sortLegendItems} />
              <Export enabled={true} />
              <ZoomAndPan argumentAxis="both" valueAxis="both" />
            </Chart>
          </div>
          <div className="grid-child">
            <div className="subtitle">Ch 2</div>
            <Chart
              height="300px"
              id="chart"
              dataSource={state.d2}
              onLegendClick={(e) => {
                const series = e.target;
                if (series.isVisible()) {
                  series.hide();
                } else {
                  series.show();
                }
              }}
            >
              <CommonSeriesSettings
                argumentField="cycle"
                valueField="value"
                type="line"
              >
                <Point size={4} />
              </CommonSeriesSettings>
              <SeriesTemplate nameField="var" />
              <Tooltip enabled={true} contentRender={renderTooltip} />
              <ValueAxis
                title={props.yLabel}
                wholeRange={[state.min, state.max]}
                visualRange={[state.min, state.max]}
              />
              <ArgumentAxis title={"Cycle"} allowDecimals={true} />
              <Legend visible={true} customizeItems={sortLegendItems} />
              <Export enabled={true} />
              <ZoomAndPan argumentAxis="both" valueAxis="both" />
            </Chart>
          </div>

          <div className="grid-child">
            <div className="subtitle">Ch 3</div>
            <Chart
              height="300px"
              id="chart"
              dataSource={state.d3}
              onLegendClick={(e) => {
                const series = e.target;
                if (series.isVisible()) {
                  series.hide();
                } else {
                  series.show();
                }
              }}
            >
              <CommonSeriesSettings
                argumentField="cycle"
                valueField="value"
                type="line"
              >
                <Point size={4} />
              </CommonSeriesSettings>
              <SeriesTemplate nameField="var" />
              <Tooltip enabled={true} contentRender={renderTooltip} />
              <ValueAxis
                title={props.yLabel}
                wholeRange={[state.min, state.max]}
                visualRange={[state.min, state.max]}
              />
              <ArgumentAxis title={"Cycle"} allowDecimals={true} />
              <Legend visible={true} customizeItems={sortLegendItems} />
              <Export enabled={true} />
              <ZoomAndPan argumentAxis="both" valueAxis="both" />
            </Chart>
          </div>
          <div className="grid-child">
            <div className="subtitle">Ch 4</div>
            <Chart
              height="300px"
              id="chart"
              dataSource={state.d4}
              onLegendClick={(e) => {
                const series = e.target;
                if (series.isVisible()) {
                  series.hide();
                } else {
                  series.show();
                }
              }}
            >
              <CommonSeriesSettings
                argumentField="cycle"
                valueField="value"
                type="line"
              >
                <Point size={4} />
              </CommonSeriesSettings>
              <SeriesTemplate nameField="var" />
              <Tooltip enabled={true} contentRender={renderTooltip} />
              <ValueAxis
                title={props.yLabel}
                wholeRange={[state.min, state.max]}
                visualRange={[state.min, state.max]}
              />
              <ArgumentAxis title={"Cycle"} allowDecimals={true} />
              <Legend visible={true} customizeItems={sortLegendItems} />
              <Export enabled={true} />
              <ZoomAndPan argumentAxis="both" valueAxis="both" />
            </Chart>
          </div>
        </div>

        <hr className="solid" />
      </div>
    </Styles>
  );
};
