import React, { useEffect, useState, useMemo, useRef } from "react";

import Scheduler, { Resource } from "devextreme-react/scheduler";
import { getPalette } from "devextreme/viz/palette";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";

import Toolbar, { Item } from "devextreme-react/toolbar";

let palette = getPalette("Bright");

const currentDate = new Date();
const groups = ["instrumentID"];

const views = [
  { name: "Week", type: "timelineWeek", intervalCount: 1 },
  { name: "2 Weeks", type: "timelineWeek", intervalCount: 2 },
  { name: "4 Weeks", type: "timelineWeek", intervalCount: 4 },
];

const editting = {
  allowAdding: false,
  allowDeleting: false,
  allowResizing: false,
  allowDragging: false,
  allowUpdating: false,
};

export const InstrumentUsageForm = (props) => {
  const [state, setState] = useState({
    groupData: [],
    instrumentData: [],
  });
  const { loading, fetchWithCheck } = useAuth0();
  const scheduler = useRef(null);
  const [cntr, setCntr] = useState(0);
  const [refreshCntr, setRefreshCntr] = useState(0);
  const [runData, setRunData] = useState([]);

  useEffect(() => {
    const callAPI = async () => {
      console.log("Reloading");
      const data = await fetchWithCheck("/instrumentUsage");

      for (var i = 0; i < data.length; i++) {
        if (data[i].side === "B") data[i].instrumentID *= -1;
      }

      setRunData(data);
    };
    if (!loading) {
      callAPI();
    }
  }, [loading, fetchWithCheck, refreshCntr, scheduler]);

  useEffect(() => {
    const callAPI = async () => {
      const instr = await fetchWithCheck("/instruments");
      let instrData = [];
      props.instrumentList.forEach((v) => {
        if (v.endsWith("A") || v.endsWith("B")) {
          const instName = v.substr(0, v.length - 1);
          const obj = instr.find((x) => x.name === instName);
          var id = obj.id;
          if (v.endsWith("B")) id *= -1;
          if (obj) {
            instrData.push({ id: id, name: v });
          }
        } else {
          const obj = instr.find((x) => x.name === v);
          if (obj) {
            instrData.push(obj);
          }
        }
      });
      setState({
        groupData: runTypeData,
        instrumentData: instrData,
      });
    };
    if (!loading) {
      callAPI();
    }
  }, [loading, fetchWithCheck, props.instrumentList]);

  useEffect(() => {
    if (scheduler.current) {
      scheduler.current.instance.option("cellDuration", 60 * 24);
      setCntr((x) => x + 1);
    }
  }, [scheduler]);

  const refreshOptions = useMemo(() => {
    return {
      icon: "refresh",
      hint: "refresh",
      text: "refresh",
      stylingMode: "text",
      onClick: () => {
        setRefreshCntr((x) => x + 1);
      },
    };
  }, []);

  const zoomInOptions = useMemo(() => {
    let enabled = true;
    console.log(cntr);
    if (scheduler.current) {
      enabled = scheduler.current.instance.option("cellDuration") > 60;
    }
    return {
      disabled: !enabled,
      icon: "plus",
      hint: "zoom in",
      text: "zoom in",
      stylingMode: "text",
      onClick: () => {
        var cellDuration = scheduler.current.instance.option("cellDuration");
        if (cellDuration === 60 * 24)
          scheduler.current.instance.option("cellDuration", 60 * 12);
        else if (cellDuration === 60 * 12)
          scheduler.current.instance.option("cellDuration", 60 * 4);
        else if (cellDuration === 60 * 4)
          scheduler.current.instance.option("cellDuration", 60 * 2);
        else if (cellDuration === 60 * 2)
          scheduler.current.instance.option("cellDuration", 60);
        scheduler.current.instance.repaint();
        setCntr((x) => x + 1);
      },
    };
  }, [scheduler, cntr]);

  const zoomOutOptions = useMemo(() => {
    let enabled = true;
    console.log(cntr);
    if (scheduler.current) {
      enabled = scheduler.current.instance.option("cellDuration") < 60 * 24;
    }
    return {
      disabled: !enabled,
      icon: "minus",
      hint: "zoom out",
      text: "zoom out",
      stylingMode: "text",
      onClick: () => {
        var cellDuration = scheduler.current.instance.option("cellDuration");
        if (cellDuration === 60)
          scheduler.current.instance.option("cellDuration", 60 * 2);
        else if (cellDuration === 60 * 2)
          scheduler.current.instance.option("cellDuration", 60 * 4);
        else if (cellDuration === 60 * 4)
          scheduler.current.instance.option("cellDuration", 60 * 12);
        else if (cellDuration === 60 * 12)
          scheduler.current.instance.option("cellDuration", 60 * 24);
        scheduler.current.instance.repaint();
        setCntr((x) => x + 1);
      },
    };
  }, [scheduler, cntr]);

  return (
    <React.Fragment>
      <Toolbar>
        <Item location="before" widget="dxButton" options={zoomOutOptions} />
        <Item location="before" widget="dxButton" options={zoomInOptions} />
        <Item location="before" widget="dxButton" options={refreshOptions} />
      </Toolbar>

      {3 > 2 && (
        <div>
          <Scheduler
            ref={scheduler}
            timeZone="America/Los_Angeles"
            dataSource={runData}
            views={views}
            defaultCurrentView="timelineWeek"
            defaultCurrentDate={currentDate}
            height={props.rowHeight * state.instrumentData.length}
            editing={editting}
            shadeUntilCurrentTime={true}
            groups={groups}
            firstDayOfWeek={4}
            startDayHour={0}
            endDayHour={24}
            onAppointmentClick={(e) => {
              props.history.push("/runs/" + e.appointmentData.text);
            }}
          >
            <Resource
              fieldExpr="groupID"
              displayExpr="text"
              allowMultiple={false}
              dataSource={runTypeData}
              label="Run Type"
              useColorAsDefault={true}
            />
            <Resource
              fieldExpr="instrumentID"
              displayExpr="name"
              allowMultiple={false}
              dataSource={state.instrumentData}
              label="Instrument"
            />
          </Scheduler>
        </div>
      )}
    </React.Fragment>
  );
};

const runTypeData = [
  {
    text: "idle",
    id: 0,
    color: "#00000020",
  },
  {
    text: "Sequencing",
    id: 1,
    color: palette.simpleSet[5],
  },
  {
    text: "Non-Sequencing",
    id: 2,
  },
];
