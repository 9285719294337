import  React from 'react'
import {Container} from 'reactstrap';
import styled from "styled-components";

const Styles = styled.div`
@media (min-width: 1200px) {
    .container {
        max-width: 5000px;
        padding-left: 100px;
        padding-right: 100px;
    }
}
  `

export const Layout = (props) => (
    <Styles>
<Container >
{props.children}
</Container>
</Styles>
)
