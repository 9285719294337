

import React from "react";
import "../App.css";
import { Link } from "react-router-dom";

export const SettingsForm = () => {


  return (
    <div>
    <br/>
        <br/>
<Link to="/Tags">Manage Tags...</Link>
    <br/>
        <br/>
<Link to="/Libraries">Manage Libraries...</Link>
    <br/>
        <br/>
<Link to="/telemetryRoles">Telemetry Roles...</Link>

{/* 
<Button onClick={async ()=>{
            let client_id = "39c2b60c-f761-43b0-8807-57df60c9ca4f";
            let client_secret = "A2.7Gt28FTkDzH2-dzWtHSbDbq_mY_Svju";
            let tenent_id = "5988487f-6da6-4123-8cc0-662a1bdca8cf";
            let group_id = "c1151798-ed30-4edc-9fd3-f353330f29e3";

            let alta_token = "$2a$04$qAyDIgzFXjaqUtrckA5UKOsQP311nmTI1svc5IJRPn1FyOcDMCuFC";

            // first we must get an authorization token using our client id, tenant id, and client secret
            var response = await fetch('https://login.microsoftonline.com/'+tenent_id+'/oauth2/v2.0/token', {
              headers: {  'Content-Type': 'application/x-www-form-urlencoded' },
              method: "POST",
              body:"client_id="+client_id+"&scope=https%3A%2F%2Fgraph.microsoft.com%2F.default&client_secret="+client_secret+"&grant_type=client_credentials",
            })
            var obj = await response.json()
            let token = obj.access_token

            // use the token to get a list of all employees in the Element_FTE group (id: c1151798-ed30-4edc-9fd3-f353330f29e3)
            response = await fetch('https://graph.microsoft.com/v1.0/groups/'+group_id+'/members?$top=500&$select=mail,jobTitle,givenName,surname,department,mobilePhone,userPrincipalName', {
              headers: { Authorization: `Bearer ${token}` }
             })
             obj = await response.json()
             const results = obj.value

            // unfortunately, manager is not retured in the above query, so we must iterate through employees and call api to get their manager
            var employees = [], i;
            for (i=0; i<results.length; i++) {
              let r = results[i]
              let emp = {Email: r.mail, Title: r.jobTitle, FirstName: r.givenName, LastName: r.surname, Department: r.department, MobilePhone: r.mobilePhone};

              response = await fetch('https://graph.microsoft.com/v1.0/users/' + r.userPrincipalName + '/manager?$select=mail', {
                headers: { Authorization: `Bearer ${token}` }
               })
              obj = await response.json()
              if (obj.mail) {
                emp.ManagerEmail = obj.mail
              } else {
                emp.ManagerEmail = "";  
              }
              employees.push(emp)
            }

            // now call the Alta API to sync employees
            await fetch('https://alta-backend.elembio.io/api/employees/upload', {
              headers: {  'Content-Type': 'application/json', Authorization: 'Bearer ' + alta_token },
              method: "POST",
              body: JSON.stringify(employees),
            })
            console.log("done!")
            console.log(employees)
        }}>
        Test Sync
        </Button>   */}


        </div>
  );
};

