import React, { useEffect, useRef } from "react";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";
import { Link } from "react-router-dom";
import Button from "devextreme-react/button";
import CustomStore from "devextreme/data/custom_store";

//import SelectBox from 'devextreme-react/select-box';
//import { StatusImages } from "./StatusImages"
import DataGrid, {
  Column,
  ColumnChooser,
  FilterRow,
  Paging,
  Pager,
  GroupPanel,
  GroupItem,
  Format,
  Summary,
  StateStoring,
  Export,
  //   RequiredRule
} from "devextreme-react/data-grid";

const handleNaN = (e) => {
  if (isNaN(e.value) || e.value <= 0) return "";
  return e.valueText;
};

export const BaselineTrackingDashboard = (props) => {
  const { fetchWithCheck } = useAuth0();
  const theGrid = useRef(null);

  const handleResize = React.useCallback(() => {
    if (theGrid) {
      theGrid.current.instance.option("height", window.innerHeight - 250);
    }
  }, [theGrid]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  var loadData = async () => {
    const data = await fetchWithCheck("/baselineTracking");

    if (data) {
      for (let i = 0; i < data.length; i++) {
        data[i].AvgQ30 = (data[i].R1PercentQ30 + data[i].R2PercentQ30) / 2;
        data[i].AvgQ40 = (data[i].R1PercentQ40 + data[i].R2PercentQ40) / 2;
      }
    }
    return data;
  };

  var customStore = new CustomStore({
    key: "RunID",
    load: loadData,
  });

  const cellRenderRunName = (cell) => {
    if (!cell.value) {
      return <div></div>;
    }
    return (
      <Link to={"/runs/" + cell.value + "?v=" + cell.data.AnalysisVersion}>
        {cell.value}
      </Link>
    );
  };

  return (
    <div>
      <br />
      <h3>Baseline Tracking Dashboard</h3>
      <Button
        onClick={() => {
          theGrid.current.instance.state({});
        }}
        text="Reset Table Formatting"
      />
      <br />
      <br />

      <DataGrid
        dataSource={customStore}
        ref={theGrid}
        wordWrapEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={"widget"}
        columnHidingEnabled={true}
        columnAutoWidth={true}
        columnFixing={{ enabled: true }}
        headerFilter={{ visible: true }}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="baselineTrackingV4"
        />

        <FilterRow visible={true} />

        <GroupPanel visible={true} />
        <Export
          enabled={true}
          fileName={"BaselineRuns"}
          allowExportSelectedData={true}
        />

        <ColumnChooser enabled={true} />

        <Column
          dataField={"RunName"}
          cellRender={cellRenderRunName}
          fixed={true}
          fixedPosition="left"
          width={161}
        />
        <Column dataField="SoftwareVersion" width={95} />
        <Column dataField="ReagentLotNumber" width={95} />
        <Column dataField={"InstrumentName"} width={93} />
        <Column
          dataField={"Date"}
          dataType={"date"}
          caption="Run Date"
          format={"dd MMM yyyy HH:mm"}
          width={125}
          visible={true}
        />

        <Column
          dataField="PFPercent"
          caption="PF (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>

        <Column
          dataField="PFCountInM"
          caption="PF Count (M)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>

        <Column
          dataField="PFPercent"
          caption="PF (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>

        <Column
          dataField="IndexingAssignedRate"
          caption="Indexing Assignment (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>

        <Column
          dataField="R2OverR1"
          caption="R2/R1"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R1PercentQ30"
          caption="R1 Q30 (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>

        <Column
          dataField="R2PercentQ30"
          caption="R2 Q30 (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>

        <Column
          dataField="AvgQ30"
          caption="Avg Q30 (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>

        <Column
          dataField="R1PercentQ40"
          caption="R1 Q40 (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>

        <Column
          dataField="R2PercentQ40"
          caption="R2 Q40 (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>

        <Column
          dataField="AvgQ40"
          caption="Avg Q40 (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>
        <Column
          dataField="R1LateCyclePhasing"
          caption="R1 Late Cycle Phasing (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R2LateCyclePhasing"
          caption="R2 Late Cycle Phasing (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R1LateCyclePrephasing"
          caption="R1 Late Cycle Prephasing (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R2LateCyclePrephasing"
          caption="R2 Late Cycle Prephasing (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R1LateCycleErrorPercent"
          caption="R1 Late Cycle Error (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R2LateCycleErrorPercent"
          caption="R2 Late Cycle Error (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R1LateCycleP90Ch1"
          caption="R1 Late Cycle P90 Ch1"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={1} />
        </Column>

        <Column
          dataField="R2LateCycleP90Ch1"
          caption="R2 Late Cycle P90 Ch1"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={1} />
        </Column>

        <Column
          dataField="R1LateCycleP90Ch2"
          caption="R1 Late Cycle P90 Ch2"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={1} />
        </Column>

        <Column
          dataField="R2LateCycleP90Ch2"
          caption="R2 Late Cycle P90 Ch2"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={1} />
        </Column>

        <Column
          dataField="R1LateCycleP90Ch3"
          caption="R1 Late Cycle P90 Ch3"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={1} />
        </Column>

        <Column
          dataField="R2LateCycleP90Ch3"
          caption="R2 Late Cycle P90 Ch3"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={1} />
        </Column>

        <Column
          dataField="R1LateCycleP90Ch4"
          caption="R1 Late Cycle P90 Ch4"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={1} />
        </Column>

        <Column
          dataField="R2LateCycleP90Ch4"
          caption="R2 Late Cycle P90 Ch4"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={1} />
        </Column>

        <Column
          dataField="R1Cycle1ZScoreGreen"
          caption="R1 Cycle 1 ZScore Green"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R2Cycle1ZScoreGreen"
          caption="R2 Cycle 1 ZScore Green"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R1Cycle1ZScoreRed"
          caption="R1 Cycle 1 ZScore Red"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R2Cycle1ZScoreRed"
          caption="R2 Cycle 1 ZScore Red"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R1Cycle150ZScoreGreen"
          caption="R1 Cycle 150 ZScore Green"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R2Cycle150ZScoreGreen"
          caption="R2 Cycle 150 ZScore Green"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R1Cycle150ZScoreRed"
          caption="R1 Cycle 150 ZScore Red"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R2Cycle150ZScoreRed"
          caption="R2 Cycle 150 ZScore Red"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R1Cycle150ResidualPercent"
          caption="R1 Cycle 150 Residual (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Column
          dataField="R2Cycle150ResidualPercent"
          caption="R2 Cycle 150 Residual (%)"
          width={100}
          customizeText={handleNaN}
        >
          <Format type="fixedPoint" precision={4} />
        </Column>

        <Summary>
          <GroupItem
            column={"title"}
            summaryType={"count"}
            displayFormat={"{0}"}
          />
        </Summary>

        <Paging defaultPageSize={100} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[100, 3000, "all"]}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
    </div>
  );
};
