import React, {useEffect, useState}  from 'react';
import CustomStore from "devextreme/data/custom_store";
import { useAuth0 } from "../react-auth0-spa";
import { SelectBox } from 'devextreme-react';
import { RadioGroup } from 'devextreme-react';

import {
  DataPrepareSettings,
  Chart,
  Series,
  Legend,
  ValueAxis,
  Point,
  Border,
  CommonPaneSettings,
  Tooltip,
  ArgumentAxis,
  Grid,
  Label,
  Export
} from 'devextreme-react/chart';
import TagBox from 'devextreme-react/tag-box';
import DateRangeSelector from './helpers/DateRangeSelector';

// function compare( a, b ) {
//   if ( a.name < b.name ){
//     return -1;
//   }
//   if ( a.last_nom > b.last_nom ){
//     return 1;
//   }
//   return 0;
// }

//objs.sort( compare );

const valueData = [ 
        {id: 0,  field: (v) => v.decayRate, name: "Decay Rate", label: "Decay Rate (per cycle)"},  
        {id: 1,  field: (v) => v.errorRate, name: "Error Rate", label: "Error Rate"},
        {id: 2,  field: (v) => v.clusterCount, name: "Polony Count", label: "# Polonies in Template"},
        {id: 3,  field: (v) => v.clusterDensity, name: "Polony Density", label: "Polonies per mm^2"},
]

const handleAssignment = (obj, i, val) => {
  if (val===-999) {
    return false;
  }
  obj['v'+i] = val;
  return true;
}

export const ScatterPlot = () => {
    const { fetchWithCheck, loading } = useAuth0();
    const [lookup, setLookup] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [updateCntr, setUpdateCntr] = useState(0);
    const [valueArgIndex, setValueArgIndex] = useState(-1)
    const [xAxis, setXAxis] = useState("Category")
    const [category, setCategory] = useState("Tags")
    const [options, setOptions] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
      const callApi = async () => {
         setLookup(await fetchWithCheck("/lookups"));
      };
      if (!loading) {
        callApi();
      }
    }, [loading, fetchWithCheck]);

    useEffect(() => {
      const callApi2 = async () => {
        if (valueArgIndex>=0) {
          const data = await fetchWithCheck("/chartData");

          var i;
          var j;
          var dataSubset = [];
          if (category==="Tags") {
            setOptions(selectedTags);
            for (i = 0; i < data.length; i++) {
              for (j = 0; j < selectedTags.length; j++) {
                const selectedTag = selectedTags[j];
                var el = { name: selectedTag, date: data[i].date, runName: data[i].runName, lane:data[i].lane, tile:data[i].tile, flowcell: data[i].flowcell };
                if (data[i].name === selectedTag) {
                    if (handleAssignment(el, j, valueData[valueArgIndex].field(data[i]))) {
                        dataSubset.push(el);
                      }
                } // if
              } // for j
            } // for i
          } else {
            var choices = [];
            for (i=0; i < data.length; i++) {
              if (selectedTags.length>0) {
                if (!selectedTags.includes(data[i].name)) {
                  continue;
                }
              }
              var name = ""
              switch (category) {
                case "Flowcells": name = data[i].flowcell; break; 
                case "Lanes": name = "Lane " + data[i].lane; break;
                case "Instruments": name = data[i].instrument; break;
                case "Operators": name = data[i].operator; break;
                case "Tiles": name = data[i].tile; break;
                default: name = "Undefined"; break;
              }
              if (!choices.includes(name)) {
                choices.push(name)
              }
            }
            choices.sort();
            setOptions(choices);
            for (i = 0; i < data.length; i++) {
              if (selectedTags.length>0) {
                if (!selectedTags.includes(data[i].name)) {
                  continue;
                }
              }
              var el2 = { date: data[i].date, runName: data[i].runName, lane:data[i].lane, tile: data[i].tile, flowcell: data[i].flowcell };
              switch (category) {
                case "Flowcells": el2.name = data[i].flowcell; break; 
                case "Lanes": el2.name = "Lane " + data[i].lane; break;
                case "Instruments": el2.name = data[i].instrument; break;
                case "Operators": el2.name = data[i].operator; break;
                case "Tiles": el2.name = data[i].tile; break;
                default: el2.name = "Undefined"; break;
              }
              j = choices.indexOf(el2.name);
              if (handleAssignment(el2, j, valueData[valueArgIndex].field(data[i]))) {
                dataSubset.push(el2);
              }
            } // for i
          } // else
          setData(dataSubset);
        }
      }
      if (!loading) {
        callApi2();
      }
    }, [loading, fetchWithCheck, valueArgIndex, category, selectedTags])
    
var loadData = async () => {
  return data;
};

var customStore = new CustomStore({
    key: "id",
    load: loadData,
});

const tagValueChange = (e) => {
  setSelectedTags(e.value);
  setUpdateCntr(x => x + 1)
}

const ylabel = valueArgIndex >= 0 ? valueData[valueArgIndex].label : "";

var xlabel = "";
var argType = "";
var argField = "";
var showLegend = false;
if (xAxis==="Date") {
   xlabel = "Run Date"
   argType = "datetime"
   argField = "date"
   showLegend = true;

} else if (xAxis === "Category") {
   xlabel = "";
   argType = "string"
   argField = "name"
}

const renderTooltip = (pointInfo) => {
  if (pointInfo && pointInfo.point && pointInfo.point.data) {
    const data = pointInfo.point.data
    const label1 = data.runName;
    const label2 = data.flowcell +  " L" + data.lane + " " + data.tile;
    return <div><strong>{label1}</strong><br/>{label2}<br/>{pointInfo.valueText}</div>
  }
  return <div/>
}

const onRangeChanged = (newstartmonth, newnummonths) => {
  // setStartMonth(newstartmonth);
  // setNumMonths(newnummonths);
  // setUpdateCntr(v => v + 1);
};

  return (
    <React.Fragment>

<DateRangeSelector 
        startmonth={200}
        nummonths={12}
        onRangeChanged={onRangeChanged} />

      <table key={category} style={{ width: "700px", marginTop: "50px", fontSize: "13px", fontFamily: "Open Sans", float: "left" }}>
        <tbody>
          <tr>
            <th style={{ width: "90px", textAlign: "left" }}>Plot: </th>
            <td style={{ width: "500px" }}>
              <SelectBox items={valueData}
                displayExpr="name"
                valueExpr="id"
                defaultValue = {valueArgIndex}
                width="200px"
                onValueChanged={(e) => { setValueArgIndex(e.value) }}
              />
            </td>
          </tr>
          <tr>
            <th style={{ textAlign: "left" }}>Categories: </th>
            <td>
               <RadioGroup items={["Tags", "Flowcells", "Lanes", "Instruments", "Operators", "Tiles"]} defaultValue={category} layout="horizontal" onValueChanged={(e) => { setCategory(e.value) }}/>
            </td>
          </tr>
         
          <tr>
            <th style={{ textAlign: "left" }}></th>
            <td> <TagBox
              dataSource={lookup.tags}
              defaultValue={selectedTags}
              onValueChanged={tagValueChange}
              showSelectionControls={true}
              applyValueMode="useButtons"
              searchEnabled={true}
              placeholder="Tags to include ..." />
              </td>
          </tr>
          <tr>
            <th style={{ textAlign: "left" }}>Plot by: </th>
            <td>
               <RadioGroup items={["Category", "Date"]} defaultValue={xAxis} layout="horizontal" onValueChanged={(e) => { setXAxis(e.value) }}/>
            </td>
          </tr>
        </tbody>
      </table>

    

    <Chart key={updateCntr + " " + valueArgIndex}
      height = "500px"
      id="chart"
      dataSource={customStore}
    >
        <DataPrepareSettings
            sortingMethod={(a,b)=>{if (a.name>b.name) {return 1} return -1}}
        />
        <ArgumentAxis argumentType={argType} title={xlabel}>
          <Label rotationAngle={20} overlappingBehavior="rotate" />
          <Grid visible={true} />
        </ArgumentAxis>

      <CommonPaneSettings>
        <Border visible={true} />
      </CommonPaneSettings>
      {options.map((x,i) => <Series key={i} name={x} argumentField={argField} valueField={"v"+i} type="scatter"><Point size={4}/></Series>)}
      

      <Tooltip
            enabled={true}
            //customizeTooltip={customizeTooltip}
            contentRender={renderTooltip}
          />
      <ValueAxis title= {ylabel} />
      <Legend visible={showLegend} />
      <Export enabled={true} />
    </Chart></React.Fragment>
  );
}