import React, { useState, useEffect } from "react";
import "../../App.css";

import { FlowcellHeatmap } from "./FlowcellHeatmap";

import styled from "styled-components";

import { SelectBox } from "devextreme-react/select-box";
import { CheckBox } from "devextreme-react/check-box";
import { NumberBox } from "devextreme-react/number-box";
// import RangeSelector, {
//   Margin,
//   Scale,
//   MinorTick,
//   Label,
//   SliderMarker,
// } from "devextreme-react/range-selector";

import { useAuth0 } from "../../react-auth0-spa";
import DataSource from "devextreme/data/data_source";

const Styles = styled.div`
  .inline-child {
    display: inline-block;
  }

  .center-div {
    margin-left: auto;
    margin-right: auto;
    width: 450px;
  }

  .offset-title {
    padding-right: 80px;
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
  }
`;

const fields = [
  {
    category: " Read-level",
    value: { field: "errorRate", multFactor: 100 },
    caption: "Error Rate (%)",
  },
  {
    category: " Read-level",
    value: { field: "errorRate140", multFactor: 100 },
    caption: "Error Rate Around Cycle 140 (%)",
  },
  {
    category: " Read-level",
    value: { field: "phasingRate", multFactor: 100 },
    caption: "Phasing (%)",
  },
  {
    category: " Read-level",
    value: { field: "prephasingRate", multFactor: 100 },
    caption: "Prephasing (%)",
  },
  {
    category: "  Run-level",
    value: { readIndependent: true, field: "polonyDensity", multFactor: 0.001 },
    caption: "Polony Density (k/mm2)",
  },
  {
    category: "  Run-level",
    value: { readIndependent: true, field: "pfDensity", multFactor: 0.001 },
    caption: "PF Density (k/mm2)",
  },
  {
    category: "  Run-level",
    value: {
      readIndependent: true,
      field: "r2R1",
      multFactor: 1,
    },
    caption: "R2 Over R1",
  },
  {
    category: "  Run-level",
    value: {
      readIndependent: true,
      field: "indexAssignedRate",
      multFactor: 100,
    },
    caption: "Index Assigned Rate (%)",
  },
  {
    category: "  Run-level",
    value: {
      readIndependent: true,
      field: "indexMismatchRate",
      multFactor: 100,
    },
    caption: "Index Mismatch Rate (%)",
  },
  {
    category: " Read-level",
    value: { field: "alignDensity", multFactor: 0.001 },
    caption: "Aligned Density (k/mm2)",
  },
  {
    category: " Read-level",
    value: { field: "chimericRate", multFactor: 100 },
    caption: "Chimeric Rate",
  },
  {
    category: " Read-level",
    value: { field: "chimericCount" },
    caption: "Chimeric Reads",
  },
  {
    category: " Read-level",
    value: { field: "duplicateRate", multFactor: 100 },
    caption: "Duplicate Rate",
  },
  {
    category: "  Run-level",
    value: { readIndependent: true, field: "failed" },
    caption: "Failed Tiles",
  },
  {
    category: " Read-level",
    value: { field: "residualRate", multFactor: 100 },
    caption: "Residual Rate (%)",
  },
  {
    category: " Read-level",
    value: { field: "residualRateChA", multFactor: 100 },
    caption: "Residual Rate Ch A (%)",
  },
  {
    category: " Read-level",
    value: { field: "residualRateChB", multFactor: 100 },
    caption: "Residual Rate Ch B (%)",
  },
  {
    category: " Read-level",
    value: { field: "residualRateChC", multFactor: 100 },
    caption: "Residual Rate Ch C (%)",
  },
  {
    category: " Read-level",
    value: { field: "residualRateChD", multFactor: 100 },
    caption: "Residual Rate Ch D (%)",
  },
  {
    category: " Read-level",
    value: { field: "decayRateChA", multFactor: 100 },
    caption: "Decay Rate Ch A (%)",
  },
  {
    category: " Read-level",
    value: { field: "decayRateChB", multFactor: 100 },
    caption: "Decay Rate Ch B (%)",
  },
  {
    category: " Read-level",
    value: { field: "decayRateChC", multFactor: 100 },
    caption: "Decay Rate Ch C (%)",
  },
  {
    category: " Read-level",
    value: { field: "decayRateChD", multFactor: 100 },
    caption: "Decay Rate Ch D (%)",
  },
  {
    category: " Read-level",
    value: { field: "phasingRateChA", multFactor: 100 },
    caption: "Phasing Rate Ch A (%)",
  },
  {
    category: " Read-level",
    value: { field: "phasingRateChB", multFactor: 100 },
    caption: "Phasing Rate Ch B (%)",
  },
  {
    category: " Read-level",
    value: { field: "phasingRateChC", multFactor: 100 },
    caption: "Phasing Rate Ch C (%)",
  },
  {
    category: " Read-level",
    value: { field: "phasingRateChD", multFactor: 100 },
    caption: "Phasing Rate Ch D (%)",
  },
  {
    category: " Read-level",
    value: { field: "prephasingRateChA", multFactor: 100 },
    caption: "Prephasing Rate Ch A (%)",
  },
  {
    category: " Read-level",
    value: { field: "prephasingRateChB", multFactor: 100 },
    caption: "Prephasing Rate Ch B (%)",
  },
  {
    category: " Read-level",
    value: { field: "prephasingRateChC", multFactor: 100 },
    caption: "Prephasing Rate Ch C (%)",
  },
  {
    category: " Read-level",
    value: { field: "prephasingRateChD", multFactor: 100 },
    caption: "Prephasing Rate Ch D (%)",
  },

  {
    category: " Read-level",
    value: { field: "meanCoverage" },
    caption: "Mean Coverage",
  },
  {
    category: " Read-level",
    value: { field: "lowCoverageSites" },
    caption: "Low Coverage Sites",
  },
  {
    category: " Read-level",
    value: { field: "lowCoverageSitesBestPolonies" },
    caption: "Low Coverage Sites Best Polonies",
  },
  {
    category: " Read-level",
    value: { field: "highClipSites" },
    caption: "High Clip Sites",
  },
  {
    category: " Read-level",
    value: { field: "highErrorThreshold" },
    caption: "High Error Threshold",
  },
  // {
  //   category: " Read-level",
  //   value: { field: "highErrorSites" },
  //   caption: "High Error Sites",
  // },
  // {
  //   category: " Read-level",
  //   value: { field: "highErrorContribution" },
  //   caption: "High Error Contribution",
  // },
  {
    category: " Read-level",
    value: { field: "coverageCV" },
    caption: "Coverage CV",
  },
  {
    category: " Read-level",
    value: { field: "coverageCVBestPolonies" },
    caption: "Coverage CV Best Polonies",
  },
  // {
  //   category: " Read-level",
  //   value: { field: "highErrorSinglets" },
  //   caption: "High Error Singlets",
  // },
  // {
  //   category: " Read-level",
  //   value: { field: "highErrorGroups" },
  //   caption: "High Error Groups",
  // },

  {
    category: " Read-level",
    value: { field: "alignedCountBestPolonies", multFactor: 0.001 },
    caption: "Best Polonies: Aligned Count (k)",
  },

  {
    category: " Read-level",
    value: { field: "alignedDensityBestPolonies", multFactor: 0.001 },
    caption: "Best Polonies: Aligned Density (k/mm2)",
  },

  {
    category: " Read-level",
    value: { field: "softClipRateBestPolonies", multFactor: 100 },
    caption: "Best Polonies: Soft Clip (%)",
  },

  {
    category: " Read-level",
    value: { field: "errorRateBestPolonies", multFactor: 100 },
    caption: "Best Polonies: Error (%)",
  },

  {
    category: "Cycle-level",
    value: { bycycle: true, field: "errorRate", multFactor: 100 },
    caption: "By Cycle: Error (%)",
  },
  {
    category: "Cycle-level",
    value: { bycycle: true, field: "errorRateNC", multFactor: 100 },
    caption: "By Cycle: Error Rate Exclude Chimera (%)",
  },
  {
    category: "Cycle-level",
    value: { bycycle: true, field: "zScoreG" },
    caption: "By Cycle: Z Score Green",
  },
  {
    category: "Cycle-level",
    value: { bycycle: true, field: "zScoreR" },
    caption: "By Cycle: Z Score Red",
  },
  {
    category: "Cycle-level",
    value: { bycycle: true, field: "averageQScore" },
    caption: "By Cycle: Average Q Score",
  },
  {
    category: "Cycle-level",
    value: { bycycle: true, field: "percentQ30", multFactor: 100 },
    caption: "By Cycle: Percent Q30 (%)",
  },
  {
    category: "Cycle-level",
    value: { bycycle: true, field: "calledARate", multFactor: 100 },
    caption: "By Cycle: Percent A (%)",
  },
  {
    category: "Cycle-level",
    value: { bycycle: true, field: "calledCRate", multFactor: 100 },
    caption: "By Cycle: Percent C (%)",
  },
  {
    category: "Cycle-level",
    value: { bycycle: true, field: "calledGRate", multFactor: 100 },
    caption: "By Cycle: Percent G (%)",
  },
  {
    category: "Cycle-level",
    value: { bycycle: true, field: "calledTRate", multFactor: 100 },
    caption: "By Cycle: Percent T (%)",
  },
  {
    category: "Cycle-level",
    value: { bycycle: true, field: "zPosition" },
    caption: "By Cycle: Z Position",
  },
  {
    category: "Cycle-level (Avg over channels)",
    value: { bycycle: true, field: "avgfwhm" },
    caption: "By Cycle: Avg FWHM",
  },
  {
    category: "Cycle-level (Avg over channels)",
    value: { bycycle: true, field: "avgresidual" },
    caption: "By Cycle: Avg Residual",
  },
  {
    category: "Cycle-level (Avg over channels)",
    value: { bycycle: true, field: "avgtx" },
    caption: "By Cycle: Avg Tx",
  },
  {
    category: "Cycle-level (Avg over channels)",
    value: { bycycle: true, field: "avgty" },
    caption: "By Cycle: Avg Ty",
  },
  {
    category: "Cycle-level (Avg over channels)",
    value: { bycycle: true, field: "avgrotation" },
    caption: "By Cycle: Avg Rotation (mDeg)",
  },
  {
    category: "Cycle-level (Avg over channels)",
    value: { bycycle: true, field: "avgphasingRate", multFactor: 100 },
    caption: "By Cycle: Avg Phasing (%)",
  },
  {
    category: "Cycle-level (Avg over channels)",
    value: { bycycle: true, field: "avgprephasingRate", multFactor: 100 },
    caption: "By Cycle: Avg Prephasing (%)",
  },
  {
    category: "Cycle-level (Avg over channels)",
    value: { bycycle: true, field: "avgp90" },
    caption: "By Cycle: Avg Signal P90",
  },
];

const objForField = (field) => {
  for (var i = 0; i < fields.length; i++) {
    if (fields[i].value.field === field) {
      return fields[i].value;
    }
  }
  return null;
};

// function format(value) {
//   var prec = 2;
//   if (value > 1) prec = 3;
//   else if (value > 10) prec = 3;
//   else if (value > 100) prec = 3;
//   else if (value > 1000) prec = 4;
//   return `${value.toPrecision(prec)}`;
// }

export const DualSurfaceFlowcell = React.memo((props) => {
  const { data, field, mousedownhandler, id, runName } = props;
  const precision = 2;
  const hidelegend = false;

  const [selectedField, setSelectedField] = useState(objForField(field));

  const [state, setState] = useState({ data1: [], data2: [] });
  const [range, setRange] = useState({
    min: 0,
    max: 100,
    auto: true,
    absMin: 0,
    absMax: 100,
  });
  const [cycle, setCycle] = useState(1);
  const [read, setRead] = useState("R1");

  const { fetchWithCheck } = useAuth0();

  useEffect(() => {
    const callAPI = async () => {
      const dt = await fetchWithCheck(
        "/tileCycleForField/" +
          id +
          "?field=" +
          selectedField.field +
          "&cycle=" +
          cycle +
          "&read=" +
          read
      );

      if (!dt) {
        setState({ data1: [], data2: [], min: 0, max: 100 });
        return;
      }
      var min = 999999,
        max = -999999;
      var factor = 1;
      if (selectedField.multFactor) {
        factor = selectedField.multFactor;
      }

      if (selectedField.field === "avgrotation") {
        for (var i = 0; i < dt.length; i++) {
          var val = dt[i].value;
          if (val >= -1 && val <= 1) {
            dt[i].value = (1e3 * dt[i].value * 180) / 3.1415926;
          } else {
            dt[i].value = -999;
          }
        }
      }

      var filteredData1 = {};
      dt.filter((v) => v.tile.endsWith("S1")).forEach((el) => {
        var val = el.value;
        if (val === -999) {
          val = NaN;
        }
        val = val * factor;
        filteredData1[el.tile] = val;
        if (val < min) {
          min = val;
        }
        if (val > max) {
          max = val;
        }
      });
      var filteredData2 = {};
      dt.filter((v) => v.tile.endsWith("S2")).forEach((el) => {
        var val = el.value;
        if (val === -999) {
          val = NaN;
        }
        val = val * factor;
        filteredData2[el.tile] = val;
        if (val < min) {
          min = val;
        }
        if (val > max) {
          max = val;
        }
      });

      if (min === 999999) {
        min = 0;
        max = 100;
      } else {
        const diff = max - min;
        max = max + diff * 0.2;
        min = min - diff * 0.2;
      }

      setState({ data1: filteredData1, data2: filteredData2 });
      if (range.auto) {
        setRange({ auto: true, min: min, max: max });
      }
    };

    if (selectedField.bycycle) {
      callAPI();
    } else {
      var min = 999999,
        max = -999999;

      var filteredData1 = {};
      data
        .filter(
          (v) =>
            v.tile.endsWith("S1") &&
            (v.read === read || selectedField.readIndependent)
        )
        .forEach((el) => {
          var val = el[selectedField.field];
          if (val === -999) {
            val = NaN;
          }
          if (selectedField.multFactor) {
            val = val * selectedField.multFactor;
          }
          filteredData1[el.tile] = val;
          if (val < min) {
            min = val;
          }
          if (val > max) {
            max = val;
          }
        });
      var filteredData2 = {};
      data
        .filter(
          (v) =>
            v.tile.endsWith("S2") &&
            (v.read === read || selectedField.readIndependent)
        )
        .forEach((el) => {
          var val = el[selectedField.field];
          if (val === -999) {
            val = NaN;
          }
          if (selectedField.multFactor) {
            val = val * selectedField.multFactor;
          }
          filteredData2[el.tile] = val;
          if (val < min) {
            min = val;
          }
          if (val > max) {
            max = val;
          }
        });

      if (min === 999999) {
        min = 0;
        max = 100;
      }

      setState({ data1: filteredData1, data2: filteredData2 });
      if (range.auto) {
        setRange({
          auto: true,
          min: min,
          max: max,
          absMin: min - (max - min) * 0.5,
          absMax: max + (max - min) * 0.5,
        });
      }
    }
  }, [data, selectedField, id, fetchWithCheck, cycle, read, range.auto]);

  const valChanged = React.useCallback((e, i) => {
    setSelectedField(e.value);
  }, []);

  var title = "";
  for (var i = 0; i < fields.length; i++) {
    if (
      selectedField.field === fields[i].value.field &&
      selectedField.bycycle === fields[i].value.bycycle
    ) {
      title = fields[i].caption;
      if (selectedField.bycycle) {
        title = title.replace("By Cycle:", "Cycle " + cycle);
      }
    }
  }

  const tmp = [...new Set(data.map((x) => x.read))];
  const sortOrder = props.runInfo.readOrder.split(",");
  let distinctReads = [];
  for (var j = 0; j < sortOrder.length; j++) {
    if (tmp.includes(sortOrder[j])) distinctReads.push(sortOrder[j]);
  }

  const groupData = new DataSource({
    store: fields,
    key: "caption",
    group: "category",
  });

  return (
    <Styles>
      <table>
        <tr>
          <td>Metric: &nbsp; </td>
          <td>
            <SelectBox
              defaultValue={selectedField}
              displayExpr="caption"
              valueExpr="value"
              grouped={true}
              placeholder="Select a field..."
              showClearButton={false}
              dataSource={groupData}
              onValueChanged={valChanged}
              searchEnabled={true}
              searchMode={"contains"}
              searchExpr={"caption"}
              searchTimeout={200}
              minSearchLength={0}
              showDataBeforeSearch={true}
              style={{ width: "300px" }}
            />
          </td>
        </tr>

        <tr>
          <td>Read:</td>
          <td>
            <SelectBox
              defaultValue={selectedField.readIndependent ? null : read}
              disabled={selectedField.readIndependent}
              placeholder=" "
              showClearButton={false}
              dataSource={distinctReads}
              onValueChanged={(v) => {
                setRead(v.value);
              }}
              style={{ width: "100px" }}
            />
          </td>
        </tr>

        <tr>
          <td>Cycle:</td>
          <td>
            <NumberBox
              style={{ width: "100px", paddingLeft: "15px" }}
              min={1}
              max={151}
              disabled={!selectedField.bycycle}
              showSpinButtons
              value={cycle}
              onValueChanged={(e) => {
                setCycle(e.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Scale:</td>
          <td>
            <CheckBox
              value={range.auto}
              onValueChanged={(e) => {
                setRange({ ...range, auto: e.value });
              }}
              text="Auto"
            />

            <div className="dx-field">
              <div className="dx-field-label">Max:</div>
              <div className="dx-field-value">
                <NumberBox
                  value={range.max.toFixed(2)}
                  disabled={range.auto}
                  onValueChanged={(e) => {
                    setRange({ ...range, max: e.value });
                  }}
                  showSpinButtons={true}
                />
              </div>
            </div>

            <div className="dx-field">
              <div className="dx-field-label">Min:</div>
              <div className="dx-field-value">
                <NumberBox
                  value={range.min.toFixed(2)}
                  disabled={range.auto}
                  onValueChanged={(e) => {
                    setRange({ ...range, min: e.value });
                  }}
                  showSpinButtons={true}
                />
              </div>
            </div>

            {/* <RangeSelector
              id="range-selector"
              value={[range.min, range.max]}
              disabled={range.auto}
              height={80}
              behavior={{
                animationEnabled: false,
                callValueChanged: "onMoving",
                allowSlidersSwap: false,
                snapToTicks: false,
              }}
              onValueChanged={(obj) => {
                setRange({ ...range, min: obj.value[0], max: obj.value[1] });
              }}
            >
              <Margin top={0} bottom={0} />
              <Scale startValue={range.absMin} endValue={range.absMax}>
                <MinorTick visible={false} />
                <Label format="decimal" />
              </Scale>
              <SliderMarker format={{ formatter: format }} />
            </RangeSelector> */}
          </td>
        </tr>
      </table>
      <br />

      <div className="offset-title">{runName + " - " + title}</div>
      <br />
      <div className="center-div">
        <FlowcellHeatmap
          data={state.data1}
          surface="1"
          minv={range.min}
          maxv={range.max}
          title="Surface 1"
          tooltipprecision={precision}
          className="inline-child"
          hidelegend="true"
          mousedownhandler={mousedownhandler}
        />
        <FlowcellHeatmap
          data={state.data2}
          surface="2"
          minv={range.min}
          maxv={range.max}
          title="Surface 2"
          tooltipprecision={precision}
          className="inline-child"
          hidelegend={hidelegend}
          mousedownhandler={mousedownhandler}
        />
      </div>
    </Styles>
  );
});
