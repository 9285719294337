import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import { AnalysisInfoTable } from "../AnalysisInfoTable";
import { useAuth0 } from "../../react-auth0-spa";
import styled from "styled-components";
import { ChannelSubPlots } from "./ChannelSubPlots";
import { DualChart } from "./DualChart";
import { Button } from "reactstrap";

import { SingleChart } from "./SingleChart";
import { MultiQueryFieldChart } from "./MultiQueryFieldChart";
import { MultiFieldChart } from "./MultiFieldChart";
import { ErrorSummaryTable } from "./ErrorSummaryTable";
import { ConfusionMatrixHeatmap } from "./ConfusionMatrixHeatmap";

import { ThumbnailBrowser } from "../Images/ThumbnailBrowser";

import Accordion from "devextreme-react/accordion";
import { FlowcellPanel } from "./FlowcellPanel";
import { DashboardContainer } from "./DashboardContainer";
import { IGVForm } from "../IGV/myigv";
import { PlotWithFieldOptions } from "./PlotWithFieldOptions";
import { ChannelPlotWithFieldOptions } from "./ChannelPlotWithFieldOptions";
import { BarChart } from "./BarChart";

const Styles = styled.div`
  .flex-container {
    display: flex;
  }

  .flex-child {
    flex: 1;
  }

  .flex-child:first-child {
    margin-right: 20px;
  }
`;
export const AnalysisDashboard = (props) => {
  const accordion = useRef(null);
  const id = props.id;
  const runInfo = props.runInfo;
  const { numcyclesR1, numcyclesR2, reads } = runInfo;
  const runName = runInfo.name;
  const history = props.history;
  const analysisVersion = props.version;

  console.log("runInfo", runInfo);
  const [tiles, setTiles] = useState([]);

  const { fetchWithCheck, loading } = useAuth0();

  useEffect(() => {
    const callApi = async () => {
      const tmp = await fetchWithCheck("/analyses/" + id + "/tiles");
      if (tmp) {
        tmp.unshift({ id: 0, name: "All Tiles Avg" });
        setTiles(tmp);
      }
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, id]);

  var items = [];

  if (id > 0) {
    items.push(
      <AnalysisInfoTable
        title="Analysis Summary"
        id={id}
        numCyclesR1={numcyclesR1}
        numCyclesR2={numcyclesR2}
      />
    );
    items.push(
      <div title="Flowcell View" className="flex-container">
        <FlowcellPanel id={id} runInfo={props.runInfo} />
      </div>
    );

    items.push(
      <DashboardContainer title="Error Rate">
        <SingleChart
          numTiles={5}
          id={id}
          field={"errorRate"}
          yLabel="% Error"
          title={runName}
          multby100={true}
          runInfo={runInfo}
        />
        <MultiQueryFieldChart
          id={id}
          title={runName}
          multby100={true}
          yLabel="% Error"
          fields={"errorRate"}
          labels={"Error Rate"}
          runInfo={runInfo}
        />
      </DashboardContainer>
    );

    items.push(
      <PlotWithFieldOptions
        title="Quality"
        id={id}
        runName={runName}
        runInfo={runInfo}
        options={[
          { field: "averageQScore", label: "Avg Q Score" },
          { field: "percentQ40", label: "Percent Q40" },
          { field: "percentQ30", label: "Percent Q30" },
          { field: "percentQ15", label: "Percent Q15" },
        ]}
      />
    );

    items.push(<BarChart title="Indices" id={props.id} />);

    items.push(
      <DashboardContainer title="Soft Clip / Deletion / Insertion">
        <DualChart
          id={id}
          title={runName}
          runInfo={runInfo}
          fieldA="softClipRate"
          fieldB="deletionRate"
          yLabel="%"
          subTitleA="Soft Clip Rate"
          subTitleB="Deletion Rate"
          multby100={true}
          nosync
        />
        <MultiQueryFieldChart
          id={id}
          multby100={true}
          title={runName}
          runInfo={runInfo}
          yLabel="%"
          fields={"softClipRate,deletionRate,insertionRate"}
          labels={"Soft Clip Rate,Deletion Rate,Insertion Rate"}
        />
      </DashboardContainer>
    );

    items.push(<IGVForm id={id} title="IGV" />);

    items.push(<ThumbnailBrowser reads={reads} title="Thumbnails" id={id} />);

    items.push(
      <ConfusionMatrixHeatmap title="Confusion Matrix" tiles={tiles} id={id} />
    );

    items.push(
      <ChannelPlotWithFieldOptions
        title="Intensity"
        id={props.id}
        runInfo={runInfo}
        options={[
          { field: "p90cc", label: "Color Corrected P90" },
          { field: "p90", label: "Raw P90" },
        ]}
        runName={runName}
      />
    );

    items.push(
      <DashboardContainer title="Residual">
        <ChannelSubPlots
          id={id}
          runInfo={runInfo}
          field="residual"
          yLabel="Residual"
          multby100={true}
        />
        <MultiFieldChart
          id={id}
          runInfo={runInfo}
          field="residual"
          groupField="channel"
          yLabel="Residual"
          runName={runName}
        />
      </DashboardContainer>
    );

    items.push(
      <ChannelPlotWithFieldOptions
        title="FWHM"
        id={id}
        runInfo={runInfo}
        options={[
          { field: "fwhmRaw", label: "Raw FWHM" },
          { field: "fwhm", label: "LoG FWHM" },
        ]}
      />
    );

    items.push(
      <DashboardContainer title="Phasing">
        <ChannelSubPlots
          id={id}
          runInfo={runInfo}
          field="phasingRate"
          yLabel="%"
          multby100={true}
        />
        <MultiFieldChart
          id={id}
          runInfo={runInfo}
          field="phasingRate"
          groupField="channel"
          yLabel="%"
          multby100={true}
        />
      </DashboardContainer>
    );

    items.push(
      <DashboardContainer title="Prephasing">
        <ChannelSubPlots
          id={id}
          runInfo={runInfo}
          field="prephasingRate"
          yLabel="%"
          multby100={true}
        />
        <MultiFieldChart
          id={id}
          runInfo={runInfo}
          field="prephasingRate"
          groupField="channel"
          yLabel="%"
          multby100={true}
        />
      </DashboardContainer>
    );

    items.push(
      <DashboardContainer title="Color Separation">
        <DualChart
          id={id}
          runInfo={runInfo}
          fieldA="zScoreG"
          fieldB="zScoreR"
          yLabel="Z Score"
          subTitleA="Green"
          subTitleB="Red"
        />
        <MultiQueryFieldChart
          id={id}
          runInfo={runInfo}
          yLabel="Z Score"
          fields={"zScoreG,zScoreR"}
          labels={"Green,Red"}
        />
      </DashboardContainer>
    );

    items.push(
      <DashboardContainer title="Adapter Rate">
        <SingleChart
          id={id}
          runInfo={runInfo}
          field={"adapterRate"}
          yLabel="%"
          multby100={true}
        />
        <MultiQueryFieldChart
          id={id}
          runInfo={runInfo}
          multby100={true}
          yLabel="%"
          fields={"adapterRate"}
          labels={"Adapter Rate"}
        />
      </DashboardContainer>
    );

    items.push(
      <MultiQueryFieldChart
        id={id}
        runInfo={runInfo}
        yLabel="%"
        title="% Bases"
        fields={"calledARate,calledCRate,calledGRate,calledTRate"}
        labels={" Called A, Called C, Called G, Called T"}
        colors={"29,178,245;245,86,74;198,144,83;151,201,92"}
        dashstyle={"solid,solid,solid,solid"}
        multby100={true}
      />
    );
  }

  items.push(<ErrorSummaryTable title="Error Summary" id={id} />);

  return (
    <Styles>
      <Button
        color="primary"
        onClick={(e) => {
          history.push(
            "/analyses/" + id + "/details/" + runName + "?v=" + analysisVersion
          );
        }}
      >
        {" "}
        Go to Table Data{" "}
      </Button>{" "}
      &nbsp; &nbsp;
      <Button
        color="info"
        onClick={(e) => {
          let a = accordion.current.instance;
          for (var i = 0; i < items.length; i++) {
            a.expandItem(i);
          }
        }}
      >
        {" "}
        Expand All Tabs
      </Button>{" "}
      &nbsp;
      <Button
        color="info"
        onClick={(e) => {
          let a = accordion.current.instance;
          for (var i = 1; i < items.length; i++) {
            a.collapseItem(i);
          }
        }}
      >
        {" "}
        Collapse All Tabs
      </Button>
      <br />
      <br />
      <Accordion
        ref={accordion}
        items={items}
        itemRender={(item) => item}
        multiple={true}
        itemTitleRender={(item) => item.props.title}
      />
    </Styles>
  );
};
