import React, { useEffect, useRef } from "react";
import "../../App.css";

import Button from 'devextreme-react/button';
import 'devextreme-react/text-area';
import 'devextreme-react/html-editor';
import DataGrid, {
    ColumnChooser,
    Paging,
    Column,
    Pager,
    GroupPanel,
    SearchPanel,
    Export,
    FilterRow
} from "devextreme-react/data-grid";


export const GeneralQueryTable = props => {
    const theGrid = useRef(null)

    useEffect(() => {
        const handleResize = () => {
          if (theGrid) { theGrid.current.instance.option("height", window.innerHeight - 150); }
        }
        window.addEventListener('resize', handleResize)
        handleResize()
        return _ => {  window.removeEventListener('resize', handleResize) }
      })

    
    var cols = props.data.fields ? props.data.fields.map(v=> {

        if (v.fieldType==="date") {
            return  <Column
                dataField={v.name}
                caption={v.label}
                dataType={"date"}
                format={"dd MMM yyyy HH:mm"}
            />
        }
        else {
            return  <Column dataField={v.name} caption={v.label}/>
        }
    }
    ) : []

    cols.unshift(<Column dataField="idx"/>)

    return (
        <React.Fragment>

      <Button onClick={() => { theGrid.current.instance.state({}) }} text="Reset Table Formatting" /><br /><br />

        <DataGrid
            ref={theGrid}
            dataSource={props.data.values}
            keyExpr="idx"
            wordWrapEnabled={true}
            showBorders={true}
            showColumnLines={true}
            showRowLines={true}
            rowAlternationEnabled={false}
            focusedRowEnabled={false}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode={"widget"}
            columnHidingEnabled={true}
            columnAutoWidth={true}
            hoverStateEnabled={false}
            scrolling={{showScrollbar: "always"}}
        >
            <SearchPanel visible={true} width={240} placeholder={"Search..."} />
            <GroupPanel visible={true} />
            <Export
                enabled={true}
                fileName={"QueryResults"}
                allowExportSelectedData={true}
            />
            <FilterRow visible={true} />
            <ColumnChooser enabled={true} />
            {cols}
            <Paging defaultPageSize={100} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 1000]}
            />
        </DataGrid>
        </React.Fragment>
    );
}
