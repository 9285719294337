import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "../react-auth0-spa";
import Tabs from "devextreme-react/tabs";

import "devextreme-react/text-area";
import "devextreme-react/html-editor";
import DataGrid, {
  Column,
  ColumnChooser,
  Format,
  Export,
  HeaderFilter,
  FilterPanel,
  FilterBuilder,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { PolonyQCDetailsTable } from "./PolonyQC/PolonyQCDetailsTable";

export const FlowcellClusterQCInfoTable = (props) => {
  const { loading, fetchWithCheck } = useAuth0();
  const [details, setDetails] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    const callApi = async () => {
      const v = await fetchWithCheck("/flowcells/" + props.id + "/clusterQC");
      setDetails(v);
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, props.id]);

  const onSelectedTabChange = useCallback(({ name, value }) => {
    if (name === "selectedIndex") {
      setCurrentTab(value);
    }
  }, []);

  var loadData = async () => {
    var data = await fetchWithCheck(
      "/flowcells/" + props.id + "/clusterQCLanes"
    );
    return data;
  };

  const filtNeg999s = (data) => {
    if (data.value === -999) {
      return <div></div>;
    }
    return <div>{data.text}</div>;
  };

  var customStore = new CustomStore({
    key: "lane",
    load: loadData,
  });

  const tabs = ["Table", "Image"];
  return (
    <React.Fragment>
      <PolonyQCDetailsTable details={details} />

      <br />

      <Tabs
        dataSource={tabs}
        selectedIndex={currentTab}
        onOptionChanged={onSelectedTabChange}
        style={{ width: "400px" }}
        className={"widget"}
      />
      {currentTab === 1 && (
        <img
          alt="Contact Sheet"
          src={details && details.image}
          width={"1200px"}
        />
      )}
      {currentTab === 0 && (
        <DataGrid
          dataSource={customStore}
          keyExpr="id"
          wordWrapEnabled={true}
          showBorders={true}
          showColumnLines={true}
          showRowLines={true}
          rowAlternationEnabled={true}
          focusedRowEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode={"widget"}
          columnHidingEnabled={true}
          columnAutoWidth={true}
          hoverStateEnabled={false}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <HeaderFilter visible={false} />
          <ColumnChooser enabled={false} />
          <FilterPanel visible={false} />
          <FilterBuilder visible={false} />
          <Column dataField={"lane"} allowEditing={false} />
          <Column dataField={"totalRegions"} allowEditing={false} />
          <Column
            dataField={"percentageOver100kpmm2"}
            allowEditing={false}
            cellRender={filtNeg999s}
          >
            <Format type="percent" precision={1} />
          </Column>
          <Column
            dataField={"density"}
            allowEditing={false}
            cellRender={filtNeg999s}
          />
          <Column
            dataField={"cvDensity"}
            allowEditing={false}
            cellRender={filtNeg999s}
          >
            <Format type="percent" precision={1} />
          </Column>

          <Column
            dataField={"p50TrapF1"}
            allowEditing={false}
            cellRender={filtNeg999s}
          />
          <Column
            dataField={"cvTrapF1"}
            allowEditing={false}
            cellRender={filtNeg999s}
          >
            <Format type="percent" precision={1} />
          </Column>
          <Column
            dataField={"p50BgndF1"}
            allowEditing={false}
            cellRender={filtNeg999s}
          />
          <Column
            dataField={"washResidualF1"}
            allowEditing={false}
            cellRender={filtNeg999s}
          >
            <Format type="fixedPoint" precision={2} />
          </Column>

          <Column
            dataField={"p50TrapF2"}
            allowEditing={false}
            cellRender={filtNeg999s}
          />
          <Column
            dataField={"cvTrapF2"}
            allowEditing={false}
            cellRender={filtNeg999s}
          >
            <Format type="percent" precision={1} />
          </Column>
          <Column
            dataField={"p50BgndF2"}
            allowEditing={false}
            cellRender={filtNeg999s}
          />
          <Column
            dataField={"washResidualF2"}
            allowEditing={false}
            cellRender={filtNeg999s}
          >
            <Format type="fixedPoint" precision={2} />
          </Column>

          <Column
            dataField={"p50TrapF3"}
            allowEditing={false}
            cellRender={filtNeg999s}
          />
          <Column
            dataField={"cvTrapF3"}
            allowEditing={false}
            cellRender={filtNeg999s}
          >
            <Format type="percent" precision={1} />
          </Column>
          <Column
            dataField={"p50BgndF3"}
            allowEditing={false}
            cellRender={filtNeg999s}
          />
          <Column
            dataField={"washResidualF3"}
            allowEditing={false}
            cellRender={filtNeg999s}
          >
            <Format type="fixedPoint" precision={2} />
          </Column>

          <Column
            dataField={"p50TrapF4"}
            allowEditing={false}
            cellRender={filtNeg999s}
          />
          <Column
            dataField={"cvTrapF4"}
            allowEditing={false}
            cellRender={filtNeg999s}
          >
            <Format type="percent" precision={1} />
          </Column>
          <Column
            dataField={"p50BgndF4"}
            allowEditing={false}
            cellRender={filtNeg999s}
          />
          <Column
            dataField={"washResidualF4"}
            allowEditing={false}
            cellRender={filtNeg999s}
          >
            <Format type="fixedPoint" precision={2} />
          </Column>
        </DataGrid>
      )}
    </React.Fragment>
  );
};
