import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Link } from "react-router-dom";
import "../App.css";
import CustomStore from "devextreme/data/custom_store";
import Button from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";

import DataGrid, {
  Editing,
  Column,
  ColumnChooser,
  Paging,
  Pager,
  GroupPanel,
  Lookup,
  Summary,
  FilterRow,
  NumericRule,
  RequiredRule,
  StateStoring,
  Export,
  Form,
} from "devextreme-react/data-grid";

import {
  SimpleItem,
  GroupItem,
  EmptyItem,
  // ButtonItem
} from "devextreme-react/form";

import styled from "styled-components";
const Styles = styled.div`
  .second-group {
    paddingbottom: 10px;
  }
  .second-group {
    background-color: rgba(191, 191, 191, 0.15);
  }
`;

const fields = [
  { name: "Last Month", value: 1 },
  { name: "Last 3 Months", value: 3 },
  { name: "Last 6 Months", value: 6 },
  { name: "Last Year", value: 12 },
  { name: "All", value: 0 },
];

var vendorsData = new DataSource({ store: [] });
var thicknessData = new DataSource({ store: [] });
var graftingChemData = new DataSource({ store: [] });
var graftingMachineData = new DataSource({ store: [] });
var clusteringMachineData = new DataSource({ store: [] });
var rcaEnzymeData = new DataSource({ store: [] });
var qcMethodData = new DataSource({ store: [] });
var surfacePrimerData = new DataSource({ store: [] });
var clusteringChemistryData = new DataSource({ store: [] });

var copiedObject = {};
var copiedAmpObject = {};

export const FlowcellsForm = () => {
  const theGrid = useRef(null);
  const [libraries, setLibraries] = useState(null);
  const [numMonths, setNumMonths] = useState(1);

  const { loading, fetchWithCheck, fetchNoResponse } = useAuth0();

  useEffect(() => {
    const handleResize = () => {
      if (theGrid) {
        theGrid.current.instance.option("height", window.innerHeight - 320);
      }
    };
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    const callApi = async () => {
      setLibraries(await fetchWithCheck("/libraries"));
      if (theGrid) {
        theGrid.current.instance.option("height", window.innerHeight - 320);
      }
    };

    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck]);

  var loadData = async () => {
    var data = await fetchWithCheck("/flowcells?numMonths=" + numMonths);
    vendorsData = new DataSource({
      store: [...new Set(data.map((x) => x.vendor))],
    });
    thicknessData = new DataSource({
      store: [...new Set(data.map((x) => x.targetCoverglassThicknessMM))],
    });
    graftingChemData = new DataSource({
      store: [...new Set(data.map((x) => x.graftingChemistry))],
    });
    graftingMachineData = new DataSource({
      store: [...new Set(data.map((x) => x.graftingMachine))],
    });
    clusteringMachineData = new DataSource({
      store: [...new Set(data.map((x) => x.clusteringMachine))],
    });
    rcaEnzymeData = new DataSource({
      store: [...new Set(data.map((x) => x.rcaEnzyme))],
    });
    qcMethodData = new DataSource({
      store: [...new Set(data.map((x) => x.qcMethod))],
    });
    clusteringChemistryData = new DataSource({
      store: [...new Set(data.map((x) => x.clusteringChemistry))],
    });
    surfacePrimerData = new DataSource({
      store: [...new Set(data.map((x) => x.surfacePrimers))],
    });
    return data;
  };

  var deleteData = async (key) => {
    await fetchNoResponse("/flowcells/" + key, {
      method: "DELETE",
    });
  };

  var updateData = async (key, values) => {
    console.log(values);

    // if (values.graftingDate) {
    //   values.graftingDate = new Date(Date.parse(values.graftingDate)).toDateString()
    // }
    console.log(values);

    if (values.targetCoverglassThicknessMM)
      values.targetCoverglassThicknessMM = parseFloat(
        values.targetCoverglassThicknessMM
      );
    var jsonstr = JSON.stringify(values).replaceAll(
      "null",
      `"2000-01-02T00:00:00Z"`
    ); // signal to set db date field to null

    await fetchNoResponse("/flowcells/" + key, {
      method: "PATCH",
      body: jsonstr,
    });
  };

  var insertData = async (values) => {
    console.log(JSON.stringify(values));
    await fetchNoResponse("/flowcells", {
      method: "POST",
      body: JSON.stringify(values),
    });
  };

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
    update: updateData,
    insert: insertData,
    remove: deleteData,
  });

  const filtNeg999s = (data) => {
    if (data.value === -999) {
      return <div></div>;
    }
    return <div>{data.text}</div>;
  };

  const AnchorCell = (cellData) => {
    return (
      <React.Fragment>
        <Link to={"/Flowcells/" + cellData.data.id}>
          {" "}
          {cellData.data.serialNumber}
        </Link>
      </React.Fragment>
    );
  };

  const calculateFilterExpressionFlowcell = (
    filterValues,
    selectedFilterOperation
  ) => {
    return function (itemData) {
      return itemData.serialNumber.includes(filterValues);
    };
  };

  const calculateFilterExpressionAnalyses = (
    filterValues,
    selectedFilterOperation
  ) => {
    return function (itemData) {
      if (!itemData.analyses || itemData.analyses.length === 0) return false;
      return itemData.analyses.some((v) => {
        return v.runName.includes(filterValues);
      });
    };
  };

  const cellRenderAnalysis = (cell) => {
    if (!cell.value) {
      return <div></div>;
    }
    const items = cell.value.map((x, i) => {
      return (
        <React.Fragment key={i}>
          <Link to={"/runs/" + x.runName + "?v=" + x.analysisVersion}>
            {x.runName + " (v" + x.analysisVersion + ")"}
          </Link>
          <br />
        </React.Fragment>
      );
    });
    return <div>{items}</div>;
  };

  var colwidth = 120;

  return (
    <Styles>
      <br />
      <h3>Flowcells</h3>
      <Button
        onClick={() => {
          theGrid.current.instance.state({});
        }}
        text="Reset Table Formatting"
      />{" "}
      &nbsp;
      <Link to="/Libraries">Manage Libraries...</Link>
      <br />
      <br />
      <SelectBox
        value={numMonths}
        valueExpr="value"
        displayExpr="name"
        placeholder="Select a value..."
        showClearButton={false}
        dataSource={fields}
        text="Data to query"
        onValueChanged={(v) => {
          setNumMonths(v.value);
          theGrid.current.instance.getDataSource().reload();
        }}
        style={{ width: "150px" }}
      />
      <br />
      <br />
      <Button
        onClick={() => {
          let keys = theGrid.current.instance.getSelectedRowKeys();
          if (keys.length === 1 && keys[0] > 0) {
            let obj = theGrid.current.instance.getSelectedRowsData()[0];
            copiedObject = {
              vendor: obj.vendor,
              targetCoverglassThicknessMM: obj.targetCoverglassThicknessMM,
              graftingChemistry: obj.graftingChemistry,
              surfacePrimers: obj.surfacePrimers,
              graftingMachine: obj.graftingMachine,
              graftingDate: obj.graftingDate,
            };
            notify("Copied");
          } else {
            alert("Can only copy a single row");
          }
        }}
        text="Copy Grafting Data From Selected Row"
      />{" "}
      &nbsp;
      <Button
        onClick={() => {
          let keys = theGrid.current.instance.getSelectedRowKeys();
          if (keys.length > 0) {
            keys.forEach((v) => {
              if (v > 0) {
                updateData(v, copiedObject);
              }
            });
            theGrid.current.instance.refresh();
            notify("Pasted");
          } else {
            alert("Can only copy single row");
          }
        }}
        text="Paste Grafting Data To Selected Rows"
      />{" "}
      &nbsp; &nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
      <Button
        onClick={() => {
          let keys = theGrid.current.instance.getSelectedRowKeys();
          if (keys.length === 1 && keys[0] > 0) {
            let obj = theGrid.current.instance.getSelectedRowsData()[0];
            copiedAmpObject = {
              clusteringChemistry: obj.clusteringChemistry,
              l1LibraryID: obj.l1LibraryID,
              l1SpikeInLibraryID: obj.l1SpikeInLibraryID,
              l2LibraryID: obj.l2LibraryID,
              l2SpikeInLibraryID: obj.l2SpikeInLibraryID,
              libraryConcentrationL1: obj.libraryConcentrationL1,
              libraryConcentrationL1SpikeIn: obj.libraryConcentrationL1SpikeIn,
              libraryConcentrationL2: obj.libraryConcentrationL2,
              libraryConcentrationL2SpikeIn: obj.libraryConcentrationL2SpikeIn,
              clusteringMachine: obj.clusteringMachine,
              rcaEnzyme: obj.rcaEnzyme,
              clusteringDate: obj.clusteringDate,
              qcMethod: obj.qcMethod,
              percentDUTP: obj.percentDUTP,
              description: obj.description,
              operator: obj.operator,
              batch: obj.batch,
              vendorPartNumber: obj.vendorPartNumber,
              l1LibraryFT: obj.l1LibraryFT,
              l1SpikeInLibraryFT: obj.l1SpikeInLibraryFT,
              l2LibraryFT: obj.l2LibraryFT,
              l2SpikeInLibraryFT: obj.l2SpikeInLibraryFT,
              ampSeedLN: obj.ampSeedLN,
              ampSeedFT: obj.ampSeedFT,
              ampExtLN: obj.ampExtLN,
              ampExtFT: obj.ampExtFT,
              coLN: obj.coLN,
              coFT: obj.coFT,
              primerLN: obj.primerLN,
              primerFT: obj.primerFT,
            };
            notify("Copied");
          } else {
            alert("No row selected");
          }
        }}
        text="Copy Amplification Data From Selected Row"
      />{" "}
      &nbsp;
      <Button
        onClick={() => {
          let keys = theGrid.current.instance.getSelectedRowKeys();
          if (keys.length > 0) {
            keys.forEach((v) => {
              if (v > 0) {
                updateData(v, copiedAmpObject);
              }
            });
            theGrid.current.instance.refresh();
            notify("Pasted");
          } else {
            alert("No row selected");
          }
        }}
        text="Paste Amplification Data To Selected Rows"
      />{" "}
      &nbsp; &nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
      <Button
        onClick={() => {
          let keys = theGrid.current.instance.getSelectedRowKeys();
          const vals = theGrid.current.instance.getSelectedRowsData();
          if (keys.length > 0) {
            keys.forEach((v, i) => {
              if (v > 0) {
                let obj = vals[i];
                updateData(v, {
                  l2LibraryID: obj.l1LibraryID,
                  l2LibraryFT: obj.l1LibraryFT,
                  libraryConcentrationL2: obj.libraryConcentrationL1,
                  l2SpikeInLibraryID: obj.l1SpikeInLibraryID,
                  l2SpikeInLibraryFT: obj.l1SpikeInLibraryFT,
                  libraryConcentrationL2SpikeIn:
                    obj.libraryConcentrationL1SpikeIn,
                });
              }
            });
            theGrid.current.instance.refresh();
            notify("Pasted");
          } else {
            alert("No row selected");
          }
        }}
        text="Copy Lane 1 values to Lane 2 for Selected Rows"
      />
      <br />
      <br />
      <DataGrid
        ref={theGrid}
        dataSource={customStore}
        wordWrapEnabled={false}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnHidingEnabled={true}
        columnResizingMode="widget"
        columnMinWidth={100}
        columnAutoWidth={false}
        columnFixing={{ enabled: true }}
        selection={{ mode: "multiple" }}
        scrolling={{ showScrollbar: "always" }}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="flowcellsTableV2"
        />
        <FilterRow visible={true} />
        <Export
          enabled={true}
          fileName={"flowcellExport"}
          allowExportSelectedData={true}
        />

        <ColumnChooser enabled={true} />

        <Editing
          mode="popup" //"form" "batch" "popup" "cell"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
        >
          <Form colCount={1}>
            <GroupItem colCount={2}>
              <SimpleItem dataField="serialNumber" colSpan={1} />
              <SimpleItem
                dataField="checkoutDate"
                editorType="dxDateBox"
                editorOptions={{
                  pickerType: "calendar",
                  showClearButton: true,
                  dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssx",
                }}
              />
              <SimpleItem
                label={{ text: "Ready For Seq" }}
                dataField="readyForSequencing"
              />
              <SimpleItem dataField="status" />
            </GroupItem>
            <EmptyItem />
            <GroupItem caption="Grafting Data" colCount={3}>
              <SimpleItem dataField="vendor" />
              <SimpleItem dataField="targetCoverglassThicknessMM" />
              <SimpleItem dataField="graftingChemistry" />
              <SimpleItem dataField="surfacePrimers" />
              <SimpleItem dataField="graftingMachine" />
              <SimpleItem
                dataField="graftingDate"
                editorType="dxDateBox"
                editorOptions={{
                  pickerType: "calendar",
                  showClearButton: true,
                  dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssx",
                }}
              />
            </GroupItem>
            <GroupItem caption="Amplification Data" colCount={4}>
              <GroupItem colCount={1} colSpan={1}>
                <SimpleItem
                  label={{
                    text: "Lane 1 Library",
                    location: "top",
                    showColon: false,
                  }}
                  dataField="l1LibraryID"
                />
                <SimpleItem
                  label={{ text: "Concentration" }}
                  dataField="libraryConcentrationL1"
                />
                <SimpleItem label={{ text: "F/T" }} dataField="l1LibraryFT" />
              </GroupItem>

              <GroupItem colCount={1} colSpan={1}>
                <SimpleItem
                  label={{
                    text: "Lane 1 Spike-in Library",
                    location: "top",
                    showColon: false,
                  }}
                  dataField="l1SpikeInLibraryID"
                />
                <SimpleItem
                  label={{ text: "Concentration" }}
                  dataField="libraryConcentrationL1SpikeIn"
                />
                <SimpleItem
                  label={{ text: "F/T" }}
                  dataField="l1SpikeInLibraryFT"
                />
              </GroupItem>

              <GroupItem colCount={1} colSpan={1}>
                <SimpleItem
                  label={{
                    text: "Lane 2 Library",
                    location: "top",
                    showColon: false,
                  }}
                  dataField="l2LibraryID"
                />
                <SimpleItem
                  label={{ text: "Concentration" }}
                  dataField="libraryConcentrationL2"
                />
                <SimpleItem label={{ text: "F/T" }} dataField="l2LibraryFT" />
              </GroupItem>

              <GroupItem colCount={1} colSpan={1}>
                <SimpleItem
                  label={{
                    text: "Lane 2 Spike-in Library",
                    location: "top",
                    showColon: false,
                  }}
                  dataField="l2SpikeInLibraryID"
                />
                <SimpleItem
                  label={{ text: "Concentration" }}
                  dataField="libraryConcentrationL2SpikeIn"
                />
                <SimpleItem
                  label={{ text: "F/T" }}
                  dataField="l2SpikeInLibraryFT"
                />
                <SimpleItem colSpan={1} dataField="libraryDilutionLot" />
                <SimpleItem colSpan={1} dataField="libraryMixStockLot" />
              </GroupItem>

              <EmptyItem colSpan={4} />

              <GroupItem colCount={3} colSpan={4}>
                <SimpleItem colSpan={1} dataField="clusteringChemistry" />
                <SimpleItem colSpan={1} dataField="percentDUTP" />

                <SimpleItem colSpan={1} dataField="rcaEnzyme" />
                <SimpleItem colSpan={1} dataField="qcMethod" />
                <SimpleItem colSpan={1} dataField="clusteringMachine" />
                <SimpleItem
                  colSpan={1}
                  dataField="clusteringDate"
                  editorType="dxDateBox"
                  editorOptions={{
                    pickerType: "calendar",
                    showClearButton: true,
                    dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssx",
                  }}
                />

                <SimpleItem colSpan={1} dataField="description" />
                <SimpleItem colSpan={1} dataField="operator" />
                <SimpleItem colSpan={1} dataField="batch" />
                <SimpleItem colSpan={1} dataField="vendorPartNumber" />
                <SimpleItem colSpan={1} dataField="ampSeedLN" />
                <SimpleItem colSpan={1} dataField="ampSeedFT" />
                <SimpleItem colSpan={1} dataField="ampExtLN" />
                <SimpleItem colSpan={1} dataField="ampExtFT" />
                <SimpleItem colSpan={1} dataField="coLN" />
                <SimpleItem colSpan={1} dataField="coFT" />
                <SimpleItem colSpan={1} dataField="primerLN" />
                <SimpleItem colSpan={1} dataField="primerFT" />
              </GroupItem>
            </GroupItem>

            {/* <ButtonItem buttonOptions={{text: 'Register',
      type: 'success',
      useSubmitBehavior: false}} /> */}
          </Form>
        </Editing>

        <GroupPanel visible={true} />

        <Column
          dataField={"serialNumber"}
          width={colwidth}
          cellRender={AnchorCell}
          calculateFilterExpression={calculateFilterExpressionFlowcell}
          fixed={true}
          fixedPosition="left"
        >
          <RequiredRule message="Serial Number cannot be blank" />
        </Column>

        <Column
          dataField={"analyses"}
          width={colwidth}
          cellRender={cellRenderAnalysis}
          editCellRender={cellRenderAnalysis}
          allowEditing={false}
          calculateFilterExpression={calculateFilterExpressionAnalyses}
        ></Column>

        <Column
          dataField={"created"}
          width={colwidth}
          defaultSortOrder={"desc"}
          dataType={"date"}
          format={"dd MMM yyyy"}
        />

        <Column
          dataField="vendor"
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              dataSource={vendorsData}
              acceptCustomValue={true}
              searchEnabled={true}
              onCustomItemCreating={(e) => {
                e.customItem = e.text;
              }}
              onValueChanged={(e) => cell.setValue(e.value)}
              itemRender={(data) => <span>{data}</span>}
            />
          )}
        ></Column>
        <Column
          dataField="targetCoverglassThicknessMM"
          width={colwidth}
          cellRender={filtNeg999s}
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              dataSource={thicknessData}
              acceptCustomValue={true}
              searchEnabled={true}
              onCustomItemCreating={(e) => {
                e.customItem = e.text;
              }}
              onValueChanged={(e) => cell.setValue(e.value)}
              itemRender={(data) => <span>{data}</span>}
            />
          )}
        >
          <NumericRule />
        </Column>

        <Column
          dataField="graftingChemistry"
          width={colwidth}
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              dataSource={graftingChemData}
              acceptCustomValue={true}
              searchEnabled={true}
              onCustomItemCreating={(e) => {
                e.customItem = e.text;
              }}
              onValueChanged={(e) => cell.setValue(e.value)}
              itemRender={(data) => <span>{data}</span>}
            />
          )}
        />
        <Column
          dataField="surfacePrimers"
          width={colwidth}
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              dataSource={surfacePrimerData}
              acceptCustomValue={true}
              searchEnabled={true}
              onCustomItemCreating={(e) => {
                e.customItem = e.text;
              }}
              onValueChanged={(e) => cell.setValue(e.value)}
              itemRender={(data) => <span>{data}</span>}
            />
          )}
        />
        <Column
          dataField="graftingMachine"
          width={colwidth}
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              dataSource={graftingMachineData}
              acceptCustomValue={true}
              searchEnabled={true}
              onCustomItemCreating={(e) => {
                e.customItem = e.text;
              }}
              onValueChanged={(e) => cell.setValue(e.value)}
              itemRender={(data) => <span>{data}</span>}
            />
          )}
        />
        <Column
          dataField="graftingDate"
          width={colwidth}
          dataType={"date"}
          format={"dd MMM yyyy"}
        />

        <Column
          dataField="clusteringChemistry"
          width={colwidth}
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              dataSource={clusteringChemistryData}
              acceptCustomValue={true}
              searchEnabled={true}
              onCustomItemCreating={(e) => {
                e.customItem = e.text;
              }}
              onValueChanged={(e) => cell.setValue(e.value)}
              itemRender={(data) => <span>{data}</span>}
            />
          )}
        />

        <Column
          dataField="l1LibraryID"
          width={colwidth}
          caption="Lane 1 Library"
        >
          <Lookup dataSource={libraries} displayExpr="name" valueExpr="id" />
        </Column>
        <Column dataField="libraryConcentrationL1" width={colwidth} />

        <Column
          dataField="l1SpikeInLibraryID"
          width={colwidth}
          caption="Lane 1 Spike-In Library"
        >
          <Lookup dataSource={libraries} displayExpr="name" valueExpr="id" />
        </Column>
        <Column dataField="libraryConcentrationL1SpikeIn" width={colwidth} />
        <Column
          dataField="l2LibraryID"
          width={colwidth}
          caption="Lane 2 Library"
        >
          <Lookup dataSource={libraries} displayExpr="name" valueExpr="id" />
        </Column>
        <Column dataField="libraryConcentrationL2" width={colwidth} />
        <Column
          dataField="l2SpikeInLibraryID"
          width={colwidth}
          caption="Lane 2 Spike-In Library"
        >
          <Lookup dataSource={libraries} displayExpr="name" valueExpr="id" />
        </Column>
        <Column dataField="libraryConcentrationL2SpikeIn" width={colwidth} />

        <Column
          dataField="clusteringMachine"
          width={colwidth}
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              dataSource={clusteringMachineData}
              acceptCustomValue={true}
              searchEnabled={true}
              onCustomItemCreating={(e) => {
                e.customItem = e.text;
              }}
              onValueChanged={(e) => cell.setValue(e.value)}
              itemRender={(data) => <span>{data}</span>}
            />
          )}
        />
        <Column
          dataField="rcaEnzyme"
          width={colwidth}
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              dataSource={rcaEnzymeData}
              acceptCustomValue={true}
              searchEnabled={true}
              onCustomItemCreating={(e) => {
                e.customItem = e.text;
              }}
              onValueChanged={(e) => cell.setValue(e.value)}
              itemRender={(data) => <span>{data}</span>}
            />
          )}
        />
        <Column
          dataField="clusteringDate"
          width={colwidth}
          dataType={"date"}
          format={"dd MMM yyyy"}
        />
        <Column
          dataField="qcMethod"
          width={colwidth}
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              dataSource={qcMethodData}
              acceptCustomValue={true}
              searchEnabled={true}
              onCustomItemCreating={(e) => {
                e.customItem = e.text;
              }}
              onValueChanged={(e) => cell.setValue(e.value)}
              itemRender={(data) => <span>{data}</span>}
            />
          )}
        />
        <Column dataField="readyForSequencing" width={colwidth} />
        <Column
          dataField="checkoutDate"
          width={colwidth}
          dataType={"date"}
          format={"dd MMM yyyy"}
        />
        <Column dataField="status" width={colwidth} />

        <Column
          dataField="percentDUTP"
          caption="%dUTP"
          width={colwidth}
          cellRender={filtNeg999s}
        />

        <Column dataField="description" width={colwidth} />
        <Column dataField="operator" width={colwidth} />
        <Column dataField="batch" width={colwidth} />
        <Column dataField="l1LibraryFT" width={colwidth} />
        <Column dataField="l1SpikeInLibraryFT" width={colwidth} />
        <Column dataField="l2LibraryFT" width={colwidth} />
        <Column dataField="l2SpikeInLibraryFT" width={colwidth} />
        <Column dataField="ampSeedLN" width={colwidth} />
        <Column dataField="ampSeedFT" width={colwidth} />
        <Column dataField="ampExtLN" width={colwidth} />
        <Column dataField="ampExtFT" width={colwidth} />
        <Column dataField="coLN" width={colwidth} />
        <Column dataField="coFT" width={colwidth} />
        <Column dataField="primerLN" width={colwidth} />
        <Column dataField="primerFT" width={colwidth} />

        <Column dataField="libraryDilutionLot" width={colwidth} />
        <Column dataField="libraryMixStockLot" width={colwidth} />

        <Summary>
          <GroupItem
            column={"title"}
            summaryType={"count"}
            displayFormat={"{0}"}
          />
        </Summary>

        <Paging defaultPageSize={40} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[20, 40, 100, 200]}
        />
      </DataGrid>
    </Styles>
  );
};
