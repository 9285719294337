import React, { useState, useEffect } from "react";
import "../../App.css";
import { useAuth0 } from "../../react-auth0-spa";
import { FlowcellHeatmap } from "../Reports/FlowcellHeatmap";
import { SelectBox } from "devextreme-react";

import { CheckBox } from "devextreme-react/check-box";
import Button from "devextreme-react/button";

import styled from "styled-components";
import { GrayscaleImage } from "./GrayscaleImage";

const Styles = styled.div`
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 10fr;
    grid-gap: 20px;
  }

  .grid-child {
    height: 830px;
  }
`;

export const ImageBrowser = React.memo((props) => {
  const reads = props.reads;
  var runRoot = props.runRoot;
  const [tiles, setTiles] = useState([]);
  const [numCycles, setNumCycles] = useState(150);
  const surfaces = ["S1", "S2"];
  const [heatmapData, setHeatmapData] = useState([]);

  const [cycle, setCycle] = useState(1);
  const [read, setRead] = useState("R1");
  const [surface, setSurface] = useState("S1");
  const [channel, setChannel] = useState("RED_F1");
  const [currentTile, setCurrentTile] = useState("");
  const [autoLoad, setAutoLoad] = useState(true);
  const [imageURL, setImageURL] = useState("-");
  const [loadNow, setLoadNow] = useState(false);
  const { fetchWithCheck, loading } = useAuth0();

  const [cycles, setCycles] = useState([]);

  useEffect(() => {
    if ((autoLoad || loadNow) && currentTile !== "") {
      // sloppy code for BB2V data
      var theTile = currentTile;
      var theRead = "/" + read;
      var trunRoot = runRoot;
      if (runRoot !== "" && !runRoot.endsWith("/")) trunRoot = runRoot + "/";

      var tilestr = theTile.replace("S1", surface);

      var readstr = theRead;
      if (runRoot.startsWith("BB")) {
        tilestr = "A" + theTile.substr(7, 1);
        readstr = "";
      }
      setLoadNow(false);
      setImageURL(
        "s3://elembio-usw2-s3-runs/" +
          trunRoot +
          "Avid" +
          readstr +
          "/C" +
          cycle.toString().padStart(3, "0") +
          "/" +
          tilestr +
          "_" +
          channel +
          ".tif"
      );
    }
  }, [runRoot, currentTile, read, cycle, surface, channel, autoLoad, loadNow]);

  useEffect(() => {
    var data = {};
    for (var i = 0; i < tiles.length; i++) {
      data[tiles[i]] = 1;
    }
    setHeatmapData(data);
  }, [tiles]);

  const mouseDownHandler = React.useCallback(async (label) => {
    setCurrentTile(label);
  }, []);

  const onCycleChanged = (e) => {
    setCycle(e.value);
  };

  const onSurfaceChanged = (e) => {
    setSurface(e.value);
  };

  useEffect(() => {
    var vals = [];
    for (var i = 1; i <= numCycles; i++) {
      vals.push(i);
    }
    setCycles(vals);
  }, [numCycles]);

  useEffect(() => {
    const callApi = async () => {
      const tmp = await fetchWithCheck(
        "/images?cycle=" + cycle + "&read=" + read + "&runRoot=" + runRoot
      );
      if (tmp) {
        console.log("tiles", tmp);
        if (runRoot.startsWith("BB")) {
          for (var i = 0; i < tmp.length; i++) {
            tmp[i] = "L1R02C0" + tmp[i].substring(1, 2) + "S1";
          }
        }
        setTiles(tmp);
      } else {
        console.log("empty");
        setTiles([]);
      }
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, cycle, read, runRoot]);

  return (
    <Styles>
      <span>
        Select a read, cycle, surface, channel and then click on a blue tile to
        view its image
      </span>
      <br />
      <br />

      <div className="grid-container" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="grid-child">
          Read:
          <SelectBox
            value={read}
            placeholder="Select a read..."
            showClearButton={false}
            dataSource={reads.split(",")}
            onValueChanged={(v) => {
              setRead(v.value);
              if (v.value === "R1") setNumCycles(props.numCyclesR1);
              if (v.value === "R2") setNumCycles(props.numCyclesR2);
            }}
            style={{ width: "300px" }}
          />
          Cycle:
          <SelectBox
            value={cycle}
            placeholder="Select a cycle..."
            showClearButton={false}
            dataSource={cycles}
            onValueChanged={onCycleChanged}
            style={{ width: "300px" }}
          />
          Surface:
          <SelectBox
            value={surface}
            placeholder="Select a surface..."
            showClearButton={false}
            dataSource={surfaces}
            onValueChanged={onSurfaceChanged}
            style={{ width: "300px" }}
          />
          Channel:
          <SelectBox
            value={channel}
            placeholder="Select a channel..."
            showClearButton={false}
            dataSource={["RED_F1", "RED_F2", "GRN_F3", "GRN_F4"]}
            onValueChanged={(e) => setChannel(e.value)}
            style={{ width: "300px" }}
          />
          <br />
          <React.Fragment>
            <CheckBox
              style={{ marginLeft: "20px" }}
              defaultValue={autoLoad}
              onValueChanged={(e) => {
                setAutoLoad(e.value);
              }}
              text="Auto Load"
            />{" "}
          </React.Fragment>
          <Button
            style={{ marginLeft: "20px" }}
            disabled={autoLoad}
            onClick={() => {
              setLoadNow(true);
            }}
            text="Load Now"
          />
          <FlowcellHeatmap
            data={heatmapData}
            surface="1"
            minv="0"
            maxv="4"
            title="Select Tile"
            tooltipprecision="0"
            hidelegend="true"
            scale="8"
            mousedownhandler={mouseDownHandler}
            selectedtile={currentTile}
            selectAny
          />
        </div>

        <div className="grid-child">
          {runRoot === "" && (
            <div>
              <br />
              <br />
              This run does not have a run folder associated with it so there
              are no images to view
            </div>
          )}

          <GrayscaleImage imageURL={imageURL} />
        </div>
      </div>
      <br />
      <br />
      <br />
    </Styles>
  );
});
