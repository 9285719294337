import React, { useState, useEffect } from "react";
import "../../App.css";

import { FlowcellHeatmap } from "./FlowcellHeatmap";

import styled from "styled-components";

import { SelectBox } from "devextreme-react/select-box";
import { CheckBox } from "devextreme-react/check-box";
import { NumberBox } from "devextreme-react/number-box";

import { useAuth0 } from "../../react-auth0-spa";
import DataSource from "devextreme/data/data_source";

const Styles = styled.div`
  .inline-child {
    display: inline-block;
  }

  .center-div {
    margin-left: auto;
    margin-right: auto;
    width: 450px;
  }

  .offset-title {
    padding-right: 80px;
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
  }
`;

const fields = [
  {
    category: "Metrology",
    value: { field: "flatnessS1" },
    caption: "Flatness S1 (um)",
  },
  {
    category: "Metrology",
    value: { field: "flatnessS2" },
    caption: "Flatness S2 (um)",
  },
  {
    category: "Metrology",
    value: { field: "coverSlipThickness" },
    caption: "Coverslip Thickness (um)",
  },
  { category: "Metrology", value: { field: "gap" }, caption: "Gap (um)" },
  {
    category: "Metrology",
    value: { field: "parallelism" },
    caption: "Parallelism (um)",
  },
  {
    category: "Metrology",
    value: { field: "clampedZS1" },
    caption: "Clamped Z S1 (um)",
  },
  {
    category: "Metrology",
    value: { field: "clampedZS2" },
    caption: "Clamped Z S2 (um)",
  },
];

const objForField = (field) => {
  for (var i = 0; i < fields.length; i++) {
    if (fields[i].value.field === field) {
      return fields[i];
    }
  }
  return null;
};

export const Flowcell = React.memo((props) => {
  const { id } = props;
  const precision = 2;

  const [selectedField, setSelectedField] = useState(fields[0].value);

  const [data, setData] = useState([]);
  const [range, setRange] = useState({ min: 0, max: 100, auto: true });

  const { loading, fetchWithCheck } = useAuth0();

  useEffect(() => {
    const callAPI = async () => {
      const dt = await fetchWithCheck(
        "/metrologyForField/" + id + "?field=" + selectedField.field
      );

      console.log(dt);

      if (!dt) {
        setData([]);
        return;
      }

      var allvals = [];

      var filteredData1 = {};
      dt.forEach((el) => {
        var val = el.value;
        if (val === -999) {
          val = NaN;
        }
        filteredData1[el.tile] = val;
        if (!isNaN(val)) allvals.push(val);
      });

      var min = 0;
      var max = 100;
      if (allvals.length > 10) {
        allvals.sort();
        min = allvals[Math.floor(allvals.length / 20)];
        max = allvals[Math.floor((allvals.length * 19) / 20)];
        const diff = max - min;
        max = max + diff * 0.15;
        min = min - diff * 0.15;
      }

      //   SVGMetadataElement()

      setData(filteredData1);
      if (range.auto) {
        setRange({ auto: true, min: min, max: max });
      }
    };
    if (!loading) {
      callAPI();
    }
  }, [loading, selectedField, id, fetchWithCheck, range.auto]);

  const valChanged = React.useCallback((e, i) => {
    setSelectedField(e.value);
  }, []);

  const groupData = new DataSource({
    store: fields,
    key: "caption",
    group: "category",
  });

  var title = "rar";

  var tmp = objForField(selectedField.field);
  if (tmp) title = tmp.caption;

  return (
    <Styles>
      <table>
        <tr>
          <td>Metric: &nbsp; </td>
          <td>
            <SelectBox
              value={selectedField}
              displayExpr="caption"
              valueExpr="value"
              grouped={true}
              placeholder="Select a field..."
              showClearButton={false}
              dataSource={groupData}
              onValueChanged={valChanged}
              searchEnabled={true}
              searchMode={"contains"}
              searchExpr={"caption"}
              searchTimeout={200}
              minSearchLength={0}
              showDataBeforeSearch={true}
              style={{ width: "300px" }}
            />
          </td>
        </tr>

        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Scale:</td>
          <td>
            <CheckBox
              value={range.auto}
              onValueChanged={(e) => {
                setRange({ ...range, auto: e.value });
              }}
              text="Auto"
            />

            <div className="dx-field">
              <div className="dx-field-label">Max:</div>
              <div className="dx-field-value">
                <NumberBox
                  value={range.max.toFixed(2)}
                  disabled={range.auto}
                  onValueChanged={(e) => {
                    setRange({ ...range, max: e.value });
                  }}
                  showSpinButtons={true}
                />
              </div>
            </div>

            <div className="dx-field">
              <div className="dx-field-label">Min:</div>
              <div className="dx-field-value">
                <NumberBox
                  value={range.min.toFixed(2)}
                  disabled={range.auto}
                  onValueChanged={(e) => {
                    setRange({ ...range, min: e.value });
                  }}
                  showSpinButtons={true}
                />
              </div>
            </div>
          </td>
        </tr>
      </table>
      <br />

      <div>
        <FlowcellHeatmap
          data={data}
          minv={range.min}
          maxv={range.max}
          title={title}
          tooltipprecision={precision}
          className="inline-child"
        />
      </div>
    </Styles>
  );
});
