import React, {
  useRef,
  memo,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

import CustomStore from "devextreme/data/custom_store";

import { CheckBox } from "devextreme-react/check-box";

import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  Paging,
  Pager,
  GroupPanel,
  GroupItem,
  Format,
  Summary,
  StateStoring,
  Export,
  Lookup,
  TotalItem,
  MasterDetail,
  //   RequiredRule
} from "devextreme-react/data-grid";

import PieChart, {
  Series,
  Label,
  Connector,
  Size,
} from "devextreme-react/pie-chart";

const sel = { mode: "single" } as any;

const customizedText = (e) => {
  if (isNaN(e.value) || e.value <= 0) return "";
  return e.valueText;
};
const customizedSerialNumText = (e) => {
  if (e.value === "00000000000000") return "";
  if (e.value === "Not Specified") return "";
  return e.valueText;
};

const cellRenderJiraKey = (cell) => {
  if (!cell.value) {
    return <div></div>;
  }
  return (
    <a href={`https://elembio.atlassian.net/browse/${cell.value}`}>
      {cell.value}
    </a>
  );
};

const cellRenderRun = (cell) => {
  if (!cell.value) {
    return <div></div>;
  }
  return <Link to={"/Runs/" + cell.value}>{cell.value}</Link>;
};

var completeStatusData = [
  "Run Success",
  "Run Complete with Issue",
  "Run Stopped - Hardware Error",
  "Run Stopped - Software Error",
  "Run Stopped - User",
  "Run Unable to be Started",
  "Mock/Test Run",
  "In Progress",
];

export const BetaTrackerTable = memo(() => {
  const grid = useRef();
  const summaryGrid = useRef();
  const chartRef = useRef();
  const { fetchWithCheck, fetchNoResponse } = useAuth0();
  const [allData, setAllData] = useState([]);
  const [filtered, setFiltered] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (grid) {
        (grid.current as any).instance.option(
          "height",
          window.innerHeight - 250
        );
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    console.log("Rerendering", chartRef.current);
    chartRef.current && (chartRef.current as any).instance.render();
  }, []);

  const summaryData = useMemo(() => {
    let tmpData = [];
    for (let i = 0; i < allData.length; i++) {
      let j = -1;
      for (let m = 0; m < tmpData.length; m++) {
        if (tmpData[m].instrument === allData[i].instrumentName) {
          j = m;
          break;
        }
      }
      if (j === -1) {
        let row = { instrument: allData[i].instrumentName };
        for (let k = 0; k < completeStatusData.length; k++) {
          row[completeStatusData[k]] = 0;
        }
        row["Unassigned"] = 0;
        tmpData.push(row);
        j = tmpData.length - 1;
      }
      let rcs = allData[i].runCompleteStatus;
      if (!rcs) rcs = "Unassigned";
      tmpData[j][rcs]++;
    }
    return tmpData;
  }, [allData]);

  const totalData = useMemo(() => {
    let data = [];
    for (let k = 0; k < completeStatusData.length; k++) {
      data.push({ label: completeStatusData[k], value: 0 });
    }
    data.push({ label: "Unassigned", value: 0 });
    for (let i = 0; i < allData.length; i++) {
      let rcs = allData[i].runCompleteStatus;
      if (!rcs) rcs = "Unassigned";
      for (let k = 0; k < data.length; k++) {
        if (rcs === data[k].label) data[k].value++;
      }
    }
    return data;
  }, [allData]);

  var loadData = useCallback(async () => {
    const url = `/betaTracking${filtered ? "?days=14" : ""}`;
    const data = await fetchWithCheck(url);

    // // const completeStatuses = data.map((x) => x.runCompleteStatus);

    // completeStatusData = [
    //   "Run Success",
    //   "Run Complete with Issue",
    //   "Run Stopped - System Erorr",
    //   "Run Stopped - User",
    //   "Run Unable to be Started",
    //   "In Progress",
    // ];
    for (let i = 0; i < data.length; i++) {
      data[
        i
      ].cycles = `${data[i].r1}+${data[i].r2},${data[i].i1}+${data[i].i1}`;

      data[i].jiraCount = data[i].jiraEntries.length;
    }
    setAllData(data);
    if (grid) {
      (grid.current as any).instance.option("height", window.innerHeight - 250);
    }
    return data;
  }, [fetchWithCheck, grid, filtered]);

  var updateData = useCallback(
    async (key, values) => {
      await fetchNoResponse("/betaTracking/" + key, {
        method: "PATCH",
        body: JSON.stringify(values),
      });
    },
    [fetchNoResponse]
  );

  useEffect(() => {
    if (grid) (grid.current as any).instance.refresh();
    if (summaryGrid) (summaryGrid.current as any).instance.refresh();
  }, [filtered]);

  var deleteData = useCallback(
    async (key) => {
      await fetchNoResponse("/betaTracking/" + key, {
        method: "DELETE",
      });
    },
    [fetchNoResponse]
  );

  var customStore = useMemo(
    () =>
      new CustomStore({
        key: "id",
        load: loadData,
        update: updateData,
        remove: deleteData,
      }),
    [loadData, updateData, deleteData]
  );

  // this is the latest datagrid example
  return (
    <div style={{ marginTop: 30 }}>
      <h3>Beta / DVT Instrument Summary</h3>

      <CheckBox
        text="Filter to last two weeks"
        style={{ marginLeft: "20px" }}
        value={filtered}
        onValueChanged={(e) => {
          setFiltered(e.value);
        }}
      />

      <div style={{ display: "flex", minHeight: 300 }}>
        <DataGrid
          ref={summaryGrid}
          dataSource={summaryData}
          showBorders={true}
          showRowLines={true}
          showColumnLines={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          columnResizingMode={"widget"}
          columnFixing={{ enabled: true }}
          wordWrapEnabled
        >
          <Summary>
            <TotalItem column="Instrument" displayFormat="Total" />
            {completeStatusData.map((x) => (
              <TotalItem column={x} summaryType="sum" displayFormat="{0}" />
            ))}
            <TotalItem
              column="Unassigned"
              summaryType="sum"
              displayFormat="{0}"
            />
          </Summary>
        </DataGrid>
        <PieChart
          ref={chartRef}
          dataSource={totalData}
          palette="Bright"
          style={{ marginLeft: 50 }}
        >
          <Series argumentField="label" valueField="value">
            <Label visible={true}>
              <Connector visible={true} width={1} />
            </Label>
          </Series>

          <Size width={500} />
        </PieChart>
      </div>
      <br />
      <br />
      <h3>Runs</h3>
      <DataGrid
        ref={grid}
        dataSource={customStore}
        showBorders={true}
        showRowLines={true}
        selection={sel}
        wordWrapEnabled={true}
        showColumnLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={"widget"}
        columnHidingEnabled={true}
        columnAutoWidth={true}
        columnFixing={{ enabled: true }}
        headerFilter={{ visible: true }}
        onEditorPreparing={(e) => {
          if (
            e.dataField === "runCompleteStatusXX" &&
            e.parentType === "dataRow"
          ) {
            // const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorName = "dxSelectBox"; // Change the editor's type
            e.editorOptions = {
              items: completeStatusData,
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                completeStatusData.push(e.text);
                e.customItem = e.text;
              },
              onValueChanged: (e, c) => {
                console.log(e, c);
              },
            };
          }
        }}
        onCellPrepared={(e) => {
          if (e.rowType !== "data") return;

          if (e.column.name === "indexAssigned") {
            if (e.value > 0) {
              if (e.value < 0.9) e.cellElement.style.backgroundColor = "red";
              else if (e.value < 0.95)
                e.cellElement.style.backgroundColor = "orange";
              else if (e.value >= 0.95)
                e.cellElement.style.backgroundColor = "green";
              e.cellElement.style.color = "white";
            }
          }

          if (e.column.name === "q30Avg") {
            if (e.value > 0) {
              if (e.value < 0.8) e.cellElement.style.backgroundColor = "red";
              else if (e.value < 0.85)
                e.cellElement.style.backgroundColor = "orange";
              else if (e.value >= 0.85)
                e.cellElement.style.backgroundColor = "green";
              e.cellElement.style.color = "white";
            }
          }
          if (e.column.name === "q30R1") {
            if (e.value > 0) {
              if (e.value < 0.85) e.cellElement.style.backgroundColor = "red";
              else if (e.value < 0.92)
                e.cellElement.style.backgroundColor = "orange";
              else if (e.value >= 0.92)
                e.cellElement.style.backgroundColor = "green";
              e.cellElement.style.color = "white";
            }
          }
          if (e.column.name === "q30R2") {
            if (e.value > 0) {
              if (e.value < 0.78) e.cellElement.style.backgroundColor = "red";
              else if (e.value < 0.85)
                e.cellElement.style.backgroundColor = "orange";
              else if (e.value >= 0.85)
                e.cellElement.style.backgroundColor = "green";
              e.cellElement.style.color = "white";
            }
          }
          if (e.column.name === "numReadsInM") {
            if (e.value > 0) {
              if (e.value >= 800) e.cellElement.style.backgroundColor = "green";
              else if (e.value < 800)
                e.cellElement.style.backgroundColor = "red";
              e.cellElement.style.color = "white";
            }
          }

          if (e.column.name === "errorMode") {
            if (e.value === "No Error")
              e.cellElement.style.backgroundColor = "green";
            else e.cellElement.style.backgroundColor = "red";
            e.cellElement.style.color = "white";
          }

          if (e.column.name === "disposition") {
            if (e.value === "No Action") {
              // do nothing
            } else {
              if (e.value === "Fixed")
                e.cellElement.style.backgroundColor = "green";
              else if (e.value === "Unresolved")
                e.cellElement.style.backgroundColor = "red";
              else e.cellElement.style.backgroundColor = "orange";
              e.cellElement.style.color = "white";
            }
          }

          if (e.column.name === "runCompleteStatus") {
            if (e.value && e.value !== "In Progress") {
              if (e.value === "Run Success")
                e.cellElement.style.backgroundColor = "green";
              else if (e.value.startsWith("Run Complete"))
                e.cellElement.style.backgroundColor = "orange";
              else if (e.value.startsWith("Mock"))
                e.cellElement.style.backgroundColor = "#666666";
              else e.cellElement.style.backgroundColor = "red";
              e.cellElement.style.color = "white";
            }
          }

          if (e.column.name === "runTime") {
            if (e.value > 0) {
              if (e.value <= 48) e.cellElement.style.backgroundColor = "green";
              else if (e.value <= 50)
                e.cellElement.style.backgroundColor = "orange";
              else if (e.value > 50)
                e.cellElement.style.backgroundColor = "red";
              e.cellElement.style.color = "white";
            }
          }
        }}
      >
        <Column
          dataField={"instrumentName"}
          caption={"Instrument"}
          dataType={"string"}
          groupIndex={0}
          width={150}
          fixed={true}
          allowEditing={false}
        />
        <Column
          dataField={"side"}
          dataType={"string"}
          width={50}
          fixed={true}
          allowEditing={false}
        />
        <Column
          dataField={"name"}
          caption={"Run Name"}
          dataType={"string"}
          width={150}
          fixed={true}
          allowEditing={false}
          cellRender={cellRenderRun}
        />
        <Column dataField={"runDescription"} dataType={"string"} width={300} />
        <Column
          dataField={"date"}
          dataType={"date"}
          format={"MMM dd yyyy HH:mm"}
          width={150}
          allowEditing={false}
        />
        <Column dataField={"runCompleteStatus"} dataType={"string"} width={250}>
          <Lookup dataSource={completeStatusData} />
        </Column>
        <Column
          dataField={"flowcell"}
          dataType={"string"}
          width={150}
          allowEditing={false}
          customizeText={customizedSerialNumText}
        />
        <Column
          dataField={"reagent"}
          dataType={"string"}
          width={150}
          allowEditing={false}
          customizeText={customizedSerialNumText}
        />
        <Column
          dataField={"buffer"}
          dataType={"string"}
          width={150}
          allowEditing={false}
          customizeText={customizedSerialNumText}
        />
        <Column
          dataField={"library"}
          dataType={"string"}
          width={150}
          allowEditing={false}
          customizeText={customizedSerialNumText}
        />
        <Column dataField={"cycles"} width={150} allowEditing={false} />
        <Column dataField={"numTiles"} width={150} allowEditing={false} />
        <Column dataField={"readOrder"} width={150} allowEditing={false} />
        <Column
          dataField={"runTime"}
          allowEditing={false}
          caption="Run Time (hrs)"
          customizeText={customizedText}
          width={150}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>
        <Column
          dataField={"controlSWVersion"}
          width={150}
          allowEditing={false}
        />
        <Column
          dataField={"numReadsInM"}
          customizeText={customizedText}
          width={150}
          allowEditing={false}
        />
        <Column
          dataField={"q30R1"}
          customizeText={customizedText}
          width={150}
          allowEditing={false}
        >
          <Format type="percent" precision={2} />{" "}
        </Column>
        <Column
          dataField={"q30R2"}
          customizeText={customizedText}
          width={150}
          allowEditing={false}
        >
          <Format type="percent" precision={2} />
        </Column>
        <Column
          dataField={"q30Avg"}
          customizeText={customizedText}
          width={150}
          allowEditing={false}
        >
          <Format type="percent" precision={2} />
        </Column>
        <Column
          dataField={"indexAssigned"}
          customizeText={customizedText}
          allowEditing={false}
          width={150}
        >
          <Format type="percent" precision={2} />
        </Column>
        <Column dataField={"jiraCount"} />
        <ColumnChooser enabled={true} />
        <GroupPanel visible={true} />
        <Summary>
          <GroupItem
            column={"title"}
            summaryType={"count"}
            displayFormat={"{0}"}
          />
        </Summary>
        <Paging defaultPageSize={50} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[30, 50, 100, "all"]}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Editing
          mode="cell" //"form" "batch" "popup" "cell"
          allowUpdating={true}
          allowAdding={false}
          allowDeleting={true}
        />
        <StateStoring
          enabled={false}
          type="localStorage"
          storageKey="betaTrackerv2"
        />
        <FilterRow visible={true} />
        a
        <Export
          enabled={true}
          fileName={"BetaTracker"}
          allowExportSelectedData={false}
        />
        <MasterDetail
          enabled={true}
          component={(props) => {
            return (
              <DataGrid
                wordWrapEnabled
                dataSource={props.data.data.jiraEntries}
              >
                <Column
                  dataField={"jiraKey"}
                  width={150}
                  cellRender={cellRenderJiraKey}
                  allowEditing={false}
                />
                <Column
                  dataField={"description"}
                  width={1400}
                  allowEditing={false}
                />
                <Column
                  dataField={"issueSeverity"}
                  width={400}
                  allowEditing={false}
                />
                <Column
                  dataField={"failureType"}
                  width={250}
                  allowEditing={false}
                />
                <Column
                  dataField={"subsystem"}
                  width={150}
                  allowEditing={false}
                />
                <Column
                  dataField={"subsystemFamily"}
                  width={150}
                  allowEditing={false}
                />
                <Column
                  dataField={"rootCause"}
                  width={250}
                  allowEditing={false}
                />
                <Column
                  dataField={"escalationIssueUnderstanding"}
                  width={250}
                  allowEditing={false}
                />{" "}
              </DataGrid>
            );
          }}
        />
      </DataGrid>
    </div>
  );
});
