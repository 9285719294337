import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import "../../App.css";
import TagBox from "devextreme-react/tag-box";

import DataGrid, {
  Selection,
  FilterRow,
  GroupPanel,
  StateStoring,
  Column,
  Lookup,
} from "devextreme-react/data-grid";

export const RunsChooser = React.memo((props) => {
  const [runs, setRuns] = useState([]);
  const [all, setAll] = useState({ tags: [], libraries: [] });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { loading, fetchWithCheck } = useAuth0();

  useEffect(() => {
    const callApi = async () => {
      setAll(await fetchWithCheck("/lookups"));
      let tmp = await fetchWithCheck("/runList");
      setSelectedRowKeys(
        props.initialValue ? props.initialValue.split(",") : []
      );
      console.log(tmp);
      setRuns(tmp);
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, props.initialValue]);

  const dataGrid_onSelectionChanged = (e) => {
    const v = (e.selectedRowKeys.length && e.selectedRowKeys) || [];
    setSelectedRowKeys(v);
    props.setTmpSelectedRuns(v);
  };

  var mymap = {};
  if (runs) {
    for (var i = 0; i < runs.length; i++) {
      mymap[runs[i].id] = runs[i].runName;
    }
  }

  const sortingMethod = (value1, value2) => {
    var isSelected1 = selectedRowKeys.includes(mymap[value1]),
      isSelected2 = selectedRowKeys.includes(mymap[value2]);

    if (isSelected1 && !isSelected2) return 1;
    if (!isSelected1 && isSelected2) return -1;

    if (value1 > value2) return 1;
    if (value1 < value2) return -1;
    return 0;
  };

  const tagEditorRender = (cell) => {
    return (
      <TagBox
        dataSource={all.tags}
        defaultValue={cell.value}
        onValueChanged={(e) => cell.setValue(e.value)}
        showSelectionControls={false}
        searchEnabled={true}
        placeholder="Choose Tags..."
      />
    );
  };

  const calculateFilterExpression = (
    filterValues,
    selectedFilterOperation,
    target
  ) => {
    return function (itemData) {
      var array1 = itemData.tags;
      var array2 = filterValues;

      if (array2.length === 0) return true;

      if (array1 === null) return false;

      return array2.every(function (value) {
        return array1.includes(value);
      });
    };
  };

  const editorPreparing = (e) => {
    if (all && e.parentType === "filterRow" && e.dataField === "tags") {
      e.editorName = "dxTagBox";
      e.editorOptions.dataSource = all.tags;
      e.editorOptions.showSelectionControls = true;
      // e.editorOptions.displayExpr = "Name";
      // e.editorOptions.valueExpr = "ID";
      e.editorOptions.value = e.value || [];
      e.editorOptions.onValueChanged = function (args) {
        e.setValue(args.value);
      };
    }
  };

  return (
    <React.Fragment>
      {/* <Button text="OK" width="150px" onClick={e=>{props.onOK(selectedRowKeys.join(","))}}/> */}

      <DataGrid
        dataSource={runs}
        keyExpr={"runName"}
        height={"95%"}
        width={"100%"}
        hoverStateEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={"widget"}
        columnHidingEnabled={true}
        columnAutoWidth={true}
        onEditorPreparing={editorPreparing}
        selectedRowKeys={selectedRowKeys}
        onSelectionChanged={dataGrid_onSelectionChanged}
        headerFilter={{ visible: true }}
      >
        <Selection mode={props.multiSelect ? "multiple" : "single"} />
        <FilterRow visible={true} />
        <GroupPanel visible={true} />

        <Column
          dataField="id"
          visible={false}
          sortOrder="desc"
          sortingMethod={sortingMethod}
        />
        <Column dataField="instrument" />
        <Column dataField="runName" />
        <Column dataField="numTiles" />
        <Column dataField="numCyclesR1" />
        <Column dataField="numCyclesR2" />
        <Column dataField="numCyclesI1" />
        <Column dataField="numCyclesI2" />

        <Column
          dataField={"l1LibraryID"}
          caption="Lane 1 Library"
          width={220}
          allowEditing={false}
        >
          <Lookup
            dataSource={all.libraries}
            displayExpr="name"
            valueExpr="id"
          />
        </Column>

        <Column
          dataField={"l2LibraryID"}
          caption="Lane 2 Library"
          width={220}
          allowEditing={false}
        >
          <Lookup
            dataSource={all.libraries}
            displayExpr="name"
            valueExpr="id"
          />
        </Column>

        <Column
          dataField={"tags"}
          editCellRender={tagEditorRender}
          width={200}
          allowSorting={false}
          calculateFilterExpression={calculateFilterExpression}
        />

        <Column
          dataField="dateStarted"
          dataType="date"
          caption="RunDate"
          format="yyyy-MM-dd"
        />

        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="runsChooser"
        />
      </DataGrid>
    </React.Fragment>
  );
});
