import React, { useState, useCallback, useEffect } from "react";
import Tabs from "devextreme-react/tabs";
import { useAuth0 } from "../react-auth0-spa";
import { FlowcellClusterQCInfoTable } from "./FlowcellClusterQCInfoTable";
import { FlowcellPrimerQC } from "./FlowcellPrimerQC";
import { Flowcell } from "./Reports/Flowcell";

export const FlowcellDetailsForm = (props) => {
  const {
    match: { params },
  } = props;

  const id = params.id;
  const [currentTab, setCurrentTab] = useState(0);
  const [details, setDetails] = useState(null);
  const { loading, fetchWithCheck } = useAuth0();

  const onSelectedTabChange = useCallback(({ name, value }) => {
    if (name === "selectedIndex") {
      setCurrentTab(value);
    }
  }, []);

  const formatDate = (date) => {
    if (!date) return "";
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("-");
  };

  useEffect(() => {
    const callApi = async () => {
      const v = await fetchWithCheck("/flowcells/" + id);
      setDetails(v);
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, id]);

  const tabs = ["Metrology", "Polony QC", "Primer QC"];

  return (
    <div>
      <br />
      <h3>Flowcell Details</h3>
      <br />
      <table
        className="table-bordered table-striped"
        style={{
          width: "600px",
          margin: "0px",
          fontSize: "13px",
          fontFamily: "Open Sans",
        }}
      >
        <tbody>
          <tr>
            <th style={{ width: "180px", textAlign: "left" }}>Serial Number</th>
            <td style={{ width: "900px" }}>
              &nbsp; {details && details.serialNumber}
            </td>
          </tr>
          <tr>
            <th style={{ textAlign: "left" }}>Date</th>
            <td>&nbsp; {details && formatDate(details.startDate)}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <Tabs
        dataSource={tabs}
        selectedIndex={currentTab}
        onOptionChanged={onSelectedTabChange}
        className={"widget"}
      />

      <hr />

      {currentTab === 0 && (
        <div>
          <Flowcell id={id} />
        </div>
      )}

      {currentTab === 1 && (
        <div>
          <FlowcellClusterQCInfoTable id={id} />
        </div>
      )}

      {currentTab === 2 && (
        <div>
          <FlowcellPrimerQC id={id} />
        </div>
      )}
    </div>
  );
};
