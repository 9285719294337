import React, { useState, useEffect, useRef} from "react";
import AceEditor from "react-ace";
import ace from 'ace-builds'
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/mysql"
import 'ace-builds/webpack-resolver'
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Button } from 'devextreme-react/button';


 const registerSnippets = function(editor, session, mode, snippetText) {
    editor.setOptions({
        enableBasicAutocompletion: true,
        enableSnippets: true,
    })

    var snippetManager = ace.require('ace/snippets').snippetManager

    var id = session.$mode.$id || ''

     var m = snippetManager.files[id] || {}

    m.scope = mode
    m.snippetText = snippetText
    m.snippet = snippetManager.parseSnippetFile(snippetText, m.scope)

    snippetManager.register(m.snippet, m.scope)
}

 const createSnippets = snippets =>
    (Array.isArray(snippets) ? snippets : [snippets])
        .map(({ name, code }) =>
            [
                'snippet ' + name,
                code
                    .split('\n')
                    .map(c => '\t' + c)
                    .join('\n'),
            ].join('\n')
        )
        .join('\n')

export const MySQLEditor = React.memo(props => {
    const aceElement = useRef(null)
    const [queryString, setQueryString] = useState(props.data ? props.data.value : props.defaultValue)

    useEffect(()=>{
        const editor = aceElement.current.editor
        registerSnippets(
            editor,
            editor.session,
            'mysql',
            createSnippets([
                { name: 'from SeqdDB tables with joins', code: 
`from SequencingRun SR 
    inner join AnalysisResult AR on AR.sequencingRunID = SR.id and AR.analysisVersion = SR.analysisVersion
    inner join ARLane ARL on ARL.analysisResultID = AR.id
    inner join ARTile ART on ART.arLaneID = ARL.id
    inner join ARTileRead ARTR on ARTR.arTileID = ART.id
    inner join ARCycle ARC on ARC.arTileReadID = ARTR.id
    inner join ARChannel ARCH on ARCH.arCycleID = ARC.id
where 
    SR.runName = '\${1:name}'`},
                { name: 'destroy', code: 'console.log("destroy")' },
            ]))
    },[])
 
    return (
        <div>
                <AceEditor
                    ref={aceElement}
                    placeholder="Enter you query here"
                    mode="mysql"
                    theme="textmate"
                    name="blah2"
                    height="380px"
                    onLoad={(v) => {
                        
                    }}
                    onChange={
                        v=>{
                            setQueryString(v)
                            if (props.data) {   
                                props.data.setValue(v)
                            }
                        }
                    }
                    fontSize={12}
                    showPrintMargin={false}
                    showGutter={true}
                    width="100%"
                    highlightActiveLine={false}
                    value={queryString}
                    setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2,
                    }}/>
            <div className="dx-field-item-help-text"> 
            Notes:<br/>
            - queries will automatically be limited to 9999 rows unless you explicitly set a different limit<br/>
            - variables that end in "s" (e.g., $RUNS) will be replaced with  <tt>(val1, val2, val3)</tt> to be used in <tt>in</tt> clauses<br/>
            - variables that are numbers will just be replaced with <tt>val</tt>, while everything else will be replaced with <tt>'val'</tt><br/>
            </div>
                            
            {props.runQuery && <div><br/><Button
                    id="button"
                    width={350}
                    height={40}
                    onClick={()=>{props.runQuery(queryString)}}
                    type="success"
                    stylingMode="contained"
                >
                    <LoadIndicator className="button-indicator" visible={props.runningQuery} />
                    <span className="dx-button-text">Run Query and Display Results</span>
                </Button>
                 <Button 
                    id="button"
                    width={350}
                    height={40}
                    style={{marginLeft:"50px"}}
                    onClick={(e) => {props.runQueryAndDownload(queryString)}}
                    type="success"
                    stylingMode="contained"> 
                    <LoadIndicator className="button-indicator" visible={props.runningQuery} />
                    <span className="dx-button-text">Run Query and Download CSV</span>
                </Button> 
                </div>
            }
                
        </div>
    );
}
)