import React, { useState, useEffect } from "react";
import "../../App.css";
import { RunInfoTable } from "./RunInfoTable";
import { useAuth0 } from "../../react-auth0-spa";
import { SelectBox } from "devextreme-react/select-box";
import { AnalysisDashboard } from "./AnalysisDashboard";

export const RunDashboard = (props) => {
  const {
    match: { params },
  } = props;

  const getUrlVars = () => {
    var vars = {};
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  };
  const vars = getUrlVars();

  const version = decodeURI(vars["v"]);

  const name = params.name;
  const [runInfo, setRunInfo] = useState(null);
  const [analysisID, setAnalysisID] = useState(0);
  const { fetchWithCheck, loading } = useAuth0();

  useEffect(() => {
    const callApi = async () => {
      const v = await fetchWithCheck("/runs/" + name);
      var reads = "R1";
      if (v.numCyclesR2 > 0) {
        reads = reads + ",R2";
      }
      if (v.numCyclesI1 > 0) {
        reads = reads + ",I1";
      }
      if (v.numCyclesI2 > 0) {
        reads = reads + ",I2";
      }
      v.reads = reads;
      console.log("run", v);
      setRunInfo(v);
      if (v.analyses && v.analyses.length > 0) {
        for (var i = 0; i < v.analyses.length; i++) {
          if (v.analyses[i].version === version) {
            setAnalysisID(v.analyses[i].id);
          }
        }
      }
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, name, version]);

  let runRoot = runInfo ? runInfo.runRoot : "";

  if (runInfo && !runRoot.startsWith(runInfo.instrument)) {
    runRoot = runInfo.instrument + "/" + runRoot;
  }
  return (
    <React.Fragment>
      <br />
      <h3>{name}</h3>
      <br />
      {runInfo && (
        <React.Fragment>
          <RunInfoTable data={runInfo} name={name} runInfo={runInfo} />
        </React.Fragment>
      )}

      {runInfo && (
        <React.Fragment>
          <strong>Select Analysis to Display:</strong> &nbsp;
          <SelectBox
            value={analysisID}
            displayExpr="version"
            valueExpr="id"
            placeholder="Select an analysis..."
            showClearButton={false}
            dataSource={runInfo.analyses}
            onValueChanged={(e) => {
              var v = "";
              setAnalysisID(e.value);
              for (var i = 0; i < runInfo.analyses.length; i++) {
                if (runInfo.analyses[i].id === e.value) {
                  v = "?v=" + runInfo.analyses[i].version;
                }
              }
              props.history.push("/runs/" + name + v);
            }}
            style={{ width: "200px", display: "inline-block" }}
          />
          <hr />
          {analysisID > 0 && runInfo && (
            <AnalysisDashboard
              history={props.history}
              runRoot={runRoot}
              name={name}
              id={analysisID}
              runInfo={runInfo}
              version={version}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
