import React from "react"

export const GeneralQueryForm = () => <span><br/><br/><br/>This page is obsolete.  Please use <strong>Reports</strong> tab.</span>
/*
import React, { useState, useCallback, useEffect, useRef} from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { Button } from 'devextreme-react/button';
import { GeneralQueryTable } from "./GeneralQueryTable";
import List from 'devextreme-react/list';
import { RadioGroup } from 'devextreme-react';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';

import AceEditor from "react-ace";
import ace from 'ace-builds'
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-textmate";

import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/mysql"
import 'ace-builds/webpack-resolver'

import styled from 'styled-components';

import Tabs from 'devextreme-react/tabs';

import {
    Chart,
    SeriesTemplate,
    ValueAxis,
    Point,
    ArgumentAxis,
    Tooltip,
    Grid,
    Legend,
    Label,
    CommonSeriesSettings,
    Series,
} from 'devextreme-react/chart';
import { RunsChooser } from "./RunsChooser";

const Styles = styled.div`
.form {
    padding: 10px 0 0 10px;
}

.label {
    margin-bottom: 10px;
    font-size: 16px;
}

.label:not(:first-child) {
    margin-top: 30px;
}

#small-indicator, #medium-indicator, #large-indicator {
    vertical-align: middle;
    margin-right: 10px;
}


#button,
#button .dx-button-content {
    padding: 0;
}

#button .button-indicator {
    height: 32px;
    width: 32px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}


.myFullscreen {
    left: 0;
    top: 0;
    width: 100%!important;
    height: 100%!important;
    position: absolute;
    background-color: black;
  }

`

 const registerSnippets = function(editor, session, mode, snippetText) {
    editor.setOptions({
        enableBasicAutocompletion: true,
        enableSnippets: true,
    })

    var snippetManager = ace.require('ace/snippets').snippetManager

    var id = session.$mode.$id || ''

    console.log(id)
    console.log(snippetManager.files)

     var m = snippetManager.files[id] || {}

    m.scope = mode
    m.snippetText = snippetText
    m.snippet = snippetManager.parseSnippetFile(snippetText, m.scope)

    snippetManager.register(m.snippet, m.scope)
}

 const createSnippets = snippets =>
    (Array.isArray(snippets) ? snippets : [snippets])
        .map(({ name, code }) =>
            [
                'snippet ' + name,
                code
                    .split('\n')
                    .map(c => '\t' + c)
                    .join('\n'),
            ].join('\n')
        )
        .join('\n')

const camelCaseConverter = text => {
    if (text.length===0) return ""
  var result = text.replace(".","_").replace( /([A-Z])/g, " $1" );
  return (result.charAt(0).toUpperCase() + result.slice(1)).trim();
}

const GraphTypeItems = [
    {label: "Line", val: "line"},
    {label: "Scatter", val: "scatter"},
    {label: "Beeswarm", val: "scatter"}
]

export const GeneralQueryForm = props => {
    const aceElement = useRef(null)
    const prevRuns = localStorage.getItem("selectedRuns")
    const { fetchNoJson } = useAuth0();
    const [queryInfo, setQueryInfo] = useState({ QueryString: (localStorage.getItem("lastQuery") ?? ""), Filename: "QueryResults.csv" });
    const [data, setData] = useState({ fields: [], values: [] });
    const [currentTab, setCurrentTab] = useState(0)
    const [xField, setXField] = useState({name:"", label:""})
    const [yField, setYField] = useState({name:"", label:""})
    const [groupField, setGroupField] = useState({name:"", label:""})
    const [graphType, setGraphType] = useState(GraphTypeItems[1])
    const [argumentType, setArgumentType] = useState("numeric")
    const [selectedRuns, setSelectedRuns] = useState( prevRuns );
    const [runningQuery, setRunningQuery] = useState(false)
    const [popupVisible, setPopupVisible] = useState(false)
    const tabs = ["Table", "Graph"];

    useEffect(()=>{
        const editor = aceElement.current.editor
        console.log(editor)
        registerSnippets(
            editor,
            editor.session,
            'mysql',
            createSnippets([
                { name: 'from SeqdDB tables with joins', code: 
`from SequencingRun SR 
    inner join AnalysisResult AR on AR.sequencingRunID = SR.id and AR.analysisVersion = SR.analysisVersion
    inner join ARLane ARL on ARL.analysisResultID = AR.id
    inner join ARTile ART on ART.arLaneID = ARL.id
    inner join ARTileRead ARTR on ARTR.arTileID = ART.id
    inner join ARCycle ARC on ARC.arTileReadID = ARTR.id
    inner join ARChannel ARCH on ARCH.arCycleID = ARC.id
where 
    SR.runName = '\${1:name}'`},
                { name: 'destroy', code: 'console.log("destroy")' },
            ]))
    },[])

    const perTileQuery = `SELECT dateStarted, tile, row, col, lane, concat(runName, ' ', date_format(dateStarted,"%Y-%m-%d")) "Run Name", I.name "Instrument", ARC.errorRate * 100 "C50 Error Percent", ARTR.phasingRate * 100 "Phasing Percent", ARTR.prephasingRate * 100 "Prephasing Percent", ARTR.alignDensity / 1000 "Aligned Density"
    FROM SequencingRun as SR
    INNER JOIN AnalysisResult AS AR ON AR.sequencingRunID = SR.id AND AR.analysisVersion = SR.analysisVersion
    INNER JOIN ARLane AS ARL ON ARL.analysisResultID = AR.id
    INNER JOIN ARTile AS ART ON ART.arLaneID = ARL.id 
    INNER JOIN ARTileRead AS ARTR ON ARTR.arTileID = ART.id
    INNER JOIN ARCycle AS ARC ON ARC.arTileReadID = ARTR.id
    INNER JOIN Instrument AS I on SR.instrumentID = I.id
    WHERE deleted = 0 AND cycle=50 AND ARC.errorRate > 0  AND ARC.errorRate < .1 AND runName in $RUNS
    ORDER BY I.name, dateStarted`

    const schemaQuery = `SHOW COLUMNS FROM ARChannel`

    const cycleQyer=  `SELECT tile, cycle, row, col, lane, ARC.errorRate * 100 "Error Percent", ARC.zScoreG, ARC.zScoreR, ARTR.alignDensity / 1000 "Aligned Density"
    FROM SequencingRun as SR
    INNER JOIN AnalysisResult AS AR ON AR.sequencingRunID = SR.id AND AR.analysisVersion = SR.analysisVersion
    INNER JOIN ARLane AS ARL ON ARL.analysisResultID = AR.id
    INNER JOIN ARTile AS ART ON ART.arLaneID = ARL.id 
    INNER JOIN ARTileRead AS ARTR ON ARTR.arTileID = ART.id
    INNER JOIN ARCycle AS ARC ON ARC.arTileReadID = ARTR.id
    WHERE ARC.errorRate > 0  AND runName in $RUNS`


    const onSelectedTabChange = useCallback(({ name, value }) => {
        if (name === "selectedIndex") {
            setCurrentTab(value)
        }
    }, []);

    
//     SELECT I.name, SR1.runName, SR1.dateStarted
// FROM SequencingRun SR1
// INNER JOIN Instrument AS I
// 	ON I.id = SR1.instrumentID
// LEFT OUTER JOIN SequencingRun SR2
//   ON (SR1.instrumentID = SR2.instrumentID AND SR1.id < SR2.id)
// GROUP BY SR1.id
// HAVING COUNT(*) < 3
// ORDER BY SR1.instrumentID, dateStarted desc;
// AND I.name IN ('P1-01','P1-02','P1-03','P1-04','P1-05')

    // const getLatestRuns = () => {
    //     var query = `SELECT SR1.runName
    //     FROM SequencingRun SR1
    //     INNER JOIN Instrument AS I
    //         ON I.id = SR1.instrumentID 
    //     LEFT JOIN SequencingRun SR2
    //       ON (SR1.instrumentID = SR2.instrumentID AND SR1.id < SR2.id AND SR2.deleted = 0 AND SR1.deleted = 0)
    //     WHERE SR1.deleted = 0
    //     GROUP BY SR1.id
    //     HAVING COUNT(*) < 3
    //     ORDER BY SR1.instrumentID, SR1.dateStarted desc`


    //     fetchNoJson("/report/generalQuery", {
    //         method: "POST",
    //         body: JSON.stringify({QueryString: query, Filename:""})
    //     })
    //     .then((txt) => {
    //         if (txt.length===0) return
    //         var runnames = [];
    //         const lines = txt.split("\n")
    //         if (lines.length > 1) {
    //             var lineNum
    //             for (lineNum = 1; lineNum < lines.length; lineNum++) {
    //                 if (lines[lineNum].length>0)
    //                     runnames.push(lines[lineNum])
    //             }
    //         }
    //         localStorage.setItem("selectedRuns", runnames.join(","))
    //     })
        
    // }

    var i,k;

    var distinctVals = [];
    var beeswarmData = [];

    var xFieldname = xField ? xField.name : '';
    var yFieldname = yField ? yField.name : '';
    var xFieldlabel = xField ? xField.label : '';
    var yFieldlabel = yField ? yField.label : '';
    var groupFieldname = groupField ? groupField.name : '';
    
    if (graphType === GraphTypeItems[2] && data.values && data.values.length>0) {
        const nVerticalBins = 100;
        var minv = data.values[0][yFieldname]
        var maxv = minv;
        for (i = 0; i < data.values.length; i++) {
            let v = data.values[i][yFieldname];
            if (v < minv) minv = v
            if (v > maxv) maxv = v
        }
        let binheight = (maxv - minv) / nVerticalBins;

        for (i = 0; i < data.values.length; i++) {
            let copy = Object.assign({}, data.values[i]);
            var val = copy[xFieldname];

            var found = false;
            for (k=0; k<distinctVals.length; k++) {
                if (distinctVals[k]===val) {
                    copy.xValue = k
                    found = true;
                    break;
                }
            }
            if (!found) {
                if (distinctVals.length > 40) {
                    copy.xValue = NaN
                } else {
                    copy.xValue = distinctVals.length
                    distinctVals.push(val)
                }
            }
            beeswarmData.push(copy)
        }

        if (distinctVals.length>0) {
            var cats = [];
            let binwidth = distinctVals.length / 400;
            for (i = 0; i < distinctVals.length; i++) {
                var binArray = []
                for (k=0; k<nVerticalBins; k++) binArray.push(0)
                cats.push(binArray)
            }
            let vals = beeswarmData
            for (i = 0; i< vals.length; i++) {
                let v = vals[i];
                const y = v[yFieldname];
                const x = v.xValue;
                if (isNaN(x)) continue;
                var ybin = Math.floor((y-minv)/binheight);
                if (ybin<0) ybin = 0
                if (ybin>=nVerticalBins) ybin = nVerticalBins-1;
                const bincnt = cats[x][ybin]
                const mag = Math.floor(bincnt/2)
                const dir = (bincnt%2 - .5) * 2
                var delta = mag * binwidth
                if (delta > 0.4) delta = 0.4

                v.xValue = v.xValue + delta * dir
                v.yValue = minv + ybin * binheight

                cats[x][ybin] = bincnt+1
            }
        }
        
    }

    return (
        <Styles>
            <br/><br/>
              <TextBox value={selectedRuns}
                onValueChanged={
                    v=>{
                       setSelectedRuns(v.value);
                        localStorage.setItem("selectedRuns", v.value)
                }}
                showClearButton={true} />
        <Button text="Show Popup" onClick={(e)=>{setPopupVisible(true)}} />
        {popupVisible && (
        <Popup
          visible={true}
          onHiding={e=>{setPopupVisible(false)}}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={true}
          title="Select Runs"
          width="80%"
          height="80%"
          fullScreen={false}
        >
            <RunsChooser initialValue={selectedRuns} onClose={e=>{setPopupVisible(false)}} onOK={v=>{
                setSelectedRuns(v); 
                localStorage.setItem("selectedRuns", v)
                setPopupVisible(false)
            }}/>
        </Popup>)}
        
            <br /><br />

{
            //  <Button style={{marginTop:"15px"}} onClick={(e) => getLatestRuns()}>
            //      Populate with latest runs
            //     </Button> 

            // <Button style={{marginTop:"15px"}} onClick={(e)=> {
            //     const queryStr = `SELECT SR.runName FROM SequencingRun SR 
            //     INNER JOIN RunTag RT
            //         ON RT.runID = SR.id 
            //     INNER JOIN Tag T
            //         ON RT.tagID = T.id AND T.name='Baseline 2.1'`
                
            //     fetchNoJson("/report/generalQuery", {
            //         method: "POST",
            //         body: JSON.stringify({QueryString: queryStr, Filename:""})
            //     })
            //     .then((txt) => {
            //         if (txt.length===0) return
            //         var runnames = [];
            //         const lines = txt.split("\n")
            //         if (lines.length > 1) {
            //             var lineNum
            //             for (lineNum = 1; lineNum < lines.length; lineNum++) {
            //                 if (lines[lineNum].length>0)
            //                     runnames.push(lines[lineNum])
            //             }
            //         }
            //         setGridBoxValue(runnames)
            //         localStorage.setItem("selectedRuns", runnames.join("|"))
            //     })

            // }}>Baseline </Button> 
}


<br /><br />
                <AceEditor
  ref={aceElement}
  placeholder="Enter you query here"
  mode="mysql"
  theme="textmate"
  name="blah2"
  onLoad={(v) => {
      
  }}
  onChange={v=>setQueryInfo({QueryString: v, Filename: ""})}
  fontSize={12}
  showPrintMargin={false}
  showGutter={true}
  width="100%"
  highlightActiveLine={false}
  value={queryInfo.QueryString}
  setOptions={{
    enableBasicAutocompletion: false,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    showLineNumbers: true,
    tabSize: 2,
  }}/>
               

           
            
  
            Query Template: &nbsp;
            <Button text={"Tile-level for Runs"}
            onClick={()=>{
                setQueryInfo({ QueryString: perTileQuery, Filename: queryInfo.Filename });
            }}
            />
            <Button text={"Cycle-level for Run"}
            onClick={()=>{
                setQueryInfo({ QueryString: cycleQyer, Filename: queryInfo.Filename });
            }}/>
            <Button text={"Run-level Summary"}/>
            <Button text={"Schema for Table"}
            onClick={()=>{
                setQueryInfo({ QueryString: schemaQuery, Filename: queryInfo.Filename });
            }}/>
            <br /><br />


            {
            //      <Button onClick={(e) => {
            //     localStorage.setItem("lastQuery", queryInfo.QueryString)
            //     fetchBlob("/report/generalQuery", {
            //         method: "POST",
            //         body: JSON.stringify(queryInfo)
            //     })
            //         .then((blob) => {
            //             // 2. Create blob link to download
            //             const url = window.URL.createObjectURL(new Blob([blob]));
            //             const link = document.createElement('a');
            //             link.href = url;
            //             link.setAttribute('download', queryInfo.Filename);
            //             // 3. Append to html page
            //             document.body.appendChild(link);
            //             // 4. Force download
            //             link.click();
            //             // 5. Clean up and remove the link
            //             link.parentNode.removeChild(link);
            //         }
            //         )
            // }}> Run Query and Download</Button> 
        }


            <Button 
          id="button"
          width={180}
          height={40}
          onClick={(e) => {
                setRunningQuery(true)
                localStorage.setItem("lastQuery", queryInfo.QueryString)
                
                let runsVar = "(" + selectedRuns.split(',').map(v=>{return "'" + v + "'"}).join(',')+ ")"
                
                var queryInfoCopy = {QueryString: queryInfo.QueryString.replace("$RUNS",runsVar), Filename: queryInfo.Filename}

                fetchNoJson("/report/generalQuery", {
                    method: "POST",
                    body: JSON.stringify(queryInfoCopy)
                })
                    .then((txt) => {
                        if (txt.length===0) return
                        var outarray = [];
                        const lines = txt.split("\n")
                        var fields = []
                        if (lines.length > 1) {
                            fields = lines[0].split(",").map(v=>{return {name: v, label: camelCaseConverter(v)}})
                            console.log(lines[0])
                            var i;
                            var lineNum
                            for (lineNum = 1; lineNum < lines.length; lineNum++) {
                                const line = lines[lineNum];
                                if (line.length===0) continue;
                                var outobj = { idx: lineNum };
                                const els = line.split(",")
                                if (els.length === fields.length) {
                                    for (i = 0; i < els.length; i++) {
                                        var tmp = els[i];
                                        if (!isNaN(tmp)) tmp = parseFloat(tmp)
                                        outobj[fields[i].name] = tmp;
                                    }
                                    outarray.push(outobj);
                                }
                            }
                        }
                        setData({ fields: fields, values: outarray });
                        setRunningQuery(false)
                    })
            }}
            type="success"
            stylingMode="contained"
            >
                <LoadIndicator className="button-indicator" visible={runningQuery} />
                <span className="dx-button-text">Run Query</span>
            </Button>
            <br /><br />

            <Tabs dataSource={tabs} selectedIndex={currentTab} onOptionChanged={onSelectedTabChange} style={{ width: "400px" }} className={"widget"} />

            {currentTab === 0 && <GeneralQueryTable data={data.values} />}

            {currentTab === 1 && (
                <React.Fragment>
                    <div className="container" style={{ display: "flex", height: "400px" }}>
                        <div style={{ width: "200px" }}>

                            <span>X</span>
                            <List
                                dataSource={data.fields}
                                valueExpr={"name"}
                                displayExpr={"label"}
                                width={200}
                                showSelectionControls={true}
                                selectionMode={"single"}
                                selectedItem={xField}
                                onSelectionChanged={v => setXField(v.addedItems[0])}
                            // selectedItemKeys={this.state.selectedItemKeys}
                            />
                        </div>
                        <div style={{ width: "200px" }}>

                            <span>Y</span>
                            <List
                                dataSource={data.fields}
                                valueExpr={"name"}
                                displayExpr={"label"}
                                width={200}
                                showSelectionControls={true}
                                selectionMode={"single"}
                                selectedItem={yField}
                                onSelectionChanged={v => setYField(v.addedItems[0])}
                            />
                        </div>

                        <div style={{ width: "200px" }}>

                            <span>Group</span>
                            <List
                                dataSource={[{name:"", label:""}, ...data.fields]}
                                valueExpr={"name"}
                                displayExpr={"label"}
                                width={200}
                                showSelectionControls={true}
                                selectionMode={"single"}
                                selectedItem={groupField}
                                onSelectionChanged={v => {console.log(v); if (v.addedItems.length===1) {setGroupField(v.addedItems[0])}}}
                            />
                        </div>

                        <div style={{ flexGrow: "1" }}>

                            <span>Graph Type</span>
                            <RadioGroup items={GraphTypeItems} displayExpr="label" value={graphType} layout="horizontal"  onValueChanged={(e) => { setGraphType(e.value) }} />
                            <br/><br/>
                            <span>Argument Type</span>
                            <RadioGroup items={['datetime' , 'numeric' , 'string']} value={argumentType} layout="horizontal" onValueChanged={(e) => { setArgumentType(e.value) }} />
                        </div>

                    </div>

                    <br/><br/>

                    {graphType === GraphTypeItems[2] && (
                        <div className="myFullScreen">
                        <Chart dataSource={beeswarmData} >
                            <ArgumentAxis argumentType={"numeric"} title={xFieldlabel} allowDecimals={false} tickInterval={1}>
                                <Label rotationAngle={30} overlappingBehavior="rotate" customizeText={e=>{return distinctVals[e.value]}} />
                                <Grid visible={true} />
                            </ArgumentAxis>
                            <Tooltip enabled={true}/>
                            <ValueAxis argumentType={"numeric"} title={yFieldlabel} />
                            {groupFieldname !== "" && (
                                <CommonSeriesSettings name={"series"} argumentField={"xValue"} valueField={"yValue"} type={"scatter"}><Point size={5} /></CommonSeriesSettings>
                            )}
                            {groupFieldname === "" && (
                                <Series name={yField.name} argumentField={"xValue"} valueField={"yValue"} type={graphType.val}><Point size={5} /></Series>
                            )}
                            {groupFieldname === "" && (
                                <Legend visible={false}/>
                            )}
                            {groupFieldname !== "" && <SeriesTemplate nameField={groupFieldname} />}
                        </Chart>      </div>                  
                    )}

                    {graphType !== GraphTypeItems[2] && (
                        <Chart dataSource={data.values} >
                            <ArgumentAxis argumentType={argumentType} title={xFieldlabel}>
                                <Label rotationAngle={20} overlappingBehavior="rotate"  />
                                <Grid visible={true} />
                            </ArgumentAxis>
                            <Tooltip enabled={true}/>
                            <ValueAxis argumentType={"numeric"} title={yFieldlabel} />
                            {groupFieldname !== "" && (
                                <CommonSeriesSettings name={"series"} argumentField={xFieldname} valueField={yFieldname} type={graphType.val}><Point size={5} /></CommonSeriesSettings>
                            )}
                            {groupFieldname === "" && (
                                <Series name={yFieldname} argumentField={xFieldname} valueField={yField.name} type={graphType.val}><Point size={5} /></Series>
                            )}
                            {groupFieldname !== "" && <SeriesTemplate nameField={groupFieldname} />}
                        </Chart>
                    )}

                </React.Fragment>
            )}

{ 
    // <div style={{
    // left: "0", top: "0", width: "100%", height: "100%",
    // position: "absolute", backgroundColor: "black", zIndex:"1000"
    // }}>

    //     </div> 
    }
        </Styles>
    );
}
*/