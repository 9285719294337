import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { SelectBox } from "devextreme-react/select-box";

import DataSource from "devextreme/data/data_source";
import TextBox from "devextreme-react/text-box";
import { MySQLEditor } from "./MySQLEditor";
import styled from "styled-components";
import { RunsTextBox } from "./RunsTextBox";
import { Popup } from "devextreme-react/popup";

import { Button } from "devextreme-react/button";
import { QueryResultsContainer } from "./QueryResultsContainer";
import { QueriesTable } from "./QueriesTable";
import notify from "devextreme/ui/notify";
//import { QueriesTable } from "./QueriesTable";

var variablesInRender;

const Styles = styled.div`
  #variables-container > .dx-field > .dx-field-label {
    width: 20%;
  }

  #variables-container > .dx-field > .dx-field-value {
    width: 80%;
  }

  #small-indicator,
  #medium-indicator,
  #large-indicator {
    vertical-align: middle;
    margin-right: 10px;
  }

  #button,
  #button .dx-button-content {
    padding: 0;
  }

  #button .button-indicator {
    height: 32px;
    width: 32px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
`;

const camelCaseConverter = (text) => {
  if (text.length === 0) return "";
  var result = text.replace(".", "_").replace(/([A-Z])/g, " $1");
  return (result.charAt(0).toUpperCase() + result.slice(1)).trim();
};

export const ReportContainer = React.memo(() => {
  const emptyQuery = {
    variables: null,
    query: "",
    id: 0,
    defaultX: "",
    defaultY: "",
    defaultPlot: "",
    defaultGroup: "",
  };
  const emptyQuery2 = {
    variables: "",
    query: "",
    id: 0,
    defaultX: "",
    defaultY: "",
    defaultPlot: "",
    defaultGroup: "",
  };
  const { fetchWithCheck, loading, fetchNoJson, fetchBlob } = useAuth0();
  const [queries, setQueries] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState(emptyQuery);
  const [queryString, setQueryString] = useState(
    localStorage.getItem("lastQuery") || ""
  );
  const [variables, setVariables] = useState(
    JSON.parse(localStorage.getItem("lastVariables")) || {}
  );
  const [cntr, setCntr] = useState(0);
  const [data, setData] = useState({
    fields: [],
    values: [],
    defaultX: "",
    defaultY: "",
    defaultPlot: "",
    defaultGroup: "",
  });
  const [runningQuery, setRunningQuery] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [reloadQueriesCntr, setReloadQueriesCntr] = useState(0);
  console.log("Rendering");
  useEffect(() => {
    const callAPI = async () => {
      var tmp = await fetchWithCheck("/queries");
      if (tmp && tmp.length > 0) {
        for (var i = 0; i < tmp.length; i++) {
          tmp[i].group = "Queries from " + tmp[i].userEmail.split("@")[0];
          tmp[i].label = tmp[i].name + " - " + tmp[i].description;
        }
        setQueries(tmp);
      }
    };
    if (!loading) callAPI();
    console.log(reloadQueriesCntr);
  }, [fetchWithCheck, loading, reloadQueriesCntr]);

  // This will parse a delimited string into an array of
  // strings. The default delimiter is the comma, but this
  // can be overriden in the second argument.
  const CSVToArray = (strData, strDelimiter) => {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = strDelimiter || ",";

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
      // Delimiters.
      "(\\" +
        strDelimiter +
        "|\\r?\\n|\\r|^)" +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        '([^"\\' +
        strDelimiter +
        "\\r\\n]*))",
      "gi"
    );

    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [];

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;

    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while ((arrMatches = objPattern.exec(strData))) {
      // Get the delimiter that was found.
      var strMatchedDelimiter = arrMatches[1];

      // Check to see if the given delimiter has a length
      // (is not the start of string) and if it matches
      // field delimiter. If id does not, then we know
      // that this delimiter is a row delimiter.
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        // Since we have reached a new row of data,
        // add an empty row to our data array.
        // arrData.push( [] );
      }

      var strMatchedValue;

      // Now that we have our delimiter out of the way,
      // let's check to see which kind of value we
      // captured (quoted or unquoted).
      if (arrMatches[2]) {
        // We found a quoted value. When we capture
        // this value, unescape any double quotes.
        strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
      } else {
        // We found a non-quoted value.
        strMatchedValue = arrMatches[3];
      }

      // Now that we have our value string, let's add
      // it to the data array.
      arrData /*[ arrData.length - 1 ]*/
        .push(strMatchedValue);
    }

    // Return the parsed data.
    return arrData;
  };

  const processResults = useCallback(
    (txt) => {
      var outarray = [];
      var fields = [];
      try {
        if (txt.length > 0) {
          const lines = txt.split("\n");
          if (lines.length > 1) {
            fields = CSVToArray(lines[0]).map((v) => {
              return {
                name: v,
                label: camelCaseConverter(v),
                fieldType: "number",
              };
            });
            var i;
            var lineNum, tmp;
            for (lineNum = 1; lineNum < lines.length; lineNum++) {
              const line = lines[lineNum];
              if (line.length === 0) continue;
              const els = CSVToArray(line);
              if (els.length === fields.length) {
                for (i = 0; i < els.length; i++) {
                  tmp = els[i];
                  if (isNaN(tmp) && fields[i].fieldType !== "string") {
                    if (tmp.length < 10 || isNaN(Date.parse(tmp)))
                      fields[i].fieldType = "string";
                    else {
                      fields[i].fieldType = "date";
                    }
                  }
                }
              }
            }
            for (lineNum = 1; lineNum < lines.length; lineNum++) {
              const line = lines[lineNum];
              if (line.length === 0) continue;
              var outobj = { idx: lineNum };
              const els = CSVToArray(line);
              if (els.length === fields.length) {
                for (i = 0; i < els.length; i++) {
                  tmp = els[i];
                  if (fields[i].fieldType === "number") {
                    if (tmp === "-999") tmp = NaN;
                    else tmp = parseFloat(parseFloat(tmp).toPrecision(4));
                  } else if (fields[i].fieldType === "date") {
                    //tmp = Date.parse(tmp)
                  }
                  outobj[fields[i].name] = tmp;
                }
                outarray.push(outobj);
              }
            }
          }
          notify("Query returned " + (lines.length - 2) + " rows");
        }
      } finally {
        setData({
          fields: fields,
          values: outarray,
          defaultX: selectedQuery.defaultX,
          defaultY: selectedQuery.defaultY,
          defaultGroup: selectedQuery.defaultGroup,
          defaultPlot: selectedQuery.defaultPlot,
        });
        setRunningQuery(false);
      }
    },
    [selectedQuery]
  );

  variablesInRender = variables;

  const substituteVariablesInQuery = (query) => {
    for (var key of Object.keys(variablesInRender)) {
      var tmp = variablesInRender[key];
      if (tmp.length === 0) continue;
      var els = tmp.split(",");
      if (els.length === 1) {
        if (isNaN(els[0])) tmp = "'" + els[0] + "'";
        else tmp = els[0];

        if (key.endsWith("S") || key.endsWith("s")) tmp = "(" + tmp + ")";
      } else {
        tmp =
          "(" +
          els
            .map((v) => {
              if (isNaN(v)) return "'" + v + "'";
              return v;
            })
            .join(",") +
          ")";
      }
      query = query.replaceAll("$" + key, tmp);
    }
    return query;
  };

  const runQueryAndDownloadCSV = (theQuery) => {
    setRunningQuery(true);
    localStorage.setItem("lastQuery", theQuery);
    localStorage.setItem("lastVariables", JSON.stringify(variablesInRender));

    var query = substituteVariablesInQuery(theQuery);

    fetchBlob("/report/generalQuery", {
      method: "POST",
      body: JSON.stringify({ QueryString: query, Filename: "file.csv" }),
    }).then((blob) => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.csv");
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
      setRunningQuery(false);
    });
  };

  const runQuery = useCallback(
    (theQuery) => {
      setRunningQuery(true);
      localStorage.setItem("lastQuery", theQuery);
      localStorage.setItem("lastVariables", JSON.stringify(variablesInRender));

      var query = substituteVariablesInQuery(theQuery);

      console.log(query);

      fetchNoJson("/report/generalQuery", {
        method: "POST",
        body: JSON.stringify({ QueryString: query, Filename: "file.csv" }),
      }).then((txt) => processResults(txt));
    },
    [fetchNoJson, processResults]
  );

  const dataStore = new DataSource({
    store: queries,
    key: "id",
    group: "group",
  });

  const setVariable = useCallback(
    (key, value) => {
      var tmp = variables;
      tmp[key] = value;
      setVariables(tmp);
      localStorage.setItem("variables." + key, value);
    },
    [setVariables, variables]
  );

  const valueChanged = useCallback(
    (e) => {
      if (e.element.id && e.element.id.length > 0) {
        setVariable(e.element.id, e.value);
      }
    },
    [setVariable]
  );

  console.log("selectedQuery.variables", selectedQuery.variables);
  console.log("variables", variables);
  var vars = [];
  if (selectedQuery.variables !== null) {
    var tmpvars = {};
    vars = selectedQuery.variables
      .split(",")
      .filter((v) => v.length > 0)
      .map((v, idx) => {
        let key = v.trim();
        var item = {};
        let prevVal = localStorage.getItem("variables." + key);
        console.log(key, prevVal);
        if (key === "RUNS")
          item = (
            <RunsTextBox
              multiSelect={true}
              defaultValue={prevVal}
              onValueChanged={(x) => setVariable("RUNS", x)}
            />
          );
        else if (key === "RUN")
          item = (
            <RunsTextBox
              multiSelec={false}
              defaultValue={prevVal}
              onValueChanged={(x) => setVariable("RUN", x)}
            />
          );
        else
          item = (
            <TextBox
              defaultValue={prevVal}
              onValueChanged={valueChanged}
              id={key}
            />
          );
        tmpvars[key] = prevVal;
        return (
          <div key={idx} className="dx-field">
            <div className="dx-field-label">${key}</div>
            <div className="dx-field-value">{item}</div>
          </div>
        );
      });
    variablesInRender = tmpvars;
  } else if (variables) {
    // having to duplicate this code is ugly, but not sure how to combine the above code with the below code
    var cnt = 0;
    for (var key of Object.keys(variables)) {
      var tmp = variables[key];
      var item = {};
      if (key === "RUNS")
        item = (
          <RunsTextBox
            defaultValue={tmp}
            multiSelect={true}
            onValueChanged={(v) => setVariable("RUNS", v)}
          />
        );
      else if (key === "RUN")
        item = (
          <RunsTextBox
            defaultValue={tmp}
            multiSelec={false}
            onValueChanged={(v) => setVariable("RUN", v)}
          />
        );
      else
        item = (
          <TextBox defaultValue={tmp} onValueChanged={valueChanged} id={key} />
        );
      vars.push(
        <div key={cnt++} className="dx-field">
          <div className="dx-field-label">${key}</div>
          <div className="dx-field-value">{item}</div>
        </div>
      );
    }
  }

  return (
    <Styles>
      <div className="dx-fieldset" id="variables-container">
        <div className="dx-fieldset-header">Saved Queries</div>
        <div style={{ display: "flex", marginBottom: "30px" }}>
          <div style={{ flex: 3 }}>
            <SelectBox
              dataSource={dataStore}
              placeholder="Select a predefined template query"
              valueExpr="id"
              grouped={true}
              displayExpr="label"
              // selectedItem={selectedQuery}
              showClearButton={true}
              onSelectionChanged={(v) => {
                if (v.selectedItem) {
                  setSelectedQuery(v.selectedItem);
                  setQueryString(v.selectedItem.query);
                } else setSelectedQuery(emptyQuery2);
                setCntr((x) => x + 1);
              }}
            />
          </div>
          <div style={{ flex: 1, marginLeft: "30px" }}>
            <Button
              text="Add or Edit Saved Queries..."
              onClick={() => setPopupVisible(true)}
            />
          </div>
        </div>
        <div className="dx-fieldset-header">Variables</div>
        {vars}
        <br />
        <div className="dx-fieldset-header">Query</div>
        <MySQLEditor
          key={cntr}
          defaultValue={queryString}
          runQuery={runQuery}
          runQueryAndDownload={runQueryAndDownloadCSV}
          runningQuery={runningQuery}
        />
        <br /> <br />
        <div className="dx-fieldset-header">Results</div>
        <QueryResultsContainer data={data} />
      </div>

      <Popup
        visible={popupVisible}
        onHiding={(e) => {
          setPopupVisible(false);
          setReloadQueriesCntr((x) => x + 1);
        }}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Query Editor"
        width="80%"
        height="80%"
        fullScreen={false}
      >
        <QueriesTable />
      </Popup>
    </Styles>
  );
});
