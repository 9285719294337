import React from "react";
import Form, { SimpleItem, GroupItem} from 'devextreme-react/form';
import styled from 'styled-components';

const Styles = styled.div`
.first-group,
.second-group {
    padding: 20px;
}
.second-group {
    background-color: rgba(191, 191, 191, 0.15);
}

.dx-form .dx-field-item-label {  
    font-weight: bold;  
}  

.dx-form .dx-field-item {
    font-size: 12px;
}

.dx-widget {
    font-size: 12px;
}
`

export const PolonyQCDetailsTable = props => {

    const rowHeight = "15px"
    
    if (props.details && props.details.aviditeF1LotNumber === -999) {
        props.details.aviditeF1LotNumber = "";
        props.details.aviditeF4LotNumber = "";
    }
    return (
        <Styles>
            <Form
                id="form"
                formData={props.details}
                readOnly={true}
                showColonAfterLabel={true}
                labelLocation={'left'}
                minColWidth={300}
                colCount={2}
            >
                <GroupItem cssClass="first-group" colCount={1} colSpan={1}>
                    <SimpleItem dataField="runName" editorOptions={{ height: rowHeight,readOnly: true }} />
                    <SimpleItem dataField="runDescription" editorOptions={{height: rowHeight, readOnly: true }} />
                    <SimpleItem dataField="instrument" editorOptions={{ height: rowHeight,readOnly: true }} />
                    <SimpleItem dataField="operator" editorOptions={{ height: rowHeight,readOnly: true }} />
                    <SimpleItem dataField="recipeName" editorOptions={{height: rowHeight, readOnly: true }} />
                    <SimpleItem dataField="condition" editorOptions={{height: rowHeight, readOnly: true }} />
                    <SimpleItem dataField="library" editorOptions={{ height: rowHeight,readOnly: true }} />
                    <SimpleItem dataField="concentration" editorOptions={{ height: rowHeight,readOnly: true }} />
                 </GroupItem>

                <GroupItem cssClass="first-group" colCount={1} colSpan={1}>
                    <SimpleItem dataField="date" editorType="dxDateBox" editorOptions={{ height: rowHeight, readOnly: true }} />
                    <SimpleItem dataField="qcMethod" editorOptions={{ height: rowHeight,readOnly: true }} />
                    <SimpleItem dataField="enzymeName" editorOptions={{ height: rowHeight,readOnly: true }} />
                    <SimpleItem dataField="hp11HybBuffer" editorOptions={{ height: rowHeight,readOnly: true }} />
                    <SimpleItem dataField="libraryHybBuffer" editorOptions={{ height: rowHeight,readOnly: true }} />
                    <SimpleItem dataField="equiPhi29LotNumber" label={{text:"Enzyme Lot Number"}} editorOptions={{ height: rowHeight,readOnly: true }} />
                    <SimpleItem dataField="laserPowerGreen" editorOptions={{ height: rowHeight,readOnly: true }} />
                    <SimpleItem dataField="laserPowerRed" editorOptions={{ height: rowHeight,readOnly: true }} />
                </GroupItem>

                <GroupItem cssClass="first-group" colCount={4} colSpan={2}>
                    <GroupItem colCount={1} colSpan={1}>
                        <SimpleItem dataField="aviditeF1" editorOptions={{ height: rowHeight,readOnly: true }} />
                        <SimpleItem caption="Avidite F1 Lot Number" dataField="aviditeF1LotNumber" editorOptions={{ height: rowHeight,readOnly: true }} />
                    </GroupItem>
                    <GroupItem colCount={1} colSpan={1}>
                        <SimpleItem dataField="aviditeF2" editorOptions={{ height: rowHeight,readOnly: true }} />
                        <SimpleItem caption="Avidite F2 Lot Number" dataField="aviditeF2LotNumber" editorOptions={{ height: rowHeight,readOnly: true }} />
                    </GroupItem>
                    <GroupItem colCount={1} colSpan={1}>
                        <SimpleItem dataField="aviditeF3" editorOptions={{ height: rowHeight,readOnly: true }} />
                        <SimpleItem caption="Avidite F3 Lot Number" dataField="aviditeF3LotNumber" editorOptions={{ height: rowHeight,readOnly: true }} />
                    </GroupItem>
                    <GroupItem colCount={1} colSpan={1}>
                        <SimpleItem dataField="aviditeF4" editorOptions={{ height: rowHeight,readOnly: true }} />
                        <SimpleItem caption="Avidite F4 Lot Number" dataField="aviditeF4LotNumber" editorOptions={{ height: rowHeight,readOnly: true }} />
                    </GroupItem>

                </GroupItem>
               
            </Form>
        </Styles>
    );
};
