import React, { useRef, useEffect, useState } from "react";
import "../../App.css";
//import igv from 'igv.esm.min.js'
import igv from "igv";
import { useAuth0 } from "../../react-auth0-spa";
import { SelectBox } from "devextreme-react";
import { NumberBox } from "devextreme-react/number-box";
import { Button } from "devextreme-react/button";

export const IGVForm = React.memo((props) => {
  const node = useRef(null);
  const numBox = useRef(null);
  const { loading, fetchWithCheck } = useAuth0();
  const id = props.id;
  const [tile, setTile] = useState(null);
  const [tiles, setTiles] = useState([]);
  const [cntr, setCntr] = useState(0);

  const defAllele = localStorage.getItem("igv_allele_freq") ?? 1;

  let alleleFreqThreshold = defAllele;
  if (numBox && numBox.current) {
    const obj = numBox.current.instance;
    alleleFreqThreshold = obj.option("value");
  }

  useEffect(() => {
    const callAPI = async () => {
      const data = await fetchWithCheck("/analyses/" + id + "/tiles");

      if (data) {
        let tmp = data.map((x) => x.name);
        tmp.sort();
        tmp.unshift("L2");
        tmp.unshift("L1");
        setTiles(tmp);
      }
    };
    if (!loading && id) callAPI();
  }, [loading, id, fetchWithCheck]);

  useEffect(() => {
    const callAPI = async () => {
      const data = await fetchWithCheck("/igvData?id=" + id + "&tile=" + tile);

      var igvDiv = node.current;
      var options = {
        version: "2.7.4",
        reference: {
          id: data.genomeName,
          name: data.genomeName,
          fastaURL: data.genomeURL,
          indexURL: data.genomeIndexURL,
        },
        tracks: [
          {
            type: "sequence",
            order: -900719925474.0991,
          },
          {
            url: data.errorSiteURL,
            indexURL: data.errorSiteIndexURL,
            name: "error_sites",
            format: "vcf",
            height: 40,
            order: 1,
            color: "#ff2101",
            visibilityWindow: 1000000000,
            displayMode: "COLLAPSED",
          },
          {
            url: data.coverageURL,
            name: "coverage",
            format: "tdf",
            height: 75,
            order: 2,
            color: "#6e6e6e",
            visibilityWindow: 1000000000,
          },
          {
            url: data.igvBAMURL,
            indexURL: data.igvBAMIndexURL,
            name: "2000x-reads",
            format: "bam",
            type: "alignment",
            height: 350,
            order: 3,
            color: "rgb(185, 185, 185)",
            displayMode: "SQUISHED",
            visibilityWindow: 50000,
            alleleFreqThreshold: alleleFreqThreshold / 100,
            samplingDepth: 75,
            showSoftClips: true,
            colorBy: "strand",
            alignmentRowHeight: 10,
          },
          {
            url: data.mismatchBAMURL,
            indexURL: data.mismatchBAMIndexURL,
            name: "trimmed_mismatch-reads",
            format: "bam",
            type: "alignment",
            height: 500,
            order: 4,
            color: "rgb(185, 185, 185)",
            displayMode: "SQUISHED",
            visibilityWindow: 25000,
            alleleFreqThreshold: alleleFreqThreshold / 100,
            samplingDepth: 1000,
            showSoftClips: true,
            colorBy: "strand",
            alignmentRowHeight: 10,
          },
        ],
      };

      igv.createBrowser(igvDiv, options).then(function (browser) {
        console.log("Created IGV browser");
      });
    };
    if (!loading && node.current && tile) {
      callAPI();
    }

    return () => {
      igv.removeAllBrowsers();
    };
  }, [loading, node, fetchWithCheck, tile, id, alleleFreqThreshold]);

  return (
    <div>
      <SelectBox
        value={tile}
        placeholder="Select a tile..."
        showClearButton={false}
        dataSource={tiles}
        onValueChanged={(v) => setTile(v.value)}
        style={{ width: "300px" }}
      />
      <br />
      <br />
      Allele Freq Threshold (%):
      <NumberBox
        ref={numBox}
        defaultValue={defAllele}
        showSpinButtons={true}
        width={150}
      />
      <Button
        onClick={(_) => {
          localStorage.setItem("igv_allele_freq", alleleFreqThreshold);
          setCntr((x) => x + 1);
        }}
        text={"Reload"}
      />
      <br />
      <br />
      <div {...props} key={cntr} style={{ height: "1200px" }} ref={node}>
        {" "}
      </div>
    </div>
  );
});
