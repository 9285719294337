import React, { useState, useEffect } from "react";
import "../../App.css";
import { useAuth0 } from "../../react-auth0-spa";
import styled from "styled-components";

import {
  Chart,
  CommonSeriesSettings,
  Export,
  SeriesTemplate,
  Legend,
  ValueAxis,
  Point,
  Tooltip,
  ArgumentAxis,
  ZoomAndPan,
} from "devextreme-react/chart";

const Styles = styled.div`
  .flex-container {
    display: flex;
  }

  .flex-child {
    flex: 1;
  }

  .flex-child:first-child {
    margin-right: 20px;
  }

  .float-container {
    padding: 20px;
  }

  .float-child {
    width: 50%;
    float: left;
    padding: 20px;
  }

  .inline-container {
  }

  .inline-child {
    display: inline-block;
  }

  .center-div {
    margin-left: auto;
    margin-right: auto;
    width: 450px;
  }

  .offset-title {
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
  }

  .subtitle {
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .grid-child {
    height: 430px;
  }

  /* Solid border */
  hr.solid {
    border-top: 3px solid rgba(191, 191, 191, 0.15);
  }

  .margins {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 150px;
    margin-left: 150px;
  }
`;
export const DualChart = (props) => {
  const { fetchWithCheck, loading } = useAuth0();
  const id = props.id;

  const [state, setState] = useState({ d1: [], d2: [], min: 0, max: 100 });

  useEffect(() => {
    const splitReadData = (data) => {
      var j;

      const tmp = [...new Set(data.map((x) => x.read))];
      const sortOrder = props.runInfo.readOrder.split(",");
      let distinctReads = [];
      for (j = 0; j < sortOrder.length; j++) {
        if (tmp.includes(sortOrder[j])) distinctReads.push(sortOrder[j]);
      }
      console.log("distinctReads", distinctReads);
      var maxCycleForRead = {};
      for (j = 0; j < distinctReads.length; j++) {
        maxCycleForRead[distinctReads[j]] = 0;
      }

      for (j = 0; j < data.length; j++) {
        if (data[j].cycle > maxCycleForRead[data[j].read]) {
          maxCycleForRead[data[j].read] = data[j].cycle;
        }
      }

      var readCycleOffset = {};
      readCycleOffset[distinctReads[0]] = 0;
      var currentOffset = 0;
      for (j = 0; j < distinctReads.length; j++) {
        readCycleOffset[distinctReads[j]] = currentOffset;
        currentOffset += maxCycleForRead[distinctReads[j]];
      }

      var factor = 1;
      if (props.multby100) factor = 100;
      var data2 = [];
      for (j = 0; j < data.length; j++) {
        data[j].var = data[j].tile + " (" + data[j].read + ")";
        data[j].cycle += readCycleOffset[data[j].read];
        if (data[j].value !== -999) {
          data[j].value = data[j].value * factor;
          data2.push(data[j]);
        }
      }
      return data2;
    };

    const callApi = async () => {
      var data1 = await fetchWithCheck(
        "/report/fieldByCycle?id=" + id + "&field=" + props.fieldA
      );
      var data2 = await fetchWithCheck(
        "/report/fieldByCycle?id=" + id + "&field=" + props.fieldB
      );

      var i;
      var tmin = 99999,
        tmax = -99999;
      var val;
      for (i = 0; i < data1.length; i++) {
        val = data1[i].value;
        if (val === -999) continue;
        if (val < tmin) {
          tmin = val;
        }
        if (val > tmax) {
          tmax = val;
        }
      }
      for (i = 0; i < data2.length; i++) {
        val = data2[i].value;
        if (val === -999) continue;
        if (val < tmin) {
          tmin = val;
        }
        if (val > tmax) {
          tmax = val;
        }
      }

      data1 = splitReadData(data1);
      data2 = splitReadData(data2);

      setState({ d1: data1, d2: data2, min: tmin, max: tmax });
    };
    if (!loading && id !== 0) {
      callApi();
    }
  }, [
    loading,
    fetchWithCheck,
    props.fieldA,
    props.fieldB,
    props.multby100,
    props.runInfo,
    id,
  ]);

  const sortLegendItems = (items) => {
    return items.sort((a, b) => {
      let itemA = a.text.toLowerCase();
      let itemB = b.text.toLowerCase();
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      return 0;
    });
  };

  const renderTooltip = (pointInfo) => {
    if (pointInfo && pointInfo.point && pointInfo.point.data) {
      const data = pointInfo.point.data;
      const label3 = "Cycle: " + Math.floor(data.cycle);
      var suffix = "";
      if (props.multby100) {
        suffix = "%";
      }
      return (
        <div>
          {data.var}
          <br />
          {label3}
          <br />
          {props.title + ": " + data.value.toFixed(4) + suffix}
        </div>
      );
    }
    return <div />;
  };
  return (
    <Styles>
      <div className="grid-container" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="grid-child">
          <Chart
            height="500px"
            dataSource={state.d1}
            title={props.title + " - " + props.subTitleA}
            onLegendClick={(e) => {
              const series = e.target;
              if (series.isVisible()) {
                series.hide();
              } else {
                series.show();
              }
            }}
          >
            <CommonSeriesSettings
              argumentField="cycle"
              valueField="value"
              type="line"
            >
              <Point size={4} />
            </CommonSeriesSettings>
            <SeriesTemplate nameField="var" />
            <Tooltip enabled={true} contentRender={renderTooltip} />
            <ValueAxis
              title={props.yLabel}
              wholeRange={props.nosync ? null : [state.min, state.max]}
              visualRange={props.nosync ? null : [state.min, state.max]}
            />
            <ArgumentAxis title={"Cycle"} allowDecimals={true} />
            <Legend visible={true} customizeItems={sortLegendItems} />
            <Export enabled={true} />
            <ZoomAndPan argumentAxis="both" valueAxis="both" />
          </Chart>
        </div>

        <div className="grid-child">
          <Chart
            height="500px"
            dataSource={state.d2}
            title={props.title + " - " + props.subTitleB}
            onLegendClick={(e) => {
              const series = e.target;
              if (series.isVisible()) {
                series.hide();
              } else {
                series.show();
              }
            }}
          >
            <CommonSeriesSettings
              argumentField="cycle"
              valueField="value"
              type="line"
            >
              <Point size={4} />
            </CommonSeriesSettings>
            <SeriesTemplate nameField="var" />
            <Tooltip enabled={true} contentRender={renderTooltip} />
            <ValueAxis
              title={props.yLabel}
              wholeRange={props.nosync ? null : [state.min, state.max]}
              visualRange={props.nosync ? null : [state.min, state.max]}
            />
            <ArgumentAxis title={"Cycle"} allowDecimals={true} />
            <Legend visible={true} customizeItems={sortLegendItems} />
            <Export enabled={true} />
            <ZoomAndPan argumentAxis="both" valueAxis="both" />
          </Chart>
        </div>
      </div>
    </Styles>
  );
};
