import React, { useState, useEffect } from "react";
import "../../App.css";
import { scaleLinear } from "d3-scale";
import { select } from "d3-selection";
import { axisRight, event, interpolateHcl, range } from "d3";

export const FlowcellHeatmap = React.memo((props) => {
  const [node, setNode] = useState();
  const {
    data,
    surface,
    minv,
    maxv,
    title,
    tooltipprecision,
    hidelegend,
    mousedownhandler,
    scale,
    selectedtile,
  } = props;

  useEffect(() => {
    if (node) {
      console.log("flowcelld data", data);
      node.innerHTML = "";
      const FLOWCELL_WIDTH = 25;
      const FLOWCELL_HEIGHT = 75;
      const TITLE_PADDING = 30;
      const TOOLTIP_OFFSET_X = { x: 10, y: -20 };
      const TOOLTIP_OFFSET_Y_FRAC = 0.1;
      const LEGEND_OFFSET = 10;
      const LEGEND_WIDTH = 20;
      const LEGEND_PADDING = 40;
      const MIN_VALUE = minv;
      const MAX_VALUE = maxv;

      var SCALE = 6; // scale factor to go from mm to pixels
      var i, j;
      var r = 0.5; // radius of the little circles at the top and bottom of the lanes

      if (scale) {
        SCALE = scale;
      }

      var tilewidth = (11.92 - 2.26) / 5;
      var tileheight = (64 - 11) / 20.0;

      var rects = [];
      var cnt = 0;
      for (i = 0; i < 5; i++) {
        for (j = -1; j < 21; j++) {
          if ((i === 0 || i === 4) && (j === -1 || j === 20)) {
            continue;
          }
          var label =
            "L1R" +
            (j + 2).toString().padStart(2, "0") +
            "C" +
            (i + 1).toString().padStart(2, "0");
          if (surface) label = label + "S" + surface;
          var val = NaN;
          if (data[label]) {
            val = data[label];
          }
          rects[cnt++] = {
            x: 2.26 + i * tilewidth,
            y: 11 + j * tileheight,
            w: tilewidth,
            h: tileheight,
            v: val,
            l: label,
          };
          label =
            "L2R" +
            (j + 2).toString().padStart(2, "0") +
            "C" +
            (5 - i).toString().padStart(2, "0");
          if (surface) label = label + "S" + surface;
          val = NaN;
          if (data[label]) {
            val = data[label];
          }
          rects[cnt++] = {
            x: FLOWCELL_WIDTH - (2.26 + i * tilewidth) - tilewidth,
            y: 11 + j * tileheight,
            w: tilewidth,
            h: tileheight,
            v: val,
            l: label,
          };
        }
      }

      // this is the div node that will contain all the d3 content
      var div = select(node);

      // Add the DIV that will hold the tooltip to the DOM
      var tooltipDiv = div
        .append("div")
        .style("position", "absolute")
        .style("text-align", "center")
        .style("width", "80px")
        .style("height", mousedownhandler ? "16px" : "32px")
        .style("padding", "2px")
        .style("font", "10px sans-serif")
        .style("background", "white")
        .style(
          "box-shadow",
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
        )
        //.style("border", "1px")
        .style("border-radius", "8px")
        .style("opacity", 0);

      /*var tempcolors = [
            'rgb(57,59,245)', // 20
            'rgb(205,205,251)',
            'rgb(255,255,255)',
            'rgb(255,255,183)',
            'rgb(255,255,143)',
            'rgb(255,255,97)',
            'rgb(252,238,79)',
            'rgb(244,188,64)',
            'rgb(238,128,49)',
            'rgb(236,88,41)',
            'rgb(234,52,36)' // 60
           ]
           var temprange = range(0, 1, .1);
           temprange.push(1);
           var tempscale = scaleLinear()
               .domain(temprange)
               .range(tempcolors)
               .interpolate(interpolateHcl);
           var tmp = []
            for (var k=0; k<1; k+=1/256.0) { 
                tmp.push(tempscale(k))
            }

            console.log(tmp);*/

      var altcolors = [
        "#ffffd9",
        "#edf8b1",
        "#c7e9b4",
        "#7fcdbb",
        "#41b6c4",
        "#1d91c0",
        "#225ea8",
        "#253494",
        "#081d58",
      ];
      // var origcolors = ['#1a9850', '#66bd63', '#a6d96a', '#d9ef8b', '#ffffbf', '#fee08b', '#fdae61', '#f46d43', '#d73027'];
      // var colorsYGB = ["#FFFFDD", "#AAF191", "#80D385", "#61B385", "#3E9583", "#217681", "#285285", "#1F2D86", "#000086"];
      //var colorsRBW = ["#2c7bb6", "#00a6ca", "#00ccbc", "#90eb9d", "#ffff8c", "#f9d057", "#f29e2e", "#e76818", "#d7191c"];

      var colors = altcolors;
      var colorRange = range(0, 1, 1.0 / (colors.length - 1));
      colorRange.push(1);

      //maps values to a value between 0 and 1
      // var scaleToValue = scaleLinear()
      //     .domain([MIN_VALUE, MAX_VALUE])
      //     .range([0,1]);

      //maps a value of 0 to 1 to a color
      var colorScale = scaleLinear()
        .domain(colorRange)
        .range(colors)
        .interpolate(interpolateHcl);

      var width =
        FLOWCELL_WIDTH * SCALE + LEGEND_OFFSET + LEGEND_WIDTH + LEGEND_PADDING;

      // if (hideLegend) {
      //     width = FLOWCELL_WIDTH * SCALE + 10
      // }

      var height = FLOWCELL_HEIGHT * SCALE;

      // add the svg node
      var svg = div
        .append("svg")
        .attr("width", width)
        .attr("height", height + TITLE_PADDING)
        .append("g")
        .attr("transform", "translate(" + 0 + "," + TITLE_PADDING + ")");

      // var selectedTileDiv = div.append("div")
      //     .style("position", "absolute")
      //     .style("width", "20px")
      //     .style("height", "20px")
      //     .style("background", "red")
      //     .style("box-shadow", "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)")
      //     .style("border", "1px")
      // //    .style("border-radius", "8px")
      //     .style("opacity", 0);

      // the main title
      svg
        .append("text")
        .attr("x", (FLOWCELL_WIDTH * SCALE) / 2)
        .attr("y", -15)
        .style("font-size", "1.0rem")
        .style("font-family", "'Open Sans', sans-serif")
        .style("fill", "#4F4F4F")
        .style("font-weight", "300")
        .style("text-align", "center")
        .style("text-anchor", "middle")
        .text(title);

      // subtitle
      // svg.append("text")
      //     .attr("x", FLOWCELL_WIDTH * SCALE / 2)
      //     .attr("y", -15)
      //     .style("font-size", "1rem")
      //     .style("font-family", "'Open Sans', sans-serif")
      //     .style("fill", "#777777")
      //     .style("font-weight","300")
      //     .style("text-align","center")
      //     .style("text-anchor","middle")
      //     .text(subtitle);

      // the outline of the flowcell
      svg
        .append("rect")
        .attr("x", 0 * SCALE)
        .attr("y", 0 * SCALE)
        .attr("width", FLOWCELL_WIDTH * SCALE)
        .attr("height", FLOWCELL_HEIGHT * SCALE)
        .attr("rx", 4)
        .attr("ry", 4)
        .style("stroke", "gray")
        .style("stroke-width", 2)
        .style("fill", "white");

      // the lines corresponding to the lanes
      svg
        .selectAll(".lines")
        .data([
          { x1: 6.25, y1: 3.0, x2: 2.26, y2: 11.0 },
          { x1: 2.26, y1: 11.0, x2: 2.26, y2: 64.0 },
          { x1: 2.26, y1: 64.0, x2: 6.25, y2: 72.0 },
          { x1: 6.65, y1: 72.0, x2: 11.92, y2: 64.0 },
          { x1: 11.92, y1: 64.0, x2: 11.92, y2: 11.0 },
          { x1: 11.92, y1: 11.0, x2: 6.65, y2: 3.0 },
          {
            x1: FLOWCELL_WIDTH - 6.25,
            y1: 3.0,
            x2: FLOWCELL_WIDTH - 2.26,
            y2: 11.0,
          },
          {
            x1: FLOWCELL_WIDTH - 2.26,
            y1: 11.0,
            x2: FLOWCELL_WIDTH - 2.26,
            y2: 64.0,
          },
          {
            x1: FLOWCELL_WIDTH - 2.26,
            y1: 64.0,
            x2: FLOWCELL_WIDTH - 6.25,
            y2: 72.0,
          },
          {
            x1: FLOWCELL_WIDTH - 6.65,
            y1: 72.0,
            x2: FLOWCELL_WIDTH - 11.92,
            y2: 64.0,
          },
          {
            x1: FLOWCELL_WIDTH - 11.92,
            y1: 64.0,
            x2: FLOWCELL_WIDTH - 11.92,
            y2: 11.0,
          },
          {
            x1: FLOWCELL_WIDTH - 11.92,
            y1: 11.0,
            x2: FLOWCELL_WIDTH - 6.65,
            y2: 3.0,
          },
        ])
        .enter() //.append("g")
        .append("line")
        .style("stroke", "gray")
        .style("stroke-width", 2)
        .attr("x1", (d) => d.x1 * SCALE)
        .attr("x2", (d) => d.x2 * SCALE)
        .attr("y1", (d) => d.y1 * SCALE)
        .attr("y2", (d) => d.y2 * SCALE);

      // the little circles at the top and bottom of the lanes
      svg
        .selectAll(".circles")
        .data([
          { cx: 6.5, cy: 3 + r },
          { cx: 6.5, cy: 72 - r },
          { cx: FLOWCELL_WIDTH - 6.5, cy: 3 + r },
          { cx: FLOWCELL_WIDTH - 6.5, cy: 72 - r },
        ])
        .enter()
        .append("circle")
        .attr("cx", (d) => d.cx * SCALE)
        .attr("cy", (d) => d.cy * SCALE)
        .attr("r", r * SCALE)
        .style("stroke", "gray")
        .style("stroke-width", 2)
        .style("fill", "white");

      // the tiles
      svg
        .selectAll(".tiles")
        .data(rects)
        .enter()
        .append("rect")
        .attr("id", (d) => d.l)
        .attr("x", (d) => d.x * SCALE)
        .attr("y", (d) => d.y * SCALE)
        .attr("rx", 1)
        .attr("ry", 1)
        .attr("class", "mytile")
        .attr("width", (d) => d.w * SCALE)
        .attr("height", (d) => d.h * SCALE)
        .style("stroke", "gray")
        .style("stroke-width", 1)
        .style("fill", (d) => {
          if (isNaN(d.v)) {
            return "#FFFFFF00";
          }
          var tmp = d.v;
          tmp = (tmp - MIN_VALUE) / (MAX_VALUE - MIN_VALUE + 0.0000001);
          if (tmp > 1) return "purple";
          if (tmp < 0) return "yellow";
          return colorScale(tmp);
        })
        .on("mouseover", (d) => {
          tooltipDiv.transition().duration(200).style("opacity", 1);
          var secondLine = "";
          if (!mousedownhandler) {
            secondLine = +d.v.toFixed(tooltipprecision).toString();
          }
          tooltipDiv
            .html(
              "<span style='color:gray'>" + d.l + "</span><br/>" + secondLine
            )
            .style("left", event.pageX + TOOLTIP_OFFSET_X.x + "px")
            .style("top", event.pageY + TOOLTIP_OFFSET_X.y + "px");
        })
        .on("mouseout", (d) => {
          tooltipDiv.transition().duration(500).style("opacity", 0);
        })
        .on("mousedown", (d) => {
          if (mousedownhandler) {
            if (!isNaN(d.v) || props.selectAny) {
              mousedownhandler(d.l);
            }
          }
        });

      if (selectedtile && selectedtile.length > 0)
        svg
          .select("#" + selectedtile)
          ./*style("fill","red")*/ style("stroke-width", 3)
          .style("stroke", "black")
          .raise();

      if (!hidelegend) {
        //Append a defs (for definition) element to your SVG
        var defs = svg.append("defs");

        //Append a linearGradient element to the defs and give it a unique id
        var linearGradient = defs
          .append("linearGradient")
          .attr("id", "linear-gradient")
          .attr("x1", "0%")
          .attr("x2", "0%")
          .attr("y1", "100%")
          .attr("y2", "0%");

        //Append multiple color stops by using D3's data/enter step
        linearGradient
          .selectAll("stop")
          .data(colorScale.range())
          .enter()
          .append("stop")
          .attr("offset", function (d, i) {
            return i / (colorScale.range().length - 1);
          })
          .attr("stop-color", function (d) {
            return d;
          });

        svg
          .append("rect")
          .attr("x", FLOWCELL_WIDTH * SCALE + LEGEND_OFFSET)
          .attr("y", FLOWCELL_HEIGHT * SCALE * TOOLTIP_OFFSET_Y_FRAC)
          .attr("width", LEGEND_WIDTH)
          .attr(
            "height",
            FLOWCELL_HEIGHT * SCALE * (1 - 2 * TOOLTIP_OFFSET_Y_FRAC)
          )
          .style("fill", "url(#linear-gradient)");

        //Color Legend container
        var legendsvg = svg
          .append("g")
          .attr("class", "legendWrapper")
          .attr(
            "transform",
            "translate(" +
              (FLOWCELL_WIDTH * SCALE + LEGEND_OFFSET + LEGEND_WIDTH) +
              "," +
              FLOWCELL_HEIGHT * SCALE * TOOLTIP_OFFSET_Y_FRAC +
              ")"
          );

        //Set scale legend
        var yScale = scaleLinear()
          .range([0, FLOWCELL_HEIGHT * SCALE * (1 - 2 * TOOLTIP_OFFSET_Y_FRAC)])
          .domain([MAX_VALUE, MIN_VALUE]);

        //Define legend axis
        var yAxis = axisRight()
          .ticks(5) //Set rough # of ticks
          .scale(yScale);

        //Set up legend axis
        legendsvg
          .append("g")
          .attr("class", "axis") //Assign "axis" class
          .call(yAxis);
      }
    }
  });

  //style={{ overflow: "scroll" }}

  return (
    <div {...props} ref={(node) => setNode(node)}>
      {" "}
    </div>
  );
});
