import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "../react-auth0-spa";
import "../App.css";

import "devextreme-react/text-area";
import "devextreme-react/html-editor";
import Button from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  Format,
  Export,
  StateStoring,
  HeaderFilter,
} from "devextreme-react/data-grid";

export const LaneTable = React.memo((props) => {
  const [state, setState] = useState(null);
  const id = props.id;
  const theGrid = useRef(null);

  const { loading, fetchWithCheck } = useAuth0();

  useEffect(() => {
    const callApi = async () => {
      const tmp = await fetchWithCheck("/analyses/" + id + "/lane");
      if (tmp && tmp.length > 0) {
        for (var i = 0; i < tmp.length; i++) {
          tmp[i].id = i;
          for (var key of Object.keys(tmp[i])) {
            if (tmp[i][key] === -999) {
              tmp[i][key] = "";
            }
          }
        }

        var fields = [];
        for (var field of Object.keys(tmp[0])) {
          fields.push(field);
        }

        setState({ data: tmp, fields: fields });
      }
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, id]);

  var columns = [];
  if (state) {
    columns = state.fields
      .filter((x) => x !== "id")
      .map((x) => (
        <Column dataField={x}>
          {" "}
          {(x.includes("Rate") || x.includes("ercent")) && (
            <Format type="percent" precision={4} />
          )}{" "}
        </Column>
      ));
  }

  return (
    <React.Fragment>
      <Button
        onClick={() => {
          theGrid.current.instance.state({});
        }}
        text="Reset Table Formatting"
      />
      <br />
      <br />

      <DataGrid
        ref={theGrid}
        dataSource={state ? state.data : []}
        keyExpr="id"
        wordWrapEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        focusedRowEnabled={false}
        allowColumnReordering={true}
        columnResizingMode={"widget"}
        columnHidingEnabled={true}
        columnAutoWidth={true}
        hoverStateEnabled={false}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="laneTable"
        />
        <Export
          enabled={true}
          fileName={"LanesTable"}
          allowExportSelectedData={true}
        />
        <HeaderFilter visible={true} />

        <ColumnChooser enabled={true} />

        {columns}
      </DataGrid>
    </React.Fragment>
  );
});
