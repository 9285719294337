import React, { useState, useEffect } from "react";
import "../../App.css";
import { scaleLinear } from "d3-scale";
import { select } from "d3-selection";
import { axisTop, axisRight, event, interpolateHcl, range } from "d3";

function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
}

export const GenericHeatmap = React.memo((props) => {
  const [node, setNode] = useState();
  var {
    data,
    rowlabels,
    minv,
    maxv,
    title,
    tooltipprecision,
    hidelegend,
    mousedownhandler,
    scale,
    nogap,
    colLabel,
  } = props;

  if (!colLabel) colLabel = "Cycle";
  useEffect(() => {
    if (node) {
      node.innerHTML = "";
      var SCALE = 1;
      if (scale) {
        SCALE = scale;
      }
      const TITLE_PADDING = 30;
      const TOOLTIP_OFFSET_X = { x: 10, y: -20 };
      const TOOLTIP_OFFSET_Y_FRAC = 0.1;
      const LEGEND_OFFSET = 10;
      const LEGEND_WIDTH = 20;
      const LEGEND_PADDING = 40;
      const MIN_VALUE = minv;
      const MAX_VALUE = maxv;

      const LEFT = 36;
      const TOP = 20;

      var i, j;

      const numRows = data.length;
      const numCols = data[0].length;

      const tilewidth = 10;
      const tileheight = 20;
      const FLOWCELL_WIDTH = tilewidth * numCols + LEFT;
      const FLOWCELL_HEIGHT = rowlabels.length * tileheight;

      var rects = [];
      var cnt = 0;
      var offset = -5;

      var rowLabels = [];

      for (j = 0; j < numRows; j++) {
        if (j % 3 === 0 && !nogap) {
          offset = offset + 5;
        }
        rowLabels[j] = {
          label: rowlabels[j],
          y: (TOP + j * tileheight + offset) * scale,
        };

        var d = new Date(2021, 0, 3, 0, 0, 0, 0);

        for (i = 0; i < numCols; i++) {
          var label =
            "R" +
            (j + 2).toString().padStart(2, "0") +
            "C" +
            (i + 1).toString().padStart(2, "0");
          var val = data[j][i];
          if (val === 0) val = -1;
          rects[cnt++] = {
            x: LEFT + i * tilewidth,
            y: TOP + j * tileheight + offset,
            w: tilewidth,
            h: tileheight,
            v: val,
            l: label,
            ylabel: rowlabels[j],
            xlabel:
              colLabel +
              " " +
              (i + 1) +
              (colLabel === "Week" ? " " + getFormattedDate(d) : ""),
          };
          d.setDate(d.getDate() + 7);
        }
      }

      // this is the div node that will contain all the d3 content
      var div = select(node);

      // Add the DIV that will hold the tooltip to the DOM
      var tooltipDiv = div
        .append("div")
        .style("position", "absolute")
        .style("text-align", "center")
        .style("width", "80px")
        .style("height", mousedownhandler ? "16px" : "50px")
        .style("padding", "2px")
        .style("font", "10px sans-serif")
        .style("background", "white")
        .style(
          "box-shadow",
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
        )
        //.style("border", "1px")
        .style("border-radius", "8px")
        .style("opacity", 0);

      var altcolors = [
        "#ffffd9",
        "#edf8b1",
        "#c7e9b4",
        "#7fcdbb",
        "#41b6c4",
        "#1d91c0",
        "#225ea8",
        "#253494",
        "#081d58",
      ];

      var colors = altcolors;
      var colorRange = range(0, 1, 1.0 / (colors.length - 1));
      colorRange.push(1);

      //maps values to a value between 0 and 1
      // var scaleToValue = scaleLinear()
      //     .domain([MIN_VALUE, MAX_VALUE])
      //     .range([0,1]);

      //maps a value of 0 to 1 to a color
      var colorScale = scaleLinear()
        .domain(colorRange)
        .range(colors)
        .interpolate(interpolateHcl);

      var width =
        FLOWCELL_WIDTH * SCALE + LEGEND_OFFSET + LEGEND_WIDTH + LEGEND_PADDING;

      // add the svg node
      var svg = div
        .append("svg")
        .attr("width", width)
        .attr(
          "height",
          (rowlabels.length * tileheight + TITLE_PADDING) * scale +
            (nogap ? 4 : rowlabels.length * 4)
        )
        .append("g")
        .attr("transform", "translate(" + 0 + "," + TITLE_PADDING + ")");

      // the main title
      svg
        .append("text")
        .attr("x", (FLOWCELL_WIDTH * SCALE) / 2)
        .attr("y", -15)
        .style("font-size", "1.0rem")
        .style("font-family", "'Open Sans', sans-serif")
        .style("fill", "#000")
        .style("font-weight", "300")
        .style("text-align", "center")
        .style("text-anchor", "middle")
        .text(title);

      // the tiles
      svg
        .selectAll(".tiles")
        .data(rects)
        .enter()
        .append("rect")
        .attr("id", (d) => d.l)
        .attr("x", (d) => d.x * SCALE)
        .attr("y", (d) => d.y * SCALE)
        .attr("rx", 1)
        .attr("ry", 1)
        .attr("class", "mytile")
        .attr("width", (d) => d.w * SCALE)
        .attr("height", (d) => d.h * SCALE)
        .style("stroke", "gray")
        .style("stroke-width", 1)
        .style("fill", (d) => {
          var tmp = d.v;
          tmp = (tmp - MIN_VALUE) / (MAX_VALUE - MIN_VALUE + 0.0000001);
          if (tmp > 1) return "purple";
          if (tmp < 0) return "white";
          return colorScale(tmp);
        })
        .on("mouseover", (d) => {
          tooltipDiv.transition().duration(200).style("opacity", 1);
          var secondLine = "";
          if (!mousedownhandler) {
            secondLine = +d.v.toFixed(tooltipprecision).toString();
          }
          tooltipDiv
            .html(d.xlabel + "<br/>" + d.ylabel + "<br/>" + secondLine)
            .style("left", event.pageX + TOOLTIP_OFFSET_X.x + "px")
            .style("top", event.pageY + TOOLTIP_OFFSET_X.y + "px");
        })
        .on("mouseout", (d) => {
          tooltipDiv.transition().duration(500).style("opacity", 0);
        })
        .on("mousedown", (d) => {
          if (mousedownhandler) {
            if (!isNaN(d.v)) {
              mousedownhandler(d.l);
              svg.selectAll(".mytile").style("fill", (e) => {
                var tmp = e.v;
                tmp = (tmp - MIN_VALUE) / (MAX_VALUE - MIN_VALUE + 0.0000001);
                if (tmp > 1) return "purple";
                if (tmp < 0) return "yellow";
                return colorScale(tmp);
              });
              svg.select("#" + d.l).style("fill", "red"); //style("stroke-width",2).style("stroke","black")
            }
          }
        });

      svg
        .selectAll(".ylabel")
        .data(rowLabels)
        .enter()
        .append("text")
        .attr("x", 0)
        .attr("y", function (d) {
          return d.y;
        })
        .style("text-anchor", "end")
        .attr(
          "transform",
          "translate(" + 2 * SCALE + "," + SCALE * (tileheight / 1.5 + 1) + ")"
        )
        // .append("a")
        // .attr("xlink:href", function (d) { return "/project/" + d.id; })
        .attr("style", "fill: #0000aa; font-size: 12px;")
        .text(function (d) {
          return d.label;
        });

      //Color Legend container
      var colAxisSVG = svg
        .append("g")
        .attr("class", "legendWrapper")
        .attr("transform", "translate(" + LEFT * SCALE + "," + TOP + ")");

      //Set scale legend
      var colScale = scaleLinear()
        .range([0, numCols * tilewidth * scale])
        .domain([1, numCols]);

      //Define legend axis
      var colAxis = axisTop()
        .ticks(5) //Set rough # of ticks
        .scale(colScale);

      //Set up legend axis
      colAxisSVG
        .append("g")
        .attr("class", "axis") //Assign "axis" class
        .call(colAxis);

      if (!hidelegend) {
        //Append a defs (for definition) element to your SVG
        var defs = svg.append("defs");

        //Append a linearGradient element to the defs and give it a unique id
        var linearGradient = defs
          .append("linearGradient")
          .attr("id", "linear-gradient")
          .attr("x1", "0%")
          .attr("x2", "0%")
          .attr("y1", "100%")
          .attr("y2", "0%");

        //Append multiple color stops by using D3's data/enter step
        linearGradient
          .selectAll("stop")
          .data(colorScale.range())
          .enter()
          .append("stop")
          .attr("offset", function (d, i) {
            return i / (colorScale.range().length - 1);
          })
          .attr("stop-color", function (d) {
            return d;
          });

        svg
          .append("rect")
          .attr("x", FLOWCELL_WIDTH * SCALE + LEGEND_OFFSET)
          .attr("y", FLOWCELL_HEIGHT * SCALE * TOOLTIP_OFFSET_Y_FRAC)
          .attr("width", LEGEND_WIDTH)
          .attr(
            "height",
            FLOWCELL_HEIGHT * SCALE * (1 - 2 * TOOLTIP_OFFSET_Y_FRAC)
          )
          .style("fill", "url(#linear-gradient)");

        //Color Legend container
        var legendsvg = svg
          .append("g")
          .attr("class", "legendWrapper")
          .attr(
            "transform",
            "translate(" +
              (FLOWCELL_WIDTH * SCALE + LEGEND_OFFSET + LEGEND_WIDTH) +
              "," +
              FLOWCELL_HEIGHT * SCALE * TOOLTIP_OFFSET_Y_FRAC +
              ")"
          );

        //Set scale legend
        var yScale = scaleLinear()
          .range([0, FLOWCELL_HEIGHT * SCALE * (1 - 2 * TOOLTIP_OFFSET_Y_FRAC)])
          .domain([MAX_VALUE, MIN_VALUE]);

        //Define legend axis
        var yAxis = axisRight()
          .ticks(5) //Set rough # of ticks
          .scale(yScale);

        //Set up legend axis
        legendsvg
          .append("g")
          .attr("class", "axis") //Assign "axis" class
          .call(yAxis);
      }
    }
  });

  //style={{ overflow: "scroll" }}

  return <div {...props} ref={(node) => setNode(node)}></div>;
});
