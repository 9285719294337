import React, { useRef, useEffect, useState, useCallback } from "react";
import { Popup } from "devextreme-react/popup";
import AddRunForm from "./AddRunForm";
import { Button, CheckBox, ScrollView } from "devextreme-react";
import { useAuth0 } from "../../react-auth0-spa";
import { confirm } from "devextreme/ui/dialog";

import vars from "./variables";
import * as drawing from "./drawing";
import * as eventHandlers from "./eventHandlers";

import { Link } from "react-router-dom";
import notify from "devextreme/ui/notify";

import { newRun } from "./constants";

const doSort = (sortByRunName: boolean) => {
  vars.runs.sort((a, b) => {
    if (sortByRunName) {
      if (a.runName > b.runName) return 1;
      if (a.runName < b.runName) return -1;
      return 0;
    }
    if (a.dateRequested > b.dateRequested) return -1;
    if (a.dateRequested < b.dateRequested) return 1;
    return 0;
  });
};

const PlannedRunCalendar = () => {
  const { loading, fetchWithCheck, fetchNoResponse, user } = useAuth0();

  vars.currentUser = user?.email || "";

  const divRef = useRef(null);
  const myCanvas = useRef(null);
  const [redrawCntr, setRedrawCntr] = useState(0);
  const [reloadCounter, setReloadCounter] = useState(0);
  const [resizeCounter, setResizeCounter] = useState(0);
  const [showOnlyMyRuns, setShowOnlyMyRuns] = useState(false);
  const [sortByRunName, setSortByRunName] = useState(false);

  useEffect(() => {
    const callAPI = async () => {
      var inst = await fetchWithCheck("/instruments");
      vars.instruments = inst.filter((v) => v.includeInPlanning);
    };
    if (!loading) callAPI();
  }, [loading, fetchWithCheck]);

  useEffect(() => {
    const callAPI = async () => {
      vars.runs = await fetchWithCheck("/plannedRuns");
      if (vars.runs === null) vars.runs = [];
      doSort(sortByRunName);
      // if (vars.runs.length > 10) {
      //   vars.runs = vars.runs.slice(1, 10);
      // }
      console.log(vars.runs);
      for (var i = 0; i < vars.runs.length; i++) {
        vars.runs[i].startDate = new Date(vars.runs[i].startDate);
      }
      setRedrawCntr((v) => v + 1);
    };
    if (!loading) callAPI();
  }, [loading, fetchWithCheck, reloadCounter, sortByRunName]);

  useEffect(() => {
    doSort(sortByRunName);
    forceRedraw();
  }, [sortByRunName]);

  useEffect(() => {
    drawing.setCanvasResolution(myCanvas);
    console.log(resizeCounter);
  }, [myCanvas, resizeCounter]);

  const updatePlannedRun = useCallback(
    async (run) => {
      if (vars.runBeingCoppied) {
        const ret = await fetchNoResponse("/plannedRuns", {
          method: "POST",
          body: JSON.stringify({ ...run, createCopy: true }),
        });
        if (ret) {
          forceReload();
          notify("Saved", "success", 100);
        }
      } else {
        const values = {
          instrumentID: run.instrumentID,
          side: run.side,
          startDate: run.startDate,
        };
        var jsonstr = JSON.stringify(values);
        await fetchNoResponse("/plannedRuns/" + run.id, {
          method: "PATCH",
          body: jsonstr,
        });
        notify("Saved", "success", 100);
      }
    },
    [fetchNoResponse]
  );

  const forceRedraw = () => setRedrawCntr((v) => (v + 1) % 100);
  const forceReload = () => setReloadCounter((v) => (v + 1) % 100);

  const onDoubleClick = useCallback((e) => {
    eventHandlers.doubleClickHandler(e, forceRedraw);
  }, []);

  const onMouseDown = useCallback((e) => {
    eventHandlers.mouseDownHandler(e, forceRedraw);
  }, []);

  const onMouseMove = useCallback((e) => {
    eventHandlers.mouseMoveHandler(e, forceRedraw);
  }, []);

  const onMouseExit = useCallback(
    (e) => {
      eventHandlers.mouseExitHandler(e, forceRedraw, updatePlannedRun);
    },
    [updatePlannedRun]
  );

  const onKeyUp = useCallback((e) => {
    eventHandlers.keyUpHandler(e, forceRedraw);
  }, []);

  const onMouseUp = useCallback(
    (e) => {
      eventHandlers.mouseUpHandler(e, forceRedraw, updatePlannedRun);
    },
    [updatePlannedRun]
  );

  const onMouseWheel = useCallback((e) => {
    eventHandlers.mouseWheelHandler(e, forceRedraw);
  }, []);

  const onResize = useCallback((e) => {
    eventHandlers.resizeHandler(e);
    setResizeCounter((v) => (v + 1) % 100);
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    if (myCanvas) {
      const cv = myCanvas.current;
      var ctx = cv.getContext("2d");
      drawing.drawAll(ctx);
    }
  }, [myCanvas, redrawCntr /* do not remove */]);

  useEffect(() => {
    if (divRef && myCanvas) {
      const dvref = divRef.current;
      const cvref = myCanvas.current;
      window.addEventListener("resize", forceRedraw);
      dvref.addEventListener("keydown", onKeyUp);
      cvref.addEventListener("mousedown", onMouseDown);
      dvref.addEventListener("mousemove", onMouseMove);
      cvref.addEventListener("mouseup", onMouseUp);
      cvref.addEventListener("wheel", onMouseWheel);
      dvref.addEventListener("mouseleave", onMouseExit);
      dvref.addEventListener("dblclick", onDoubleClick);
      return () => {
        cvref.removeEventListener("mousedown", onMouseDown);
        dvref.removeEventListener("mousemove", onMouseMove);
        dvref.removeEventListener("mouseup", onMouseUp);
        dvref.removeEventListener("mouseleave", onMouseExit);
        dvref.removeEventListener("dblclick", onDoubleClick);
        cvref.removeEventListener("wheel", onMouseWheel);
        dvref.removeEventListener("keydown", onKeyUp);
        window.removeEventListener("resize", onResize);
      };
    }
  }, [
    myCanvas,
    divRef,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onDoubleClick,
    onMouseExit,
    onKeyUp,
    onResize,
    onMouseWheel,
  ]);

  const onDeleteRun = useCallback(() => {
    const deletePlannedRun = async (run) => {
      await fetchNoResponse("/plannedRuns/" + run.id, {
        method: "DELETE",
      });
      notify("Deleted", "success", 100);
      vars.selectedRow = -1;
      vars.deleteButtonEnabled = false;
      forceReload();
    };
    const tmp = vars.runs.filter(
      (v) =>
        v.instrumentID === 0 &&
        (!vars.showOnlyMyRuns || vars.currentUser === v.requester)
    );
    const run = tmp[vars.selectedRow];
    let result = confirm(
      "<i>Are you sure you want to delete this run?</i>",
      run.runName
    );
    result.then((dialogResult) => {
      if (dialogResult) deletePlannedRun(run);
    });
  }, [fetchNoResponse]);

  const onNewRun = useCallback(() => {
    vars.selectedRun = {
      ...newRun,
      requester: user?.email,
    };
    vars.isEditing = false;
    vars.popupVisible = true;
    forceRedraw();
  }, [user?.email]);

  return (
    <>
      <div ref={divRef}>
        <div style={{ height: "500ox", width: "100%", position: "relative" }}>
          <canvas ref={myCanvas}></canvas>
          <Button
            text="Add New Run..."
            onClick={onNewRun}
            style={{
              position: "absolute",
              left: 50,
              top: 20,
              width: 150,
              height: 30,
            }}
          />
          <Button
            text="Delete Run..."
            disabled={!vars.deleteButtonEnabled}
            onClick={onDeleteRun}
            style={{
              position: "absolute",
              left: 250,
              top: 20,
              width: 150,
              height: 30,
            }}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              left: 10,
              top: 50,
              width: 350,
              height: 30,
            }}
          >
            <CheckBox
              text="Show only runs I requested"
              value={showOnlyMyRuns}
              onValueChanged={(v) => {
                setShowOnlyMyRuns(v.value);
                vars.showOnlyMyRuns = v.value;
                forceRedraw();
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              left: 360,
              top: 50,
              width: 350,
              height: 30,
            }}
          >
            <CheckBox
              text="Sort by run name"
              value={sortByRunName}
              onValueChanged={(v) => {
                setSortByRunName(v.value);
              }}
            />
          </div>

          <Link
            to="/PlannedRunsList"
            style={{
              position: "absolute",
              left: 80,
              top: vars.bottom + 15,
              width: 400,
              height: 30,
            }}
          >
            All Planned Runs...
          </Link>
          {/* <span
            style={{
              position: "absolute",
              left: 80,
              top: vars.bottom + 15,
              width: 400,
              height: 30,
            }}
          >
            <em>shift-click-drag on a run to make a duplicate of it</em>
          </span>

          <span
            style={{
              position: "absolute",
              left: 80,
              top: vars.bottom + 45,
              width: 400,
              height: 30,
            }}
          >
            <em>press escape to cancel a drag operation</em>
          </span> */}
        </div>
      </div>

      {vars.popupVisible && (
        <Popup
          visible={vars.popupVisible}
          onHiding={() => {
            vars.popupVisible = false;
            vars.isEditing = false;
            forceRedraw();
          }}
          dragEnabled={true}
          resizeEnabled={true}
          closeOnOutsideClick={true}
          showCloseButton={false}
          showTitle={true}
          title={
            vars.isEditing ? vars.selectedRun.runName : "Create a Planned Run"
          }
          container=".dx-viewport"
          maxWidth={1150}
          width="80%"
          height="80vh"
        >
          <ScrollView style={{ marginLeft: 50 }}>
            <AddRunForm
              visible={vars.popupVisible}
              selectedRun={vars.selectedRun}
              isEditing={vars.isEditing}
              onCancel={() => {
                vars.popupVisible = false;
                vars.isEditing = false;
                forceReload();
              }}
              onSubmit={async (data, editedData) => {
                if (vars.isEditing) {
                  if (!editedData.cloudAnalysis && editedData.analysisEnabled) {
                    editedData.roiWidth = 5472;
                    editedData.roiHeight = 3648;
                  }
                  editedData.runCountNumber = data.runCountNumber;
                  editedData.runName = data.runName;
                  const jsonstr = JSON.stringify(editedData);
                  const ret = await fetchNoResponse("/plannedRuns/" + data.id, {
                    method: "PATCH",
                    body: jsonstr,
                  });

                  if (ret) {
                    vars.popupVisible = false;
                    vars.isEditing = false;
                    forceReload();
                    return true;
                  }
                  return false;
                } else {
                  console.log("payload", data);
                  const ret = await fetchNoResponse("/plannedRuns", {
                    method: "POST",
                    body: JSON.stringify(data),
                  });

                  if (ret) {
                    vars.popupVisible = false;
                    vars.isEditing = false;
                    forceReload();
                    notify("Saved", "success", 100);
                    return true;
                  }
                  return false;
                }
              }}
            />
          </ScrollView>
        </Popup>
      )}
    </>
  );
};

export default PlannedRunCalendar;
