import React from "react";
import Form, { SimpleItem, GroupItem} from 'devextreme-react/form';
import styled from 'styled-components';

const Styles = styled.div`
.first-group,
.second-group {
    padding: 20px;
}
.second-group {
    background-color: rgba(191, 191, 191, 0.15);
}

.dx-form .dx-field-item-label {  
    font-weight: bold;  
}  

.dx-form .dx-field-item {
    font-size: 12px;
}

.dx-widget {
    font-size: 12px;
}
`

export const PrimerQCDetailsTable = props => {
    const rowHeight = "15px"
    
    return (
        <Styles>
            <Form
                id="form"
                formData={props.details}
                readOnly={true}
                showColonAfterLabel={true}
                labelLocation={'left'}
                minColWidth={300}
                colCount={1}
            >
                <GroupItem cssClass="first-group" colCount={1} colSpan={1}>
                    <SimpleItem dataField="date" editorType="dxDateBox" editorOptions={{ height: rowHeight, readOnly: true }} />
                    <SimpleItem dataField="operator" editorOptions={{ height: rowHeight,readOnly: true }} />
                    <SimpleItem dataField="analysisMethod" editorOptions={{height: rowHeight, readOnly: true }} />
                    <SimpleItem dataField="analysisVersion" editorOptions={{height: rowHeight, readOnly: true }} />
                    <SimpleItem dataField="primer" editorOptions={{height: rowHeight, readOnly: true }} />
                 </GroupItem>
            </Form>
        </Styles>
    );
};
