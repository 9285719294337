import React, { useState, useEffect } from "react";
import Form, { EmptyItem, GroupItem, SimpleItem } from "devextreme-react/form";
import styled from "styled-components";
import { useAuth0 } from "../react-auth0-spa";

const Styles = styled.div`
  .first-group,
  .second-group {
    padding: 20px;
  }
  .second-group {
    background-color: rgba(191, 191, 191, 0.15);
  }
`;

const prettySize = (bytes, separator = "", postFix = "") => {
  if (bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.min(
      parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10),
      sizes.length - 1
    );
    return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${separator}${
      sizes[i]
    }${postFix}`;
  }
  return "n/a";
};

export const AnalysisInfoTable = (props) => {
  const { fetchWithCheck, loading } = useAuth0();
  const [data, setData] = useState({});
  const { numCyclesR1, numCyclesR2 } = props;

  // comment
  useEffect(() => {
    const callApi = async () => {
      const data = await fetchWithCheck("/analyses/" + props.id);
      data.maxMemory = prettySize(data.maxMemory);

      var diff = data.elapsedTime;

      var hours = Math.floor(diff / (60 * 60));
      diff -= hours * (60 * 60);

      var mins = Math.floor(diff / 60);
      diff -= mins * 60;

      data.elapsedTime =
        hours.toString().padStart(2, "0") +
        ":" +
        mins.toString().padStart(2, "0") +
        ":" +
        diff.toFixed(1).toString().padStart(2, "0");

      if (data.analysisRoot) data.analysisRoot = "s3://" + data.bucket + "/" + data.analysisRoot;

      data.extrapolatedReads =
        data.extrapolatedReads.toFixed(1) + " Million Reads";

      if (data.percentQ30 && data.percentQ30 !== -999) {
        data.percentQ30 = (data.percentQ30 * 100).toFixed(1) + "%";
      } else {
        data.percentQ30 = "N/A";
      }

      if (data.percentQ30R1 && data.percentQ30R1 !== -999) {
        data.percentQ30R1 = data.percentQ30R1.toFixed(1) + "%";
      } else {
        data.percentQ30R1 = "N/A";
      }

      if (data.percentQ30R2 && data.percentQ30R2 !== -999) {
        data.percentQ30R2 = data.percentQ30R2.toFixed(1) + "%";
      } else {
        data.percentQ30R2 = "N/A";
      }

      if (data.percentQ40 && data.percentQ40 !== -999) {
        data.percentQ40 = (data.percentQ40 * 100).toFixed(1) + "%";
      } else {
        data.percentQ40 = "N/A";
      }

      if (data.percentQ40R1 && data.percentQ40R1 !== -999) {
        data.percentQ40R1 = data.percentQ40R1.toFixed(1) + "%";
      } else {
        data.percentQ40R1 = "N/A";
      }

      if (data.percentQ40R2 && data.percentQ40R2 !== -999) {
        data.percentQ40R2 = data.percentQ40R2.toFixed(1) + "%";
      } else {
        data.percentQ40R2 = "N/A";
      }

      var err = 0;
      var errn = 0;

      if (data.percentErrorR1 && data.percentErrorR1 !== -999) {
        err = data.percentErrorR1 * numCyclesR1;
        data.percentErrorR1 = data.percentErrorR1.toFixed(4) + "%";
        errn += numCyclesR1;
      } else {
        data.percentErrorR1 = "N/A";
      }

      if (data.percentErrorR2 && data.percentErrorR2 !== -999) {
        err += data.percentErrorR2 * numCyclesR2;
        data.percentErrorR2 = data.percentErrorR2.toFixed(4) + "%";
        errn += numCyclesR2;
      } else {
        data.percentErrorR2 = "N/A";
      }

      if (data.outlierSubtileCycleRate !== -999) {
        data.outlierSubtileCycleRate =
          data.outlierSubtileCycleRate.toFixed(6) * 100 + "%";
      } else {
        data.outlierSubtileCycleRate = "N/A";
      }

      if (data.errorsDueToOutlierSubtileCycle !== -999 && errn > 0) {
        data.errorsDueToOutlierSubtileCycle =
          ((err * data.errorsDueToOutlierSubtileCycle) / errn).toFixed(4) + "%";
      } else {
        data.errorsDueToOutlierSubtileCycle = "N/A";
      }

      if (data.assignedPercentage !== -999) {
        data.assignedPercent = data.assignedPercentage.toFixed(4) + "%";
      } else {
        data.assignedPercent = "N/A";
      }

      setData(data);
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, props.id, numCyclesR1, numCyclesR2]);

  return (
    <Styles>
      {!loading && (
        <React.Fragment>
          <Form
            id="form"
            style={{ marginBottom: "25px" }}
            formData={data}
            readOnly={false}
            showColonAfterLabel={true}
            labelLocation={"left"}
            minColWidth={300}
            colCount={1}
          >
            <GroupItem cssClass="second-group" colCount={4}>
              <SimpleItem
                dataField="analysisName"
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="analysisDate"
                editorType="dxDateBox"
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="elapsedTime"
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="maxMemory"
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                colSpan={1}
                dataField="percentQ30"
                label={{ text: "Percent Q30" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                colSpan={1}
                dataField="percentQ30R1"
                label={{ text: "Percent Q30 (R1)" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                colSpan={1}
                dataField="percentQ30R2"
                label={{ text: "Percent Q30 (R2)" }}
                editorOptions={{ readOnly: true }}
              />
              <EmptyItem />
              <SimpleItem
                colSpan={1}
                dataField="percentQ40"
                label={{ text: "Percent Q40" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                colSpan={1}
                dataField="percentQ40R1"
                label={{ text: "Percent Q40 (R1)" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                colSpan={1}
                dataField="percentQ40R2"
                label={{ text: "Percent Q40 (R2)" }}
                editorOptions={{ readOnly: true }}
              />
              <EmptyItem />
              <SimpleItem
                colSpan={1}
                dataField="percentErrorR1"
                label={{ text: "Percent Error (R1)" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                colSpan={1}
                dataField="percentErrorR2"
                label={{ text: "Percent Error (R2)" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                colSpan={1}
                dataField="extrapolatedReads"
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="analysisVersion"
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                label={{ text: "DB ID" }}
                dataField="analysisID"
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                label={{ text: "UUID" }}
                dataField="uuid"
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                label={{ text: "Bubble Rate" }}
                dataField="outlierSubtileCycleRate"
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                label={{ text: "Error Contribution Due to Bubbles" }}
                dataField="errorsDueToOutlierSubtileCycle"
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="assignedPercent"
                editorOptions={{ readOnly: true }}
              />
              <EmptyItem />
              <SimpleItem
                colSpan={2}
                label={{ text: "Analysis Folder" }}
                dataField="analysisRoot"
                editorOptions={{ readOnly: true }}
              />
            </GroupItem>
          </Form>
        </React.Fragment>
      )}
    </Styles>
  );
};
