import React, { useEffect, useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import { useAuth0 } from "../../react-auth0-spa";

import {
  Chart,
  ValueErrorBar,
  Legend,
  ValueAxis,
  Point,
  Border,
  CommonPaneSettings,
  Tooltip,
  ArgumentAxis,
  Grid,
  Label,
  Export,
  CommonSeriesSettings,
  SeriesTemplate,
} from "devextreme-react/chart";
import DateRangeSelector from "../helpers/DateRangeSelector";
import TagBox from "devextreme-react/tag-box";
import { Button } from "reactstrap";

export const ErrorByCycle = () => {
  const { fetchWithCheck, fetchBlob, loading } = useAuth0();
  const [selectedRuns, setSelectedRuns] = useState([]);
  //  const [runIDs, setRunIDs] = useState([]);
  const [runList, setRunList] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const [instrumentList, setInstrumentList] = useState([]);
  const [dateRange, setDateRange] = useState({
    from: { year: 2020, month: 1 },
    to: { year: 2022, month: 1 },
  });
  const [data, setData] = useState([]);
  useEffect(() => {
    const callApi = async () => {
      const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      const startdate =
        dateRange.from.year +
        "-" +
        dateRange.from.month.toString().padStart(2, "0") +
        "-01";
      const endDate =
        dateRange.to.year +
        "-" +
        dateRange.to.month.toString().padStart(2, "0") +
        "-" +
        daysInMonth[dateRange.to.month - 1];

      setInstrumentList(
        await fetchWithCheck(
          "/instrumentList?startDate=" + startdate + "&endDate=" + endDate
        )
      );
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, dateRange]);

  useEffect(() => {
    const callApi = async () => {
      const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      const startdate =
        dateRange.from.year +
        "-" +
        dateRange.from.month.toString().padStart(2, "0") +
        "-01";
      const endDate =
        dateRange.to.year +
        "-" +
        dateRange.to.month.toString().padStart(2, "0") +
        "-" +
        daysInMonth[dateRange.to.month - 1];

      var instruments = selectedInstruments.map((x) => x.id).join(",");

      setRunList(
        await fetchWithCheck(
          "/runList?startDate=" +
            startdate +
            "&endDate=" +
            endDate +
            "&instruments=" +
            instruments
        )
      );
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, dateRange, selectedInstruments]);

  useEffect(() => {
    const callApi = async () => {
      if (selectedRuns.length === 0) {
        setData({ data: [], ids: [] });
      } else {
        var runs = selectedRuns.map((x) => "'" + x.runID + "'").join(",");
        var tdata = await fetchWithCheck(
          "/report/tileLevelStats?field=errorRate&runs=" + runs
        ); //fixmer2
        setData(tdata);
      }
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, selectedRuns]);

  var loadData = async () => {
    return data;
  };

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
  });

  const renderTooltip = (pointInfo) => {
    if (pointInfo && pointInfo.point && pointInfo.point.data) {
      const data = pointInfo.point.data;
      const label1 = data.runID;
      const label1b = data.instrument;
      //const label2 = "Tile: " + /*data.flowcell +  " L" + data.lane + " " + */data.tile;
      const label3 = "Cycle: " + Math.floor(data.cycle);
      return (
        <div>
          <strong>{label1}</strong>
          <br />
          {label1b}
          <br />
          {label3}
          <br />
          {"Median: " + data.median.toFixed(2) + "%"}
          <br />
          {"Mean: " + data.mean.toFixed(2) + "%"}
          <br />
          {"Dev: " + data.dev.toFixed(2) + "%"}
        </div>
      );
    }
    return <div />;
  };

  const onRangeChanged = (range) => {
    setDateRange(range);
  };

  var showError = true;

  return (
    <React.Fragment>
      <br />
      <br />
      Please select the data range of the runs you wish to include. You may
      filter by instrument and you may choose to plot only selected runs
      <br />
      <br />
      <DateRangeSelector
        startmonth={200}
        nummonths={12}
        onRangeChanged={onRangeChanged}
      />
      <br />
      <strong>Instruments:</strong>
      <TagBox
        dataSource={instrumentList}
        displayExpr="name"
        defaultValue={selectedInstruments}
        onValueChanged={(e) => {
          setSelectedInstruments(e.value);
        }}
        showSelectionControls={true}
        applyValueMode="useButtons"
        searchEnabled={true}
        placeholder="Instruments to include ..."
      />
      <strong>Runs:</strong>
      <TagBox
        dataSource={runList}
        displayExpr="runID"
        defaultValue={selectedRuns}
        onValueChanged={(e) => {
          setSelectedRuns(e.value);
        }}
        showSelectionControls={true}
        applyValueMode="useButtons"
        searchEnabled={true}
        placeholder="Runs to include ..."
      />
      <br />
      <Chart height="500px" id="chart" dataSource={customStore}>
        <ArgumentAxis argumentType={"numeric"} title={"Cycle"}>
          <Label rotationAngle={20} overlappingBehavior="hide" />
          <Grid visible={true} />
        </ArgumentAxis>

        <CommonPaneSettings>
          <Border visible={true} />
        </CommonPaneSettings>

        <CommonSeriesSettings
          argumentField="cycle"
          valueField={"median"}
          type="line"
        >
          <Point size={5} />
          {showError && (
            <ValueErrorBar
              highValueField="p75"
              lowValueField="p25"
              color="gray"
              opacity={0.5}
            />
          )}
        </CommonSeriesSettings>

        <SeriesTemplate nameField="runID" />

        <Tooltip
          enabled={true}
          //customizeTooltip={customizeTooltip}
          contentRender={renderTooltip}
        />
        <ValueAxis title={"Error Rate (%)"} />
        <Legend visible={true} />
        <Export enabled={true} />
      </Chart>
      <Button
        onClick={(e) => {
          if (selectedRuns.length > 0) {
            var runs = selectedRuns.map((x) => "'" + x.runID + "'").join(",");
            fetchBlob(
              "/report/tileLevelStats?field=errorRate&runs=" +
                runs +
                "&format=csv"
            ).then((blob) => {
              // 2. Create blob link to download
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `report.csv`);
              // 3. Append to html page
              document.body.appendChild(link);
              // 4. Force download
              link.click();
              // 5. Clean up and remove the link
              link.parentNode.removeChild(link);
            });
          }
        }}
      >
        Download CSV
      </Button>
      {/* 
        <ChannelStatsPlot/>
        <br/><br/>
        <FlowcellHeatmap data={mydata} surface={1} minv={0} maxv={60} title={"LE410"} subtitle={"Phasing (%)"} tooltipPrecision={1}/> */}
    </React.Fragment>
  );
};
