import React from "react";
import "../../App.css";
import { useAuth0 } from "../../react-auth0-spa";
import styled from "styled-components";
import CustomStore from "devextreme/data/custom_store";

import {
  Chart,
  CommonSeriesSettings,
  Export,
  SeriesTemplate,
  Legend,
  ValueAxis,
  Point,
  Tooltip,
  ValueErrorBar,
  ArgumentAxis,
  ZoomAndPan,
} from "devextreme-react/chart";

const Styles = styled.div`
  .offset-title {
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
  }
`;
export const SingleChart = (props) => {
  const { fetchWithCheck } = useAuth0();

  var showlegend = true;
  if (props.showlegend !== null) {
    showlegend = props.showlegend;
  }

  const sortLegendItems = (items) => {
    return items.sort((a, b) => {
      let itemA = a.text.toLowerCase();
      let itemB = b.text.toLowerCase();
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      return 0;
    });
  };

  var loadData = async () => {
    var data, i;
    data = await fetchWithCheck(
      "/report/fieldByCycle?id=" + props.id + "&field=" + props.field
    );
    if (props.multby100) {
      for (i = 0; i < data.length; i++) {
        if (data[i].value !== -999) {
          data[i].value = data[i].value * 100;
        } else {
          data[i].value = NaN;
        }
      }
    } else {
      for (i = 0; i < data.length; i++) {
        if (data[i].value === -999) {
          data[i].value = NaN;
        }
      }
    }

    var j;

    const tmp = [...new Set(data.map((x) => x.read))];
    const sortOrder = props.runInfo.readOrder.split(",");
    console.log("sort order", sortOrder);
    let distinctReads = [];
    for (j = 0; j < sortOrder.length; j++) {
      if (tmp.includes(sortOrder[j])) distinctReads.push(sortOrder[j]);
    }
    console.log("distinctReads", distinctReads);

    var maxCycleForRead = {};
    for (j = 0; j < distinctReads.length; j++) {
      maxCycleForRead[distinctReads[j]] = 0;
    }

    for (j = 0; j < data.length; j++) {
      if (data[j].cycle > maxCycleForRead[data[j].read]) {
        maxCycleForRead[data[j].read] = data[j].cycle;
      }
    }

    var readCycleOffset = {};
    readCycleOffset[distinctReads[0]] = 0;

    var currentOffset = 0;
    for (j = 0; j < distinctReads.length; j++) {
      readCycleOffset[distinctReads[j]] = currentOffset;
      currentOffset += maxCycleForRead[distinctReads[j]];
    }

    var data2 = [];
    for (j = 0; j < data.length; j++) {
      data[j].var = data[j].tile + " (" + data[j].read + ")";
      data[j].cycle += readCycleOffset[data[j].read];
      data2.push(data[j]);
    }

    return data2;
  };

  const customStore = new CustomStore({
    key: "id",
    load: loadData,
  });

  const renderTooltip = (pointInfo) => {
    if (pointInfo && pointInfo.point && pointInfo.point.data) {
      const data = pointInfo.point.data;
      const label3 = "Cycle: " + Math.floor(Math.floor(data.cycle));
      var suffix = "";
      if (props.multby100) {
        suffix = "%";
      }
      return (
        <div>
          {data.var}
          <br />
          {label3}
          <br />
          {data.value.toFixed(4) + suffix}
        </div>
      );
    }
    return <div />;
  };

  return (
    <Styles>
      <Chart
        id="chart"
        title={props.title}
        dataSource={customStore}
        onLegendClick={(e) => {
          const series = e.target;
          if (series.isVisible()) {
            series.hide();
          } else {
            series.show();
          }
        }}
      >
        <CommonSeriesSettings
          argumentField="cycle"
          valueField="value"
          type="line"
        >
          <Point size={4} />
          <ValueErrorBar
            highValueField="p75"
            lowValueField="p25"
            color="gray"
            opacity={0.5}
          />
        </CommonSeriesSettings>
        <SeriesTemplate nameField="var" />
        <Tooltip enabled={true} contentRender={renderTooltip} />
        <ValueAxis title={props.yLabel} />
        <ArgumentAxis title="Cycle" allowDecimals={true} />
        <Legend
          visible={showlegend}
          customizeItems={sortLegendItems}
          position="outside"
          horizontalAlignment="center"
          verticalAlignment="bottom"
        />
        <ZoomAndPan argumentAxis="both" valueAxis="both" />
        <Export enabled={true} />
      </Chart>
    </Styles>
  );
};
