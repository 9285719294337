import React, { useRef } from "react";
import { useAuth0 } from "../../react-auth0-spa";

import CustomStore from "devextreme/data/custom_store";
import { Link } from "react-router-dom";

import DataGrid, {
  Editing,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Export,
  Column,
  Paging,
  Pager,
  StateStoring,
} from "devextreme-react/data-grid";

export const PlannedRunsList = () => {
  const grid = useRef();
  const { fetchWithCheck, fetchNoResponse } = useAuth0();

  var loadData = async () => {
    const groups = await fetchWithCheck("/groups");
    const instruments = await fetchWithCheck("/instruments");
    const libraries = await fetchWithCheck("/libraries");

    const groupsLookup = new Map();
    for (let i = 0; i < groups.length; i++)
      groupsLookup.set(groups[i].id, groups[i].name);
    const instrumentLookup = new Map();
    for (let i = 0; i < instruments.length; i++)
      instrumentLookup.set(instruments[i].id, instruments[i].name);
    const libraryLookup = new Map();
    for (let i = 0; i < libraries.length; i++)
      libraryLookup.set(libraries[i].id, libraries[i].name);
    instrumentLookup.set(0, "Backlog");

    const data = await fetchWithCheck("/plannedRuns?full");

    for (let i = 0; i < data.length; i++) {
      data[i].tags = data[i].tags.join("; ");
      data[i].OBPA = !data[i].cloudAnalysis;
      data[i].instrument = instrumentLookup.get(data[i].instrumentID);
      data[i].group = groupsLookup.get(data[i].groupID);
      data[i].library = libraryLookup.get(data[i].libraryID);

      if (data[i].instrument === "Backlog") {
        data[i].side = null;
        data[i].startDate = null;
      }
    }

    return data;
  };

  var updateData = async (key, values) => {
    await fetchNoResponse("/plannedRuns/" + key, {
      method: "PATCH",
      body: JSON.stringify(values),
    });
  };

  var insertData = async (values) => {
    await fetchNoResponse("/plannedRuns", {
      method: "POST",
      body: JSON.stringify(values),
    });
  };

  var removeData = async (key) => {
    await fetchNoResponse("/plannedRuns/" + key, {
      method: "DELETE",
    });
  };

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
    update: updateData,
    insert: insertData,
    remove: removeData,
  });

  return (
    <div>
      <br />
      <h3>Planned Runs</h3>
      <Link
        to="/PlannedRunsCalendar"
        style={{
          position: "absolute",
          left: 500,
          top: 98,
          width: 400,
          height: 30,
        }}
      >
        Planned Runs Calendar...
      </Link>

      <DataGrid
        ref={grid}
        dataSource={customStore}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onEditorPreparing={(e) => {
          if (e.parentType === "dataRow" && e.dataField === "runName") {
            e.editorOptions.readOnly = e.row.data.groupID !== 0;
          }
        }}
        onToolbarPreparing={(e) => {
          let toolbarItems = e.toolbarOptions.items;
          // Adds a new item
          toolbarItems.push({
            widget: "dxButton",
            options: {
              icon: "refresh",
              hint: "reload data",
              onClick: function () {
                (grid.current as any).instance.refresh();
              },
            },
            location: "after",
          });

          toolbarItems.push({
            widget: "dxButton",
            options: {
              icon: "revert",
              hint: "reset formatting",
              onClick: function () {
                (grid.current as any).instance.state({});
              },
            },
            location: "after",
          });
        }}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="plannedRunsTable"
        />

        <HeaderFilter visible={true} />
        <FilterRow visible={true} />

        <GroupPanel visible={true} />

        <Export
          enabled={true}
          fileName={"PlannedRuns"}
          allowExportSelectedData={true}
        />

        <Editing
          mode="batch"
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />
        <ColumnChooser enabled={true} />

        <Paging defaultPageSize={40} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[20, 40, "all"]}
          showInfo={true}
          showNavigationButtons={true}
        />

        <Column
          dataField={"dateRequested"}
          dataType={"date"}
          format={"MMM dd yyyy HH:mm"}
          allowEditing={false}
        />
        <Column
          dataField={"startDate"}
          dataType={"date"}
          format={"MMM dd yyyy HH:mm"}
          allowEditing={false}
        />
        <Column dataField={"group"} allowEditing={false} />
        <Column dataField={"runName"} />
        <Column dataField={"r1Cycles"} />
        <Column dataField={"r2Cycles"} />
        <Column dataField={"i1Cycles"} />
        <Column dataField={"i2Cycles"} />
        <Column dataField={"kitSize"} allowEditing={false} />
        <Column dataField={"instrument"} allowEditing={false} />
        <Column dataField={"side"} allowEditing={false} />
        <Column dataField={"requester"} />
        <Column dataField={"description"} allowEditing={false} />
        <Column dataField={"library"} allowEditing={false} />
        <Column dataField={"libraryConcentration"} />
        <Column dataField={"tags"} allowEditing={false} />
        <Column dataField={"tileSelectionModule"} allowEditing={false} />
        <Column dataField={"OBPA"} allowEditing={false} />
        <Column dataField={"notificationEmails"} />
      </DataGrid>
    </div>
  );
};

export default PlannedRunsList;
