import { memo } from "react";
// import { TelemetryInstruments } from "./TelemetryInstruments";
// import { TelemetryRuns } from "./TelemetryRuns";
// import { TelemetryRunContainer } from "./TelemetryRunContainer";
// import { useLocation } from "react-router-dom";

// import "./styles.css";

// import { currentTheme, refreshTheme } from "devextreme/viz/themes";

export const TelemetryContainer = memo(() => {
  //   const [selectedInstrumentID, setSelectedInstrumentID] = useState(null);
  //   const [selectedRunID, setSelectedRunID] = useState(null);
  //   const [instrumentName, setInstrumentName] = useState(null);
  //   const [runName, setRunName] = useState(null);

  //   const location = useLocation();
  //   const params = new URLSearchParams(location.search);
  //   const tabIdx = params.get("t");
  //   const [tab, setTab] = useState(tabIdx ? parseInt(tabIdx) : 0);

  //   useEffect(() => {
  //     currentTheme("generic.dark");
  //     refreshTheme();
  //     document.body.style.backgroundColor = "rgb(41,46,50)";
  //     document.body.style.color = "lightgray";
  //     return () => {
  //       currentTheme("generic.light");
  //       refreshTheme();
  //     };
  //   }, []);

  return (
    <div>
      <h2>
        This page is depcrecated. Please visit{" "}
        <a href="https://telemetry.elembio.io">https://telemetry.elembio.io</a>
      </h2>
    </div>
  );
  // return (
  //   <div className="mydark" style={{ marginTop: 30, textAlign: "center" }}>
  //     <h2>AVITI Telemetry</h2>

  //     <div
  //       style={{
  //         display: "flex",
  //         marginTop: 30,
  //         color: "white",
  //         textAlign: "left",
  //       }}
  //     >
  //       <TelemetryInstruments
  //         instrumentSelected={useCallback(
  //           (id, name) => {
  //             window.history.pushState(
  //               null,
  //               null,
  //               "?i=" + name + "&r=" + null + "&t=" + tab
  //             );
  //             setSelectedRunID(null);
  //             setInstrumentName(name);
  //             setRunName(null);
  //             setSelectedInstrumentID(id);
  //           },
  //           [tab]
  //         )}
  //       />
  //       <TelemetryRuns
  //         instrumentID={selectedInstrumentID}
  //         runSelected={useCallback(
  //           (runid, runName) => {
  //             window.history.pushState(
  //               null,
  //               null,
  //               "?i=" + instrumentName + "&r=" + runName + "&t=" + tab
  //             );
  //             setSelectedRunID(runid);
  //             setRunName(runName);
  //           },
  //           [instrumentName, tab]
  //         )}
  //       />
  //       <TelemetryRunContainer
  //         selectedInstrumentID={selectedInstrumentID}
  //         selectedRunID={selectedRunID}
  //         defaultTab={tab}
  //         tabSelected={useCallback(
  //           (tab) => {
  //             window.history.pushState(
  //               null,
  //               null,
  //               "?i=" + instrumentName + "&r=" + runName + "&t=" + tab
  //             );
  //             setTab(tab);
  //           },
  //           [instrumentName, runName]
  //         )}
  //       />
  //     </div>
  //   </div>
  // );
});
