
import  React from "react"
import { withRouter } from "react-router"
import { NavigationBar } from './NavigationBar';


class AppContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            currentURL: "/"
        }
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            console.log("on route change: " + location.pathname);
            this.setState({currentURL: location.pathname})
        });
    }
    componentWillUnmount() {
        this.unlisten()
    }
    render() {
        return <div>
        <NavigationBar/>
        {this.props.children}
        <br/>
        <br/>
        </div>
    }
}

export default withRouter(AppContainer)
