import { PlannedRun } from "./constants";

export interface Variables {
  canvasHeight: number;
  canvasWidth: number;
  somethingChanged: boolean;
  bottom: number;
  highlightedRow: number;
  selectedRow: number;
  instruments: { name: string; id: number }[];
  runs: PlannedRun[];
  runBeingCoppied: boolean;
  copiedRun: PlannedRun;
  runBeingDraggedIdx: number;
  runForMouseOverIdx: number;
  deltaX: number;
  deltaY: number;
  backlogDeltaY: number;
  draggingVBacklogScroll: boolean;
  origBacklogDeltaY: number;
  origDeltaX: number;
  origDeltaY: number;
  pt: { x: number; y: number };
  orig: PlannedRun;
  origStart: Date;
  dragPos: { x: number; y: number };
  panning: boolean;
  draggingVScroll: boolean;
  draggingHScroll: boolean;
  popupVisible: boolean;
  selectedRun: PlannedRun;
  deleteButtonEnabled: boolean;
  isEditing: boolean;
  showOnlyMyRuns: boolean;
  currentUser: string;
}

const vars: Variables = {
  canvasHeight: window.innerHeight - 120,
  canvasWidth: window.innerWidth - 160,
  somethingChanged: false,
  bottom: 0,
  highlightedRow: -1,
  selectedRow: -1,
  instruments: [],
  runs: [],
  runBeingCoppied: false,
  copiedRun: null,
  runBeingDraggedIdx: -1,
  runForMouseOverIdx: -1,
  deltaX: 0,
  deltaY: 0,
  backlogDeltaY: 0,
  draggingVBacklogScroll: false,
  origBacklogDeltaY: 0,
  orig: null,
  origDeltaX: 0,
  origDeltaY: 0,
  pt: { x: -1, y: -1 },
  origStart: new Date(),
  dragPos: { x: 0, y: 0 },
  panning: false,
  draggingVScroll: false,
  draggingHScroll: false,
  popupVisible: false,
  selectedRun: null,
  deleteButtonEnabled: false,
  isEditing: false,
  showOnlyMyRuns: false,
  currentUser: '',
};

export default vars;
