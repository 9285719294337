import React, { useState } from "react"
import Tabs from 'devextreme-react/tabs';
import { GeneralQueryTable } from "./GeneralQueryTable";
import { GeneralQueryGraph } from "./GeneralQueryGraph";


const tabs = ["Table", "Graph"];

export const QueryResultsContainer = ({ data }) => {
    const [currentTab, setCurrentTab] = useState(0)

    console.log("insideQueryResults", data)
    return <React.Fragment>

        <Tabs dataSource={tabs}
            selectedIndex={currentTab}
            onOptionChanged={
                ({ name, value }) => {
                    if (name === "selectedIndex") {
                        setCurrentTab(value)
                    }
                }
            }

            width={"300px"} />
        <br />
        <div style={{display: (currentTab===0) ? "block" : "none"}}><GeneralQueryTable data={data} /> </div>
        <div style={{display: (currentTab===1) ? "block" : "none"}}><GeneralQueryGraph data={data} /></div>

    </React.Fragment>
}