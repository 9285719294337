import React, { useState, useEffect } from "react";
import "../../App.css";

import { useAuth0 } from "../../react-auth0-spa";
import styled from 'styled-components';
import { GenericHeatmap } from "../Reports/GenericHeatmap";

import { SelectBox } from 'devextreme-react';
import { CheckBox } from 'devextreme-react/check-box';
import { NumberBox } from 'devextreme-react/number-box';

const Styles = styled.div`
.flex-container {
    display: flex;
}

.flex-child {
    flex: 1;
}  

.flex-child:first-child {
    margin-right: 20px;
} 

.offset-title {
    margin-top: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
}

`

let labels = ["C->A", "C->G", "C->T", "A->C", "A->G", "A->T", "G->C", "G>-A", "G->T", "T->C", "T->A", "T->G"];

export const ConfusionMatrixHeatmap = props => {
    const id = props.id;
    const [data, setData] = useState(null)
    const [range, setRange] = useState({min:0, max: 100, auto: true})
    const [selectedTile, setSelectedTile] = useState(0);
    const { fetchWithCheck, loading } = useAuth0();


    useEffect(() => {
        const callApi = async () => {

            const tmp = await fetchWithCheck("/analyses/" + id + "/confusionMatrix?tileID=" + selectedTile)
            
            var maxv = 0, i;
            if (tmp && range.auto) {
                for (i = 0; i < tmp.length; i++) {
                    for (var j = 0; j < tmp[i].length; j++) {
                        if (tmp[i][j] > maxv) {
                            maxv = tmp[i][j];
                        }
                    }
                }
                setRange({auto: true, min:0, max:maxv})
            }
            setData(tmp)

        };
        if (!loading) {
            callApi();
        }
    }, [loading, fetchWithCheck, id, selectedTile, range.auto]);


    const valChanged = React.useCallback((e) => { setSelectedTile(e.value) }, [])

    var name = ""
    var i
    
    console.log(selectedTile)
    for (i=0; i<props.tiles.length; i++) {
        if (props.tiles[i].id === selectedTile) {
            name = props.tiles[i].name;
        }
    }


    return (
        <Styles>
            {data && (<React.Fragment>
            <div className="offset-title">Confusion Matrix</div>
        <table ><tr>
            <td>Tile to display: &nbsp; </td>
            <td>
                <SelectBox
                    value={selectedTile}
                    displayExpr="name"
                    valueExpr="id"
                    placeholder="Select a tile..."
                    showClearButton={false}
                    dataSource={props.tiles}
                    onValueChanged={valChanged}
                    style={{ 'width': '300px' }} />
            </td></tr><br/>

            <tr>
                <td>Scale:</td>
                <td>
                     <CheckBox value={range.auto} onValueChanged={(e)=>{setRange({...range,auto:e.value})}} text="Auto"/>
                

          <div className="dx-field">
            <div className="dx-field-label">Max:</div>
            <div className="dx-field-value">
              <NumberBox
                value={range.max.toFixed(2)}
                disabled={range.auto}
                onValueChanged={(e) => {setRange({...range, max: e.value})}}
                showSpinButtons={true}
              />
            </div>
          </div>


          <div className="dx-field">
            <div className="dx-field-label">Min:</div>
            <div className="dx-field-value">
              <NumberBox
                value={range.min.toFixed(2)}
                disabled={range.auto}
                onValueChanged={(e) => {setRange({...range, min: e.value})}}
                showSpinButtons={true}
              />
            </div>
          </div>
                     
                </td>
            </tr>
          </table>
<br/>
                <GenericHeatmap title={name + " Errors by Cycle"} data={data} rowlabels={labels} minv={range.min} maxv={range.max} tooltipprecision={1} hidelegend={false} scale={1} />
            </React.Fragment>)}
        </Styles>

    );
}
