import React, {useRef} from "react";
import { useAuth0 } from "../../react-auth0-spa";
import CustomStore from "devextreme/data/custom_store";
import SelectBox from 'devextreme-react/select-box';
import Button from 'devextreme-react/button';

import {  Label, SimpleItem, GroupItem, EmptyItem } from 'devextreme-react/form';
import DataGrid, {
  Editing,
  Column,
  Paging,
  ColumnChooser,
  Pager,
  StateStoring,
  GroupPanel,
  FilterRow,
  Form,
} from "devextreme-react/data-grid";

import { MySQLEditor } from "./MySQLEditor";

const plotOptions = ["Scatter", "Line", "Bee Swarm", "Bar"]

var allQueries = []

export const QueriesTable = () => {
  const theGrid = useRef(null)

  const { fetchWithCheck, fetchNoResponse, user } = useAuth0();

  var loadData = async () => {
    var tmp = await fetchWithCheck("/queries");
    allQueries = tmp
    return tmp
  };

  var updateData = async (key, values) => {
    var theQuery = null
    for (var i=0; i<allQueries.length; i++) {
      if (key===allQueries[i].id) {
        theQuery = allQueries[i]
        break
      }
    }
    if (theQuery) {
      if (theQuery.userEmail !== user.email) {
        alert("You cannot edit someone else's query")
        return
      }
    }
    await fetchNoResponse("/queries/"+key, {
        method: "PATCH",
        body: JSON.stringify(values)
    })
  }

  var insertData = async (values) => {
    await fetchNoResponse("/queries", {
        method: "POST",
        body: JSON.stringify(values)
    })
  }

  var removeData = async (key) => {
    await fetchNoResponse("/queries/"+key, {
        method: "DELETE"
    })
  }

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
    update: updateData,
    insert: insertData,
    remove: removeData
  });

  return (
    <React.Fragment>
      <span>Listed below are the queries that you have access to (either you created them or they are public).  You cannot edit or delete queries that you did not create.  Create a new query by presing the + button.</span>

      <Button onClick={() => { theGrid.current.instance.state({}) }} text="Reset Table Formatting" /> &nbsp;
     
      <DataGrid
        ref={theGrid}
        dataSource={customStore}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        height={"90%"}

        allowColumnReordering={true}
        columnHidingEnabled={true}
        columnResizingMode="widget"
        columnMinWidth={100}
        columnFixing={{ enabled: true }}
        selection={{ mode: "single" }}
        scrolling={{ showScrollbar: "always"}}
      >

        <StateStoring enabled={true} type="localStorage" storageKey="queriesTable" />
        <FilterRow visible={true} />
        <ColumnChooser enabled={true} />
        <GroupPanel visible={true} />

        <Editing
          mode="popup"
          allowAdding={true}
          allowDeleting={e=>e.row.data.userEmail===user.email}
          allowUpdating={true}
        >
          <Form>
            <SimpleItem dataField="name"/>
            <SimpleItem dataField="public"/>
            <SimpleItem colSpan={2} dataField="description"/>
            <SimpleItem colSpan={2} dataField="variables"  helpText="Comma-separated value names. RUN and RUNS are special names that show a runs selector"/>
            
            <EmptyItem colSpan={2}/>
            <GroupItem colCount={4}  colSpan={2} >
              <SimpleItem dataField="defaultPlot"/>
              <SimpleItem dataField="defaultX"/>
              <SimpleItem dataField="defaultY"/>
              <SimpleItem dataField="defaultGroup"/>
            </GroupItem>
            <EmptyItem colSpan={2}/>
            
            <SimpleItem  dataField="query" colSpan={2}  >
                <Label location="top" alignment="left" text= "Query"/>
            </SimpleItem>
          </Form>

        </Editing>


        <Column dataField={"name"} />
        <Column dataField={"userEmail"} allowEditing={false} caption="Owner"
        defaultSortOrder={"asc"}/>
        <Column dataField={"description"} visible={false}/>
        <Column dataField={"variables"} />

        <Column dataField="defaultPlot"
        editCellRender={cell => <SelectBox
          defaultValue={cell.value}
          dataSource={plotOptions}
          acceptCustomValue={false}
          searchEnabled={false}
          onValueChanged={e => cell.setValue(e.value)}
          itemRender={data => <span>{data}</span>}
          />}
        />
        <Column dataField="defaultX"/>
        <Column dataField="defaultY"/>
        <Column dataField="defaultGroup"/>
        <Column dataField={"public"} dataType="boolean"/>
        <Column dataField={"created"} allowEditing = {false} 
        dataType={"date"}
        format={"yyyy-MM-dd"}/>

        <Column dataField={"query"} visible={false}  editCellComponent={MySQLEditor}/>

        <Paging defaultPageSize={100} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[20, 40, 100, 200]}
        />
      </DataGrid>
    </React.Fragment>
  );
};
