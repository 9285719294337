import React, { useState } from "react";
import "../../App.css";
import { ChannelSubPlots } from "./ChannelSubPlots";
import { MultiFieldChart } from "./MultiFieldChart";
import { RadioGroup } from "devextreme-react";
import { DashboardContainer } from "./DashboardContainer";

export const ChannelPlotWithFieldOptions = (props) => {
  const id = props.id;
  const [currentField, setCurrentField] = useState(props.options[0].field);

  return (
    <div>
      <RadioGroup
        valueExpr="field"
        displayExpr="label"
        items={props.options}
        defaultValue={props.options[0].field}
        layout="horizontal"
        onValueChanged={(e) => {
          setCurrentField(e.value);
        }}
      />
      <br />
      <br />
      <DashboardContainer title={props.title}>
        <ChannelSubPlots
          id={id}
          field={currentField}
          runInfo={props.runInfo}
          yLabel={
            props.options.filter((x) => x.field === currentField)[0].label
          }
        />
        <MultiFieldChart
          id={id}
          field={currentField}
          groupField="channel"
          title={props.runInfo.name}
          runInfo={props.runInfo}
          yLabel={
            props.options.filter((x) => x.field === currentField)[0].label
          }
        />
      </DashboardContainer>
    </div>
  );
};
