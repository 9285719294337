import React, { useState, useEffect, useRef } from "react"
import { SelectBox } from 'devextreme-react/select-box';
import { RadioGroup } from 'devextreme-react';

import { BeeSwarmChart } from "./BeeSwarmChart";
import { ReportChart } from "./ReportChart";

const GraphTypeItems = [
    { label: "Scatter", val: "scatter" },
    { label: "Bee Swarm", val: "scatter" },
    { label: "Line", val: "line" },
    { label: "Bar", val: "bar" },
]


export const GeneralQueryGraph = ({ data }) => {
    const emptyField = { name: "", label: "" }
    const [xField, setXField] = useState(emptyField)
    const [yField, setYField] = useState(emptyField)
    const [groupField, setGroupField] = useState(emptyField)
    const [graphType, setGraphType] = useState(null)
    const xsb = useRef(null)
    const ysb = useRef(null)
    const gsb = useRef(null)

    useEffect(() => {
        let xv = data.fields.filter(x=>x.name===data.defaultX)
        if (xv.length === 1) setXField(xv[0])
        if (xsb && xsb.current)  xsb.current.instance.option("value",data.defaultX)

        let yv = data.fields.filter(x=>x.name===data.defaultY)
        if (yv.length === 1) setYField(yv[0])
        if (ysb && ysb.current)  ysb.current.instance.option("value",data.defaultY)

        let gv = data.fields.filter(x=>x.name===data.defaultGroup)
        if (gv.length === 1) setGroupField(gv[0])
        if (gsb && gsb.current)  gsb.current.instance.option("value",data.defaultGroup)
        
        let pv = GraphTypeItems.filter(x=>x.label===data.defaultPlot)
        if (pv.length === 1) setGraphType(pv[0])
    }, [data.fields, data.defaultX, xsb, ysb, gsb, data.defaultPlot,data.defaultY, data.defaultGroup])

    console.log("xfield",xField)
    return <React.Fragment>
        <div>

            <div style={{ display: "flex", marginBottom: "10px" }}>

                <div style={{ flex: 1 }}>Graph Type:</div>
                <div style={{ flex: 5 }}>
                    <RadioGroup items={GraphTypeItems} displayExpr="label" value={graphType} layout="horizontal" onValueChanged={(e) => { setGraphType(e.value) }} />
                </div>
                <div style={{ flex: 5 }} />
            </div>


            <div style={{ display: "flex", marginBottom: "2px" }}>
                <div style={{ flex: 1 }}>X Field:</div>
                <div style={{ flex: 5 }}>
                    <SelectBox
                        ref={xsb}
                        dataSource={data.fields}
                        placeholder="Select a field for the x axis"
                        valueExpr="name"
                        grouped={false}
                        displayExpr="label"
                        selectedItem={xField}
                        showClearButton={true}
                        onSelectionChanged={v => {
                            if (v.selectedItem) setXField(v.selectedItem)
                            else setXField(emptyField)
                        }}
                    />
                </div>
                <div style={{ flex: 5 }}>
                    
                </div>
            </div>


            <div style={{ display: "flex", marginBottom: "2px" }}>
                <div style={{ flex: 1 }}>Y Field:</div>
                <div style={{ flex: 5 }}>
                    <SelectBox
                        ref={ysb}
                        dataSource={data.fields}
                        placeholder="Select a field for the y axis"
                        valueExpr="name"
                        grouped={false}
                        displayExpr="label"
                        selectedItem={yField}
                        showClearButton={true}
                        onSelectionChanged={v => {
                            if (v.selectedItem) setYField(v.selectedItem)
                            else setYField(emptyField)
                        }}
                    />
                </div>
                <div style={{ flex: 5 }} />
            </div>


            <div style={{ display: "flex", marginBottom: "2px" }}>
                <div style={{ flex: 1 }}>Group Field:</div>
                <div style={{ flex: 5 }}>
                    <SelectBox
                        ref={gsb}
                        dataSource={data.fields}
                        placeholder="Select a field for grouping "
                        valueExpr="name"
                        grouped={false}
                        displayExpr="label"
                        selectedItem={groupField}
                        showClearButton={true}
                        onSelectionChanged={v => {
                            if (v.selectedItem) setGroupField(v.selectedItem)
                            else setGroupField(emptyField)
                        }}
                    />
                </div>
                <div style={{ flex: 5 }} />
            </div>

        </div>


        {graphType === GraphTypeItems[1] && (
                <div style={{width:"100%"}} >  
                  <BeeSwarmChart 
                    data = {data}
                    height = {600}
                    xField = {xField}
                    yField = {yField}
                    groupField = {groupField}
                />
                </div>
            )}

            {(graphType && graphType !== GraphTypeItems[1]) && (
                <div style={{width:"100%"}} >
                    <ReportChart 
                    data = {data}
                    height = {600}
                    xField = {xField}
                    yField = {yField}
                    graphType = {graphType}
                    groupField = {groupField}
                    />
                </div>
            )}
    </React.Fragment>
}

