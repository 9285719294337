import React, { useState, useEffect } from "react";
import { useAuth0 } from "./react-auth0-spa";
import { Link } from "react-router-dom";
import "./App.css";

import CustomStore from "devextreme/data/custom_store";

import DataGrid, {
  Column,
  GroupPanel,
  SearchPanel,
  HeaderFilter,
} from "devextreme-react/data-grid";

export const SearchContainer = () => {
  const getUrlVars = () => {
    var vars = {};
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  };
  const vars = getUrlVars();

  const searchTerm = decodeURI(vars["searchTerm"]);

  const { loading, fetchWithCheck } = useAuth0();
  const [results, setResults] = useState([]);

  useEffect(() => {
    const callApi = async () => {
      var tmp = await fetchWithCheck("/analysesSimple");

      for (var i = 0; i < tmp.length; i++) {
        tmp[i].analysisName =
          tmp[i].analysisName + " (v" + tmp[i].analysisVersion + ")";
      }

      setResults(tmp);
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck]);

  var loadData = () => {
    if (searchTerm.length === 0) {
      return [];
    }
    return results.filter((x) =>
      x.runName.toUpperCase().includes(searchTerm.toUpperCase())
    );
  };

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
  });

  const AnchorCell = (cellData) => {
    if (cellData.data.analysisVersion === "") return <div></div>;
    return (
      <React.Fragment>
        <Link
          to={
            "/runs/" +
            cellData.data.runName +
            "?v=" +
            cellData.data.analysisVersion
          }
        >
          {" "}
          {cellData.data.analysisName}
        </Link>
      </React.Fragment>
    );
  };

  const AnchorCellRunName = (cellData) => {
    return (
      <React.Fragment>
        <Link to={"/runs/" + cellData.data.runName}>
          {" "}
          {cellData.data.runName}
        </Link>
      </React.Fragment>
    );
  };

  const AnchorCellTelemetry = (cellData) => {
    return (
      <React.Fragment>
        <a
          href={
            "https://telemetry.elembio.io/#/runs?r=" +
            cellData.data.runID +
            "&t=0"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {cellData.data.runName}
        </a>
      </React.Fragment>
    );
  };

  return (
    <div>
      <br />
      <h2>Search Results</h2>
      <DataGrid
        dataSource={customStore}
        wordWrapEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
      >
        <SearchPanel visible={false} width={240} placeholder={"Search..."} />
        <GroupPanel visible={false} />
        <HeaderFilter visible={false} />

        <Column
          dataField={"runName"}
          allowEditing={false}
          cellRender={AnchorCellRunName}
        />
        <Column
          caption="Telemetry"
          dataField={"runName"}
          allowEditing={false}
          cellRender={AnchorCellTelemetry}
        />
        <Column
          dataField={"analysisName"}
          allowEditing={false}
          cellRender={AnchorCell}
        />
      </DataGrid>
    </div>
  );
};
