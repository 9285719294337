import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "../react-auth0-spa";
import Button from "devextreme-react/button";
import "../App.css";

import "devextreme-react/text-area";
import "devextreme-react/html-editor";
import DataGrid, {
  Column,
  ColumnChooser,
  Paging,
  Pager,
  GroupPanel,
  GroupItem,
  Summary,
  SearchPanel,
  Export,
  StateStoring,
  HeaderFilter,
} from "devextreme-react/data-grid";

export const StatusHistoryTable = React.memo((props) => {
  const grid = useRef();

  const [state, setState] = useState(null);
  const [reloadCounter, setReloadCounter] = useState(0);

  const { loading, fetchWithCheck } = useAuth0();

  useEffect(() => {
    const callApi = async () => {
      const tmp = await fetchWithCheck("/runs/" + props.name + "/history");
      if (tmp && tmp.length > 0) {
        var fields = [];
        for (var field of Object.keys(tmp[0])) {
          fields.push(field);
        }

        console.log(tmp);
        setState({ data: tmp, fields: fields });
      }
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, props.name, reloadCounter]);

  var columns = [];
  if (state) {
    columns = state.fields
      .filter((x) => x !== "id" && x !== "message")
      .map((x) =>
        x === "timestamp" ? (
          <Column
            dataField={x}
            dataType={"date"}
            format={"dd MMM yyyy HH:mm:ss"}
          />
        ) : (
          <Column dataField={x} />
        )
      );
  }

  return (
    <React.Fragment>
      <Button
        onClick={() => {
          grid.current.instance.state({});
        }}
        text="Reset Table Formatting"
      />
      &nbsp; &nbsp;
      <Button
        onClick={() => {
          setReloadCounter((v) => v + 1);
        }}
        text="Reload"
      />
      <br />
      <DataGrid
        ref={grid}
        dataSource={state ? state.data : []}
        keyExpr="id"
        wordWrapEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        focusedRowEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={"widget"}
        columnHidingEnabled={true}
        columnAutoWidth={true}
        hoverStateEnabled={false}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="historyTable"
        />
        <SearchPanel visible={true} width={240} placeholder={"Search..."} />

        <GroupPanel visible={true} />
        <Export
          enabled={true}
          fileName={"HistoryTable"}
          allowExportSelectedData={true}
        />
        <HeaderFilter visible={true} />

        <ColumnChooser enabled={true} />

        {columns}

        <Summary>
          <GroupItem
            column={"title"}
            summaryType={"count"}
            displayFormat={"{0}"}
          />
        </Summary>

        <Paging defaultPageSize={1000} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 1000]} />
      </DataGrid>
    </React.Fragment>
  );
});
