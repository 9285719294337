import React from "react";
import "./App.css";
import "devextreme/dist/css/dx.common.css";
//import 'devextreme/dist/css/dx.light.css';
import "devextreme/dist/css/dx.material.blue.light.compact.css";

import initFontAwesome from "./initFontAwesome";
import { Router, Route, Switch } from "react-router-dom";
import { Home } from "./Home";
import { Layout } from "./Layout";
import { NoMatch } from "./NoMatch";
import { FlowcellsForm } from "./components/Flowcells";
import { SequencingRunsForm } from "./components/SequencingRuns";
import { AnalysesForm } from "./components/Analyses";
import { FlowcellDetailsForm } from "./components/FlowcellDetails";
import { FlowcellReportForm } from "./components/FlowcellReport";
import AppContainer from "./AppContainer";
import { PrivateRoute } from "./components/PrivateRoute";
import Profile from "./components/Profile";
import history from "./utils/history";

import { useAuth0 } from "./react-auth0-spa";
import { ScatterPlot } from "./components/ScatterPlot";
import { TagsForm } from "./components/TagEditor";
import { SettingsForm } from "./components/Settings";
import { ErrorByCycle } from "./components/Reports/ErrorByCycle";
import { ReportContainer } from "./components/Reports/ReportContainer";
import { AnalysisDetailsForm } from "./components/AnalysisDetailsForm";
import { SearchContainer } from "./SearchContainer";
import { GeneralQueryForm } from "./components/Reports/GeneralQuery";
import { TestForm } from "./components/TestForm";
import { RunDashboard } from "./components/Dashboard/RunDashboard";
import { LibrariesForm } from "./components/LibrariesTable";
import { IGVForm } from "./components/IGV/myigv";
import { GrayscaleImage } from "./components/Images/GrayscaleImage";
import { InstrumentsForm } from "./components/Instruments";
import { RunsQualityMetrics } from "./components/RunsQualityMetrics";
import { QualityTrackingDashboard } from "./components/QualityTrackingDashboard";
import { RunTrackingDashboard } from "./components/RunTrackingDashboard";
import { PilotTrackingDashboard } from "./components/PilotTrackingDashboard";
import { ProductionTrackingDashboard } from "./components/ProductionTrackingDashboard";
import { BaselineTrackingDashboard } from "./components/BaselineTrackingDashboard";
import PlannedRunCalendar from "./views/PlannedRunsCalendar/PlannedRunCalendar";
import { PlannedRunsList } from "./views/PlannedRunsCalendar/PlannedRunsList";
import { TelemetryContainer } from "src/views/Telemetry/TelemetryContainer";
import { BetaTrackerTable } from "src/views/BetaTracker/BetaTrackerTable";

import { FunctionNavigator } from "./views/CustomRecipes/FunctionNavigator";
import {TelemetryRoles} from "./views/TelemetryRoles/TelemetryRoles";

initFontAwesome();

const App = () => {
  const { loading } = useAuth0();

  if (loading) {
    return <div className="spinner"></div>;
  }

  console.log("location", history.location);
  if (history.location.pathname.startsWith("/TelemetryNew")) {
    return (
      <Router history={history}>
        <div
          style={{
            paddingLeft: 100,
            paddingRight: 100,
          }}
        >
          <Switch>
            <PrivateRoute
              exact
              path="/TelemetryNew"
              component={TelemetryContainer}
            />
          </Switch>
        </div>
      </Router>
    );
  }

  if (history.location.pathname.includes("/FunctionNavigator"))
    return <FunctionNavigator />;

  return (
    <Router history={history}>
      <AppContainer>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <PrivateRoute
              exact
              path="/RunTracking"
              component={RunTrackingDashboard}
            />
            <PrivateRoute
              exact
              path="/QualityTracking"
              component={QualityTrackingDashboard}
            />
            <PrivateRoute
              exact
              path="/ProductionTracking"
              component={ProductionTrackingDashboard}
            />
            <PrivateRoute
              exact
              path="/BaselineTracking"
              component={BaselineTrackingDashboard}
            />
            <PrivateRoute
              exact
              path="/BetaTracker"
              component={BetaTrackerTable}
            />
            <PrivateRoute path="/search" component={SearchContainer} />
            <PrivateRoute exact path="/Settings" component={SettingsForm} />
            <PrivateRoute exact path="/Tags" component={TagsForm} />
            <PrivateRoute exact path="/Libraries" component={LibrariesForm} />
            <PrivateRoute exact path="/Flowcells" component={FlowcellsForm} />
            <PrivateRoute exact path="/Runs" component={SequencingRunsForm} />
            <PrivateRoute
              exact
              path="/RunQuality"
              component={RunsQualityMetrics}
            />

            <PrivateRoute exact path="/Runs/:name" component={RunDashboard} />
            <PrivateRoute exact path="/Analyses" component={AnalysesForm} />
            <PrivateRoute exact path="/Reports" component={ReportContainer} />
            <PrivateRoute
              exact
              path="/Analyses/:id/details/:name"
              component={AnalysisDetailsForm}
            />

            <PrivateRoute
              path="/Flowcells/:id"
              component={FlowcellDetailsForm}
            />
            <PrivateRoute exact path="/Reports/Plot" component={ScatterPlot} />
            <PrivateRoute
              exact
              path="/Reports/Flowcells"
              component={FlowcellReportForm}
            />
            <PrivateRoute
              exact
              path="/Reports/ErrorByCycle"
              component={ErrorByCycle}
            />

            <PrivateRoute
              exact
              path="/PlannedRunsCalendar"
              component={PlannedRunCalendar}
            />

            <PrivateRoute
              exact
              path="/PlannedRunsList"
              component={PlannedRunsList}
            />

            <PrivateRoute
              exact
              path="/PilotTracking"
              component={PilotTrackingDashboard}
            />

            <PrivateRoute
            exact
            path="/TelemetryRoles"
            component={TelemetryRoles}
            />

            <PrivateRoute exact path="/Query" component={GeneralQueryForm} />
            <PrivateRoute exact path="/Test" component={TestForm} />
            <PrivateRoute
              exact
              path="/Instruments"
              component={InstrumentsForm}
            />

            {/* <Route path="/Revs" component={RevsForm}/>
                <Route path="/PartNumbers" component={PartNumbersForm}/>
                <Route path="/Suppliers" component={SuppliersForm}/> */}
            <Route path="/profile" component={Profile} />
            <PrivateRoute exact path="/igv" component={IGVForm} />
            <PrivateRoute path="/image" component={GrayscaleImage} />
            <Route component={NoMatch} />
          </Switch>
        </Layout>
      </AppContainer>
    </Router>
  );
};

export default App;
