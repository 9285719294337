import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Link } from "react-router-dom";
import "../App.css";
import CustomStore from "devextreme/data/custom_store";
import "devextreme-react/text-area";
import "devextreme-react/html-editor";
import DataGrid, {
  Editing,
  Column,
  ColumnChooser,
  Paging,
  Pager,
  GroupPanel,
  GroupItem,
  Summary,
  SearchPanel,
  Export,
  HeaderFilter,
} from "devextreme-react/data-grid";

export const AnalysesForm = (props) => {
  const { fetchWithCheck } = useAuth0();

  var loadData = () => {
    return fetchWithCheck("/analyses");
  };

  var customStore = new CustomStore({
    key: "id",
    load: loadData,
    // update: updateData,
    // insert: insertData,
    // remove: removeData
  });

  const AnchorCell = (cellData) => {
    return (
      <React.Fragment>
        <Link to={"/analyses/" + cellData.data.id}>
          {" "}
          {cellData.data.runName}
        </Link>
      </React.Fragment>
    );
  };

  return (
    <div>
      <br />
      <h3>Analyses</h3>
      {/* {admin && (<Button variant="secondary" onClick={() => setShowModal(true)}>
            Upload Part Numbers...
          </Button>)} */}

      <DataGrid
        //onEditorPreparing={editorPreparing}
        dataSource={customStore}
        //onRowInserted={onRowInserted}
        wordWrapEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={"widget"}
        columnHidingEnabled={true}
        columnAutoWidth={true}
      >
        <SearchPanel visible={true} width={240} placeholder={"Search..."} />

        <GroupPanel visible={true} />
        <Export
          enabled={true}
          fileName={"SequencingRuns"}
          allowExportSelectedData={true}
        />
        <HeaderFilter visible={true} />

        <ColumnChooser enabled={true} />

        <Editing
          mode="form"
          allowDeleting={true}
          allowAdding={false}
          allowUpdating={false}
        />

        <Column
          dataField={"analysisName"}
          allowEditing={false}
          cellRender={AnchorCell}
        />
        <Column dataField={"analysisType"} />
        <Column dataField={"analysisVersion"} />
        <Column dataField={"analysisParams"} visible={false} />
        <Column dataField={"runName"} allowEditing={false} />
        <Column dataField={"flowcell"} allowEditing={false} />
        <Column dataField={"instrument"} allowEditing={false} />
        <Column
          dataField={"created"}
          dataType={"date"}
          format={"dd MMM yyyy HH:mm"}
          allowEditing={false}
        />
        <Column dataField={"analysisSW"} visible={false} />
        <Column dataField={"comment"} />

        {/* <Column
                    dataField={"id"}
                    caption={"Thumbnail"}
                >
                </Column> */}

        <Summary>
          <GroupItem
            column={"title"}
            summaryType={"count"}
            displayFormat={"{0}"}
          />
        </Summary>

        <Paging defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 20, 50, 100]}
        />
      </DataGrid>
    </div>
  );
};
