import React from "react";
import TabPanel from "devextreme-react/tab-panel";
import { PlannedRunsForm } from "./PlannedRunsForm";
import { InstrumentUsageForm } from "./InstrumentUsageForm";

export const InstrumentsForm = (props) => {
  const tabs = ["P2 Utilization", "BB2,P1 Utilization"];

  let defaultIndex = 0;
  var obj = localStorage.getItem("instrumentTab");
  if (obj) defaultIndex = obj;
  return (
    <React.Fragment>
      <br />
      <br />
      <TabPanel
        dataSource={tabs}
        defaultSelectedIndex={defaultIndex}
        onItemClick={(v) => {
          console.log(v);
          localStorage.setItem("instrumentTab", v.itemIndex);
        }}
        itemComponent={(v) => {
          if (v.index === 2) {
            return <PlannedRunsForm {...props} />;
          }
          if (v.index === 1) {
            return (
              <InstrumentUsageForm
                {...props}
                rowHeight={80}
                instrumentList={[
                  "P1-01",
                  "P1-02",
                  "P1-03",
                  "P1-04",
                  "P1-05",
                  "BB2V1",
                  "BB2V2",
                  "BB2V3",
                  "BB2V4",
                  "BB2V5",
                  "BB2V6",
                  "BB2V7",
                ]}
              />
            );
          }

          if (v.index === 0) {
            return (
              <InstrumentUsageForm
                {...props}
                rowHeight={80}
                instrumentList={[
                  //      "P2-01",
                  "P2-01A",
                  "P2-01B",
                  "P2-02A",
                  "P2-02B",
                  "P2-03A",
                  "P2-03B",
                  "P2-04A",
                  "P2-04B",
                  "P2-05A",
                  "P2-05B",
                  "P2-06A",
                  "P2-06B",
                  "P2-07A",
                  "P2-07B",
                  "P2-08A",
                  "P2-08B",
                  "P2-09A",
                  "P2-09B",
                  "P2-10A",
                  "P2-10B",
                ]}
              />
            );
          }
        }}
      />
    </React.Fragment>
  );
};
