import React, { useState, useEffect, useRef } from "react";
import Form, {
  SimpleItem,
  GroupItem,
  ButtonItem,
  EmptyItem,
} from "devextreme-react/form";
import styled from "styled-components";
import { useAuth0 } from "../../react-auth0-spa";
import { StatusHistoryTable } from "../StatusHistoryTable";
import Accordion from "devextreme-react/accordion";
import { ImageBrowser } from "../Images/ImageBrowser";
import { DriftCharts } from "./DriftCharts";

const Styles = styled.div`
  .first-group,
  .second-group {
    padding: 20px;
  }
  .second-group {
    background-color: rgba(191, 191, 191, 0.15);
  }
`;

// const htmlEditorOptions = {
//     height: 180,
//     toolbar: {
//         items: [
//             "undo", "redo", "separator",
//             {
//                 formatName: "size",
//                 formatValues: ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"] },
//             {
//                 formatName: "font",
//                 formatValues: ["Arial", "Courier New", "Georgia", "Impact", "Lucida Console", "Tahoma", "Times New Roman", "Verdana"]
//             },
//             "separator", "bold", "italic", "strike", "underline", "separator",
//             "alignLeft", "alignCenter", "alignRight", "alignJustify", "separator",
//             "orderedList", "bulletList", "separator",
//             {
//                 formatName: "header",
//                 formatValues: [false, 1, 2, 3, 4, 5]
//             }, "separator",
//             "color", "background", "separator",
//             "link", //"image",
//             "separator",
//             "clear", "codeBlock", "blockquote"
//         ]
//     } } ;

export const RunInfoTable = (props) => {
  const accordion = useRef(null);
  const { fetchNoResponse, fetchWithCheck, loading } = useAuth0();
  const [editedFields, setEditedFields] = useState({});
  const [lookups, setLookups] = useState({
    tags: null,
    errorModes: null,
    runStatuses: null,
  });

  const data = props.data;

  data.runFolder = "s3://" + data.bucket + "/" + data.runRoot;

  console.log("data", data);
  useEffect(() => {
    const callApi = async () => {
      const tags = await fetchWithCheck("/tags");
      const errorModes = await fetchWithCheck("/errorModes");
      const runStatuses = await fetchWithCheck("/runCompleteStatus");
      const libs = await fetchWithCheck("/libraries");
      setLookups({
        tags: tags,
        errorModes: errorModes,
        runStatuses: runStatuses,
        libraries: libs,
      });
    };
    if (!loading) {
      callApi();
    }
  }, [loading, fetchWithCheck, props.id]);

  // const updateFlowcellDB = async (e) => {
  //   if (Object.keys(editedFields).length === 0) {
  //     return;
  //   }
  //   await fetchNoResponse("/flowcells/" + data.flowcellID, {
  //     method: "PATCH",
  //     body: JSON.stringify(editedFields),
  //   });
  //   setEditedFields({});
  // };

  const updateDB = async (e) => {
    if (Object.keys(editedFields).length === 0) {
      return;
    }
    await fetchNoResponse("/runs/" + data.id, {
      method: "PATCH",
      body: JSON.stringify(editedFields),
    });
    setEditedFields({});
  };

  const fieldDataChanged = (e) => {
    var tmp = editedFields;
    editedFields[e.dataField] = e.value;
    setEditedFields(tmp);
  };

  // const cellRenderFlowcell = (e) => {
  //   if (!e.editorOptions.value) {
  //     return;
  //   }
  //   return (
  //     <Link to={"/flowcells/" + props.data.flowcellID}>
  //       {e.editorOptions.value}
  //     </Link>
  //   );
  // };

  const cellRenderLink = (e) => {
    if (!e.editorOptions.value) {
      return;
    }
    return (
      <a href={e.editorOptions.value} target="_blank" rel="noopener noreferrer">
        {e.editorOptions.value}
      </a>
    );
  };

  const cellRenderTelemetryLink = (e) => {
    if (!e.editorOptions.value) {
      return;
    }
    const url =
      "https://telemetry.elembio.io/#/runs?r=" + e.editorOptions.value + "&t=0";
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  };

  const statusHistory = (
    <div title="Status History">
      <StatusHistoryTable name={props.name} />
    </div>
  );

  const editableForm = (
    <div title="Run Details">
      <Form
        id="form"
        formData={data}
        readOnly={false}
        showColonAfterLabel={true}
        labelLocation={"left"}
        minColWidth={300}
        colCount={6}
        onFieldDataChanged={fieldDataChanged}
      >
        <GroupItem cssClass="first-group" colCount={2} colSpan={4}>
          <SimpleItem
            dataField="date"
            editorType="dxDateBox"
            editorOptions={{ readOnly: true }}
          />
          <SimpleItem
            dataField="instrument"
            editorOptions={{ readOnly: true }}
          />
          <SimpleItem
            dataField="requester"
            editorOptions={{ readOnly: true }}
          />
          <SimpleItem dataField="operator" editorOptions={{ readOnly: true }} />
          <SimpleItem
            label={{ text: "DB ID" }}
            dataField="id"
            editorOptions={{ readOnly: true }}
          />
          <SimpleItem
            label={{ text: "UUID" }}
            dataField="uuid"
            editorOptions={{ readOnly: true }}
          />
          <SimpleItem dataField="numTiles" editorOptions={{ readOnly: true }} />
          <SimpleItem
            dataField="runFolder"
            editorOptions={{ readOnly: true }}
          />
          <SimpleItem
            label={{ text: "Control SW Version" }}
            dataField="controlSWVersion"
            editorOptions={{ readOnly: true }}
          />
          <SimpleItem
            label={{ text: "Jira URL" }}
            render={cellRenderLink}
            dataField="jiraURL"
            editorOptions={{ readOnly: true }}
          />
        </GroupItem>
        <GroupItem cssClass="first-group" colCount={1} colSpan={2}>
          <SimpleItem
            dataField="numCyclesR1"
            editorOptions={{ readOnly: true }}
          />
          <SimpleItem
            dataField="numCyclesR2"
            editorOptions={{ readOnly: true }}
          />
          <SimpleItem
            dataField="numCyclesI1"
            editorOptions={{ readOnly: true }}
          />
          <SimpleItem
            dataField="numCyclesI2"
            editorOptions={{ readOnly: true }}
          />
          <SimpleItem
            label={{ text: "Telemetry" }}
            render={cellRenderTelemetryLink}
            dataField="uuid"
            editorOptions={{ readOnly: true }}
          />
        </GroupItem>
        <GroupItem cssClass="first-group" colCount={3} colSpan={6}>
          <SimpleItem
            dataField="l1LibraryID"
            label={{ text: "Library" }}
            colSpan={2}
            editorType="dxSelectBox"
            editorOptions={{
              displayExpr: "name",
              valueExpr: "id",
              items: lookups.libraries,
              readOnly: true,
            }}
          />
          <SimpleItem
            dataField="l1LibraryConcentration"
            editorOptions={{ readOnly: true }}
          />

          <SimpleItem
            dataField="l1SpikeInLibraryID"
            label={{ text: "Spike-in Library" }}
            colSpan={2}
            editorType="dxSelectBox"
            editorOptions={{
              displayExpr: "name",
              valueExpr: "id",
              items: lookups.libraries,
              readOnly: true,
            }}
          />
          <SimpleItem
            dataField="l1SpikeInLibraryConcentration"
            editorOptions={{ readOnly: true }}
          />

          <SimpleItem
            dataField="libraryDilutionLot"
            editorOptions={{ readOnly: true }}
          />
          <SimpleItem
            dataField="libraryMixStockLot"
            caption="Library Mix Stock Lot"
            editorOptions={{ readOnly: true }}
          />
          <EmptyItem />
          <EmptyItem />
        </GroupItem>

        <GroupItem
          cssClass="second-group"
          colCount={3}
          caption="Editable Run Information"
          colSpan={6}
        >
          {/* <SimpleItem dataField="chemistry" editorOptions={{ readOnly: false }} /> */}

          <SimpleItem
            dataField="runDescription"
            editorOptions={{ readOnly: false }}
            colSpan={2}
          />
          <SimpleItem
            dataField="readOrder"
            editorType="dxSelectBox"
            editorOptions={{
              items: ["R1,I1,I2,R2", "I1,I2,R1,R2", "R1,R2,I1,I2"],
            }}
            colSpan={1}
          />
          <SimpleItem
            dataField="tags"
            colSpan={3}
            editorType="dxTagBox"
            editorOptions={{ items: lookups.tags }}
          />
          <SimpleItem
            dataField="runStatusID"
            label={{ text: "Completion Status" }}
            colSpan={1}
            editorType="dxSelectBox"
            editorOptions={{
              items: lookups.runStatuses,
              displayExpr: "status",
              valueExpr: "id",
            }}
          />
          <SimpleItem
            dataField="errorModeID"
            label={{ text: "Error Mode" }}
            colSpan={1}
            editorType="dxSelectBox"
            editorOptions={{
              items: lookups.errorModes,
              displayExpr: "errorMode",
              valueExpr: "id",
            }}
          />
          <SimpleItem
            dataField="side"
            label={{ text: "Instrument Side" }}
            colSpan={1}
            editorType="dxSelectBox"
            editorOptions={{
              items: ["", "A", "B"],
            }}
          />
          <SimpleItem dataField="comment" colSpan={3} editorType="dxTextBox" />

          <ButtonItem
            horizontalAlignment="left"
            buttonOptions={{
              text: "Save Changes to Run",
              type: "success",
              onClick: updateDB,
            }}
          />
        </GroupItem>
        {/* <GroupItem cssClass="first-group"  colCount={1} colSpan={1} visible={true}>
                    <SimpleItem dataField="summary" colSpan={1} editorType="dxHtmlEditor" editorOptions={htmlEditorOptions}/>
                </GroupItem> */}

        <EmptyItem colSpan={6} />
      </Form>
    </div>
  );

  var items = [];
  items.push(editableForm);

  items.push(
    <DriftCharts
      title="Real-time Stats"
      runName={data.name}
      runInfo={props.runInfo}
    />
  );

  items.push(statusHistory);
  items.push(
    <ImageBrowser
      reads={data.reads}
      title="Full Images"
      id={data.id}
      runRoot={data.runRoot}
      numCyclesR1={data.numCyclesR1}
      numCyclesR2={data.numCyclesR2}
    />
  );

  return (
    <Styles>
      {!loading && (
        <React.Fragment>
          <Accordion
            ref={accordion}
            collapsible={true}
            multiple={true}
            items={items}
            itemRender={(item) => item}
            itemTitleRender={(item) => (
              <small>
                <center>&nbsp;&nbsp;{item.props.title}</center>
              </small>
            )}
          />

          <br />
        </React.Fragment>
      )}
    </Styles>
  );
};
