import React from "react";
import { Link, withRouter } from "react-router-dom";
import DropDownButton from "devextreme-react/drop-down-button";
import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";
import logoImage from "./assets/elogo.png";
import { Button } from "reactstrap";
import { useAuth0 } from "./react-auth0-spa";

const buttonDropDownOptions = { width: 230 };
const downloads = [
  { label: "Production", value: "/ProductionTracking" },
  { label: "Baseline", value: "/BaselineTracking" },
  { label: "Beta", value: "/BetaTracker" },
  { label: "Pilot", value: "/PilotTracking" },
  { label: "Quality", value: "/QualityTracking" },
  { label: "Dev Run", value: "/RunTracking" },
];

const Styles = styled.div`
  .dx-button-content .dx-button-text {
    color: #bbb;
    font-weight: 400;
    font-size: 13px;
    font-family: Roboto, RobotoFallback, "Noto Kufi Arabic", Helvetica, Arial,
      sans-serif;
    text-transform: none;
  }
  .navbar {
    background-color: #000;
  }

  .navbar-default .navbar-toggle {
    border-color: transparent; /* Removes border color */
    float: left; /* Move navbar toggle to left */
  }
  .navbar-default .navbar-toggle .icon-bar {
    background-color: #f00; /* Changes regular toggle color */
  }
  .navbar-default .navbar-toggle .icon-bar:hover {
    background-color: #fff; /* Changes toggle color on hover */
  }

  a,
  .navbar-brand,
  .navbar-nav .nav-link {
    color: #bbb;

    &:hover {
      color: white;
    }
  }

  .navbar .container {
    position: relative;
  }

  .navbar-inner {
    padding: 7px 0;

    background: #292929;

    border-bottom: 1px solid #121212;

    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  .navbar-fixed-top {
    position: static;
  }

  .navbar .nav a {
    font-size: 11px;
  }

  .navbar .brand {
    font-weight: 600;
    position: relative;
    top: 2px;
  }

  .navbar .brand span {
    color: #ff9900;
  }

  .navbar .search-query {
    background-color: #444;
    width: 150px;
    font-size: 11px;
    font-weight: bold;
  }

  .navbar .search-query::-webkit-input-placeholder {
    color: #666;
  }

  .navbar .search-query:-moz-placeholder {
    color: #666;
  }

  .search-query {
    color: #eee;
  }
`;

export const NavigationBar = withRouter((props) => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const history = props.history;

  console.log(isAuthenticated, loginWithRedirect, logout);

  return (
    <Styles>
      <Navbar expand="lg">
        <Link to="/">
          <img src={logoImage} alt="SeqDB Logo" height={36} />{" "}
        </Link>
        &nbsp;
        {/* <Navbar.Brand href="/">nanoERP</Navbar.Brand> */}
        {isAuthenticated && (
          <React.Fragment>
            <Navbar.Toggle className="navbar-dark" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Item>
                  <div
                    className="navbar-search"
                    style={{ paddingTop: "6px", float: "right" }}
                  >
                    <div
                      style={{
                        paddingTop: "2px",
                        color: "#AAAAAA",
                        float: "left",
                      }}
                    >
                      search runs:&nbsp;
                    </div>
                    <input
                      type="text"
                      className="search-query"
                      placeholder="run name"
                      name="searchTerm"
                      onChange={(e) => {
                        e.persist();
                        props.history.push(
                          "/search?searchTerm=" + encodeURI(e.target.value)
                        );
                      }}
                    />
                  </div>
                </Nav.Item>
                <Nav.Item>
                  <div style={{ marginLeft: "20px" }}></div>
                </Nav.Item>
                <Nav.Item>
                  <DropDownButton
                    text="Trackers"
                    dropDownOptions={buttonDropDownOptions}
                    displayExpr="label"
                    items={downloads}
                    onItemClick={(e) => {
                      history.push(e.itemData.value);
                    }}
                    //  onItemClick={this.onItemClick}
                  />
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/PlannedRunsCalendar">Planning</Link>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/Runs">Runs</Link>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/Instruments">Instruments</Link>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/Flowcells">Flowcells</Link>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/Libraries">Libraries</Link>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/Reports">Reports</Link>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/Settings">Settings</Link>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </React.Fragment>
        )}
        &nbsp; &nbsp;
        {isAuthenticated && (
          <div
            style={{
              color: "#FF0",
              fontSize: "8",
              borderLeft: "10",
              borderRight: "10",
            }}
          >
            {localStorage.getItem("user")}
            &nbsp;
            <Button
              onClick={(e) => {
                logout();
                // window.location.assign("/login")
              }}
            >
              Logout
            </Button>
          </div>
        )}
        {!isAuthenticated && (
          <Button
            className="ml-auto"
            onClick={(e) => {
              loginWithRedirect();
            }}
          >
            Login
          </Button>
        )}
      </Navbar>
    </Styles>
  );
});

/*
import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
  .navbar {
    background-color: #222;
  }
  a, .navbar-brand, .navbar-nav .nav-link {
    color: #bbb;
    &:hover {
      color: white;
    }
  }
`;


export const NavigationBar = () => (
  <Styles>
    <Navbar expand="lg">
      <Navbar.Brand href="/">Code Life</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item>
            <Nav.Link>
              <Link to="/">Home</Link>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link>
              <Link to="/about">About</Link>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link>
              <Link to="/contact">Contact</Link>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Styles >
)
*/
