import React, { useState, useEffect } from "react";
import "../../App.css";
import { useAuth0 } from "../../react-auth0-spa";
import { FlowcellHeatmap } from "../Reports/FlowcellHeatmap";
import { SelectBox } from 'devextreme-react';


import styled from 'styled-components';


const Styles = styled.div`
.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}

.grid-child {
    height: 830px;
}
`

export const ThumbnailBrowser = React.memo((props) => {
    const id = props.id;
    const reads = props.reads;
    const [state, setState] = useState({ thumbnails: [] })
    const [heatmapData, setHeatmapData] = useState([])
    const [selectedImage, setSelectedImage] = useState({avid:"", wash:""})
    const [cycle, setCycle] = useState(1)
    const [read, setRead] = useState("R1")
    const [surface, setSurface] = useState("S1")
    const [currentTile, setCurrentTile] = useState("")

    const { fetchWithCheck, loading } = useAuth0();

    useEffect(() => {
        const callApi = async () => {
            var str = currentTile.replace("S1", surface);
            var i;
            for (i = 0; i < state.thumbnails.length; i++) {
                if (state.thumbnails[i].tile === str && state.thumbnails[i].cycle === cycle && state.thumbnails[i].read === read) {
                    var tmp = await fetchWithCheck("/thumbnail/" + state.thumbnails[i].id)
                    console.log(tmp)
                    setSelectedImage({avid: tmp.imageStr, wash: tmp.washImageStr});
                    break;
                }
            }
        }
        callApi();
    }, [fetchWithCheck, state.thumbnails, cycle, surface, currentTile, read])

    useEffect(() => {
        var data={}
        for (var i = 0; i < state.thumbnails.length; i++) {
            const tmp = state.thumbnails[i]
            if (tmp.cycle!==cycle || tmp.read!==read) continue
    //        var s = tmp.tile.substring(tmp.tile.length - 2);
   //         if (s!==surface)  continue
            data[tmp.tile] = tmp.hasAvidImage + tmp.hasWashImage;
        }
        setHeatmapData(data)
        console.log(data)
    }, [state.thumbnails, cycle, read, surface])

    const mouseDownHandler = React.useCallback(async (label) => {
        setCurrentTile(label)
    }, [])

    const onCycleChanged = (e) => {
        setCycle(e.value)
    }

    const onSurfaceChanged = (e) => {
        setSurface(e.value)
    }

    useEffect(() => {
        const callApi = async () => {
            var cycles = [];
            var surfaces = ["S1"];

            const tmp = await fetchWithCheck("/analyses/" + id + "/thumbnails")
            if (tmp) {
                var i;
                for (i = 0; i < tmp.length; i++) {
                    if (tmp[i].tile.startsWith("A")) {
                        tmp[i].tile = "L1R02C0" + tmp[i].tile.substring(1, 2) + "S1";
                    }
                    cycles.push(tmp[i].cycle);
                    surfaces.push(tmp[i].tile.substring(tmp[i].tile.length - 2))
                }
                cycles = [...new Set(cycles)];//.sort(); 
                surfaces = [...new Set(surfaces)];//.sort();

                setState({ thumbnails: tmp, cycles: cycles, surfaces: surfaces })
            }
        };
        if (!loading) {
            callApi();
        }
    }, [loading, fetchWithCheck, id]);


    return (
        <Styles>
            <span>Select a cycle and surface and then click on a blue tile to view its thumbnail</span><br/><br/>

            <div className="grid-container" style={{ backgroundColor: "#FFFFFF" }}>
                <div className="grid-child">

                Read:
            <SelectBox
                        value={read}
                        placeholder="Select a read..."
                        showClearButton={false}
                        dataSource={reads.split(",")}
                        onValueChanged={(v)=>{setRead(v.value)}}
                        style={{ 'width': '300px' }}
                    />
                    Cycle:
            <SelectBox
                        value={cycle}
                        placeholder="Select a cycle..."
                        showClearButton={false}
                        dataSource={state.cycles}
                        onValueChanged={onCycleChanged}
                        style={{ 'width': '300px' }}
                    />
            Surface:
            <SelectBox
                        value={surface}
                        placeholder="Select a surface..."
                        showClearButton={false}
                        dataSource={state.surfaces}
                        onValueChanged={onSurfaceChanged}
                        style={{ 'width': '300px' }}
                    />
                    <br />
            <FlowcellHeatmap
                data={heatmapData}
                surface="1"
                minv="0"
                maxv="4"
                title="Select Tile"
                tooltipprecision="0"
                hidelegend="true"
                scale="8"
                mousedownhandler={mouseDownHandler}
                selectedtile={currentTile}
            />
                </div>

                <div className="grid-child">
                    <img src={selectedImage.avid} alt={selectedImage.avid} />
                    <img src={selectedImage.wash} alt={selectedImage.wash} />
                </div>
            </div>
            <br/><br/><br/>
        </Styles>

    );
}
)